import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ChatService } from 'src/app/service/chat.service';

@Injectable({
  providedIn: 'root'
})
export class JoinedChatUserGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private router: Router
  ) { }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const chatRoomId = +childRoute.params.id;
    const user = await this.authService.getCurrentUser().toPromise();
    const resp = await this.chatService.isJoinedChatRoom(chatRoomId, user.id).toPromise();
    if (resp.isJoined) {
      return true
    }
    this.router.navigate(['/tabs/chat']);
    return false;
  }
}