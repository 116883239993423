import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Feed, FeedType } from 'src/app/model/feed';
import { Influencer } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { FeedService } from 'src/app/service/feed.service';
import { ProductService } from 'src/app/service/product.service';
import { UserService } from 'src/app/service/user.service';
import { Product, ProductStatus, Section, SectionType } from './../../model/product';

@Component({
  selector: 'section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss'],
})
export class SectionListComponent implements OnInit {
  @Input() sections: Section[];
  feeds: Feed[] = [];
  SectionType = SectionType;
  sliderConfig = {
    freeMode: false,
    observer: true,
    observeParents: true,
    initialSlide: 0,
    slidesPerView: 1.2,
    spaceBetween: 0
  };
  feedSliderConfig = {
    freeMode: false,
    observer: true,
    observeParents: true,
    initialSlide: 0,
    slidesPerView: 1.2,
    spaceBetween: 20
  };
  influencers: Influencer[];
  lightProducts: Product[];

  categorySelectedMenu = 'lodge';
  lodgeProducts: Product[];
  onedayProducts: Product[];
  learnProducts: Product[];
  natureProducts: Product[];

  accompanySelectedMenu = 'friends';
  friendsProducts: Product[];
  familyProducts: Product[];
  childrenProducts: Product[];
  petProducts: Product[];

  constructor(
    private feedService: FeedService,
    private productService: ProductService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.feedService.searchRefeined({ page: 1, limit: 12, hasPhoto: true, types: [FeedType.REVIEW] }).subscribe(resp => {
      this.feeds = resp.items;
    })
    this.authService.getCurrentUser().subscribe(user => {
      this.productService.search({ userId: user?.id, page: 1, limit: 16, status: ProductStatus.ENTERED, analysisHashtags: ['벙개모임'], orderBy: 'createdAt' }).subscribe(resp => this.lightProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 8, status: ProductStatus.ENTERED, categoryIds: [2] }).subscribe(resp => this.lodgeProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 8, status: ProductStatus.ENTERED, categoryIds: [3] }).subscribe(resp => this.onedayProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 8, status: ProductStatus.ENTERED, categoryIds: [7] }).subscribe(resp => this.learnProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 8, status: ProductStatus.ENTERED, categoryIds: [6] }).subscribe(resp => this.natureProducts = resp.items);

      this.productService.search({ userId: user?.id, page: 1, limit: 9999, status: ProductStatus.ENTERED, analysisHashtags: ['특성_친구들과'] }).subscribe(resp => this.friendsProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 9999, status: ProductStatus.ENTERED, analysisHashtags: ['특성_친정식구'] }).subscribe(resp => this.familyProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 9999, status: ProductStatus.ENTERED, analysisHashtags: ['특성_자녀들과'] }).subscribe(resp => this.childrenProducts = resp.items);
      this.productService.search({ userId: user?.id, page: 1, limit: 9999, status: ProductStatus.ENTERED, analysisHashtags: ['특성_애견동반'] }).subscribe(resp => this.petProducts = resp.items);
    })
    this.userService.searchInfluencers({ page: 1, limit: 999 }).subscribe(resp => {
      this.influencers = resp.items;
    })
  }

  goToTheme(hashtag: string) {
    this.router.navigate(['/tabs/theme', hashtag])
  }

  selectCategoryFilter(key: string) {
    this.categorySelectedMenu = key;
  }

  selectAccompanyFilter(key: string) {
    this.accompanySelectedMenu = key;
  }
}
