import { Component, OnInit } from '@angular/core';
import { CheckDesktopService } from 'src/app/service/check-desktop.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {
  isDesktop = false;

  constructor(
    private cds: CheckDesktopService,
  ) { }

  ngOnInit() {
    this.cds.setIsDesktop(window.innerWidth);
    this.cds.isDesktop.subscribe(resp => this.isDesktop = resp);
  }
}
