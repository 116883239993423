import { Directive, HostListener, Input } from '@angular/core'
import { Router } from '@angular/router'

@Directive({
  selector: '[link]'
})
export class LinkDirective {
  @Input() link: string

  // ctrl+click, cmd+click
  @HostListener('click', ['$event'])
  onClick($event) {
    if ($event.ctrlKey || $event.metaKey) {
      $event.preventDefault()
      $event.stopPropagation()
      window.open(this.link, '_blank')
    } else if (this.link.includes('http')) {
      window.open(this.link, '_blank')
    } else {
      this.router.navigate([this.link])
    }
  }

  constructor(private router: Router) { }
}