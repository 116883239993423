<div *ngIf="!isDesktop" style="margin-bottom:16px">
  <button class="nnb-btn-block nnb-btn-sm nnb-btn-secondary" (click)="showMenu()">
    <span *ngIf="!isShowMenu">목록 보기</span>
    <span *ngIf="isShowMenu">목록 숨기기</span>
  </button>
</div>
<div class="row">
  <!-- 상품 속성 메뉴 -->
  <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" [ngStyle]="{
      'visibility':isShowMenu || isDesktop?'visible':'hidden',
       'position':isShowMenu?'block':'inline-block',
       'height':isShowMenu?'auto':'0px'}" class="step-col">
    <div class="step" data-target="#test-l-1">
      <button class="trigger"
        [ngStyle]="{'color': formGroup.controls.title.valid && formGroup.controls.hashtags.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.title.valid && formGroup.controls.hashtags.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        상품 제목
      </button>
    </div>
    <div class="step" data-target="#test-l-2">
      <button class="trigger" [ngStyle]="{'color': formGroup.controls.photos.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.photos.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        대표 이미지
      </button>
    </div>
    <div class="step" data-target="#test-l-3">
      <button class="trigger" (click)="dispatch()" [ngStyle]="{'color': formGroup.controls.address.valid && 
      formGroup.controls.detailAddress.valid && 
      formGroup.controls.howToCome.valid && 
      formGroup.controls.runningDays.valid &&
      formGroup.controls.runningHours.valid &&
      formGroup.controls.runningMinutes.valid 
      ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.address.valid && 
          formGroup.controls.detailAddress.valid && 
          formGroup.controls.howToCome.valid && 
          formGroup.controls.runningDays.valid &&
          formGroup.controls.runningHours.valid &&
          formGroup.controls.runningMinutes.valid 
          ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        장소/소요시간
      </button>
    </div>
    <div class="step" data-target="#test-l-4">
      <button class="trigger"
        [ngStyle]="{'color': formGroup.controls.price.valid && formGroup.controls.discountPrice.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.price.valid && formGroup.controls.discountPrice.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        가격
      </button>
    </div>
    <div class="step" data-target="#test-l-5">
      <button class="trigger" [ngStyle]="{'color': (this.formGroup.value.version === 1 ?
      this.formGroup.controls.point.valid &&
      this.formGroup.controls.recommend.valid &&
      this.formGroup.controls.description.valid &&
      this.formGroup.controls.courses.valid :
      this.formGroup.controls.recommend.valid &&
      this.formGroup.controls.description.valid &&
      this.formGroup.controls.summaries.valid &&
      this.formGroup.controls.sections.valid &&
      this.formGroup.controls.courses.valid) ? '#8E46EC':''}">
        <img [src]="(this.formGroup.value.version === 1 ?
        this.formGroup.controls.point.valid &&
        this.formGroup.controls.recommend.valid &&
        this.formGroup.controls.description.valid &&
        this.formGroup.controls.courses.valid :
        this.formGroup.controls.recommend.valid &&
        this.formGroup.controls.description.valid &&
        this.formGroup.controls.summaries.valid &&
        this.formGroup.controls.sections.valid &&
        this.formGroup.controls.courses.valid) ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':
          'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        소개
      </button>
    </div>
    <div class="step" data-target="#test-l-6">
      <button class="trigger"
        [ngStyle]="{'color': formGroup.controls.checkList.valid && formGroup.controls.includeList.valid && formGroup.controls.excludeList.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.checkList.valid && formGroup.controls.includeList.valid && formGroup.controls.excludeList.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        준비물
      </button>
    </div>
    <div class="step" data-target="#test-l-7">
      <button class="trigger" [ngStyle]="{'color': formGroup.controls.notice.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.notice.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        유의사항
      </button>
    </div>
    <div class="step" data-target="#test-l-8">
      <button class="trigger" [ngStyle]="{'color': formGroup.controls.refundPolicy.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.controls.refundPolicy.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        환불 정책
      </button>
    </div>
    <div class="step" data-target="#test-l-9">
      <button class="trigger" (click)="lastCheck()" [disabled]="formGroup.invalid"
        [ngStyle]="{'cursor':formGroup.invalid ? 'not-allowed':'pointer'}"
        [ngStyle]="{'color': formGroup.valid ? '#8E46EC':''}">
        <img
          [src]="formGroup.valid ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg'">
        최종 확인
      </button>
    </div>
  </div>
  <!-- 상품 속성 편집 -->
  <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
    <!-- <div class="bs-stepper-content" [ngStyle]="{'padding': isDesktop ? '0px 400px 0px 20px': ''}"> -->
    <div class="bs-stepper-content">
      <form [formGroup]="formGroup">
        <!-- 상품제목 -->
        <div id="test-l-1" class="content">
          <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          <notion-desc [description]="descriptions[0]"></notion-desc>
          <form-input [name]="'상품 제목'" [controlName]="'title'" [group]="formGroup" [maxLength]="30"
            [errors]="[{key:'maxlength',message:'30자 제한입니다.'}]" [placeholder]="'상품 제목을 작성해주세요.'"></form-input>
          <div style="height:16px"></div>
          <form-input [name]="'해시 태그'" [controlName]="'hashtags'" [group]="formGroup" [type]="'select'"
            [selectList]="hashtags" [multiple]="true" [addTag]="addTag" [template]="hashtagTpl" [valueProp]="'id'"
            (searchEvent)="checkHashtag($event)" [maxSelectedItems]="8">
          </form-input>
          <div class="desc">최대 8개까지 등록 가능합니다.</div>
          <ng-template #hashtagTpl let-element>
            {{element.name}}
          </ng-template>
        </div>
        <!-- 대표이미지 -->
        <div id="test-l-2" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[1]"></notion-desc>
          <div class="input-label" style="margin-bottom: 16px;">
            상품 이미지&nbsp;<span class="invalid-input">(필수)</span>
          </div>
          <form-input [name]="''" [controlName]="'photos'" [isRequired]="false" [group]="formGroup" [maxPhotos]="8"
            [type]="'photo'" (imageCroppedEvent)="imageCropped($event)" [croppedImageForModal]="croppedImage"
            [converter]="toProductRepresentationPhotoCreateDto" [canGif]="false">
          </form-input>
          <hr style="margin-top:15px">
        </div>
        <!-- 장소/소요시간 -->
        <div id="test-l-3" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[2]"></notion-desc>

          <form-input [name]="'주소'" [controlName]="'address'" [group]="formGroup" [type]="'map'" [latitude]="latitude"
            [longitude]="longitude" [id]="random" [mapOption]="{draggable:false,scrollWheel:false}"
            [placeholder]="'도로명/지번주소 입력 후 검색'" (mapSearchEvent)="searchMap($event)">
          </form-input>
          <div style="height:16px"></div>
          <form-input [name]="'상세 장소'" [controlName]="'detailAddress'" [group]="formGroup" [maxLength]="500"
            [placeholder]="'상세 장소를 작성해주세요.'" [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]"></form-input>
          <div style="height:16px"></div>
          <form-input [name]="'오는 방법'" [controlName]="'howToCome'" [type]="'textarea'" [group]="formGroup"
            [placeholder]="'(선택사항) 오는 방법을 작성해주세요.'" [errors]="[{key:'maxlength',message:'1000자 제한입니다.'}]"
            [maxLength]="1000" [rows]="5" [isRequired]="false">
          </form-input>
          <div style="height:16px"></div>
          <div style="display: flex;align-items: center;">
            <div style="display: flex;align-items: flex-end;">
              <form-input [name]="'상품 진행시간'" [controlName]="'runningDays'" [group]="formGroup" [type]="'select'"
                [selectList]="days" [clearable]="false">
              </form-input>
              <p style="margin-left: 6px;">일</p>
            </div>
            <div style="display: flex;align-items: flex-end;margin-top:24px">
              <form-input [name]="''" [controlName]="'runningHours'" [group]="formGroup" [type]="'select'"
                [selectList]="hours" [clearable]="false">
              </form-input>
              <p style="margin-left: 6px;">시간</p>
            </div>
            <div style="display: flex;align-items: flex-end;margin-top:24px">
              <form-input [name]="''" [controlName]="'runningMinutes'" [group]="formGroup" [type]="'select'"
                [selectList]="minutes" [clearable]="false">
              </form-input>
              <p style="margin-left: 6px;">분</p>
            </div>
          </div>
          <hr>
          <div class="desc">
            예를 들어 1박2일의 경우 2일, 2박3일의 경우 3일 입력합니다. 하루 내에 진행되는 상품은 시간/분만 입력하시면 됩니다.</div>
          <div style="height:16px"></div>
          <div style="display: flex;align-items: flex-end;margin-top:24px">
            <form-input [name]="'예약 가능 시간'" [controlName]="'reservationHours'" [group]="formGroup" [type]="'select'"
              [selectList]="reservationHours" [clearable]="false">
            </form-input>
            <p style="margin-left: 6px;">시간 전까지</p>
          </div>

          <p class="desc">최소 몇 시간 전까지 예약을 받을 수 있는지 입력합니다.</p>
        </div>
        <!-- 가격 -->
        <div id="test-l-4" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[3]"></notion-desc>
          <form-input [name]="'가격'" [controlName]="'price'" [type]="'tel'" [group]="formGroup"
            (valueChangeEvent)="checkDiscountPrice()" [max]="100000000" [placeholder]="'가격을 작성해주세요.'"
            [errors]="[{key:'min',message:'0원 이상이어야 합니다.'},{key:'max',message:'100000000원 제한입니다.'},{key:'pattern',message:'숫자만 입력 가능합니다.'}]">
          </form-input>
          <div style="height:16px"></div>
          <form-input [name]="'할인된 가격'" [controlName]="'discountPrice'" [type]="'tel'" [group]="formGroup" [errors]="[{key:'min',message:'0원보다 커야합니다.(할인된 가격을 책정하지 않으려면 숫자를 지우면 됩니다.'},
            {key:'max',message:'100000000원 제한입니다.'},
            {key:'isUpper',message:'할인된 가격이 같거나 더 클 수 없습니다.'},
            {key:'pattern',message:'숫자만 입력 가능합니다.'}]" [isUnUseDirty]="false" [isRequired]="false" [max]="100000000"
            [placeholder]="'(선택사항) 할인된 가격을 적어주세요.'">
          </form-input>
          <br>
          <p class="desc" style="margin: 0px;">다음과 같이 표시됩니다.</p>
          <div class="preview-price">
            <price *ngIf="formGroup.controls.price.valid && formGroup.controls.discountPrice.valid"
              [price]="+formGroup.controls.price.value"
              [discountPrice]="formGroup.controls.discountPrice.value? +formGroup.controls.discountPrice.value:undefined">
            </price>
          </div>
        </div>
        <!-- 소개 -->
        <div id="test-l-5" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[4]"></notion-desc>

          <div *ngIf="isAdmin" style="padding:16px;border-radius: 8px;border: 1px solid #d8d8d8;margin-bottom: 32px;">
            <div style="font-size: 16px;font-weight: 600;margin-bottom: 8px;">관리자 전용 도구</div>
            <div style="display: flex;gap:8px;">
              <button class="nnb-btn-md nnb-btn-outline" (click)="changeVersion(1)"
                [disabled]="formGroup.value.version === 1">버전1로 소개 작성하기</button>
              <button class="nnb-btn-md nnb-btn-outline" (click)="changeVersion(2)"
                [disabled]="formGroup.value.version === 2">버전2로 소개 작성하기</button>
            </div>
          </div>

          <div *ngIf="formGroup.value.version===1" class="input-label">
            상세&nbsp;<span class="invalid-input">(필수)</span>
          </div>

          <div *ngIf="formGroup.value.version===2" class="input-label">기타 정보</div>
          <div class="desc">상세페이지 상단 유튜브 영상 또는 공지 사항 등 강조하고 싶은 정보를 작성해주세요.</div>

          <div id="ql-toolbar">
            <button style="margin-right: 0rem;width: 1.7rem;height: 1.7rem;" class="ql-strike"></button>
            <select style="margin-right: 0rem;width: 2rem;height: 2rem;" class="ql-color"></select>
            <button style="margin-left:-0.2rem;margin-right: 0.2rem;width: 1.7rem;height: 1.7rem;"
              class="ql-link"></button>
            <button style="margin-right: 0.2rem;width: 1.7rem;height: 1.7rem;" class="ql-image"></button>
            <button style="margin-right: 0.2rem;width: 1.7rem;height: 1.7rem;" class="ql-video"></button>
            <select style="margin-right: 0.2rem;width: 2rem;height: 2rem;margin-top: -0.1rem;"
              class="ql-align"></select>
          </div>
          <quill-editor [ngClass]="{'version2-description':formGroup.value.version===2}" #quill
            style="-webkit-user-select: text" formControlName="description"
            [placeholder]="formGroup.value.version===1 ? '프로그램 소개를 작성해주세요':'기타 정보를 작성해주세요'" [styles]="quillStyle"
            (onEditorCreated)="fileUploadService.getEditorInstance($event,quill,'meetings')">
          </quill-editor>
          <div style="height:32px"></div>

          <ng-container *ngIf="formGroup.value.version===1">
            <form-input [name]="'포인트'" [controlName]="'point'" [type]="'textarea'" [group]="formGroup"
              [placeholder]="'포인트를 작성해주세요.'" [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500">
            </form-input>
            <div style="height:32px"></div>
            <form-input [name]="'추천 성향'" [controlName]="'recommend'" [type]="'textarea'" [group]="formGroup"
              [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [placeholder]="'추천 성향을 작성해주세요.'" [maxLength]="500">
            </form-input>
          </ng-container>

          <ng-container *ngIf="formGroup.value.version===2">
            <form-input [name]="'추천 성향'" [controlName]="'recommend'" [type]="'textarea'" [group]="formGroup"
              [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500">
            </form-input>
            <div style="height:32px"></div>
            <div class="input-label">
              상세요약&nbsp;<span class="invalid-input">(필수)</span>
            </div>
            <div class="desc">상세요약 이미지 권장 사이즈는 높이 180px입니다.</div>
            <div formArrayName="summaries">
              <div *ngFor="let summary of getSummary().controls; let index = index;let last=last;">
                <div [formGroupName]="index" style="display:flex;gap:8px">
                  <div style="width:24px">
                    <img *ngIf="index==0"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up-grey.svg'">
                    <img *ngIf="index!=0" style="cursor: pointer;" (click)="moveUp(index, 'summaries')"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up.svg'">
                    <img *ngIf="!last" style="cursor: pointer;" (click)="moveDown(index, 'summaries')"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down.svg'">
                    <img *ngIf="last"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down-grey.svg'">
                  </div>
                  <div style="width:100%;padding:15px;border: 1px solid #E8E8EF;border-radius: 6px;">
                    <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 4px;">
                      <div class="nnb-badge nnb-badge-sm nnb-badge-purple">요약 {{index+1| number:'2.0'}}</div>
                      <button *ngIf="index!=0" class="nnb-btn-sm" (click)="removeSummary(index)">삭제</button>
                    </div>
                    <form-input [name]="''" [controlName]="'photos'" [isRequired]="false"
                      [group]="getSummary().controls[index]" [maxPhotos]="1" [multiplePhoto]="false" [type]="'photo'"
                      [converter]="toProductRepresentationPhotoCreateDto" [canGif]="false"
                      [maintainAspectRatio]="false">
                    </form-input>
                    <div style="height:6px"></div>
                    <form-input [name]="''" [controlName]="'title'" [group]="getSummary().controls[index]"
                      [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0" [placeholder]="'제목을 입력해주세요.'">
                    </form-input>
                    <div style="height:6px"></div>
                    <form-input [name]="''" [controlName]="'description'" [group]="getSummary().controls[index]"
                      [errors]="[{key:'maxlength',message:'200자 제한입니다.'}]" [maxLength]="0"
                      [placeholder]="'요약 내용을 입력해주세요.'">
                    </form-input>
                  </div>
                </div>
                <div style="height: 10px;"></div>
              </div>
            </div>
            <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block" (click)="addSummary()">요약 추가</button>
            <div style="height:32px"></div>
            <div class="input-label">
              상세&nbsp;<span class="invalid-input">(필수)</span>
            </div>
            <div formArrayName="sections">
              <div *ngFor="let section of getSection().controls; let sectionIndex = index; let sectionLast = last;">
                <div [formGroupName]="sectionIndex" style="display:flex;gap:8px;">
                  <div style="width:24px">
                    <img *ngIf="sectionIndex==0"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up-grey.svg'">
                    <img *ngIf="sectionIndex!=0" style="cursor: pointer;" (click)="moveUp(sectionIndex, 'sections')"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up.svg'">
                    <img *ngIf="!sectionLast" style="cursor: pointer;" (click)="moveDown(sectionIndex, 'sections')"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down.svg'">
                    <img *ngIf="sectionLast"
                      [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down-grey.svg'">
                  </div>
                  <div style="width:100%;padding:15px;border: 1px solid #E8E8EF;border-radius: 6px;">
                    <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 4px;">
                      <div class="nnb-badge nnb-badge-sm nnb-badge-purple">섹션 {{sectionIndex+1| number:'2.0'}}</div>
                      <button *ngIf="sectionIndex!=0" class="nnb-btn-sm"
                        (click)="removeSection(sectionIndex)">삭제</button>
                    </div>
                    <form-input [name]="''" [controlName]="'title'" [group]="getSection().controls[sectionIndex]"
                      [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0"
                      [placeholder]="'섹션 제목을 입력해주세요.'">
                    </form-input>
                    <hr style="margin: 16px 0px">
                    <div formArrayName="paragraphs">
                      <div
                        *ngFor="let paragraph of getParagraph(sectionIndex).controls; let paragraphIndex = index; let paragraphLast=last">
                        <div [formGroupName]="paragraphIndex" style="display:flex;gap:8px;">
                          <div style="width:24px">
                            <img *ngIf="paragraphIndex==0"
                              [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up-grey.svg'">
                            <img *ngIf="paragraphIndex!=0" style="cursor: pointer;"
                              (click)="moveUp2(sectionIndex, paragraphIndex, 'paragraphs')"
                              [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-up.svg'">
                            <img *ngIf="!paragraphLast" style="cursor: pointer;"
                              (click)="moveDown2(sectionIndex, paragraphIndex, 'paragraphs')"
                              [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down.svg'">
                            <img *ngIf="paragraphLast"
                              [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/simple-arrow-down-grey.svg'">
                          </div>
                          <div style="width:100%;">
                            <div style=" display:flex;justify-content: space-between;align-items: center;margin-bottom:
                            4px;">
                              <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey">문단 {{paragraphIndex+1|
                                number:'2.0'}}
                              </div>
                              <button *ngIf="paragraphIndex!=0" class="nnb-btn-sm"
                                (click)="removeParagraph(sectionIndex,paragraphIndex)">삭제</button>
                            </div>
                            <form-input [name]="''" [controlName]="'title'"
                              [group]="getParagraph(sectionIndex).controls[paragraphIndex]"
                              [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0"
                              [placeholder]="'문단의 제목을 입력해주세요.'" [ngStyle]="{'min-width': isDesktop? '200px':''}">
                            </form-input>
                            <div style="height:6px"></div>
                            <form-input [name]="''" [controlName]="'time'"
                              [group]="getParagraph(sectionIndex).controls[paragraphIndex]"
                              [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0"
                              [placeholder]="'(선택사항) 14:00 ~ 2시간 / 시작시간 ~ 소요시간을 입력해주세요.'"
                              [ngStyle]="{'min-width': isDesktop? '200px':''}">
                            </form-input>
                            <div style="height:6px"></div>
                            <form-input [name]="''" [controlName]="'photos'" [isRequired]="false"
                              [group]="getParagraph(sectionIndex).controls[paragraphIndex]" [maxPhotos]="10"
                              [type]="'photo'" [converter]="toProductRepresentationPhotoCreateDto" [canGif]="false"
                              [maintainAspectRatio]="false">
                            </form-input>
                            <div style="height:6px"></div>
                            <form-input [name]="''" [controlName]="'description'" [type]="'textarea'" [rows]="5"
                              [group]="getParagraph(sectionIndex).controls[paragraphIndex]"
                              [errors]="[{key:'maxlength'}]" [maxLength]="0" [placeholder]="'내용을 입력해주세요.'"
                              style="width:100%">
                            </form-input>
                            <form-input [name]="''" [controlName]="'note'" [type]="'textarea'" [rows]="1"
                              [group]="getParagraph(sectionIndex).controls[paragraphIndex]"
                              [errors]="[{key:'maxlength'}]" [maxLength]="0"
                              [placeholder]="'(선택사항) 추가 또는 강조 내용을 입력해주세요.'" style="width:100%">
                            </form-input>
                          </div>
                        </div>
                        <hr style="margin: 16px 0px" *ngIf="!paragraphLast">
                      </div>
                    </div>
                    <button class="nnb-btn-outline nnb-btn-md nnb-btn-block" (click)="addParagraph(sectionIndex)">문단
                      추가</button>
                  </div>
                  <div style="height: 10px;"></div>
                </div>
                <div *ngIf="!sectionLast" style="height: 10px;"></div>
              </div>
            </div>
            <div style="height: 10px;"></div>
            <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block" (click)="addSection()">섹션 추가</button>
          </ng-container>
          <div style="height:32px"></div>
          <div class="input-label">
            일정
          </div>
          <div formArrayName="courses">
            <div *ngFor="let course of getCourse().controls; let courseIndex = index;">
              <div [formGroupName]="courseIndex" style="padding:15px;border: 1px solid #0000001a;border-radius: 6px;">
                <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 4px;">
                  <div class="nnb-badge nnb-badge-sm nnb-badge-purple">일정 {{courseIndex+1| number:'2.0'}}</div>
                  <button class="nnb-btn-sm" (click)="removeCourse(courseIndex)">삭제</button>
                </div>
                <form-input [name]="''" [controlName]="'title'" [group]="getCourse().controls[courseIndex]"
                  [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0"
                  [placeholder]="'첫째날/둘째날/1일차/2일차 등 일정 제목을 입력해주세요.'">
                </form-input>
                <hr style="margin: 15px 0px">
                <div formArrayName="schedules">
                  <div *ngFor="let schedule of getSchedule(courseIndex).controls; let scheduleIndex = index;">
                    <div [formGroupName]="scheduleIndex">
                      <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 4px;">
                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey">항목 {{scheduleIndex+1| number:'2.0'}}
                        </div>
                        <button *ngIf="scheduleIndex!=0" class="nnb-btn-sm"
                          (click)="removeScheudle(courseIndex,scheduleIndex)">삭제</button>
                      </div>
                      <form-input [name]="''" [controlName]="'subTitle'"
                        [group]="getSchedule(courseIndex).controls[scheduleIndex]"
                        [errors]="[{key:'maxlength',message:'20자 제한입니다.'}]" [maxLength]="0"
                        [placeholder]="'14:00 ~ 2시간 / 시작시간 ~ 소요시간을 입력해주세요.'"
                        [ngStyle]="{'min-width': isDesktop? '200px':''}">
                      </form-input>
                      <div style="height: 10px;"></div>
                      <form-input [name]="''" [controlName]="'content'"
                        [group]="getSchedule(courseIndex).controls[scheduleIndex]" [errors]="[{key:'maxlength'}]"
                        [maxLength]="0" [placeholder]="'내용을 입력해주세요.'" style="width:100%">
                      </form-input>
                      <div style="height: 10px;"></div>
                      <form-input [name]="''" [controlName]="'note'" [type]="'textarea'" [rows]="1"
                        [group]="getSchedule(courseIndex).controls[scheduleIndex]" [errors]="[{key:'maxlength'}]"
                        [maxLength]="0" [placeholder]="'(선택사항) 추가 사항을 입력해주세요.'" style="width:100%">
                      </form-input>
                    </div>
                    <div style="height: 10px;"></div>
                  </div>
                </div>
                <button class="nnb-btn-outline nnb-btn-md nnb-btn-block" (click)="addScheudle(courseIndex)">항목
                  추가</button>
              </div>
              <div style="height: 10px;"></div>
            </div>
          </div>
          <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="addCourse()">일정 추가</button>
        </div>
        <!-- 준비물 -->
        <div id="test-l-6" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[5]"></notion-desc>
          <form-input [name]="'준비물'" [controlName]="'checkList'" [type]="'textarea'" [group]="formGroup"
            [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500" [placeholder]="'준비물을 작성해주세요.'">
          </form-input>
          <div style="height:16px"></div>
          <form-input [name]="'포함 사항'" [controlName]="'includeList'" [type]="'textarea'" [group]="formGroup"
            [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500" [isRequired]="false"
            [placeholder]="'(선택사항) 포함 사항을 작성해주세요.'">
          </form-input>
          <div style="height:16px"></div>
          <form-input [name]="'불포함 사항'" [controlName]="'excludeList'" [type]="'textarea'" [group]="formGroup"
            [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500" [isRequired]="false"
            [placeholder]="'(선택사항) 불포함 사항을 작성해주세요.'">
          </form-input>
          <div style="height:16px"></div>
          <div style="display:flex;gap:10px">
            <div class="input-label">숙소 시설</div>
            <input type="checkbox" (change)="onIsAmenity($event)" [formControlName]="'isAmenity'">
          </div>
          <ng-container *ngIf="formGroup.controls.isAmenity.value">
            <div class="desc">(원하시는 항목이 없는 경우 상품 소개 또는 포함사항에 추가 정보를 기재해주세요.)</div>
            <div style="display: flex;flex-wrap: wrap;">
              <div *ngFor="let amenity of amenities" style="width: 30%;margin: 7px 0px;">
                <input type="checkbox" [value]="amenity.id"
                  [checked]="formGroup.controls.amenities.value.includes(amenity.id)"
                  (change)="onCheckAmenity($event, amenity)" />
                {{amenity.name}}
              </div>
            </div>
          </ng-container>
        </div>
        <!-- 유의사항 -->
        <div id="test-l-7" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary  nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()">다음</button>
          </div>
          <notion-desc [description]="descriptions[6]"></notion-desc>
          <form-input [name]="'유의 사항'" [controlName]="'notice'" [type]="'textarea'" [group]="formGroup"
            [placeholder]="'유의사항을 작성해주세요.'" [errors]="[{key:'maxlength',message:'500자 제한입니다.'}]" [maxLength]="500"
            [isRequired]="false">
          </form-input>

          <div style="display: flex;gap: 6px;align-items: center;">
            <input type="checkbox" [checked]="noticeChcked" (change)="checkAllowPolicy($event)">
            <div>노는법 정책을 따르겠습니다.</div>
          </div>
        </div>
        <!-- 환불정책 -->
        <div id="test-l-8" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary  nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" (click)="next()" [disabled]="formGroup.invalid">다음</button>
          </div>
          <notion-desc [description]="descriptions[7]"></notion-desc>
          <div class="input-label">
            환불 정책&nbsp;<span class="invalid-input">(필수)</span>
          </div>
          <form-input [name]="''" [controlName]="'refundPolicy'" [type]="'textarea'" [group]="formGroup"
            [placeholder]="'환불 정책을 작성해주세요.'" [errors]="[{key:'maxlength',message:'1000자 제한입니다.'}]" [maxLength]="1000"
            [rows]="5" [isRequired]="false">
          </form-input>
        </div>
        <!-- 최종확인 -->
        <div id="test-l-9" class="content">
          <div class="next-prev">
            <button class="nnb-btn-secondary nnb-btn-md" (click)="prev()">이전</button>
            <button class="nnb-btn-md nnb-btn-primary" [disabled]="formGroup.invalid || isLoad" debounce-click
              (debounceClick)="add()">
              <div style="display:flex;align-items: center;gap:4px">
                <div>
                  <div *ngIf="!isLoad">{{submitMessage}}</div>
                  <div *ngIf="isLoad">{{loadMessage}}</div>
                </div>
                <img *ngIf="isLoad" style="width:16px;height:16px"
                  src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/loading-dark.gif">
              </div>
            </button>
          </div>
          <notion-desc [description]="descriptions[8]"></notion-desc>
          <meeting-preview *ngIf="previewProduct" [product]="previewProduct"></meeting-preview>
        </div>
      </form>
    </div>
  </div>
</div>