import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { LoggerService, TargetType } from './logger.service';
import { MessageService } from './message.service';
import { Product } from '../model/product';
declare var Kakao;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  NNB_STRING_DIVIDER = '_NNB_STRING_DIVIDER_';

  constructor(
    private cookieService: CookieService,
    private logger: LoggerService,
    private clipboardService: ClipboardService,
    private messageService: MessageService
  ) {
    this.loadScript('https://developers.kakao.com/sdk/js/kakao.js')
  }

  getQuillStyle() {
    return {
      'height': '100%',
      'word-spacing': 'normal',
      'font-weight': 'normal',
      'letter-spacing': '0px',
    }
  }

  loadScript(url: string) {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setQueryParams(dto: any): string {
    const queries = Object.keys(dto).filter(key => typeof dto[key] !== 'undefined').map(key => {
      const value = dto[key];
      if (Array.isArray(value)) {
        return `${key}=[${value}]`
      }
      else return `${key}=${value}`;
    }).join('&');
    return queries;
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportAsExcelFileFromSheets(sheets, fileName: string) {
    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: Object.keys(sheets)
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, `${fileName}_${moment().format('YYYYMMDDHHmmss')}${this.EXCEL_EXTENSION}`);
  }

  downloadFileFromUrl(url: string, name: string) {
    FileSaver.saveAs(url, name);
  }

  getDate(date: Date, isEnd = false): moment.Moment {
    const mdate = !isEnd ? moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : moment(date).set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    return mdate;
  }

  groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  getRandomColor(): string {
    let random = this.getRandomString();
    while (random.length !== 6) {
      random = this.getRandomString();
    }
    return `#${random}3D`;
  }

  async convertURLtoFile(url: string) {
    const response = await fetch(url);
    const data = await response.blob();
    const filename = url.split("/").pop();
    const metadata = { type: data.type };
    return new File([data], filename!, metadata);
  };

  private getRandomString() {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  downloadImage(imageUrl: string, filename: string) {
    this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
      console.log(base64data);
      const base64Image = "data:image/jpg;base64," + base64data;
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("href", base64Image);
      link.setAttribute("download", `${filename}.jpg`);
      link.click();
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  async share(shareInfo: ShareInfo) {
    if (shareInfo.logging) {
      this.logger.addLog({ pageName: shareInfo.logging.pageName, eventName: shareInfo.logging.eventName, eventType: 'click', targetUrl: '', targetInfo: { type: TargetType.ETC } });
    }
    switch (shareInfo.sns) {
      case 'kakaotalk': {
        if (shareInfo.kakaotalk.handler) {
          shareInfo.kakaotalk.handler();
        }
        const config = {
          objectType: 'feed',
          content: {
            title: shareInfo.kakaotalk.title,
            description: shareInfo.kakaotalk.description,
            imageUrl: shareInfo.kakaotalk.imageUrl,
            link: {
              mobileWebUrl: shareInfo.kakaotalk.url,
              webUrl: shareInfo.kakaotalk.url
            },
          },
          success: function (response) {
            console.log(response);
          },
          fail: function (error) {
            console.log(error);
          }
        }
        if (!Kakao.isInitialized()) {
          Kakao.init(environment.KAKAO_AUTH_KEY);
        }
        Kakao.Link.sendDefault(config);
        return false;
      }
      case 'link': {
        if (shareInfo.link.handler) {
          shareInfo.link.handler();
        }
        this.clipboardService.copyFromContent(shareInfo.link.url);
        this.messageService.toast('🔗 주소가 복사되었습니다.')
        return false;
      }
      case 'download': {
        if (shareInfo.download.handler) {
          shareInfo.download.handler();
        }
        this.downloadFileFromUrl(shareInfo.download.url, shareInfo.download.filename)
        return false;
      }
      case 'device': {
        if (shareInfo.device.handler) {
          shareInfo.device.handler();
        }
        if (navigator.share) {
          navigator.share({
            files: shareInfo.device?.files,
            text: shareInfo.device?.text,
            title: shareInfo.device?.title,
            url: shareInfo.device?.url
          } as ShareData)
        }
        return false;
      }
    }
  }

  setIntervalNow(seconds, callback) {
    callback();
    return setInterval(callback, seconds * 1000);
  }

  setCategory(categories): string {
    if (categories?.find(c => c.name === '숙박')) {
      return '숙박';
    } else if (categories?.find(c => c.name === '당일')) {
      return '당일';
    } else if (categories?.find(c => c.name === '배움')) {
      return '배움';
    } else if (categories?.find(c => c.name.includes('같이가요'))) {
      return '벙개모임';
    } else {
      return '자연';
    }
  }

  setRegion(product: Product): string {
    if (!product.isOffline) {
      return '온라인';
    } else {
      if (product?.sido) {
        if (product.sido.includes('특별시') || product.sido.includes('광역시') || product.sido.includes('특별자치시') || product.sido.includes('특별자치도')) {
          const city = product.sido;
          const regex = /(특별시|광역시|특별자치시|특별자치도)$/;
          return city.replace(regex, '');
        } else {
          if (product.sido === '경기도') return '경기';
          else if (product.sido === '경상북도') return '경북';
          else if (product.sido === '경상남도') return '경남';
          else if (product.sido === '전라북도') return '전북';
          else if (product.sido === '전라남도') return '전남';
          else if (product.sido === '충청북도') return '충북';
          else if (product.sido === '충청남도') return '충남';
          else return product.sido;
        }
      }
    }
  }
}

export interface ShareInfo {
  sns: string;
  logging?: {
    pageName: string,
    eventName: string,
  };
  kakaotalk?: {
    url: string,
    title: string,
    description: string,
    imageUrl: string,
    handler?: () => void
  };
  link?: {
    url: string,
    handler?: () => void
  };
  download?: {
    url: string,
    filename: string,
    handler?: () => void
  };
  device?: {
    files?: File[];
    text?: string;
    title?: string;
    url?: string;
    handler?: () => void
  }
}