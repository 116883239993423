import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanReservationDto, HostStatistics, KakaoPayReadyDto, MyPaymentSearchDto, NaverPayCash, OrderItemCreateDto, PaymentCancel, PaymentCancelDto, PaymentCancelRequest, PaymentCancelRequsetSearchDto, PaymentSearchDto, PayMethod, PaypleCardAdd, PaypleCardAddDto, PaypleUserDefine, PreOrderCreateDto } from 'src/app/model/payment';
import { UtilService } from 'src/app/service/util.service';
import { environment } from 'src/environments/environment';
import { Coupon } from '../model/coupon';
import { Product } from '../model/product';
import { PaymentSurveyMap, SurveyCreateDto } from '../model/survey';
import { Pagination } from './../model/pagination';
import { Payment, PaymentCreateDto, PaypleGetMethod } from './../model/payment';
import { Survey } from './../model/survey';
import { NonMember, User } from './../model/user';
import { UrlService } from './url.service';
declare var PaypleCpayAuthCheck;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  urlPrefix = UrlService.prefix;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  payNonMember(dto: PaymentCreateDto) {
    return this.http.post<Payment>(`${this.urlPrefix}/payments/nonMember`, dto);
  }

  join(dto: PaymentCreateDto) {
    return this.http.post<Payment>(`${this.urlPrefix}/payments`, dto);
  }

  pay(method: PayMethod, user: User | NonMember, meeting: Product, phoneNumber: string, price: number,
    tickets: { ticketId: number, quantity: number, totalPrice: number }[], coupon: Coupon, point: number, alliance: string, survey?: Survey, socarCar?: string, socarId?: string) {
    let obj = {};
    obj['PCD_CPAY_VER'] = "1.0.1";
    obj['payple_auth_file'] = "/api/payments/pg/auth";
    obj['PCD_PAY_GOODS'] = meeting.title;
    obj['PCD_PAY_TOTAL'] = price;
    obj['PCD_PAYER_NO'] = user.id;
    obj['PCD_PAYER_NAME'] = user.name;
    obj['PCD_PAYER_HP'] = user.phoneNumber;
    obj['PCD_PAYER_EMAIL'] = user.email;
    obj['PCD_PAY_WORK'] = "PAY";
    obj['PCD_RST_URL'] = `${environment.host}/api/payments/callback`;
    const userDefine: PaypleUserDefine = {
      phoneNumber,
      userId: user.id,
      userEmail: user.email,
      userName: user.name,
      mid: meeting.id,
      couponId: coupon ? coupon.id : '',
      point: point ? point : 0,
      tickets,
      alliance,
      surveyId: survey?.id,
      host: window.location.origin,
      socarCar,
      socarId
    }
    obj['PCD_USER_DEFINE1'] = encodeURIComponent(JSON.stringify(userDefine));
    switch (method) {
      // [from docs.payple.kr] 앱카드 결제
      // 카드 일반 결제 (다날 결제)
      case PayMethod.CARD: {
        obj['PCD_CARD_VER'] = "02"; // 카드 결제에만 쓰임
        obj['PCD_PAY_TYPE'] = "card";
        break;
      }
      // [from docs.payple.kr] 일회성 간편 결제
      // 계좌 일반 결제
      case PayMethod.TRANSFER: {
        obj['PCD_PAY_TYPE'] = "transfer";
        obj['PCD_TAXSAVE_FLAG'] = "N" // 계좌 결제에만 쓰임, 현금영수증 N
        break;
      }
    }
    PaypleCpayAuthCheck(obj);
  }

  paySimple(payerId: string, user: User | NonMember, meeting: Product, price: number,
    tickets: { ticketId: number, quantity: number, totalPrice: number }[], coupon: Coupon, point: number, alliance: string, survey?: Survey, socarCar?: string, socarId?: string): Observable<Payment> {
    return this.http.post<Payment>(`/api/payments/simple-payment`, {
      payerId,
      productId: meeting.id,
      price,
      userId: user.id,
      couponId: coupon ? coupon.id : '',
      point: point ? point : 0,
      tickets,
      alliance,
      surveyId: survey?.id,
      host: window.location.origin,
      socarCar,
      socarId
    });
  }

  getPaymentsCountByProduct(productId: number, excludeCancel?: boolean): Observable<number> {
    const query = this.utilService.setQueryParams({ excludeCancel })
    return this.http.get<number>(`/api/payments/by/host/${productId}?${query}`)
  }

  cancelPayment(dto: PaymentCancelDto): Observable<PaymentCancel> {
    return this.http.post<PaymentCancel>(`/api/payments/cancel`, dto)
  }

  cancelNaverPay(dto: PaymentCancelDto): Observable<PaymentCancel> {
    return this.http.post<PaymentCancel>(`/api/payments/cancel/naverPay`, dto)
  }

  cancelKakaoPay(dto: PaymentCancelDto): Observable<PaymentCancel> {
    return this.http.post<PaymentCancel>(`/api/payments/cancel/kakaoPay`, dto)
  }

  cancelFreePayment(paymentId: number, reason: string): Observable<PaymentCancel> {
    return this.http.post<PaymentCancel>(`/api/payments/cancel/free`, { paymentId, reason })
  }

  getPurchasedProduct(paymentId: number): Observable<Payment> {
    return this.http.get<Payment>(`api/payments/owner/id/${paymentId}`);
  }

  getPurchasedProducts(search: MyPaymentSearchDto): Observable<Pagination<Payment>> {
    return this.http.post<Pagination<Payment>>(`api/payments/owner/search`, search);
  }

  getPaymentsByHost(search: PaymentSearchDto): Observable<Pagination<Payment>> {
    const query = this.utilService.setQueryParams(search);
    return this.http.get<Pagination<Payment>>(`api/payments/by/host?${query}`);
  }

  getPaymentsAllByHost(search: PaymentSearchDto): Observable<Payment[]> {
    const query = this.utilService.setQueryParams(search);
    return this.http.get<Payment[]>(`api/payments/by/hostAll/?${query}`);
  }

  search(search: PaymentSearchDto): Observable<Pagination<Payment>> {
    const query = this.utilService.setQueryParams(search);
    return this.http.get<Pagination<Payment>>(`api/payments?${query}`);
  }

  searchAll(search: PaymentSearchDto): Observable<Payment[]> {
    const query = this.utilService.setQueryParams(search);
    return this.http.get<Payment[]>(`api/payments/searchAll?${query}`);
  }

  getNonMemberPayment(name: string, phoneNumber: string, orderId: string): Observable<Payment> {
    return this.http.get<Payment>(`api/payments/nonMember?name=${name}&phoneNumber=${phoneNumber}&orderId=${orderId}`);
  }

  isPaidProduct(userId: number, productId: number): Observable<any> {
    return this.http.get<any>(`/api/payments/is-paid-product/userId/${userId}/productId/${productId}`)
  }

  addPaypleCard(dto: PaypleCardAddDto): Observable<PaypleCardAdd> {
    return this.http.post<PaypleCardAdd>(`/api/payments/pg/card`, dto);
  }

  getPaypleMethods(): Observable<PaypleGetMethod[]> {
    return this.http.get<PaypleGetMethod[]>(`/api/payments/pg/method`);
  }

  removePaypleMethod(payerId: string): Observable<any> {
    return this.http.delete<any>(`/api/payments/pg/method/${payerId}`);
  }

  editDescription(paymentId: number, description: string) {
    return this.http.put(`/api/payments/${paymentId}/description`, { description })
  }

  getTotalPriceByMonth(year: number): Observable<{ year: number, month: number, totalPrice: number }[]> {
    return this.http.post<{ year: number, month: number, totalPrice: number }[]>(`/api/payments/stat/total_price_by_month`, { year });
  }

  getTotalPrice() {
    return this.http.get<HostStatistics>(`/api/payments/stat/total_price`);
  }

  getGuestCount() {
    return this.http.get<HostStatistics>(`/api/payments/stat/guest_count`);
  }

  // TODO: 서버에서 예약 가능한지 체크해야함
  canReservationFromServer(dto: CanReservationDto): Observable<boolean> {
    return this.http.post<boolean>('/api/payments/can-reservation/', dto)
  }

  kakaoPayReady(dto: KakaoPayReadyDto): Observable<{ redirectUrl: string }> {
    return this.http.post<{ redirectUrl: string }>('api/payments/kakaoPay/ready', dto);
  }

  getNaverPayCashReceipt(paymentId: string): Observable<NaverPayCash> {
    return this.http.get<NaverPayCash>(`api/payments/naverPay/cash_receipt?paymentId=${paymentId}`)
  }

  addPreOrder(dto: PreOrderCreateDto): Observable<any> {
    return this.http.post<any>(`api/payments/pre-order`, dto)
  }

  createSurvey(dto: SurveyCreateDto): Observable<Survey> {
    return this.http.post<Survey>(`/api/survey`, dto)
  }

  mapPayment(paymentId: number, surveyId: number): Observable<any> {
    return this.http.post<Survey>(`/api/survey/map/payment`, { paymentId, surveyId });
  }

  getPaymentSurveyMapByPayment(paymentId: number): Observable<PaymentSurveyMap[]> {
    return this.http.get<PaymentSurveyMap[]>(`/api/survey/map/payment/${paymentId}`);
  }

  getSaleAgentInfo(saleAgentYear, saleAgentQuarter): Observable<Payment[]> {
    return this.http.post<Payment[]>(`/api/payments/sale-agent`, { saleAgentYear, saleAgentQuarter });
  }

  changeOrderItem(paymentId: number, orderItems: OrderItemCreateDto[]) {
    return this.http.post<Payment>(`/api/payments/change-orderItems`, { paymentId, orderItems });
  }

  isTicketReservationEnd(id: number): Observable<boolean> {
    return this.http.post<boolean>(`/api/payments/tickets/isReservationEnd`, { ticketId: id });
  }

  getRemainReviewFeedCount(): Observable<number> {
    return this.http.get<number>('/api/payments/remain-review-feed/count')
  }

  requestCancel(dto: PaymentCancelRequest): Observable<PaymentCancelRequest> {
    return this.http.post<PaymentCancelRequest>('/api/payments/cancel/request', dto);
  }

  searchRequestCancel(search: PaymentCancelRequsetSearchDto): Observable<Pagination<PaymentCancelRequest>> {
    const query = this.utilService.setQueryParams(search);
    return this.http.get<Pagination<PaymentCancelRequest>>(`api/payments/cancel/request?${query}`);
  }

  completeRequestCancel(id: number) {
    return this.http.put(`api/payments/cancel/request`, { id })
  }

  completeRequestCancelByPayment(paymentId: number) {
    return this.http.put(`api/payments/cancel/request/byPayment`, { id: paymentId })
  }

  getUnCompletedRequestCancelCount(): Observable<number> {
    return this.http.get<number>('api/payments/cancel/request/count');
  }
}
