import { Component, Input, OnInit } from '@angular/core';
import { Product } from './../../model/product';

@Component({
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input() product: Product;
  @Input() price: number;
  @Input() discountPrice: number;

  constructor() { }


  ngOnInit() { }
}