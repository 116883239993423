import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { Chat, ChatCreateDto, ChatRoom, ChatRoomSearchDto, UserOftenPhrase, UserOftenPhraseCreateDto } from './../model/chat';
import { Pagination, PaginationSearchDto } from './../model/pagination';

@Injectable({ providedIn: 'root' })
export class ChatService {

  constructor(
    private socket: Socket,
    private http: HttpClient
  ) {
    this.socket.on('connect', () => {
      // const socketId = this.socket.ioSocket.id;
      // sessionStorage.setItem('socketId', socketId);
      // sessionStorage.setItem('isReloadClick', '0');

      // const isDisconnected = sessionStorage.getItem('isDisconnected') === '1';
      // if (isDisconnected) {
      //   const oldSocketId = sessionStorage.getItem('socketId');
      //   this.http.delete(`/api/chats/socketId/${oldSocketId}`).subscribe(resp => {
      //     console.log(`${oldSocketId} deleted`);
      //   })
      //   sessionStorage.setItem('isReloadClick', '1');
      //   sessionStorage.setItem('isDisconnected', '0');
      //   alert('서버와 정상적으로 재연결되었습니다. 확인을 누르시면 새로고침됩니다.')
      //   window.location.reload();
      // }
      sessionStorage.setItem('isDisconnected', '0');
    })

    this.socket.on('disconnect', () => {
      // const isReloadClick = sessionStorage.getItem('isReloadClick') === '1';
      // if (isReloadClick) sessionStorage.setItem('isDisconnected', '0');
      // else sessionStorage.setItem('isDisconnected', '1');
      sessionStorage.setItem('isDisconnected', '1');
    });
  }

  getAddedChatWS(): Observable<Chat> {
    return this.socket.fromEvent<Chat>('chatAdded');
  }

  getAddedChatForChatRoomWS(): Observable<Chat> {
    return this.socket.fromEvent<Chat>('chatAddedForChatRoom');
  }

  getChatRoomsWS(): Observable<Pagination<ChatRoom>> {
    return this.socket.fromEvent<Pagination<ChatRoom>>('chatRooms');
  }

  getChatsWS(): Observable<Pagination<Chat>> {
    return this.socket.fromEvent<Pagination<Chat>>('chats');
  }

  getEndedChatRoomWS(): Observable<Chat> {
    return this.socket.fromEvent<Chat>('endedChatRoom');
  }

  sendChatWS(dto: ChatCreateDto) {
    this.socket.emit('addChat', dto);
  }

  joinChatRoomWS(chatRoomId: number, search: PaginationSearchDto) {
    this.socket.emit('joinChatRoom', { chatRoomId, search });
  }

  emitPaginationChatRoomsAndCreateConnectionWS(page: number, limit: number) {
    this.socket.emit('paginationChatRoomsAndCreateConnection', { page, limit });
  }

  emitPaginationChatRoomsWS(page: number, limit: number) {
    this.socket.emit('paginationChatRooms', { page, limit });
  }

  emitPaginationChatsWS(chatRoomId: number, search: PaginationSearchDto) {
    this.socket.emit('paginationChats', { chatRoomId, search });
  }

  leaveChatRoomWS(chatRoomId: number) {
    this.socket.emit('leaveChatRoom', chatRoomId);
  }

  disconnectUserWS() {
    this.socket.emit('disconnectUser');
  }

  getChatRoomByUser(hostId: number, productId: number): Observable<ChatRoom> {
    return this.http.get<ChatRoom>(`api/chats/hostId/${hostId}/productId/${productId}`);
  }

  getChatRooms(dto: ChatRoomSearchDto): Observable<Pagination<ChatRoom>> {
    return this.http.post<Pagination<ChatRoom>>('/api/chats/search', dto);
  }

  getChatRoomsByAdmin(dto: ChatRoomSearchDto): Observable<Pagination<ChatRoom>> {
    return this.http.post<Pagination<ChatRoom>>('/api/chats/search/admin', dto);
  }

  getChatRoomById(id: number): Observable<ChatRoom> {
    return this.http.get<ChatRoom>(`/api/chats/${id}`);
  }

  getChatRoomByIdAdmin(id: number): Observable<ChatRoom> {
    return this.http.get<ChatRoom>(`/api/chats/admin/${id}`);
  }

  isJoinedChatRoom(chatRoomId: number, userId: number): Observable<{ isJoined: boolean }> {
    return this.http.get<{ isJoined: boolean }>(`/api/chats/chatRoom/${chatRoomId}/user/${userId}`);
  }

  endChatRoom(chatRoomId: number) {
    return this.http.delete(`/api/chats/chatRoom/${chatRoomId}`);
  }

  getOftenPhrases(): Observable<UserOftenPhrase[]> {
    return this.http.get<UserOftenPhrase[]>('api/chats/often/phrase');
  }

  addOftenPhrase(dto: UserOftenPhraseCreateDto): Observable<UserOftenPhrase> {
    return this.http.post<UserOftenPhrase>('api/chats/often/phrase', dto)
  }

  deleteOftenPhrase(id: number) {
    return this.http.delete(`api/chats/often/phrase/${id}`);
  }

  askToHost(hostId: number, productId: number): Observable<ChatRoom> {
    return this.http.post<ChatRoom>(`api/chats/ask_to_host/`, { hostId, productId })
  }

  getChats(chatRoomId: number, page: number, limit: number) {
    return this.http.post<Pagination<Chat>>(`api/chats/chat/search`, { chatRoomId, page, limit })
  }

  setChangedDate(messages: Pagination<Chat>) {
    if (messages.items.length === 1) {
      messages.items[0].changedDate = messages.items[0].createdAt;
    }
    messages.items = messages.items.map((message, index) => {
      if (index != 0) {
        // TODO: moment().get('month')일 때 0부터 시작함, api 확인 필요
        if (moment(message.createdAt).get('date') !== moment(messages.items[index - 1].createdAt).get('date')) {
          message.changedDate = message.createdAt;
        }
      }
      if (message.message) {
        if (!message.message.includes('<a href="')) {
          message.message = this.linkify(message.message)
        }
      }
      return message;
    })
    return messages;
  }

  linkify(inputText) {
    if (!inputText) return inputText;
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }
}