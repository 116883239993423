import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShareInfo, UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() utmContent?: string;
  @Input() url?: string;

  constructor(
    private utilService: UtilService,
    private bottomSheet: MatBottomSheet,
  ) {
  }

  ngOnInit() {

  }

  share(sns: string) {
    const url = this.url ?? window.location.href;
    const shareInfo: ShareInfo = {
      sns,
      kakaotalk: {
        url: this.utmContent ? `${url}?utm_source=nonunbub&utm_medium=share&utm_campaign=kakaotalk&utm_content=${this.utmContent}` : url,
        title: this.title,
        description: this.description,
        imageUrl: this.image,
      },
      link: {
        url: this.utmContent ? `${url}?utm_source=nonunbub&utm_medium=share&utm_campaign=link_copy&utm_content=${this.utmContent}` : url,
        handler: () => {
          this.bottomSheet.dismiss();
        }
      }
    }
    this.utilService.share(shareInfo);
  }

  openShareModal(template: TemplateRef<any>) {
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  closeBm() {
    this.bottomSheet.dismiss()
  }
}
