import { UtilService } from 'src/app/service/util.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { MessageService } from 'src/app/service/message.service';
import { UserService } from 'src/app/service/user.service';
import { SectionType } from './../../model/product';
import { AuthService } from './../../service/auth.service';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;
  @Input() viewType: SectionType;
  @Input() showSale = true;
  @Input() needClickEvent = false;
  @Output() clickEvent = new EventEmitter();
  ProductSection = SectionType;
  user: User;
  category: string;
  region: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private messageService: MessageService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.region = this.utilService.setRegion(this.product);
    this.category = this.utilService.setCategory(this.product?.categories);
  }

  goDetailPage(product: Product) {
    if (this.needClickEvent) {
      this.clickEvent.emit(product);
    }
    else {
      this.router.navigate(['/tabs/meeting-detail', product.id])
    }
  }

  setLike(productId: number, isLike: boolean) {
    event.stopPropagation();
    this.authService.getCurrentUser().subscribe(user => {
      this.user = user;
      if (!this.user) {
        this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
      } else {
        this.userService.likeProduct(productId, isLike).subscribe(resp => {
          if (isLike) this.messageService.toast(undefined, undefined, 'like');
          this.product.isSetLike = isLike;
        })
      }
    })
  }
}
