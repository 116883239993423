import { Injectable } from '@angular/core';
import { S3Service } from 'src/app/service/s3.service';
import { MessageService } from './message.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  urlPrefix = UrlService.prefix;

  constructor(
    private s3Service: S3Service,
    private messageService: MessageService,
  ) { }

  async onFileChange(event, folder) {
    const fileTypes = ["image/gif", "image/jpeg", "image/png"];
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (fileTypes.find(t => t === file.type)) {
        if (file.size < 10000000) {
          const res = await this.s3Service.uploadFile(file, folder);
          return res;
        } else {
          this.messageService.popup({
            title: '업로드',
            description: `이미지 사이즈가 10MB를 넘습니다. (사이즈: 약 ${Math.ceil(file.size / 1000)}KB)`,
            cancelLabel: '확인',
          })
        }
      } else {
        this.messageService.popup({
          title: '업로드',
          description: `다음 이미지 형식만 가능합니다. (${fileTypes})`,
          cancelLabel: '확인',
        })
      }
    } else {
      this.messageService.popup({
        title: '업로드',
        description: `파일이 선택되지 않았습니다.`,
        cancelLabel: '확인',
      })
    }
  }

  getEditorInstance(editorInstance: any, quill: any, path: string) {
    let toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();
      input.onchange = () => {
        const editor = quill.quillEditor;
        const range = editor.getSelection();
        this.fileChanged(quill, range, input.files[0], path);
      };
    })
    editorInstance.on('text-change', (delta) => {
      const images = delta.ops
        .filter(op => op?.insert?.image?.includes('data:image'))
        .map(op => op?.insert?.image);
      images.forEach(image => {
        const file = this.base64ToFile(image, 'file');
        const editor = quill.quillEditor;
        const range = editor.selection.savedRange;
        editor.deleteText(range.index, 1);
        this.fileChanged(quill, range, file, path);
      })
    })
  }

  private fileChanged(quill: any, range: any, file: File, path: string) {
    const fileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
    const editor = quill.quillEditor;
    editor.insertEmbed(range.index, 'image', 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/loading-dark.gif');
    editor.formatText(range.index, 1, 'width', '25px');

    if (fileTypes.find(t => t === file.type)) {
      this.s3Service.uploadFile(file, path).then(res => {
        this.s3Service.resizeImage(res.Key, `${path}-resized`, { options: { width: 800, fit: 'cover' }, gif: true }).then(resp => {
          const link = res.Location.replace(`/${path}`, `/${path}-resized`);
          editor.deleteText(range.index, 1);
          editor.insertEmbed(range.index, 'text', `\n\n\n`, 'user');
          editor.insertEmbed(range.index, 'image', `${link}`, 'user');
        });
      });
    } else {
      this.messageService.popup({
        title: '업로드',
        description: `다음 이미지 형식만 가능합니다. (${fileTypes})`,
        cancelLabel: '확인',
      })
      editor.deleteText(range.index, 1);
    }
  }

  base64ToFile(dataurl, fileName) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  async urlToFile(url) {
    const response = await fetch(url);
    const data = await response.blob();
    const filename = url.split("/").pop();
    const metadata = { type: `image/jpeg` };
    return new File([data], filename!, metadata);
  };
}