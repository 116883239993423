<div class="meeting">
  <skeleton></skeleton>
  <div class="body">
    <p class="sub-title desc">
      <skeleton [width]="'100%'" [height]="'26px'"></skeleton>
    </p>
    <p class="title">
      <skeleton [width]="'90%'" [height]="'18px'"></skeleton>
    </p>
    <p class=" price">
      <skeleton [width]="'40%'" [height]="'20px'"></skeleton>
    </p>
  </div>
</div>