import { User } from './user';

export enum ReportCategory {
    SPAM = '스팸',
    SEXUAL = '나체 이미지 또는 성적 불건전',
    AVERSION = '혐오 발언',
    FRAUD = '사기 또는 거짓',
    VIOLENCE = '폭력 또는 위험한 개인',
    ETC = '기타'
}

export class Report {
    constructor(
        public id: number,
        public reporter: User,
        public user: User,
        public description: string,
        public category: ReportCategory,
        public createdAt: Date,
        public updatedAt: Date,
    ) { }
}

export class ReportCreateDto {
    constructor(
        public category: ReportCategory,
        public userId?: number,
        public feedId?: number,
        public feedCommentId?: number,
        public description?: string,
    ) { }
}

export class BlockCreateDto {
    constructor(
        public userId?: number,
        public feedId?: number,
        public feedCommentId?: number
    ) { }
}

export class BlockAllCreateDto {
    constructor(
        public userId: number
    ) { }
}