<div class="section-md">
    <div *ngIf="product.onlyWomen" class="nnb-badge nnb-badge-md nnb-badge-red"
        style="margin-right: 8px;margin-bottom:16px">여성만 참여가능
    </div>
    <div [ngTemplateOutlet]="saleTpl"></div>
    <div
        style="gap: 8px;margin-top: 16px;margin-bottom: 40px;width: 100%;overflow-x: auto;overflow-y: hidden;white-space: nowrap;display: flex;align-items: center;">
        <div *ngFor="let hashtag of normalHashtags" class="hashtag">#{{hashtag.name}}&nbsp;</div>
    </div>

    <div style="font-size: 16px;font-weight: 500;margin-bottom:8px;">호스트 상세</div>
    <div style="margin-bottom: 8px;border-radius: 8px;border: 1px solid #E8E8EF;padding: 16px;
        display:flex;justify-content: space-between;align-items: center;" (click)="goToHost()">
        <div style="display:flex;justify-content: space-between;align-items: center;gap:4px">
            <img *ngIf="product.host.profilePhoto" style="width:40px;height:40px;border-radius: 50%;"
                [src]="product.host.profilePhoto">
            <img *ngIf="product.host.phoneNumber"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/host.svg" class="nnb-icon">
            <div style="font-weight: 500;">{{product.host.nickname}}</div>
        </div>
        <img [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/arrow-right.svg'"
            style="width:24px;height:24px;">
    </div>
    <div style="font-size: 14px;color:#5B6371;line-height: 140%;">해당 상품을 직접 운영하며 친절함과 체계적인 운영 검증이 완료된 호스트 입니다.</div>

    <ng-container *ngIf="alliance">
        <!-- TODO 현대드림투어 전용상품 하드코딩 -->
        <div *ngIf="!(alliance.company == '현대드림투어' && product.id == 835)" class="chips"
            style="display: flex;gap: 8px;align-items: center; margin: 12px 0px;flex-wrap: wrap;">
            <img [src]="alliance.logo" style="height: 32px;max-width: 120px;object-fit: contain;">
            <div *ngIf="alliance.discountPrice">제휴 {{alliance.discountPrice}}원 추가 할인</div>
            <div *ngIf="alliance.discountPercent">제휴 {{alliance.discountPercent}}% 추가 할인</div>
        </div>
    </ng-container>

    <div *ngIf="product.coupons?.length > 0 && largestCouponDiscountPrice > 0" style="margin-top:40px">
        <div style="font-weight:500;margin-bottom:8px;">적용 가능한 쿠폰이 있어요!</div>
        <button class="nnb-btn-primary nnb-btn-block nnb-btn-lg" style="background: black;" (click)="openCouponBm()">최대
            {{largestCouponDiscountPrice|number}}원 쿠폰 다운로드</button>
    </div>
</div>

<ng-template #couponTpl>
    <div style="font-size: 22px;font-weight: 600;margin-bottom:32px">쿠폰</div>
    <div *ngFor="let coupon of product.coupons">
        <div class="coupon-container">
            <coupon [coupon]="coupon" [isPossible]="coupon.isPossible"></coupon>
            <button *ngIf="coupon.isPossible" class="nnb-btn-primary nnb-btn-block nnb-btn-md"
                (debounceClick)="downloadCoupon(coupon)" style="background: black;margin-top:16px">쿠폰 다운받기</button>
            <button *ngIf="!coupon.isPossible" class="nnb-btn-primary nnb-btn-block nnb-btn-md" disabled
                style="margin-top:16px;display:flex;align-items: center;gap:4px;justify-content: center;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/gray.svg">
                <div>쿠폰 발급 완료</div>
            </button>
        </div>
    </div>
    <button class="nnb-btn-secondary nnb-btn-block nnb-btn-md" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #saleTpl>
    <div style="margin-bottom: 12px;">
        <div [ngTemplateOutlet]="titleTpl"></div>
    </div>
    <div style="display:flex;gap:12px;flex-direction: column;">
        <div style="line-height: 100%;">
            <div *ngIf="product.discountPrice">
                <div style="display:flex;align-items:center;gap:4px;margin-bottom:4px">
                    <div style="color:#570DCA;font-size: 18px;font-weight: 700;line-height: 100%;">
                        {{((product.price-product.discountPrice)/product.price*100).toFixed(0)}}%</div>
                    <div style="color: #A6A8B8;font-size: 14px;font-weight: 400;text-decoration-line: line-through;">
                        {{product.price|number}}원</div>
                </div>
                <div style="font-size: 20px;font-weight: 600;letter-spacing: -0.4px;">
                    <div *ngIf="product.discountPrice > 0" style="display:flex;gap:4px;align-items: flex-end;">
                        <div>{{product.discountPrice|number}}원</div>
                        <div *ngIf="product.minParticipants"
                            style="font-size: 15px;font-weight: 500;line-height: 100%;color:#5B6371">
                            ({{product.minParticipants}}인 기준)
                        </div>
                    </div>
                    <div *ngIf="product.discountPrice == 0">무료</div>
                </div>
            </div>
            <div *ngIf="!product.discountPrice">
                <div style="font-size: 20px;font-weight: 600;letter-spacing: -0.4px;">
                    <div *ngIf="product.price > 0" style="display:flex;gap:4px;align-items: flex-end;">
                        <div>{{product.price|number}}원</div>
                        <div *ngIf="product.minParticipants"
                            style="font-size: 15px;font-weight: 500;line-height: 100%;color:#5B6371">
                            ({{product.minParticipants}}인 기준)
                        </div>
                    </div>
                    <div *ngIf="product.price == 0">무료</div>
                </div>
            </div>
            <div *ngIf="product.maxParticipants && product.maxPrice"
                style="display:flex;gap:4px;align-items: center;margin-top:8px">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user-dark-grey.svg">
                <div style="color: #5B6371;font-size: 14px;font-weight: 600;line-height: 100%;">
                    {{product.maxParticipants}}인</div>
                <div style="color: #5B6371;font-size: 14px;font-weight: 600;line-height: 100%;">
                    {{product.maxPrice|number}}원</div>
            </div>
        </div>
        <div style="display:inline-flex;gap:6px;flex-direction:column;align-items: flex-start;">
            <div *ngIf="product.isAddDiscountPrice" class="nnb-badge nnb-badge-md nnb-badge-blue"
                style="height:30px;display: inline-flex;gap: 3px;align-items: center;background: #EFF6FF;color:#1D4ED8">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/receipt-tax.svg">
                추가인원 할인
            </div>
            <!-- <div class="nnb-badge nnb-badge-md nnb-badge-purple"
          style="height:30px;display: inline-flex;gap: 3px;align-items: center;">
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/coupon-ticket.svg">
          최대 10,000원 쿠폰할인
        </div> -->
            <div *ngIf="product.agency" class="nnb-badge nnb-badge-md nnb-badge-light-grey"
                style="height:30px;display: inline-flex;gap: 3px;align-items: center;">
                <img *ngIf="product.agencyImage" style="width:16px;height:16px" [src]="product.agencyImage">
                {{product.agency}}
            </div>
        </div>
        <div style="display:flex;gap:17.5px;align-items: center;">
            <div style="display:flex;gap:4px;align-items: center;" *ngIf="product.reviewFeedCount > 0">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/message-filled.svg"
                    style="width:18px;height:18px">
                <div class="info">후기</div>
                <div class="like" style="text-decoration: underline;" (click)="goToFeed()">{{product.reviewFeedCount}}건
                </div>
            </div>
            <div style="display:flex;gap:4px;align-items: center;" *ngIf="product.likes > 0">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-dark-grey.svg"
                    style="width:18px;height:18px">
                <div class="info">찜</div>
                <div class="info">{{product.likes}}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #titleTpl>
    <div
        style="display:flex;gap:4px;align-items: center;font-size: 15px;font-weight: 500;margin-bottom: 2px;color:#5B6371">
        <div>{{product.representationCategory}}·{{region}}</div>
        <div>{{product.bizName}}</div>
    </div>
    <div class="title">{{product.title}}</div>
</ng-template>