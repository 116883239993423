import { Component, OnInit } from '@angular/core';
import { ChannelService } from './../../service/channel.service';

@Component({
  selector: 'contact-footer',
  templateUrl: './contact-footer.component.html',
  styleUrls: ['./contact-footer.component.scss'],
})
export class ContactFooterComponent implements OnInit {
  constructor(
    private channelService: ChannelService
  ) { }

  ngOnInit() {
  }

  openChanneltalk() {
    this.channelService.openChat()
  }
}
