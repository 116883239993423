<div [@triggerName]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<div id="tabBar">
  <div style="height:74px"></div>

  <div
    style="background:#fff;height: 66px;padding:10px 0px;width: 100%;bottom:0;position:fixed;z-index: 900;box-shadow: 0 -6px 16px rgba(0, 0, 0, 0.02)">
    <div class="container" style="display:flex;justify-content:space-around">
      <div class="menu" routerLink="/tabs/home" routerLinkActive="active" #rlaHome="routerLinkActive">
        <img
          [src]="rlaHome.isActive ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/home-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/home-off.svg'">
        <div>홈</div>
      </div>
      <div class="menu" routerLink="/tabs/feed" routerLinkActive="active" #rlaFeed="routerLinkActive">
        <img
          [src]="rlaFeed.isActive ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/soup-on.svg': 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/soup-off.svg'">
        <div>커뮤니티</div>
      </div>
      <div class="menu" routerLink="/tabs/coaching" routerLinkActive="active" #rlaCoaching="routerLinkActive">
        <img
          [src]="rlaCoaching.isActive ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/wellness-on.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/wellness-off.svg'">
        <div>웰니스</div>
      </div>
      <div *ngIf="!alliance" class="menu" routerLink="/tabs/chat" routerLinkActive="active" #rlaChat="routerLinkActive">
        <img
          [src]="rlaChat.isActive ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chat-on.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chat-off.svg'">
        <div>대화</div>
      </div>
      <div class="menu" routerLink="/tabs/my-info" routerLinkActive="active" #rlaMyInfo="routerLinkActive">
        <img
          [src]="rlaMyInfo.isActive ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user-on.svg':'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user-off.svg'">
        <div>나의 노는법</div>
      </div>
    </div>
  </div>
</div>

<popup [config]="popupConfig"></popup>

<ngx-loading-bar [color]="'#6c757d'" [includeSpinner]="false"></ngx-loading-bar>