import { Component, Input } from '@angular/core';

@Component({
  selector: 'empty',
  templateUrl: './empty.component.html',
})
export class EmptyComponent {
  @Input() label = '비어있습니다.'
  @Input() image = 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/soup-off.svg'
  @Input() imageWidth = 48;
  @Input() imageHeight = 48;
  @Input() margin: string;

  constructor() { }
}
