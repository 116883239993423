<div *ngIf="data.type!=='like'" style="text-align:center;white-space: pre-wrap;">
  <div *ngIf="!data.icon">{{data.message}}</div>
  <div *ngIf="data.icon" style="display:flex;gap:8px;align-items: center;justify-content: center;">
    <img [src]="data.icon" style="height:36px">
    <div>{{data.message}}</div>
  </div>
</div>
<div *ngIf="data.type==='like'">
  <div
    style="justify-content: center;white-space: pre-wrap;display:flex;gap:2px;align-items:center;margin-bottom: 1px;">
    <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg">
    <div>찜한 모임에 저장!</div>
  </div>
  <div style="text-align:center;font-size:14px;">나의노는법>찜한 모임에서 <span style="text-decoration:underline;"
      [routerLink]="'/tabs/my-like-product'">확인</span></div>
</div>