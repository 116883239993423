import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { OrderCreateDto, OrderItemCreateDto, PayMethod, PaymentCreateDto } from 'src/app/model/payment';
import { EndStandard, ExpectedPrice, Product, ProductStatus } from 'src/app/model/product';
import { Gender, User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { ChatService } from 'src/app/service/chat.service';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { UserService } from 'src/app/service/user.service';
import { ShareInfo, UtilService } from 'src/app/service/util.service';
import { environment } from 'src/environments/environment';
import { CalendarHeader } from '../../calendar/calendar.component';
import { Ticket } from './../../../model/product';
import { PaymentService } from './../../../service/payment.service';
import { TicketService } from './../../../service/ticket.service';
declare var gtag;

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(25%)' }),
        animate('80ms', style({ transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('80ms', style({ transform: 'translateY(25%)' }))
      ])
    ]
    )
  ],
})
export class PaymentComponent implements OnInit {
  @Input() product: Product;
  ProductStatus = ProductStatus;
  currentUser: User;
  form: FormGroup;
  isCalendarView = true;
  @Input() dates: { date: Date, isClosed: boolean, isReservationEnd: boolean }[];
  @Input() startAt: Date;
  @Input() user: User;
  selectedDate: Date;
  selectedTickets: Ticket[];
  selectedTicket: Ticket;
  expectedPrices: ExpectedPrice[];
  EndStandard = EndStandard;
  quantity: number;
  price: number;
  discountPrice: number;
  discountPercent: number;
  startDateString: string;
  calendarHeader = CalendarHeader;
  phoneNumberForm: FormGroup;

  isShowParticipants: boolean;

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private router: Router,
    private chatService: ChatService,
    private messageService: MessageService,
    private bottomSheet: MatBottomSheet,
    private ticketService: TicketService,
    private utilService: UtilService,
    private userService: UserService,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this.isShowParticipants = !!this.product?.hashtags?.find(h => h.name === '참여 현황 전시')
    this.phoneNumberForm = new FormGroup({
      phone: new FormControl(this.user?.phoneNumber, [Validators.required, Validators.pattern("[0-9]{11}")]),
    })
    this.authService.getCurrentUser().subscribe(resp => {
      this.currentUser = resp;
      this.productService.getTicketGroups(this.product.id, false, true, false).subscribe(resp => {
        const sorted = resp.sort((a, b) => a.hours > b.hours ? 1 : -1);
        const group = this.utilService.groupBy(sorted, (group) => {
          if (group.minutes !== 0) return `${group.hours}시 ${group.minutes}분`;
          else return `${group.hours}시`;
        });
        const set = Array.from(new Set(Object.keys(group)));
        if (set.length === 1) {
          this.startDateString = `${set[0]} 시작`;
        } else {
          this.startDateString = `${set[0]} 시작 외 ${set.length - 1}개`
        }
      })

      this.form = new FormGroup({
        date: new FormControl('', Validators.compose([Validators.required]))
      })
      this.form.controls.date.valueChanges.subscribe(date => {
        this.startAt = date;
        this.selectedDate = date;
        this.selectedTicket = undefined;
        this.productService.getTicketByDate(this.product.id, moment(this.selectedDate).format('YYYY-MM-DD')).subscribe(resp => {
          this.selectedTickets = resp.sort((a, b) => {
            return a.group.id < b.group.id ? -1 : 1;
          }).filter(ticket => {
            return !ticket.isClosed &&
              // !ticket.isReservationEnd &&
              this.productService.canReservation(moment(ticket.date).format('YYYY-MM-DD HH:mm:ss'), this.product.reservationHours)
          }).map(ticket => {
            ticket = this.ticketService.setMinPrice(ticket);
            ticket.options = ticket.options.map(option => this.ticketService.setMinPrice(option));
            return ticket;
          })
          this.isCalendarView = false;
          this.form.controls.date.reset(undefined, { emitEvent: false });
        });
      })
    })
  }

  updateFormDate(value) {
    const date = this.dates.find(date => moment(date.date).format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD'));
    if (!date.isClosed) {
      this.form.get('date').setValue(value);
    }
  }

  dateFilter = (calendarDate): boolean => {
    const isSame = !!this.dates.find(date => {
      return moment(date.date).format('YYYY-MM-DD') === moment(calendarDate).format('YYYY-MM-DD') &&
        // !date.isClosed &&
        // !date.isReservationEnd &&
        this.productService.canReservation(moment(date.date).format('YYYY-MM-DD HH:mm:ss'), this.product.reservationHours)
    });
    return isSame;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const date = this.dates.find(date => moment(date.date).format('YYYY-MM-DD') === moment(cellDate).format('YYYY-MM-DD'));
      if (date?.isClosed) {
        // return 'closed-date';
        return 'reservation-end-date';
      }
      if (date?.isReservationEnd) {
        return 'reservation-end-date';
      }
      return '';
    }
    return '';
  }

  question() {
    if (!this.currentUser) {
      this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
      return;
    }
    if (this.product.host.id === this.currentUser.id) {
      this.messageService.popup({
        title: '호스트 문의',
        description: '이 모임의 호스트입니다.',
        cancelLabel: '확인',
      })
      return;
    }
    this.chatService.getChatRoomByUser(this.product.host.id, this.product.id).subscribe(chatRoom => {
      if (chatRoom) this.router.navigate(['/tabs/chat-detail', chatRoom.id])
      else {
        this.chatService.askToHost(this.product.host.id, this.product.id).subscribe(resp => {
          this.router.navigate(['/tabs/chat-detail', resp.id])
        })
      }
    })
  }

  openBm(template) {
    if (!this.currentUser) {
      this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
      return;
    }
    this.authService.getCurrentUserFromServer().subscribe(resp => {
      this.currentUser = resp;
      if (!this.product.onlyWomen) {
        this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet-payment' });
        return;
      }
      if (!this.currentUser.ci) {
        this.messageService.popup({
          title: '본인인증 필요',
          description: '이 모임은 여성만 참여 할 수 있습니다. 최초 1회 본인인증이 필요합니다.',
          confirmLabel: '본인인증 하기',
          confirmBtnClass: 'primary',
          cancelLabel: '취소',
          confirmHandler: () => {
            window.location.href = `${environment.host}/auth/danal?userId=${this.currentUser.id}&returnUrl=/tabs/meeting-detail/${this.product.id}`;
          }
        })
        return;
      }
      if (this.currentUser.gender === Gender.MALE) {
        this.messageService.toast('💁🏻‍♀️ 이 모임은 여성만 참여 할 수 있습니다.')
        return;
      }
      this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet-payment' });
    })
  }

  closeBm() {
    this.bottomSheet.dismiss()
  }

  selectTicket(ticket: Ticket) {
    if (ticket.isReservationEnd) {
      return;
    }
    if (this.selectedTicket) {
      if (this.selectedTicket.id === ticket.id) {
        this.selectedTicket = undefined;
      } else {
        this.selectedTicket = ticket;
      }
    } else {
      this.selectedTicket = ticket;
    }

    if (this.selectedTicket) {
      if (this.selectedTicket.endStandard === EndStandard.PER_PERSON) {
        this.quantity = 1;
        const { pricePerPerson, prices, maxParticipants } = this.selectedTicket;
        this.expectedPrices = this.ticketService.getPerPersonExpectedPrices(pricePerPerson, prices, maxParticipants)
      } else {
        this.quantity = 1;
        const { minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices } = this.selectedTicket;
        this.expectedPrices = this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices);
      }
      this.setPrice();
    }
  }

  setPrice() {
    const expectedPrice = this.expectedPrices.find(value => value.quantity === this.quantity);
    if (expectedPrice) {
      this.price = expectedPrice.price;
      this.discountPrice = expectedPrice.discountPrice;
      this.discountPercent = expectedPrice.discountPercent;
    }
  }

  clickSelectedDate() {
    this.isCalendarView = true;
    this.selectedTicket = undefined;
  }

  changeCount(flag: boolean) {
    const value = flag ? +this.quantity + 1 : +this.quantity - 1;
    if (this.selectedTicket.endStandard === EndStandard.PER_PERSON) {
      const min = 1;
      const max = this.selectedTicket.maxParticipants;
      if (value < min) {
        this.messageService.toast(`💁🏻‍♀️ 최소인원은 ${min}명입니다.`)
        return;
      }
      if (value + this.selectedTicket.paymentedCount > max) {
        this.messageService.toast(`💁🏻‍♀️ 가능한 자리는 ${max - this.selectedTicket.paymentedCount}명입니다.`)
        return;
      }
    } else {
      const min = 1;
      const max = this.selectedTicket.maxParticipantsPerTeam;
      if (value < min) {
        this.messageService.toast(`💁🏻‍♀️ 최소인원은 ${min}명입니다.`)
        return;
      }
      if (value > max) {
        this.messageService.toast(`💁🏻‍♀️ 가능한 자리는 ${max}명입니다.`)
        return;
      }
    }
    this.quantity = value;
    this.setPrice();
  }

  payment(addPhoneTpl) {
    if (!this.selectedTicket) {
      this.messageService.popup({
        title: '결제하기',
        description: '티켓을 선택해주세요.',
        cancelLabel: '확인'
      })
      return;
    }
    const selectInfo = JSON.stringify({ ticketId: this.selectedTicket.id, quantity: this.quantity });
    if (this.discountPrice === 0) {
      this.authService.getCurrentUser().subscribe(user => {
        if (!user.phoneNumber) {
          this.bottomSheet.open(addPhoneTpl);
        } else {
          this.messageService.popup({
            title: '참여하기',
            description: '확인을 누르시면 바로 참여 예약됩니다.',
            confirmLabel: '확인',
            confirmBtnClass: 'primary',
            confirmHandler: () => {
              this.bottomSheet.dismiss();
              const now = new Date();
              const items = [new OrderItemCreateDto(this.selectedTicket.id, this.quantity)];
              const order = new OrderCreateDto(this.user.id, this.product.id, undefined, undefined, now, items)
              const dto = new PaymentCreateDto(order, undefined, undefined, now, 0, PayMethod.FREE, 0, 0, true, '무료 모임 참여');
              this.paymentService.join(dto).subscribe(resp => {
                this.router.navigate(['/tabs/payment-success', resp.id])
              });
            },
            cancelLabel: '취소'
          })
        }
      })
    } else {
      this.bottomSheet.dismiss();
      if (!this.currentUser) {
        this.router.navigate(['/tabs/login'], { queryParams: { nonMember: true, returnUrl: `/tabs/payment/pay?queryParams=${selectInfo}` } });
      } else {
        this.router.navigate(['/tabs/payment/pay'], { queryParams: { queryParams: selectInfo } });
      }
    }
  }

  closeAddPhoneTpl(selectTicketTpl) {
    this.closeBm();
    this.openBm(selectTicketTpl)
  }

  like(id: number, isLike: boolean) {
    if (!this.user) {
      this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
    } else {
      this.userService.likeProduct(id, isLike).subscribe(resp => {
        this.productService.getRefinedProduct(this.product.id, this.user.id).subscribe(resp => {
          this.product = resp;
          if (isLike) this.messageService.toast(undefined, undefined, 'like');
        })
      })
    }
  }

  async share(sns: string) {
    const url = `https://nonunbub.com/tabs/meeting-detail/${this.product.id}`;
    const shareInfo: ShareInfo = {
      sns,
      kakaotalk: {
        url: `${url}?utm_source=nonunbub&utm_medium=share&utm_campaign=kakaotalk&utm_content=${this.product.id}`,
        title: '노는법',
        description: this.product.title,
        imageUrl: this.product.representationPhotos[0].photo,
        handler: () => {
          if (environment.production) {
            gtag('event', 'share', {
              method: 'kakaotalk',
              item_id: `${this.product.id}`,
              item_name: `${this.product.title}`,
            });
          }
        }
      },
      link: {
        url: `${url}?utm_source=nonunbub&utm_medium=share&utm_campaign=link_copy&utm_content=${this.product.id}`,
        handler: () => {
          this.bottomSheet.dismiss();
          if (environment.production) {
            gtag('event', 'share', {
              method: 'link_copy',
              item_id: `${this.product.id}`,
              item_name: `${this.product.title}`,
            });
          }
        }
      }
    }
    this.utilService.share(shareInfo);
  }

  openShareModal(meeting: Product, template: TemplateRef<any>) {
    this.product = meeting;
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }
}