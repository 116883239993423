import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Category } from 'src/app/model/category';
import { Product } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { CheckDesktopService } from './../../service/check-desktop.service';

@Component({
  selector: 'meeting-preview',
  templateUrl: './meeting-preview.component.html',
  styleUrls: ['./meeting-preview.component.scss'],
})
export class MeetingPreviewComponent implements OnInit, OnChanges {
  quillStyle;
  @Input() product: Product;
  host: User;
  isDesktop = true;
  category: Category;
  representationScrollConfigs = {
    pagination: {
      clickable: true,
      type: 'fraction'
    },
    speed: 400,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true
    },
  }

  constructor(
    private utilService: UtilService,
    private authService: AuthService,
    private cds: CheckDesktopService,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    this.cds.isDesktop.subscribe(resp => this.isDesktop = resp);
    this.authService.getCurrentUser().subscribe(user => {
      this.host = user;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.product.firstChange) {
      this.quillStyle = this.utilService.getQuillStyle();
    }
  }

  openBm(template) {
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  closeBm() {
    this.bottomSheet.dismiss();
  }

  setPosition(sectionId: string, productId: number) {
    const id = `section-${sectionId}-${productId}`;
    const yOffset = -80;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
