import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseCreateDto, HashtagCreateDto, HashtagType, Product, ProductRepresentationPhotoCreateDto, ProductUpdateDto } from 'src/app/model/product';
import { AuthService } from 'src/app/service/auth.service';
import { FormService } from 'src/app/service/form.service';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { S3Service } from 'src/app/service/s3.service';
import { UtilService } from 'src/app/service/util.service';
import { environment } from 'src/environments/environment';
import { MeetingControl } from './../meeting-add/meeting-control';

@Component({
  selector: 'meeting-edit',
  templateUrl: './meeting-edit.page.html',
  styleUrls: ['./meeting-edit.page.scss'],
})
export class MeetingEditPage extends MeetingControl implements OnInit, AfterViewInit {
  meeting: Product;

  constructor(
    private formService: FormService,
    private router: Router,
    public ngZone: NgZone,
    public s3Service: S3Service,
    private utilService: UtilService,
    public authService: AuthService,
    private fb: FormBuilder,
    private productService: ProductService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    public bottomSheet: MatBottomSheet
  ) {
    super(ngZone, s3Service, authService, bottomSheet);
  }

  ngOnInit() {
    //TODO set list hashtags
    this.productService.searchHashtag({ page: 1, limit: 9999, isAnalysis: false }).subscribe(resp => {
      this.hashtags = resp.items;
    })
    this.productService.searchAmenity({ page: 1, limit: 9999 }).subscribe(resp => {
      this.amenities = resp.items;
    })
    this.authService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
    this.quillStyle = this.utilService.getQuillStyle();
    this.meetingForm = this.fb.group({
      version: new FormControl(''),
      title: new FormControl('', this.formService.getValidators(30)),
      hashtags: new FormControl('', Validators.required),
      photos: new FormArray([], Validators.required),
      address: new FormControl('', this.formService.getValidators(65535)),
      detailAddress: new FormControl('', this.formService.getValidators(500)),
      howToCome: new FormControl('', Validators.maxLength(1000)),
      runningDays: new FormControl('', Validators.compose([Validators.required, Validators.min(0), Validators.max(30)])),
      runningHours: new FormControl('', Validators.compose([Validators.required, Validators.min(0), Validators.max(23)])),
      runningMinutes: new FormControl('', Validators.compose([Validators.required, Validators.min(0), Validators.max(50)])),
      reservationHours: new FormControl('', Validators.compose([Validators.required, Validators.min(6), Validators.max(120)])),
      price: new FormControl('', this.formService.getValidators(10, [Validators.min(0), Validators.max(100000000), Validators.pattern("^[0-9]*$")])),
      discountPrice: new FormControl('', [Validators.min(1), Validators.max(100000000), Validators.pattern("^[0-9]*$"), this.validateDiscountPrice('price')]),
      recommend: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])),
      description: new FormControl(''),
      courses: new FormArray([]),
      notice: new FormControl('', Validators.maxLength(500)),
      checkList: new FormControl('', this.formService.getValidators(500)),
      includeList: new FormControl('', Validators.maxLength(500)),
      excludeList: new FormControl('', Validators.maxLength(500)),
      amenities: new FormArray([]),
      isAmenity: new FormControl(),
      refundPolicy: new FormControl('', this.formService.getValidators(1000)),
    })
    this.route.params.subscribe(params => {
      this.productService.getRefinedProduct(+params.id).subscribe(resp => {
        this.meeting = resp;
        if (this.meeting.version === 1) {
          this.meetingForm.addControl('point', new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])))
        } else if (this.meeting.version === 2) {
          this.meetingForm.addControl('summaries', new FormArray([]))
          this.meetingForm.addControl('sections', new FormArray([]))
        }
        this.setFormControlsValue(this.meeting);
        this.loadMap(this.meeting);
      }, error => {
        if ((error as HttpErrorResponse).status === 404) {
          this.messageService.popup({
            title: '상품 수정',
            description: '상품이 존재하지 않습니다.',
            cancelLabel: '확인',
          })
          this.router.navigate(['/tabs/home']);
        }
      });
    })
  }

  private setFormControlsValue(product: Product) {
    const noti = product.notice ? product.notice.replace(/<br>/g, "\n") : product.notice;
    const check = product.checkList ? product.checkList.replace(/<br>/g, "\n") : product.checkList;
    const inc = product.includeList ? product.includeList.replace(/<br>/g, "\n") : product.includeList;
    const ex = product.excludeList ? product.excludeList.replace(/<br>/g, "\n") : product.excludeList;
    this.meetingForm.controls.version.setValue(product.version);
    this.meetingForm.controls.title.setValue(product.title);
    this.meetingForm.controls.hashtags.setValue(product.hashtags.filter(h => !h.isAnalysis).map(h => h.id));
    const photoArray = (this.meetingForm.controls.photos as FormArray)
    product.representationPhotos.forEach((photo, index) => {
      photoArray.setControl(index, new FormControl(photo, Validators.required))
    })
    this.meetingForm.controls.address.setValue(product.address);
    this.meetingForm.controls.detailAddress.setValue(product.detailAddress);
    this.meetingForm.controls.howToCome.setValue(product.howToCome);
    this.meetingForm.controls.runningDays.setValue(product.runningDays);
    this.meetingForm.controls.runningHours.setValue(product.runningHours);
    this.meetingForm.controls.runningMinutes.setValue(product.runningMinutes);
    this.meetingForm.controls.reservationHours.setValue(product.reservationHours);
    this.meetingForm.controls.price.setValue(product.price);
    this.meetingForm.controls.discountPrice.setValue(product.discountPrice);
    this.meetingForm.controls.recommend.setValue(product.recommend);
    this.meetingForm.controls.description.setValue(product.description);
    const amenityArray = (this.meetingForm.controls.amenities as FormArray);
    product.amenities.forEach(amenity => {
      amenityArray.push(new FormControl(amenity.id));
    })
    this.meetingForm.controls.isAmenity.setValue(product.amenities?.length > 0);
    const courseArray = (this.meetingForm.controls.courses as FormArray);
    const courseGroup = this.utilService.groupBy(product.courses, course => course.title);
    Object.keys(courseGroup).forEach(key => {
      courseArray.push(new FormGroup({
        title: new FormControl(key, this.formService.getValidators(20)),
        schedules: new FormArray(courseGroup[key].map(element => {
          return new FormGroup({
            subTitle: new FormControl(element.subTitle, this.formService.getValidators(20)),
            content: new FormControl(element.content, Validators.required),
            note: new FormControl(element.note),
          })
        }))
      }))
    });
    this.meetingForm.controls.notice.setValue(noti);
    this.meetingForm.controls.checkList.setValue(check);
    this.meetingForm.controls.includeList.setValue(inc);
    this.meetingForm.controls.excludeList.setValue(ex);
    this.meetingForm.controls.refundPolicy.setValue(product.refundPolicy);

    if (this.meeting.version === 1) {
      this.meetingForm.controls.point.setValue(product.point);
    } else if (this.meeting.version === 2) {
      const summaryArray = (this.meetingForm.controls.summaries as FormArray);
      product.summaries.forEach(summary => {
        const photoArray = new FormArray([]);
        summary.photos.forEach((photo, index) => {
          photoArray.push(new FormGroup({
            photo: new FormControl(photo)
          }))
        })
        summaryArray.push(new FormGroup({
          photos: photoArray,
          title: new FormControl(summary.title, this.formService.getValidators(20)),
          description: new FormControl(summary.description, this.formService.getValidators(200)),
        }))
      })
      const sectionArray = (this.meetingForm.controls.sections as FormArray);
      product.sections.forEach(section => {
        const paragraphArray = new FormArray([], Validators.required);
        section.paragraphs.forEach(paragraph => {
          const photoArray = new FormArray([]);
          paragraph.photos.forEach((photo, index) => {
            photoArray.push(new FormGroup({
              photo: new FormControl(photo)
            }))
          })
          paragraphArray.push(new FormGroup({
            title: new FormControl(paragraph.title, this.formService.getValidators(20)),
            time: new FormControl(paragraph.time, Validators.maxLength(20)),
            photos: photoArray,
            description: new FormControl(paragraph.description, Validators.required),
            note: new FormControl(paragraph.note),
          }))
        })
        sectionArray.push(new FormGroup({
          title: new FormControl(section.title, this.formService.getValidators(20)),
          paragraphs: paragraphArray
        }))
      })
    }
  }

  loadMap(meeting: Product) {
    this.latitude = meeting.lat
    this.longitude = meeting.lon;
    this.zoom = 15;
  }

  async edit() {
    this.isLoad = true;
    const { version, title, hashtags, photos, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes, reservationHours, price, discountPrice, point, recommend,
      summaries, description, sections, courses, amenities, notice, checkList, includeList, excludeList, refundPolicy } = this.meetingForm.value;
    const hashtagNames = hashtags.map(v => {
      if (v.label) return new HashtagCreateDto(HashtagType.PRODUCT, false, v.label);
      else return new HashtagCreateDto(HashtagType.PRODUCT, false, undefined, v);
    });
    if (version === 2) {
      let summaryPhotoList = [];
      for (let summary of summaries) {
        for (let photo of summary.photos) {
          if (photo.file) {
            const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
            await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { height: 800, fit: 'cover' }, gif: true });
            const link = res.Location.replace(`/meetings`, `/meetings-resized`);
            summaryPhotoList.push(link)
          } else {
            summaryPhotoList.push(photo.photo ?? photo);
          }
        }
        summary.photos = summaryPhotoList;
        summaryPhotoList = [];
      }
      let sectionPhotoList = [];
      for (let section of sections) {
        for (let paragraph of section.paragraphs) {
          for (let photo of paragraph.photos) {
            if (photo.file) {
              const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
              await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { width: 800, height: 800, fit: 'cover' }, gif: true });
              const link = res.Location.replace(`/meetings`, `/meetings-resized`);
              sectionPhotoList.push(link)
            } else {
              sectionPhotoList.push(photo.photo ?? photo)
            }
          }
          paragraph.photos = sectionPhotoList;
          sectionPhotoList = [];
        }
      }
    }
    const courseList = [];
    courses.forEach(course => {
      course.schedules.forEach(schedule => {
        courseList.push(new CourseCreateDto(course.title, schedule.subTitle, schedule.content, schedule.note));
      })
    });
    const dp = (discountPrice === '' || discountPrice === null) ? null : +discountPrice;
    for (let photo of photos) {
      if (photo.file) {
        const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
        await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { width: 800, height: 800, fit: 'cover' }, gif: true });
        const link = res.Location.replace(`/meetings`, `/meetings-resized`);
        photo.photo = link;
        delete photo.file;
      }
    }
    const photoDtos = photos.map(photo => new ProductRepresentationPhotoCreateDto(photo.photo, 0))

    const dto = {
      version, title, point, recommend, summaries, description, sections, courses: courseList, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes, reservationHours,
      price: +price, discountPrice: dp,
      lat: this.latitude, lon: this.longitude, country: this.country, sido: this.sido, sigugun: this.sigugun, dongmyun: this.dongmyun, ri: this.ri, rest: this.rest,
      notice, checkList, includeList, excludeList, refundPolicy, sortOrder: this.meeting.sortOrder,
      status: this.meeting.status, isBusiness: true, hashtags: hashtagNames, amenities, representationPhotos: photoDtos, isOffline: true, onlyWomen: this.meeting.onlyWomen
    } as ProductUpdateDto
    this.productService.updateProduct(this.meeting.id, dto).subscribe(product => {
      this.messageService.popup({
        title: '상품 수정',
        description: '상품을 수정하였습니다.',
        cancelLabel: '확인',
      })
      this.passDeactivate = true;
      this.isLoad = false;
      this.router.navigate(['/host/meeting-management/hosted-meetings']);
      this.meetingForm.reset();
    }, error => {
      this.messageService.popup({
        title: '상품 수정',
        description: '상품 수정에 문제가 발생했습니다. 작성하신 내용이 저장되지 않을 수 있으니 백업 하시고 노는법에게 문의주세요.',
        cancelLabel: '확인',
      })
      console.log(error);
      this.isLoad = false;
    })
  }
}
