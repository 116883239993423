import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgSelectModule } from '@ng-select/ng-select';
import { PullToRefreshModule } from '@piumaz/pull-to-refresh';
import { LightgalleryModule } from 'lightgallery/angular/12';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { koLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCronUiModule } from 'ngx-cron-ui';
import { TableModule } from 'ngx-easy-table';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { SurveyModule } from "survey-angular-ui";
import { SwiperModule } from 'swiper/angular';
import { environment } from './../environments/environment';
import { BubbleComponent } from './component/bubble/bubble.component';
import { CalendarHeader } from './component/calendar/calendar.component';
import { ChatRoomComponent } from './component/chat-room/chat-room.component';
import { CommentComponent } from './component/comment/comment.component';
import { ContactFooterComponent } from './component/contact-footer/contact-footer.component';
import { ContactComponent } from './component/contact/contact.component';
import { CouponComponent } from './component/coupon/coupon.component';
import { CronUiComponent } from './component/cron-ui/cron-ui.component';
import { EmptyMeetingCardComponent } from './component/empty-meeting-card/empty-meeting-card.component';
import { EmptyComponent } from './component/empty/empty.component';
import { FeedListComponent } from './component/feed-list/feed-list.component';
import { FeedComponent } from './component/feed/feed.component';
import { FooterComponent } from './component/footer/footer.component';
import { FormErrorsComponent } from './component/form-errors/form-errors.component';
import { FormInputComponent } from './component/form-input/form-input.component';
import { HeaderComponent } from './component/header/header.component';
import { InfinityScrollComponent } from './component/infinity-scroll/infinity-scroll.component';
import { MapComponent } from './component/map/map.component';
import { MeetingControlComponent } from './component/meeting-control/meeting-control.component';
import { PaymentComponent } from './component/meeting-detail/payment/payment.component';
import { SummaryComponent } from './component/meeting-detail/summary/summary.component';
import { MeetingPreviewComponent } from './component/meeting-preview/meeting-preview.component';
import { MyPaymentComponent } from './component/my-payment/my-payment.component';
import { NotionDescComponent } from './component/notion-desc/notion-desc.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { PopupComponent } from './component/popup/popup.component';
import { PriceComponent } from './component/price/price.component';
import { ProductCardComponent } from './component/product-card/product-card.component';
import { SectionListComponent } from './component/section-list/section-list.component';
import { SegmentComponent } from './component/segment/segment.component';
import { ShareComponent } from './component/share/share.component';
import { ScheduleControlComponent } from './component/shedule-control/schedule-control.component';
import { ScheduleDetailComponent } from './component/shedule-detail/schedule-detail.component';
import { SkeletonComponent } from './component/skeleton/skeleton.component';
import { SnackBarComponent } from './component/snackbar/snackbar.component';
import { WellnessCardComponent } from './component/wellness-card/wellness-card.component';
import { ClickLogDirective } from './directive/click-log.directive';
import { DebounceClickDirective } from './directive/debounce-click.directive';
import { InputDigitsOnlyDirective } from './directive/input.digits.only.directive';
import { LinkDirective } from './directive/link.directive';
import { ScrollDepthDirective } from './directive/scroll-depth.directive';
import { DateAgoPipe } from './pipe/date-ago.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';

export const CUSTOM_DATE_TIME_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

defineLocale('ko', koLocale);

FullCalendarModule.registerPlugins([
  dayGridPlugin, interactionPlugin
]);

@Injectable({
  providedIn: 'root'
})
export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Add a second'
  downSecondLabel = 'Minus a second'
  upMinuteLabel = 'Add a minute'
  downMinuteLabel = 'Minus a minute'
  upHourLabel = 'Add a hour'
  downHourLabel = 'Minus a hour'
  prevMonthLabel = 'Previous month'
  nextMonthLabel = 'Next month'
  prevYearLabel = 'Previous year'
  nextYearLabel = 'Next year'
  prevMultiYearLabel = 'Previous 21 years'
  nextMultiYearLabel = 'Next 21 years'
  switchToMonthViewLabel = 'Change to month view'
  switchToMultiYearViewLabel = 'Choose month and year'
  cancelBtnLabel = '취소'
  setBtnLabel = '설정'
  rangeFromLabel = 'From'
  rangeToLabel = 'To'
  hour12AMLabel = '오전'
  hour12PMLabel = '오후'
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['일', '월', '화', '수', '목', '금', '토'];
  }
  override getFirstDayOfWeek(): number {
    return 0;
  }
}

@NgModule({
  declarations: [
    TruncatePipe,
    DateAgoPipe,
    ProductCardComponent,
    EmptyMeetingCardComponent,
    SkeletonComponent,
    CommentComponent,
    FormInputComponent,
    InputDigitsOnlyDirective,
    DebounceClickDirective,
    LinkDirective,
    ClickLogDirective,
    ScrollDepthDirective,
    FooterComponent,
    MeetingPreviewComponent,
    MeetingControlComponent,
    FormErrorsComponent,
    MyPaymentComponent,
    NotionDescComponent,
    PriceComponent,
    PaginationComponent,
    MapComponent,
    EmptyComponent,
    ChatRoomComponent,
    PaymentComponent,
    SummaryComponent,
    FeedComponent,
    BubbleComponent,
    CouponComponent,
    SegmentComponent,
    HeaderComponent,
    InfinityScrollComponent,
    ScheduleControlComponent,
    CronUiComponent,
    ScheduleDetailComponent,
    PopupComponent,
    ContactFooterComponent,
    CalendarHeader,
    SectionListComponent,
    SnackBarComponent,
    ContactComponent,
    ShareComponent,
    WellnessCardComponent,
    FeedListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: '#ql-toolbar'
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey,
      libraries: ['places']
    }),
    ClipboardModule,
    ModalModule.forRoot(),
    TableModule,
    NgxCronUiModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    CarouselModule.forRoot(),
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    ImageCropperModule,
    NgxMaskModule.forRoot(),
    SwiperModule,
    PullToRefreshModule,
    InfiniteScrollModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    DragDropModule,
    SurveyModule,
    LightgalleryModule,
    MatStepperModule,
    MatTooltipModule,
    ProgressbarModule.forRoot()
  ],
  exports: [
    ProductCardComponent,
    EmptyMeetingCardComponent,
    SkeletonComponent,
    CommentComponent,
    MyPaymentComponent,
    NotionDescComponent,
    FormInputComponent,
    PriceComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    TruncatePipe,
    DateAgoPipe,
    QuillModule,
    AgmCoreModule,
    ClipboardModule,
    ModalModule,
    InputDigitsOnlyDirective,
    DebounceClickDirective,
    LinkDirective,
    ClickLogDirective,
    ScrollDepthDirective,
    FooterComponent,
    MeetingPreviewComponent,
    TableModule,
    MeetingControlComponent,
    FormErrorsComponent,
    FeedComponent,
    NgxCronUiModule,
    FullCalendarModule,
    BsDatepickerModule,
    NgSelectModule,
    CarouselModule,
    NgxPaginationModule,
    PaginationComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RatingModule,
    TabsModule,
    MapComponent,
    EmptyComponent,
    ChatRoomComponent,
    PaymentComponent,
    SummaryComponent,
    ImageCropperModule,
    NgxMaskModule,
    BubbleComponent,
    CouponComponent,
    SegmentComponent,
    SwiperModule,
    HeaderComponent,
    PullToRefreshModule,
    InfiniteScrollModule,
    InfinityScrollComponent,
    ScheduleControlComponent,
    CronUiComponent,
    ScheduleDetailComponent,
    PopupComponent,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    ContactFooterComponent,
    MatNativeDateModule,
    MatDatepickerModule,
    CalendarHeader,
    SectionListComponent,
    SnackBarComponent,
    DragDropModule,
    ContactComponent,
    SurveyModule,
    LightgalleryModule,
    ShareComponent,
    MatStepperModule,
    WellnessCardComponent,
    FeedListComponent,
    MatTooltipModule,
    ProgressbarModule
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    // { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class SharedModule { }
