import { Component, Input } from '@angular/core';

@Component({
  selector: 'bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
})
export class BubbleComponent {
  @Input() text: string;
  @Input() showBubble: boolean;

  constructor(
  ) { }

  closeBubble() {
    this.showBubble = false;
  }
}
