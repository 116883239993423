import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  hideTabBarPages: string[] = [
    // 'iframe',
    // 'coupon-list',
    // 'edit-profile',
    // 'feed-add',
    // 'settings',
    // 'pay',
    // 'user-point-history',
    // 'my-like-product',
    // 'product',
    // 'blog',
    // 'event',
    'login',
    'issue',
    'processing',
    'wellness-symptom-add',
    'wellness-change-code',
    'wellness-donation-info',
    'wellness-donation-more',
    'need-user-info',
    'wellness-campaign',
    'simple-product-add'
  ];
  routeParamPages: string[] = [
    // 'meeting-detail',
    // 'payment',
    // 'event-detail',
    // 'blog-detail',
    // 'my-payment-detail',
    // 'category-detail',
    // 'review-detail',
    // 'garden',
    // 'chat-detail',
    // 'feed-detail',
    // 'profile',
    // 'feed-list',
    // 'prepare-product',
    'result',
    'wellness-category',
    'symptom',
    'wellness-feed-add',
    'share',
    'feed-detail',
    'wellness-info',
    'wellness-my-type',
    'wellness-symptom',
    'wellness-type',
    'simple-product-edit',
    'payment-success'
  ];

  constructor(
    private router: Router,
    private cookieService: CookieService) {
    this.navEvents();
  }

  navEvents() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.showHideTabs(e);
      });
  }

  showHideTabs(e: NavigationEnd) {
    const urlArray = e.url.split('/');
    const pageUrl = urlArray[urlArray.length - 1];
    const pageUrlParent = urlArray[urlArray.length - 2];
    const page = pageUrl.split('?')[0];
    const hideParamPage = this.routeParamPages.indexOf(pageUrlParent) > -1 && !isNaN(Number(page));
    let shouldHide = this.hideTabBarPages.indexOf(page) > -1 || hideParamPage;

    const tabBarFlag = this.cookieService.get('nnb_tab_bar');
    if (tabBarFlag == '0') {
      shouldHide = true;
      setTimeout(() => {
        const footers = document.getElementsByClassName('footer');
        for (let i = 0; i < footers.length; i++) {
          footers[i]['style'].bottom = '0px'
        }
      }, 1000)
    }

    try {
      setTimeout(() => shouldHide ? this.hideTabs() : this.showTabs());
    } catch (err) { }
  }

  hideTabs() {
    const tabBar = document.getElementById('tabBar');
    if (tabBar !== null && tabBar.style.display !== 'none') tabBar.style.display = 'none';
  }

  showTabs() {
    const tabBar = document.getElementById('tabBar');
    if (tabBar !== null && tabBar.style.display !== 'flex') tabBar.style.display = 'flex';
  }
}
