<ng-container *ngIf="!isDesktop">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav>
      <div style="width: 230px">
        <div class="sidemenu" *ngIf="isDesktop" style="display:flex;align-items:center;gap:12px">
          <div>
            <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/host'">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
                style="width: 55px;">
            </a>
          </div>
          <div style="color:#6c757d" [routerLink]="'/host'">비즈니스 센터</div>
        </div>
        <div class="sidemenu" [routerLink]="'/host/meeting-management'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-product-documents-48.png">
          상품 관리
        </div>
        <div class="sidemenu" [routerLink]="'/host/schedule'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-48.png">
          티켓 관리
        </div>
        <div class="sidemenu" [routerLink]="'/host/reservation'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-check-48.png">
          예약 관리
        </div>
        <div class="sidemenu" [routerLink]="'/host/review'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-team-48.png">
          피드 관리
        </div>
        <div class="sidemenu" [routerLink]="'/host/settlement'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coins-48.png">
          정산 관리
        </div>
        <div class="sidemenu" [routerLink]="'/host/stat'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-graph-48.png">
          통계
        </div>
        <div class="sidemenu" [routerLink]="'/host/info'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-name-tag-48.png">
          호스트 정보
        </div>
        <div class="sidemenu" [routerLink]="'/host/blog'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-info-48.png">
          공지 사항
        </div>
        <a href="https://nnbhost.super.site" target="_blank" style="text-decoration: none;color:black">
          <div class="sidemenu">
            <img style="width: 24px;"
              src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-help-48.png">
            도움말/문의
          </div>
        </a>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <header class="header" [headerTpl]="headerTpl" [isBackButton]="false" [style]="{}"></header>
      <ng-template #headerTpl>
        <div style="display:flex">
          <div style="flex:1">
            <img (click)="sidenav.toggle()"
              src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/menu.svg">
          </div>
          <div class="header-title-md">
            <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/host'">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
                style="width: 55px;">
            </a>&nbsp;&nbsp;<span style="color:#6c757d" [routerLink]="'/host'">비즈니스 센터</span>
          </div>
          <div style="flex:1"></div>
        </div>
      </ng-template>
      <div style="padding: 0px 16px">
        <router-outlet></router-outlet>
        <div style="height:74px"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="isDesktop">
  <div style="display:flex;">
    <div style="border-right: 1px solid #f1f3f5;min-width: 230px;">
      <header class="header" [headerTpl]="headerTpl" [isBackButton]="false" [style]="{}"></header>
      <ng-template #headerTpl>
        <div class="header-title-md">
          <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/host/main'">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
              style="width: 55px;">
          </a>&nbsp;&nbsp;<span style="color:#6c757d" [routerLink]="'/host'">비즈니스 센터</span>
        </div>
      </ng-template>

      <div class="sidemenu" [routerLink]="'/host/meeting-management'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-product-documents-48.png">
        상품 관리
      </div>
      <div class="sidemenu" [routerLink]="'/host/schedule'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-48.png">
        티켓 관리
      </div>
      <div class="sidemenu" [routerLink]="'/host/reservation'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-check-48.png">
        예약 관리
      </div>
      <div class="sidemenu" [routerLink]="'/host/review'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-team-48.png">
        피드 관리
      </div>
      <div class="sidemenu" [routerLink]="'/host/settlement'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coins-48.png">
        정산 관리
      </div>
      <div class="sidemenu" [routerLink]="'/host/stat'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-graph-48.png">
        통계
      </div>
      <div class="sidemenu" [routerLink]="'/host/info'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-name-tag-48.png">
        호스트 정보
      </div>
      <div class="sidemenu" [routerLink]="'/host/blog'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-info-48.png">
        공지 사항
      </div>
      <a href="https://nnbhost.super.site" target="_blank" style="text-decoration: none;color:black">
        <div class="sidemenu">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-help-48.png">
          도움말/문의
        </div>
      </a>
    </div>
    <div style="padding:55px 32px 160px 32px; width: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>