import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventComment, EventCommentCreateDto } from './../model/event';
import { Pagination } from './../model/pagination';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  urlPrefix = UrlService.prefix;
  static DELETE_FLAG = '삭제 되었습니다'

  constructor(
    private http: HttpClient
  ) { }

  // getCommentsByMeeting(id: number, page: number): Observable<Pagination<ProductReview>> {
  //   return this.http.get<Pagination<ProductReview>>(`/api/reviews/products?page=${page}&limit=${10}&product=${id}`);
  // }

  // getCommentsByUser(id: number, page: number): Observable<Pagination<ProductReview>> {
  //   return this.http.get<Pagination<ProductReview>>(`/api/reviews/products?page=${page}&limit=${10}&user=${id}`);
  // }

  // delete(comment: ProductReview): Observable<ProductReview[]> {
  //   return this.http.delete<ProductReview[]>(`/api/reviews/products/${comment.id}`);
  // }

  // comment(review: ProductReviewCreateDto) {
  //   return this.http.post(`/api/reviews/products`, review);
  // }

  getEventCommentByEvent(id: number, page: number, limit: number = 10): Observable<Pagination<EventComment>> {
    return this.http.get<Pagination<EventComment>>(`/api/reviews/events?page=${page}&limit=${limit}&event=${id}`);
  }

  deleteEventComment(review: EventComment): Observable<EventComment[]> {
    return this.http.delete<EventComment[]>(`/api/reviews/events/${review.id}`);
  }

  addEventComment(review: EventCommentCreateDto) {
    return this.http.post(`/api/reviews/events`, review);
  }
}
