<ng-container *ngIf="isUnUseDirty">
  <ng-container *ngIf="formGroup.controls[controlName].errors">
    <div *ngIf="isRequired && formGroup.controls[controlName].errors.required">
      <div class="desc" style="color:red">필수 입력 항목입니다</div>
    </div>
    <div *ngFor="let error of errors">
      <div *ngIf="formGroup.controls[controlName].errors[error.key]">
        <div class="desc" style="display: flex;align-items: center;gap:1px;color:red">{{error.message}}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isUnUseDirty">
  <div *ngIf="formGroup.controls[controlName].dirty">
    <ng-container *ngIf="formGroup.controls[controlName].errors">
      <div *ngIf="isRequired && formGroup.controls[controlName].errors.required">
        <div class="desc" style="color:red">필수 입력 항목입니다.</div>
      </div>
      <div *ngFor="let error of errors">
        <div *ngIf="formGroup.controls[controlName].errors[error.key]">
          <div class="desc" style="display: flex;align-items: center;gap:1px;color:red">{{error.message}}</div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>