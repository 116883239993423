import { registerLocaleData } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import localeKo from "@angular/common/locales/ko";
import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { PopupConfig } from "src/app/component/popup/popup.component";
import { AuthService } from "src/app/service/auth.service";
import { CheckDesktopService } from "src/app/service/check-desktop.service";
import { MessageService } from "src/app/service/message.service";
import { UserService } from "src/app/service/user.service";
import { environment } from "src/environments/environment";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Utm } from "utm-extractor";
import { Alliance } from "./model/alliance";
import { User } from "./model/user";
import { routeTransitionAnimations } from "./route-transition-animations";
import { AllianceService } from "./service/alliance.service";
import { ChannelService } from "./service/channel.service";
import { TabService } from "./service/tab.service";
SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: "root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  animations: [routeTransitionAnimations],
})
export class AppComponent implements OnInit {
  user: User;
  isDesktop;
  popupConfig: PopupConfig;
  alliance: Alliance;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private channelService: ChannelService,
    private messageService: MessageService,
    private tabService: TabService,
    private cds: CheckDesktopService,
    private allianceService: AllianceService
  ) {}

  ngOnInit(): void {
    registerLocaleData(localeKo);
    this.checkBrowser();
    this.setClientIp();
    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.cds.setIsDesktop(window.innerWidth);
      this.cds.isDesktop.subscribe((resp) => (this.isDesktop = resp));
      this.messageService.event.subscribe((resp) => {
        this.popupConfig = resp;
      });
      this.setUserLocation();
      this.setChannel();
      this.setAlliance(user);
      this.setUtm(user);
      this.setDanalAuthCookie();
    });
  }

  async setDanalAuthCookie() {
    const message = this.cookieService.get("nnb-danal-auth-fail");
    if (message) {
      setTimeout(() => {
        this.messageService.popup({
          title: "본인인증 실패",
          description: message,
          cancelLabel: "확인",
          isNested: true,
          isStrict: true,
        });
      }, 1300);
      this.cookieService.remove("nnb-danal-auth-fail");
    }
    this.cookieService.remove("nnb-danal-auth-fail");
  }

  async setUtm(user: User) {
    const utm = new Utm(window.location.href);
    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
      utm.get();

    if (!user && utm_source) {
      this.cookieService.put("nnb_utm_source", utm_source);
      this.cookieService.put("nnb_utm_medium", utm_medium);
      this.cookieService.put("nnb_utm_campaign", utm_campaign);
      this.cookieService.put("nnb_utm_term", utm_term);
      this.cookieService.put("nnb_utm_content", utm_content);
    }

    if (user && !user.utmSource) {
      const utmSource = this.cookieService.get("nnb_utm_source");
      const utmMedium = this.cookieService.get("nnb_utm_medium");
      const utmCampaign = this.cookieService.get("nnb_utm_campaign");
      const utmTerm = this.cookieService.get("nnb_utm_term");
      const utmContent = this.cookieService.get("nnb_utm_content");
      if (utmSource) {
        await this.userService.editAndUpdateToken(user.id, {
          utmSource,
          utmMedium,
          utmCampaign,
          utmTerm,
          utmContent,
        });
      } else if (utm_source) {
        await this.userService.editAndUpdateToken(user.id, {
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
          utmTerm: utm_term,
          utmContent: utm_content,
        });
      }
    }
  }

  setAlliance(user: User) {
    const origin = window.location.origin;
    const isExistSubdomain = origin !== environment.host;
    if (isExistSubdomain) {
      this.allianceService.get(origin).subscribe(async (alliance) => {
        this.alliance = alliance;
        this.allianceService.setAlliance(alliance);
        if (user && !user.company) {
          await this.userService.editAndUpdateToken(user.id, {
            company: alliance.company,
          });
        }
      });
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData?.animationState
    );
  }

  setUserLocation() {
    if (this.user && !this.user?.geoLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          if (lat && lon) {
            this.userService
              .update(this.user.id, {
                geoLocation: JSON.stringify({ lat, lon }),
              })
              .subscribe((resp) => {
                console.log("updated user");
              });
          }
        });
      }
    }
  }

  async checkBrowser() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    if (isIEOrEdge) {
      this.messageService.popup({
        description:
          "노는법은 구글 크롬 브라우저에 최적화 되어 있습니다. 사용 중이신 브라우저에서는 오류가 발생할 수 있으니 크롬 브라우저에서 이용해주세요.",
        cancelLabel: "확인",
      });
    }
  }

  setClientIp() {
    this.http.get(`${environment.host}/api/util/ip`).subscribe((resp) => {
      this.cookieService.put("nnb_client_ip", `${resp["ip"]}`);
    });
  }

  setChannel() {
    if (environment.production && this.user) {
      this.channelService.boot({
        pluginKey: "ba55a3cb-ad37-4bdc-b07a-0e9ef20aeb88",
        member: true,
        memberId: this.user.id,
        profile: {
          name: this.user.nickname,
          avatarUrl: this.user.profilePhoto,
          mobileNumber: this.user?.phoneNumber,
          email: this.user.email,
          role: this.user.role,
        },
      });
    } else {
      // this.channelService.boot({ pluginKey: 'ba55a3cb-ad37-4bdc-b07a-0e9ef20aeb88' });
    }
  }
}
