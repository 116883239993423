import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared.module';
import { HostPage } from './host.page';
import { HostedMeetingsPage } from './hosted-meetings/hosted-meetings.page';
import { MeetingAddPage } from './meeting-add/meeting-add.page';
import { MeetingEditPage } from './meeting-edit/meeting-edit.page';
import { MeetingManagementPage } from './meeting-management/meeting-management.page';

const routes: Routes = [
  {
    path: '',
    component: HostPage
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    HostPage,
    MeetingAddPage,
    MeetingEditPage,
    HostedMeetingsPage,
    MeetingManagementPage
  ]
})
export class HostPageModule { }
