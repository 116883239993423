import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { MessageService } from 'src/app/service/message.service';
import { UserService } from 'src/app/service/user.service';
import { UtilService } from 'src/app/service/util.service';
import { FileUploadService } from './../../service/file-upload.service';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {
  // common
  @ViewChild('label') label: ElementRef;
  @ViewChild('formInput') formInput: ElementRef;
  invalid: boolean;
  @Input() name: string;
  @Input() type = 'text';
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() errors: { key: string, message: string }[];
  @Input() maxLength: number;
  @Input() isRequired = true;
  @Input() isRequiredLabel = true;
  @Input() value: any = '';
  @Input() isUnUseDirty = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() textCountPosition = 'top';
  @Output() valueChangeEvent = new EventEmitter();
  // textarea
  @Input() rows = 7;
  @Input() border = true;
  // text || number || tel || password
  @Input() max: number;
  @Input() mask: string;
  @Input() dropSpecialCharacters = true;
  @Input() suffix: string;
  @Input() showMaskTyped = false;
  @Input() textAlign = 'left';
  @Input() height: number;
  @Input() fontSize: number;
  @Output() focusEvent = new EventEmitter();
  // select options
  @Input() selectList: any[];
  @Input() valueProp: string;
  @Input() template: TemplateRef<any>;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() notFoundText: string;
  @Input() addTag = false;
  @Input() multiple = false;
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() groupName: string;
  @Input() maxSelectedItems = 'none';
  @Output() addElement = new EventEmitter();
  @Output() removeElement = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  // date time
  @Input() selectMode = "single";
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() pickerType = 'both';
  @Input() startView: 'month' | 'year' | 'multi-years' = 'month';
  // map
  @Input() id: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() mapOption: {
    draggable: true,
    scrollWheel: true
  }
  @Output() mapSearchEvent = new EventEmitter();
  // count
  @Input() minValue = 1;
  // phone
  @Input() user: User;
  @Output() smsAuthEvent = new EventEmitter();
  isRequestAuth = false;
  isSMSAuth = false;
  remainTime: string;
  interval: any;
  @ViewChild('authNumber') authNumber: ElementRef;
  // radio-box
  @Input() radios: { value: string, titleTpl: TemplateRef<any>, contentTpl: TemplateRef<any>, content?: string }[];
  @Input() titleStyle: string;
  @Input() radioType: 'horizontal' | 'vertical' = 'vertical';
  // photo
  @Input() multiplePhoto = true;
  @Input() maxPhotos: number;
  @Input() croppedImageForModal: any;
  @Input() aspectRatio = 1 / 1
  @Input() resizeToWidth = 800;
  @Input() resizeToHeight = 800;
  @Input() converter: Function;
  @Input() canGif = true;
  @Input() maintainAspectRatio = true;
  @Input() useInitialBtn = false;
  @Output() clickInitialBtnEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef<any>;
  croppedImage: any = '';
  modalRef: BsModalRef;
  canvasRotation = 0;
  selectedImageFile;
  selectedIndex: number;

  async onFileInput(event, template) {
    if (event.target.files.length > 0) {
      const fileTypes = ["image/jpeg", "image/png"];
      if (this.canGif) fileTypes.push("image/gif");
      const files = [...event.target.files];
      if (this.group.controls[this.controlName].value.length + files.length > this.maxPhotos) {
        this.messageService.popup({
          title: '업로드',
          description: `최대 ${this.maxPhotos}장까지 업로드 가능합니다.`,
          cancelLabel: '확인',
        })
        return;
      }
      const isImageType = files.filter(file => fileTypes.find(t => t === file.type)).length === files.length;
      if (!isImageType) {
        this.messageService.popup({
          title: '업로드',
          description: `다음 이미지 형식만 가능합니다. (${fileTypes})`,
          cancelLabel: '확인',
        })
        return;
      }
      for (const file of files) {
        const f = await this.readFile(file);
        const converted = this.converter(f);
        (this.group.controls[this.controlName] as FormArray).push(new FormControl(converted))
      }
      this.fileInput.nativeElement.value = null;
    }
  }

  async editPhoto(index: number, photo, template) {
    if (photo.file) this.selectedImageFile = photo.file;
    else {
      this.selectedImageFile = await this.fileUploadService.urlToFile(photo.photo)
    }
    this.selectedIndex = index;
    const config = {
      class: 'modal-lg',
      animated: true,
      backdrop: true
    }
    this.canvasRotation = 0;
    this.modalRef = this.modalService.show(template, config);
  }

  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const file = this.fileUploadService.base64ToFile(this.croppedImage, 'cropped-image');
    const f = await this.readFile(file);
    this.croppedImageForModal = this.converter(f);
  }

  addPhoto() {
    this.modalRef.hide();
    (this.group.controls[this.controlName] as FormArray).controls[this.selectedIndex].setValue(this.croppedImageForModal);
    this.fileInput.nativeElement.value = null;
  }

  deletePhoto(index: number) {
    const photos: any[] = this.group.controls[this.controlName].value;
    photos.splice(index, 1);
    (this.group.controls[this.controlName] as FormArray).removeAt(index);
  }

  readFile(file: File): Promise<{ url: any, file: File }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve({ url: reader.result, file });
      reader.readAsDataURL(file);
    });
  }

  rotateRight() {
    this.canvasRotation++;
  }

  setInitialImage() {
    this.clickInitialBtnEvent.emit();
  }

  constructor(
    private modalService: BsModalService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private authService: AuthService,
    private userService: UserService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.group.statusChanges.subscribe(resp => {
      if (this.group.controls[this.controlName]) {
        this.invalid = (this.group.controls[this.controlName].dirty || this.isUnUseDirty) && this.group.controls[this.controlName].invalid;
      }
    })
    this.group.valueChanges.subscribe(resp => {
      if (this.group.controls[this.controlName]) {
        this.invalid = (this.group.controls[this.controlName].dirty || this.isUnUseDirty) && this.group.controls[this.controlName].invalid;
      }
    })
  }

  changeEvent(event) {
    this.valueChangeEvent.emit(event);
  }

  selectSearchEvent(event) {
    this.searchEvent.emit(event);
  }

  addEvent(event) {
    this.addElement.emit(event);
  }

  removeEvent(event) {
    this.removeElement.emit(event.value);
  }

  checkNumber(event) {
    if (this.type === 'number' || this.type === 'tel') {
      const value = event.target.value;
      if (value === "") {
        this.group.controls[this.controlName].setValue(value);
      } else {
        const onlyNumber = +value.replace(/[^0-9]*/g, '');
        this.group.controls[this.controlName].setValue(onlyNumber);
      }
      if (typeof this.max !== 'undefined') {
        if (value > this.max) {
          this.group.controls[this.controlName].setValue(this.max);
        }
      }
    }
  }

  focus() {
    this.focusEvent.emit();
    if (this?.formInput?.nativeElement) {
      if (this.invalid) {
        this.formInput.nativeElement.style.border = '1px solid red';
      } else {
        this.formInput.nativeElement.style.border = '1px solid #570DCA';
      }
      if (this.label) {
        this.label.nativeElement.style.fontSize = '16px';
        if (this.invalid) {
          this.label.nativeElement.style.color = 'red';
        } else {
          this.label.nativeElement.style.color = '#570DCA';
        }
      }
    }
  }

  focusout() {
    if (this?.formInput?.nativeElement) {
      if (this.invalid) {
        this.formInput.nativeElement.style.border = '1px solid red';
      } else {
        this.formInput.nativeElement.style.border = '1px solid #E8E8EF';
      }
      if (this.label) {
        this.label.nativeElement.style.fontSize = '16px';
        if (this.invalid) {
          this.label.nativeElement.style.color = 'red';
        } else {
          this.label.nativeElement.style.color = 'black';
        }
      }
    }
  }

  searchMap(event) {
    this.mapSearchEvent.emit(event)
  }

  onSmsAuthEvent(event) {
    this.smsAuthEvent.emit(event);
  }

  changeCount(flag: boolean) {
    const control = this.group.controls[this.controlName];
    const value = flag ? +control.value + 1 : +control.value - 1;
    if (value < this.minValue) return;
    control.setValue(value);
    control.markAsDirty();
  }

  // phone
  startTimer(duration) {
    let timer = duration, minutes, seconds;
    const interval = this.utilService.setIntervalNow(1, () => {
      minutes = parseInt(`${timer / 60}`, 10);
      seconds = parseInt(`${timer % 60}`, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.remainTime = minutes + ":" + seconds;
      if (--timer < 0) {
        clearInterval(this.interval);
        this.remainTime = '00:00'
      }
    });
    return interval;
  }

  requestAuthSMS() {
    clearInterval(this.interval);
    this.authService.requestAuthSms(this.group.controls[this.controlName].value).subscribe(resp => {
      if (resp) {
        this.isRequestAuth = true;
        this.interval = this.startTimer(60 * 5);
      } else {
        this.isRequestAuth = false;
      }
    })
  }

  authSMS() {
    const phoneNumber = this.group.controls[this.controlName].value;
    this.authService.checkAuthSms(phoneNumber, this.authNumber.nativeElement.value).subscribe(resp => {
      if (resp) {
        this.isSMSAuth = true;
        this.smsAuthEvent.emit(true);
        this.messageService.popup({
          title: '휴대폰 인증',
          description: '휴대폰 인증에 성공하였습니다.',
          cancelLabel: '확인',
        })
        if (this.group && this.controlName) {
          this.group.controls[this.controlName].setValue(phoneNumber);
        }
        if (this.user) {
          this.userService.editAndUpdateToken(this.user.id, { phoneNumber }).then(resp => {
            console.log(resp);
          })
        }
      } else {
        this.isSMSAuth = false;
        this.messageService.popup({
          title: '인증 실패',
          description: '휴대폰 번호와 인증번호를 다시 확인해주세요.',
          cancelLabel: '확인',
        })
      }
    })
  }
}
