<div class="frame">
    <form [formGroup]="form" *ngIf="form">
        <!-- 상품 정보 -->
        <ng-container *ngIf="type === 'add' || type === 'copy-add'">
            <div class="top-menu">
                <div style="display:flex;justify-content:space-between">
                    <div style="font-size:20px;font-weight:400">상품 티켓 추가</div>
                    <div style="display:flex;gap:8px">
                        <button class="nnb-btn-sm nnb-btn-secondary" (click)="close()">나가기</button>
                        <button *ngIf="!isLoad" class="nnb-btn-sm nnb-btn-primary" [disabled]="form.invalid"
                            (click)="complete()">추가하기</button>
                        <button *ngIf="isLoad" class="nnb-btn-sm nnb-btn-primary" [disabled]="true">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/loading-dark.gif"
                                style="width:16px;height:16px">&nbsp;추가 중입니다.
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-md">
                <div class="heading-1" style="margin-bottom:16px">상품 티켓 추가를 시작합니다.<br>아래 상품이 맞는지 확인해주세요.</div>
                <div class="product" *ngIf="product">
                    <img class="img" [src]="product.representationPhotos[0].photo">
                    <div>
                        <div class="title">{{product.title}}</div>
                        <div class="nnb-badge" [ngClass]="'nnb-badge-'+getStatusBadge(product.status).badge">
                            {{product.status}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type === 'edit'">
            <div class="top-menu">
                <div style="display:flex;justify-content:space-between">
                    <div style="font-size:20px;font-weight:400">상품 티켓 수정</div>
                    <div style="display:flex;gap:8px">
                        <button class="nnb-btn-sm nnb-btn-secondary" (click)="close()">나가기</button>
                        <button *ngIf="!isLoad" class="nnb-btn-sm nnb-btn-primary" [disabled]="form.invalid"
                            (click)="complete()">수정완료</button>
                        <button *ngIf="isLoad" class="nnb-btn-sm nnb-btn-primary" [disabled]="true">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/loading-dark.gif"
                                style="width:16px;height:16px">&nbsp;수정 중입니다.
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-md">
                <div class="heading-1" style="margin-bottom:16px">티켓을 수정합니다.<br>아래 상품이 맞는지 확인해주세요.</div>
                <div class="product" *ngIf="product">
                    <img class="img" [src]="product.representationPhotos[0].photo">
                    <div>
                        <div class="title">{{product.title}}</div>
                        <div class="nnb-badge" [ngClass]="'nnb-badge-'+getStatusBadge(product.status).badge">
                            {{product.status}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- 일정 선택하기 -->
        <div class="section-md" *ngIf="type==='add'||type==='copy-add'">
            <div class="heading-1">일정 선택하기</div>
            <div style="margin-bottom:72px">
                <div class="heading-2">1. 티켓 판매 기간을 선택하세요. <span class="desc">(필수)</span>
                </div>
                <input *ngIf="isDesktop" type="text" class="input" [formControlName]="'period'" bsDaterangepicker
                    [bsConfig]="{containerClass: 'theme-default', displayMonths:2}" [minDate]="minDate"
                    [maxDate]="maxDate" placeholder="시작일 ~ 종료일">
                <input *ngIf="!isDesktop" type="text" class="input" [formControlName]="'period'" bsDaterangepicker
                    [bsConfig]="{containerClass: 'theme-default', displayMonths:1}" [minDate]="minDate"
                    [maxDate]="maxDate" placeholder="시작일 ~ 종료일" (onShown)="onShowPicker($event)">
                <form-errors [formGroup]="form" [controlName]="'period'"
                    [errors]="[{key:'maxDurationDays',message:'총 기간은 최대 1년까지 입력가능합니다.'}]"> </form-errors>
            </div>

            <div style="margin-bottom: 72px;">
                <div class="heading-2">2. 일주일에 몇 번 판매할까요? <span class="desc">(필수)</span></div>
                <cron-ui (onChangeEvent)="changeDow($event)" [dowCron]="form.value.dowCron"></cron-ui>
            </div>

            <div class="heading-2">3. 몇 시에 시작할까요? <span class="desc">(필수)</span></div>
            <div style="display:flex;gap:8px;align-items: center;">
                <form-input [type]="'select'" [selectList]="hours" [group]="form" [controlName]="'hours'"
                    [valueProp]="'value'" [template]="timeTpl" [clearable]="false" [placeholder]="'시간'"></form-input>
                <div>:</div>
                <form-input [type]="'select'" [selectList]="minutes" [group]="form" [controlName]="'minutes'"
                    [placeholder]="'분'" [valueProp]="'value'" [template]="timeTpl" [clearable]="false"></form-input>
                <ng-template #timeTpl let-element>{{element.label}}</ng-template>
            </div>
        </div>

        <!-- 판매 단위 선택하기 -->
        <div class="section-md" [id]="'schedule-control-endStandard-'+product.id">
            <div class="endStandard">
                <div class="heading-1">판매 단위 선택하기</div>
                <form-input [type]="'radio-box'" [radios]="[
                {value: EndStandard.PER_PERSON , titleTpl: perPersonTitleTpl, contentTpl: perPersonContentTpl},
                {value: EndStandard.PER_TEAM, titleTpl: perTeamTitleTpl, contentTpl: perTeamContentTpl},
                ]" [group]="form" [controlName]="'endStandard'"
                    (valueChangeEvent)="clickEndStandard($event)"></form-input>
                <ng-template #perPersonTitleTpl>
                    <div class="title">인원</div>
                </ng-template>
                <ng-template #perPersonContentTpl>
                    <div class="desc">티켓 <strong>판매 단위가 '1명, 10명과 같은 인원 수'</strong></div>
                    <div class="desc">예시 1) 저희 상품은 하루에 최소 2명에서 최대 10명의 고객을 모실 수 있습니다.</div>
                </ng-template>
                <ng-template #perTeamTitleTpl>
                    <div class="title">팀</div>
                </ng-template>
                <ng-template #perTeamContentTpl>
                    <div class="desc">티켓 <strong>판매 단위가 '1팀, 10팀과 같은 팀 수'</strong></div>
                    <div class="desc">예시 1) 저희 숙소에는 방이 3개 있어 하루에 3팀을 수용할 수 있습니다.</div>
                    <div class="desc">예시 2) 저희 수업은 최대 인원 10명인 2팀을 동시에 수용할 수 있습니다.</div>
                </ng-template>
            </div>
        </div>

        <!-- 티켓 기본 정보 입력하기 -->
        <div class="section-md">
            <div class="heading-1">티켓 기본 정보 입력하기</div>
            <div style="margin-bottom: 72px;">
                <div class="heading-2">1. 티켓명을 입력하세요. <span class="desc">(필수)</span></div>
                <form-input [name]="''" [textCountPosition]="'bottom'" [controlName]="'name'" [group]="form"
                    [maxLength]="24" [errors]="[{key:'maxlength',message:'24자 제한입니다.'}]"
                    [placeholder]="'예) 1인 티켓, 반지 만들기'"></form-input>
            </div>

            <div style="margin-bottom: 24px">
                <div class="heading-2">2. 티켓 특징을 최대 3개 선택해주세요. <span class="desc">(선택)</span></div>
                <div style="display: flex;flex-wrap: wrap;gap: 16px;">
                    <div *ngFor="let badge of badges;">
                        <div class="chips" [ngClass]="{'chip-activate':form.controls.badges.value.includes(badge.id)}"
                            style="display:flex;align-items:center;gap:3px;" (click)="onCheckBadge(badge)">
                            <div>{{badge.name}}</div>
                            <img *ngIf="form.controls.badges.value.includes(badge.id)"
                                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg"
                                style="width: 16px;">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-bottom: 72px">
                <div class="heading-3">호스트님만의 강조하고 싶은 티켓의 특징이 있다면 남겨주세요.</div>
                <form-input [textCountPosition]="'bottom'" [name]="''" [type]="'textarea'" [rows]="4"
                    [controlName]="'requestBadgeDescription'" [group]="form" [maxLength]="100"
                    [errors]="[{key:'maxlength',message:'100자 제한입니다.'}]"
                    [placeholder]="'예) 아름다운 경관에서 바베큐 파티가 가능합니다. 아름다운 경관이 추가된다면 좋겠어요!&#13;&#10;1. 관리자에게 호스트의 의견을 제출한다.&#13;&#10;2. 관리자는 제출 의견을 바탕으로 시스템에서 뱃지를 추가 생성해 준다.'"
                    [isRequired]="false"></form-input>
            </div>

            <div style="margin-bottom: 72px">
                <div class="heading-2">3. 티켓에 대한 한 줄 설명 작성하기 <span class="desc">(선택)</span></div>
                <div formArrayName="description" style="margin-bottom:12px">
                    <div *ngFor="let desc of getDescs().controls; let index = index;">
                        <div style="display:flex;gap:8px;align-items: center;" [formGroupName]="index">
                            <form-input style="width:80%" [name]="''" [controlName]="'desc'"
                                [textCountPosition]="'bottom'" [group]="getDescs().controls[index]" [maxLength]="32"
                                [errors]="[{key:'maxlength',message:'32자 제한입니다.'}]"
                                [placeholder]="'예) 아름다운 경관에서 바베큐 파티가 가능합니다.'" [isRequired]="false"></form-input>
                            <button class="nnb-btn-sm" (click)="removeDesc(index)">삭제</button>
                        </div>
                    </div>
                </div>
                <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block" (click)="addDesc()">설명 추가하기
                    ({{getDescs().length}}/5)</button>
            </div>
        </div>

        <!-- 옵션 만들기 -->
        <div class="section-md">
            <div class="heading-1" style="margin-bottom:4px">하나의 티켓에 여러 옵션이 있나요? <span class="desc">(선택)</span></div>
            <div class="desc" style="margin-bottom:24px">아래 이미지와 같이 최소 2개 이상의 티켓 옵션이 필요하다면 '옵션 생성하기' 버튼을 클릭하세요.</div>
            <img style="margin-bottom: 24px"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/option-example.svg">
            <button class="nnb-btn-md nnb-btn-outline-primary nnb-btn-block" (click)="addOption()">옵션 생성하기</button>
        </div>

        <!-- 옵션 기본 정보 입력하기 -->
        <div formArrayName="options" *ngIf="getOptions().controls.length > 0">
            <div *ngFor="let option of getOptions().controls; let optionIndex = index;" [formGroupName]="optionIndex">
                <div class="section-md">
                    <div style="display:flex;justify-content: space-between;">
                        <div class="heading-1">옵션 {{optionIndex+1}}번</div>
                        <button class="nnb-btn-sm nnb-btn-outline-red" (click)="removeOption(optionIndex)">옵션
                            삭제</button>
                    </div>
                    <div class="heading-1">옵션 기본 정보 입력하기</div>
                    <div style="margin-bottom: 72px;">
                        <div class="heading-2">1. 옵션명을 입력하세요. <span class="desc">(필수)</span></div>
                        <form-input [name]="''" [textCountPosition]="'bottom'" [controlName]="'name'"
                            [group]="getOptions().controls[optionIndex]" [maxLength]="24"
                            [errors]="[{key:'maxlength',message:'24자 제한입니다.'}]"
                            [placeholder]="'예) 1인 티켓, 반지 만들기'"></form-input>
                    </div>

                    <div style="margin-bottom: 24px">
                        <div class="heading-2">2. 옵션 특징을 최대 3개 선택해주세요. <span class="desc">(선택)</span></div>
                        <div style="display: flex;flex-wrap: wrap;gap: 16px;">
                            <div *ngFor="let badge of badges;">
                                <div class="chips"
                                    [ngClass]="{'chip-activate':getOptions().at(optionIndex).get('badges').value.includes(badge.id)}"
                                    style="display:flex;align-items:center;gap:3px;"
                                    (click)="onCheckBadgeByOption(badge,optionIndex)">
                                    <div>{{badge.name}}</div>
                                    <img *ngIf="getOptions().at(optionIndex).get('badges').value.includes(badge.id)"
                                        src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg"
                                        style="width: 16px;">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div class="heading-3">호스트님만의 강조하고 싶은 옵션의 특징이 있다면 남겨주세요.</div>
                        <form-input [textCountPosition]="'bottom'" [name]="''" [type]="'textarea'" [rows]="4"
                            [controlName]="'requestBadgeDescription'" [group]="getOptions().controls[optionIndex]"
                            [maxLength]="100" [errors]="[{key:'maxlength',message:'100자 제한입니다.'}]"
                            [placeholder]="'예) 아름다운 경관에서 바베큐 파티가 가능합니다. 아름다운 경관이 추가된다면 좋겠어요!&#13;&#10;1. 관리자에게 호스트님의 의견을 알려주세요.&#13;&#10;2. 제출 의견을 바탕으로 검토 후 티켓 특징 뱃지를 추가 생성해드립니다.'"
                            [isRequired]="false"></form-input>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div class="heading-2">3. 옵션에 대한 한 줄 설명 작성하기 <span class="desc">(선택)</span></div>
                        <div style="margin-bottom:12px">
                            <div *ngFor="let desc of getDescsByOption(optionIndex).controls; let descIndex = index;">
                                <div style="display:flex;gap:8px;align-items: center;">
                                    <form-input style="width:80%" [name]="''" [controlName]="'desc'"
                                        [textCountPosition]="'bottom'"
                                        [group]="getDescsByOption(optionIndex).controls[descIndex]" [maxLength]="32"
                                        [errors]="[{key:'maxlength',message:'32자 제한입니다.'}]"
                                        [placeholder]="'예) 아름다운 경관에서 바베큐 파티가 가능합니다.'" [isRequired]="false"></form-input>
                                    <button class="nnb-btn-sm"
                                        (click)="removeDescByOption(optionIndex, descIndex)">삭제</button>
                                </div>
                            </div>
                        </div>
                        <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block"
                            (click)="addDescByOption(optionIndex)">
                            설명 추가하기 ({{getDescsByOption(optionIndex).length}}/5)
                        </button>
                    </div>
                </div>

                <!-- 옵션 인원 단위 가격 설정 -->
                <div
                    *ngIf="form.value.endStandard && (getOptions().at(optionIndex).get('pricePerPerson')||getOptions().at(optionIndex).get('extraPricePerPerson')) ">
                    <div *ngIf="form.value.endStandard === EndStandard.PER_PERSON">
                        <div class="section-md">
                            <div class="heading-1">인원 단위 가격 설정</div>
                            <div style="margin-bottom:72px">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>1. 하루에 최대</div>
                                    <form-input [group]="getOptions().controls[optionIndex]" [type]="'number'"
                                        [controlName]="'maxParticipants'" [max]="100"
                                        [errors]="[{key:'max',message:'100명 제한입니다.'}]" style="width:100px"
                                        [placeholder]="'숫자'"
                                        (valueChangeEvent)="updatePerPersonExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                    <div>명의 고객을 모실 수 있습니다.</div>
                                </div>
                                <div class="desc">최대 인원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                            </div>

                            <div style="margin-bottom: 72px">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>2. 시작 가능 최소 인원은</div>
                                    <form-input [group]="getOptions().controls[optionIndex]" [type]="'number'"
                                        [controlName]="'minParticipants'" [max]="100"
                                        [errors]="[{key:'max',message:'100명 제한입니다.'},{key:'isUpper',message:'최대 인원보다 클 수 없습니다.'}]"
                                        [placeholder]="'숫자'" style="width:100px"
                                        (valueChangeEvent)="updatePerPersonExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                    <div>명 입니다.</div>
                                </div>
                                <div class="desc">모임 진행을 위한 최소 인원 수 입니다.</div>
                            </div>

                            <div style="margin-bottom: 72px">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>3. 1인 기본 가격은</div>
                                    <form-input [group]="getOptions().controls[optionIndex]" [type]="'number'"
                                        [controlName]="'pricePerPerson'" [max]="10000000"
                                        [errors]="[{key:'max',message:'10,000,000원 제한입니다.'}]" style="width:140px"
                                        [placeholder]="'금액'"
                                        (valueChangeEvent)="updatePerPersonExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                    <div>원 입니다.</div>
                                </div>
                                <div class="desc">입력하신 1인 기본 가격을 기준으로 인원별 금액 범위를 별도로 설정할 수 있습니다.</div>
                            </div>

                            <div class="heading-2" style="margin-bottom:4px">4. 가격 범위 별도 설정하기 <span
                                    class="desc">(선택)</span>
                            </div>
                            <div class="desc" style="margin-bottom: 24px">원하는 인원 수 만큼 가격을 별도로 설정할 수 있습니다.</div>
                            <div formArrayName="prices" class="prices" *ngIf="getPricesByOption(optionIndex).length>0">
                                <div
                                    *ngFor="let price of getPricesByOption(optionIndex).controls; let priceIndex = index; let last=last;">
                                    <div style="display:flex;gap:8px;align-items:center;;flex-wrap: wrap;"
                                        [ngStyle]="{'margin-bottom':last?'':'16px'}" [formGroupName]="priceIndex">
                                        <form-input [name]="''" [controlName]="'quantity'" style="width:100px"
                                            [type]="'number'" [placeholder]="'숫자'"
                                            [group]="getPricesByOption(optionIndex).controls[priceIndex]"
                                            (valueChangeEvent)="updatePerPersonExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)">
                                        </form-input>
                                        <div>인 이상 시 1인 가격은</div>
                                        <form-input [name]="''" [controlName]="'price'" style="width:140px"
                                            [type]="'number'" [placeholder]="'금액'"
                                            [group]="getPricesByOption(optionIndex).controls[priceIndex]"
                                            (valueChangeEvent)="updatePerPersonExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)">
                                        </form-input>
                                        <div>원 입니다.</div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                            *ngIf="ticketService.getPercent(getPricesByOption(optionIndex).controls[priceIndex].value.price,getOptions().at(optionIndex).value.pricePerPerson) == 0">
                                            정가
                                        </div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                            *ngIf="ticketService.getPercent(getPricesByOption(optionIndex).controls[priceIndex].value.price,getOptions().at(optionIndex).value.pricePerPerson) != 0">
                                            {{ticketService.getPercent(getPricesByOption(optionIndex).controls[priceIndex].value.price,getOptions().at(optionIndex).value.pricePerPerson)|number}}%
                                            할인 가격
                                        </div>
                                        <button class="nnb-btn-sm"
                                            (click)="removePriceByOption(optionIndex, priceIndex)">삭제</button>
                                    </div>
                                </div>
                            </div>

                            <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block"
                                (click)="addPriceByOption(optionIndex)">범위 추가하기</button>
                        </div>

                        <div style="padding: 24px">
                            <div class="heading-1">인당 티켓 가격 미리보기</div>
                            <div class="desc" style="margin-bottom: 16px">입력하신 인원과 가격 기준으로 계산한 인원당 티켓 가격입니다.</div>
                            <div style="background: #fff;padding: 24px 16px; border-radius: 12px;"
                                *ngIf="expectedPrices">
                                <div *ngFor="let price of expectedPrices[optionIndex]; let last=last"
                                    style="display:flex;justify-content:space-between;"
                                    [ngStyle]="{'margin-bottom':last?'':'24px'}">
                                    <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                                    <div style="display:flex;gap:8px">
                                        <div>{{price.pricePerPerson|number}}원 X {{price.quantity|number}}명 = </div>
                                        <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원
                                        </div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                            *ngIf="price.discountPercent == 0">정가</div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                            *ngIf="price.discountPercent != 0">
                                            {{price.discountPercent|number}}% 할인 가격
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="form.value.endStandard === EndStandard.PER_TEAM">
                        <div class="section-md">
                            <div class="heading-1" style="margin-bottom:4px">팀 단위 가격 설정</div>
                            <div style="margin-bottom: 72px">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>1. 하루에 최대</div>
                                    <form-input style="width:100px" [group]="getOptions().controls[optionIndex]"
                                        [controlName]="'maxTeamCapacity'" [placeholder]="'숫자'" [type]="'number'"
                                        style="width:100px">
                                    </form-input>
                                    <div>팀을 수용할 수 있습니다.</div>
                                </div>
                                <div class="desc" style="margin-bottom:24px">최대 팀원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                                <div class="min-max-container">
                                    <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                        class="heading-2">
                                        <div>한 팀의 최소 수용 인원은</div>
                                        <form-input style="width:100px" [group]="getOptions().controls[optionIndex]"
                                            [controlName]="'minParticipantsPerTeam'" [placeholder]="'숫자'"
                                            [type]="'number'"
                                            (valueChangeEvent)="updatePerTeamExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                        <div>명 입니다.</div>
                                    </div>
                                    <div class="desc" style="margin-bottom:40px">모임 진행을 위한 최소 인원 수 입니다.</div>
                                    <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                        class="heading-2">
                                        <div>한 팀의 최대 수용 인원은</div>
                                        <form-input style="width:100px" [group]="getOptions().controls[optionIndex]"
                                            [controlName]="'maxParticipantsPerTeam'" [placeholder]="'숫자'"
                                            [type]="'number'"
                                            (valueChangeEvent)="updatePerTeamExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                        <div>명 입니다.</div>
                                    </div>
                                    <div class="desc">모임 진행을 위한 최대 인원 수 입니다.</div>
                                </div>
                            </div>

                            <div style="margin-bottom: 32px">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:24px;flex-wrap: wrap;margin-bottom:40px"
                                    class="heading-2">
                                    <div>2. 1팀의 시작 가격은</div>
                                    <form-input style="width:140px" [group]="getOptions().controls[optionIndex]"
                                        [controlName]="'pricePerTeam'" [placeholder]="'금액'" [type]="'number'"
                                        (valueChangeEvent)="updatePerTeamExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex)"></form-input>
                                    <div>원 입니다.</div>
                                </div>
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>한 명 당 추가 금액은</div>
                                    <form-input style="width:140px" [group]="getOptions().controls[optionIndex]"
                                        [controlName]="'extraPricePerPerson'" [placeholder]="'금액'" [type]="'number'"
                                        (valueChangeEvent)="updatePerTeamExpectedPricesByOption(getOptions().controls[optionIndex],optionIndex);"></form-input>
                                    <div>원 입니다.</div>
                                </div>
                                <div class="desc" style="margin-bottom:40px">입력하신 1팀 시작 가격과 추가 금액을 기준으로 인원별 금액을 계산합니다.
                                </div>
                            </div>

                            <div class="price-container">
                                <table class="table price-table">
                                    <tr>
                                        <td style="font-weight: 500;padding:8px 8px 8px 2px">인원</td>
                                        <td style="font-weight: 500;padding:8px 8px 8px 2px">정가</td>
                                        <td style="font-weight: 500;padding:8px 8px 8px 2px">할인가</td>
                                        <td style="font-weight: 500;padding:8px">할인율</td>
                                    </tr>
                                    <tr
                                        *ngFor="let price of getPricesByOption(optionIndex).controls; let priceIndex = index; let last=last;">
                                        <ng-container
                                            *ngIf="priceIndex >= +getOptions().controls[optionIndex].value.minParticipantsPerTeam-1">
                                            <td>{{price.value.quantity}}명 가격</td>
                                            <td>{{price.value.price|number}}원</td>
                                            <td style="width:140px">
                                                <form-input [name]="''" [controlName]="'discountPrice'"
                                                    [type]="'number'" [placeholder]="'금액'"
                                                    [group]="getPricesByOption(optionIndex).controls[priceIndex]"
                                                    (valueChangeEvent)="updatePerTeamExpectedPricesByOption2(getOptions().controls[optionIndex],optionIndex)">
                                                </form-input>
                                            </td>
                                            <td style=" padding: 2px 2px 2px 8px">
                                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                                    style="margin-top: 10px"
                                                    *ngIf="price.value.discountPrice == price.value.price">정가</div>
                                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                                    style="margin-top: 10px"
                                                    *ngIf="price.value.discountPrice < price.value.price">
                                                    {{ticketService.getPercent(price.value.discountPrice,
                                                    price.value.price)}}% 할인
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div style="padding: 24px">
                            <div class="heading-1">한 팀 티켓 가격 미리보기</div>
                            <div class="desc" style="margin-bottom: 16px">한 팀 기준으로 계산한 인원당 티켓 가격입니다.</div>
                            <div style="background: #fff;padding: 24px 16px; border-radius: 12px;"
                                *ngIf="expectedPrices">
                                <div *ngFor="let price of expectedPrices[optionIndex]; let last=last"
                                    style="display:flex;justify-content:space-between;"
                                    [ngStyle]="{'margin-bottom':last?'':'24px'}">
                                    <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                                    <div style="display:flex;gap:8px">
                                        <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원
                                        </div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                            *ngIf="price.discountPercent == 0">
                                            정가
                                        </div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                            *ngIf="price.discountPercent != 0">
                                            {{price.discountPercent|number}}% 할인 가격
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 인원 단위 가격 설정 -->
        <div
            *ngIf="form.value.endStandard && (form.controls.pricePerPerson||form.controls.extraPricePerPerson) && form.value.options.length === 0">
            <div *ngIf="form.value.endStandard === EndStandard.PER_PERSON">
                <div class="section-md">
                    <div class="heading-1">인원 단위 가격 설정</div>
                    <div style="margin-bottom:72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>1. 하루에 최대</div>
                            <form-input [group]="form" [type]="'number'" [controlName]="'maxParticipants'" [max]="100"
                                [errors]="[{key:'max',message:'100명 제한입니다.'}]" style="width:100px" [placeholder]="'숫자'"
                                (valueChangeEvent)="validationMin()"></form-input>
                            <div>명의 고객을 모실 수 있습니다.</div>
                        </div>
                        <div class="desc">최대 인원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>2. 시작 가능 최소 인원은</div>
                            <form-input [group]="form" [type]="'number'" [controlName]="'minParticipants'" [max]="100"
                                [errors]="[{key:'max',message:'100명 제한입니다.'},{key:'isUpper',message:'최대 인원보다 클 수 없습니다.'}]"
                                [placeholder]="'숫자'" style="width:100px"
                                (valueChangeEvent)="validationMin()"></form-input>
                            <div>명 입니다.</div>
                        </div>
                        <div class="desc">모임 진행을 위한 최소 인원 수 입니다.</div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>3. 1인 기본 가격은</div>
                            <form-input [group]="form" [type]="'number'" [controlName]="'pricePerPerson'"
                                [max]="10000000" [errors]="[{key:'max',message:'10,000,000원 제한입니다.'}]"
                                style="width:140px" [placeholder]="'금액'"
                                (valueChangeEvent)="updatePerPersonExpectedPrices();"></form-input>
                            <div>원 입니다.</div>
                        </div>
                        <div class="desc">입력하신 1인 기본 가격을 기준으로 인원별 금액 범위를 별도로 설정할 수 있습니다.</div>
                    </div>

                    <div class="heading-2" style="margin-bottom:4px">4. 가격 범위 별도 설정하기 <span class="desc">(선택)</span>
                    </div>
                    <div class="desc" style="margin-bottom: 24px">원하는 인원 수 만큼 가격을 별도로 설정할 수 있습니다.</div>
                    <div formArrayName="prices" class="prices" *ngIf="getPrices().length>0">
                        <div *ngFor="let price of getPrices().controls; let index = index; let last=last;">
                            <div style="display:flex;gap:8px;align-items:center;;flex-wrap: wrap;"
                                [ngStyle]="{'margin-bottom':last?'':'16px'}" [formGroupName]="index">
                                <form-input [name]="''" [controlName]="'quantity'" style="width:100px" [type]="'number'"
                                    [placeholder]="'숫자'" [group]="getPrices().controls[index]"
                                    (valueChangeEvent)="updatePerPersonExpectedPrices();">
                                </form-input>
                                <div>인 이상 시 1인 가격은</div>
                                <form-input [name]="''" [controlName]="'price'" style="width:140px" [type]="'number'"
                                    [placeholder]="'금액'" [group]="getPrices().controls[index]"
                                    (valueChangeEvent)="updatePerPersonExpectedPrices();">
                                </form-input>
                                <div>원 입니다.</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                    *ngIf="ticketService.getPercent(getPrices().controls[index].value.price,form.value.pricePerPerson) == 0">
                                    정가
                                </div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                    *ngIf="ticketService.getPercent(getPrices().controls[index].value.price,form.value.pricePerPerson) != 0">
                                    {{ticketService.getPercent(getPrices().controls[index].value.price,form.value.pricePerPerson)|number}}%
                                    할인
                                </div>
                                <button class="nnb-btn-sm" (click)="removePrice(index)">삭제</button>
                            </div>
                        </div>
                    </div>

                    <button class="nnb-btn-md nnb-btn-secondary nnb-btn-block" (click)="addPrice()">범위 추가하기</button>
                </div>

                <div style="padding: 24px">
                    <div class="heading-1">인당 티켓 가격 미리보기</div>
                    <div class="desc" style="margin-bottom: 16px">입력하신 인원과 가격 기준으로 계산한 인원당 티켓 가격입니다.</div>
                    <div style="background: #fff;padding: 24px 16px; border-radius: 12px;" *ngIf="expectedPrices">
                        <div *ngFor="let price of expectedPrices[0]; let last=last"
                            style="display:flex;justify-content:space-between;"
                            [ngStyle]="{'margin-bottom':last?'':'24px'}">
                            <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                            <div style="display:flex;gap:8px">
                                <div>{{price.pricePerPerson|number}}원 X {{price.quantity|number}}명 = </div>
                                <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                    *ngIf="price.discountPercent == 0">정가</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue" *ngIf="price.discountPercent != 0">
                                    {{price.discountPercent|number}}% 할인
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="form.value.endStandard === EndStandard.PER_TEAM">
                <div class="section-md">
                    <div class="heading-1" style="margin-bottom:4px">팀 단위 가격 설정</div>
                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>1. 하루에 최대</div>
                            <form-input style="width:100px" [group]="form" [controlName]="'maxTeamCapacity'"
                                [placeholder]="'숫자'" [type]="'number'" style="width:100px">
                            </form-input>
                            <div>팀을 수용할 수 있습니다.</div>
                        </div>
                        <div class="desc" style="margin-bottom:24px">최대 팀원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                        <div class="min-max-container">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>한 팀의 최소 수용 인원은</div>
                                <form-input style="width:100px" [group]="form" [controlName]="'minParticipantsPerTeam'"
                                    [placeholder]="'숫자'" [type]="'number'"
                                    (valueChangeEvent)="updatePerTeamExpectedPrices();"></form-input>
                                <div>명 입니다.</div>
                            </div>
                            <div class="desc" style="margin-bottom:40px">모임 진행을 위한 최소 인원 수 입니다.</div>
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>한 팀의 최대 수용 인원은</div>
                                <form-input style="width:100px" [group]="form" [controlName]="'maxParticipantsPerTeam'"
                                    [placeholder]="'숫자'" [type]="'number'"
                                    (valueChangeEvent)="updatePerTeamExpectedPrices();"></form-input>
                                <div>명 입니다.</div>
                            </div>
                            <div class="desc">모임 진행을 위한 최대 인원 수 입니다.</div>
                        </div>
                    </div>

                    <div style="margin-bottom: 32px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:24px;flex-wrap: wrap;margin-bottom:40px"
                            class="heading-2">
                            <div>2. 1팀의 시작 가격은</div>
                            <form-input style="width:140px" [group]="form" [controlName]="'pricePerTeam'"
                                [placeholder]="'금액'" [type]="'number'"
                                (valueChangeEvent)="updatePerTeamExpectedPrices();"></form-input>
                            <div>원 입니다.</div>
                        </div>
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>한 명 당 추가 금액은</div>
                            <form-input style="width:140px" [group]="form" [controlName]="'extraPricePerPerson'"
                                [placeholder]="'금액'" [type]="'number'"
                                (valueChangeEvent)="updatePerTeamExpectedPrices();"></form-input>
                            <div>원 입니다.</div>
                        </div>
                        <div class="desc" style="margin-bottom:40px">입력하신 1팀 시작 가격과 추가 금액을 기준으로 인원별 금액을 계산합니다.</div>
                    </div>

                    <div class="price-container">
                        <table class="table price-table">
                            <tr>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">인원</td>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">정가</td>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">할인가</td>
                                <td style="font-weight: 500;padding:8px">할인율</td>
                            </tr>

                            <tr *ngFor="let price of getPrices().controls; let index = index; let last=last;">
                                <ng-container *ngIf="index >= +form.value.minParticipantsPerTeam-1">
                                    <td>{{price.value.quantity}}명 가격</td>
                                    <td>{{price.value.price|number}}원</td>
                                    <td style="width:140px">
                                        <form-input [name]="''" [controlName]="'discountPrice'" [type]="'number'"
                                            [placeholder]="'금액'" [group]="getPrices().controls[index]"
                                            (valueChangeEvent)="updatePerTeamExpectedPrices2()">
                                        </form-input>
                                    </td>
                                    <td style=" padding: 2px 2px 2px 8px">
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                            style="margin-top: 10px"
                                            *ngIf="price.value.discountPrice == price.value.price">정가</div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-blue" style="margin-top: 10px"
                                            *ngIf="price.value.discountPrice < price.value.price">
                                            {{ticketService.getPercent(price.value.discountPrice, price.value.price)}}%
                                            할인
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                </div>

                <div style="padding: 24px">
                    <div class="heading-1">한 팀 티켓 가격 미리보기</div>
                    <div class="desc" style="margin-bottom: 16px">한 팀 기준으로 계산한 인원당 티켓 가격입니다.</div>
                    <div style="background: #fff;padding: 24px 16px; border-radius: 12px;" *ngIf="expectedPrices">
                        <div *ngFor="let price of expectedPrices[0]; let last=last"
                            style="display:flex;justify-content:space-between;"
                            [ngStyle]="{'margin-bottom':last?'':'24px'}">
                            <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                            <div style="display:flex;gap:8px">
                                <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                    *ngIf="price.discountPercent == 0">
                                    정가
                                </div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue" *ngIf="price.discountPercent != 0">
                                    {{price.discountPercent|number}}% 할인
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>