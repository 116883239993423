import { AfterViewInit, Directive, ElementRef, HostListener, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import Stepper from 'bs-stepper';
import { QuillEditorComponent } from 'ngx-quill';
import { Amenity, Product, ProductStatus } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/service/auth.service';
import { S3Service } from 'src/app/service/s3.service';
import { Hashtag } from '../../../model/product';

@Directive()
export class MeetingControl implements AfterViewInit {
    user: User;
    quillStyle;
    quill: QuillEditorComponent;
    meetingForm: FormGroup;
    hashtags: Hashtag[];
    amenities: Amenity[];

    stepper: Stepper;
    @ViewChild('stepperRef') stepperRef: ElementRef;

    SEOUL_LATITUDE = 37.566535;
    SEOUL_LONGITUDE = 126.9779692;
    latitude: number;
    longitude: number;
    country: string;
    sido: string;
    sigugun: string;
    dongmyun: string;
    ri: string;
    rest: string;
    zoom: number;

    previewProduct: Product;

    passDeactivate = false;
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification(event: any) {
        if (!this.passDeactivate && this.meetingForm.dirty) {
            event.returnValue = true;
        }
    }

    isLoad = false;
    phoneNumberForm: FormGroup;
    @ViewChild('addPhoneTpl') addPhoneTpl: any;

    constructor(
        public ngZone: NgZone,
        public s3Service: S3Service,
        public authService: AuthService,
        public bottomSheet: MatBottomSheet
    ) { }

    ngAfterViewInit(): void {
        this.stepper = new Stepper(this.stepperRef.nativeElement, {
            linear: false,
            animation: true,
            selectors: {
                trigger: '.trigger',
            }
        });
        if (window.location.href.includes('meeting-add')) {
            this.authService.getCurrentUserFromServer().subscribe(user => {
                if (!user?.phoneNumber) {
                    this.phoneNumberForm = new FormGroup({
                        phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{11}")]),
                    })
                    this.bottomSheet.open(this.addPhoneTpl, { closeOnNavigation: true, disableClose: true, panelClass: 'nnb-bottom-sheet' });
                }
            })
        }
    }

    quillLoad(event) {
        this.quill = event;
    }

    validateDiscountPrice(controlName: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const discountPrice = +control.value;
            const price = +control.root.value[controlName];
            const isUpper = price !== 0 && price <= discountPrice;
            return isUpper ? { 'isUpper': { isUpper } } : null;
        };
    }

    checkDiscountPrice() {
        const price = this.meetingForm.controls.price;
        const discountPrice = this.meetingForm.controls.discountPrice;
        if (+price !== 0 && +price.value <= +discountPrice.value) {
            discountPrice.setErrors({ 'isUpper': true })
        } else {
            discountPrice.setErrors({ 'isUpper': null });
        }
        discountPrice.updateValueAndValidity();
    }

    changeAddress(event) {
        this.meetingForm.controls.address.setValue(event.address);
        const { latitude, longitude, country, sido, sigugun, dongmyun, ri, rest } = event;
        this.latitude = latitude;
        this.longitude = longitude;
        this.country = country;
        this.sido = sido;
        this.sigugun = sigugun;
        this.dongmyun = dongmyun;
        this.ri = ri;
        this.rest = rest;
    }

    next() {
        const index = this.stepper['_currentIndex'];
        if (index === 7) {
            this.makePreviewProduct();
        }
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        })
        this.stepper.next();
    }

    prev() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        })
        this.stepper.previous();
    }

    makePreviewProduct() {
        if (this.meetingForm.valid) {
            if (this.meetingForm.value.version === 1) {
                const { version, title, hashtags, photos, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes, reservationHours, price, discountPrice,
                    point, recommend, description, courses, amenities, notice, checkList, includeList, excludeList, refundPolicy } = this.meetingForm.value;
                const amenityList = this.amenities.filter(a => amenities.find(v => a.id == v));
                this.previewProduct = new Product(0, title, price, discountPrice, recommend, version, address, detailAddress, howToCome, runningDays,
                    runningHours, runningMinutes, reservationHours, this.latitude, this.longitude, undefined, notice, checkList, includeList, excludeList,
                    refundPolicy, 0, ProductStatus.DISABLED, true, true, '', undefined, description, point, undefined, undefined, undefined, hashtags, undefined, amenityList, 0, false, undefined, new Date(), new Date(), photos, courses, [])
            } else {
                const { version, title, hashtags, photos, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes, reservationHours, price, discountPrice,
                    recommend, description, summaries, sections, courses, amenities, notice, checkList, includeList, excludeList, refundPolicy } = this.meetingForm.value;
                const amenityList = this.amenities.filter(a => amenities.find(v => a.id == v));
                this.previewProduct = new Product(0, title, price, discountPrice, recommend, version, address, detailAddress, howToCome, runningDays,
                    runningHours, runningMinutes, reservationHours, this.latitude, this.longitude, undefined, notice, checkList, includeList, excludeList,
                    refundPolicy, 0, ProductStatus.DISABLED, true, true, '', undefined, description, undefined, summaries, sections, undefined, hashtags, undefined, amenityList, 0, false, undefined, new Date(), new Date(), photos, courses, [])
            }
        }
    }

    ngOnDestroy() {
        this.stepper.reset();
        this.meetingForm.reset();
        (this.meetingForm.controls.photos as FormArray).clear();
    }
}