import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminPage } from './page/admin/admin.page';
import { HostPage } from './page/host/host.page';
import { HostedMeetingsPage } from './page/host/hosted-meetings/hosted-meetings.page';
import { MeetingAddPage } from './page/host/meeting-add/meeting-add.page';
import { MeetingEditPage } from './page/host/meeting-edit/meeting-edit.page';
import { MeetingManagementPage } from './page/host/meeting-management/meeting-management.page';
import { AuthGuard } from './service/auth-guard.service';
import { CanDeactivateGuard } from './service/can-deactivate.guard';
import { JoinedChatUserGuard } from './service/joined-chat-user-guard.service';
import { RoleGuard } from './service/role-guard';
import { UserIsReviewFeedValidGuard } from './service/user-is-review-feed-valid-guard.service';

export class RouterConfiguration implements ExtraOptions {
  scrollPositionRestoration: "enabled" | "disabled" | "top" = "enabled";
  preloadingStrategy: PreloadAllModules;
  relativeLinkResolution: 'legacy';
}

const routes: Routes = [
  {
    path: 'tabs', children: [
      { path: 'home', children: [{ path: '', loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule) }] },
      { path: 'meeting-detail/:id', children: [{ path: '', loadChildren: () => import('./page/product/meeting-detail/meeting-detail.module').then(m => m.MeetingDetailPageModule) }] },
      { path: 'alone', children: [{ path: '', loadChildren: () => import('./page/home/alone/alone.module').then(m => m.AlonePageModule) }] },
      { path: 'alone-calendar', children: [{ path: '', loadChildren: () => import('./page/home/alone/alone-calendar/alone-calendar.module').then(m => m.AloneCalendarPageModule) }] },
      { path: 'iframe', children: [{ path: '', loadChildren: () => import('./page/home/iframe/iframe.module').then(m => m.IframePageModule) }] },
      { path: 'search', children: [{ path: '', loadChildren: () => import('./page/home/search/search/search.module').then(m => m.SearchPageModule) }] },
      { path: 'search-result', children: [{ path: '', loadChildren: () => import('./page/home/search/search-result/search-result.module').then(m => m.SearchResultPageModule) }] },
      { path: 'my-info', children: [{ path: '', loadChildren: () => import('./page/user/my-info/my-info.module').then(m => m.MyInfoPageModule) }] },
      { path: 'login', data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/user/login/login.module').then(m => m.LoginPageModule) }] },
      { path: 'settings', children: [{ path: '', loadChildren: () => import('./page/home/settings/settings.module').then(m => m.SettingsPageModule) }] },
      { path: 'chat', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/chat/chat.module').then(m => m.ChatPageModule) }] },
      { path: 'chat-detail/:id', canActivateChild: [AuthGuard, JoinedChatUserGuard], children: [{ path: '', loadChildren: () => import('./page/chat/chat-detail/chat-detail.module').then(m => m.ChatDetailPageModule) }] },
      { path: 'my-product', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/my-product/my-product.module').then(m => m.MyProductPageModule) }] },
      { path: 'feed', children: [{ path: '', loadChildren: () => import('./page/feed/feed.module').then(m => m.FeedPageModule) }] },
      { path: 'feed-list/:id', children: [{ path: '', loadChildren: () => import('./page/feed/feed-list/feed-list.module').then(m => m.FeedListPageModule) }] },
      { path: 'feed-add', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/feed/feed-add/feed-add.module').then(m => m.FeedAddPageModule) }] },
      { path: 'feed-add/:id', canActivateChild: [AuthGuard, UserIsReviewFeedValidGuard], children: [{ path: '', loadChildren: () => import('./page/feed/feed-add/feed-add.module').then(m => m.FeedAddPageModule) }] },
      { path: 'feed-edit/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/feed/feed-edit/feed-edit.module').then(m => m.FeedEditPageModule) }] },
      { path: 'feed-detail/:id', children: [{ path: '', loadChildren: () => import('./page/feed/feed-detail/feed-detail.module').then(m => m.FeedDetailPageModule) }] },
      { path: 'feed-photo-detail/:id', children: [{ path: '', loadChildren: () => import('./page/feed/feed-photo-detail/feed-photo-detail.module').then(m => m.FeedPhotoDetailPageModule) }] },
      { path: 'theme/:id', children: [{ path: '', loadChildren: () => import('./page/home/theme/theme.module').then(m => m.ThemePageModule) }] },
      { path: 'theme-detail/:id', children: [{ path: '', loadChildren: () => import('./page/home/theme-detail/theme-detail.module').then(m => m.ThemeDetailPageModule) }] },
      { path: 'region/:id', children: [{ path: '', loadChildren: () => import('./page/home/region/region.module').then(m => m.RegionPageModule) }] },
      { path: 'meetings', children: [{ path: '', loadChildren: () => import('./page/product/product-list/product-list.module').then(m => m.ProductListPageModule) }] },
      { path: 'coupon-list', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/coupon-list/coupon-list.module').then(m => m.CouponListPageModule) }] },
      { path: 'edit-profile', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule) }] },
      { path: 'find-password', loadChildren: () => import('./page/user/find-password/find-password.module').then(m => m.FindPasswordPageModule) },
      { path: 'reset-password', loadChildren: () => import('./page/user/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
      { path: 'need-user-info', loadChildren: () => import('./page/user/need-user-info/need-user-info.module').then(m => m.NeedUserInfoPageModule) },
      { path: 'garden/:id', children: [{ path: '', loadChildren: () => import('./page/user/garden/garden.module').then(m => m.GardenPageModule) }] },
      { path: 'prepare-product/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/prepare-product/prepare-product.module').then(m => m.PrepareProductPageModule) }] },
      { path: 'my-like-product', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/my-like-product/my-like-product.module').then(m => m.MyLikeProductPageModule) }] },
      { path: 'signup', children: [{ path: '', loadChildren: () => import('./page/user/signup/signup.module').then(m => m.SignupPageModule) }] },
      { path: 'user-point-history', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/user-point-history/user-point-history.module').then(m => m.UserPointHistoryPageModule) }] },
      { path: 'profile/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/user/profile/profile.module').then(m => m.ProfilePageModule) }] },
      { path: 'blog', children: [{ path: '', loadChildren: () => import('./page/blog/blog.module').then(m => m.BlogPageModule) }] },
      { path: 'blog-add', canActivateChild: [AuthGuard, RoleGuard], data: { roles: ['admin'] }, children: [{ path: '', loadChildren: () => import('./page/blog/blog-add/blog-add.module').then(m => m.BlogAddPageModule) }] },
      { path: 'blog-edit/:id', canActivateChild: [AuthGuard, RoleGuard], data: { roles: ['admin'] }, children: [{ path: '', loadChildren: () => import('./page/blog/blog-edit/blog-edit.module').then(m => m.BlogEditPageModule) }] },
      { path: 'blog-detail/:id', children: [{ path: '', loadChildren: () => import('./page/blog/blog-detail/blog-detail.module').then(m => m.BlogDetailPageModule) }] },
      { path: 'event', children: [{ path: '', loadChildren: () => import('./page/event/event.module').then(m => m.EventPageModule) }] },
      { path: 'event-detail/:id', children: [{ path: '', loadChildren: () => import('./page/event/event-detail/event-detail.module').then(m => m.EventDetailPageModule) }] },
      { path: 'event-edit/:id', canActivateChild: [AuthGuard, RoleGuard], data: { roles: ['admin'] }, children: [{ path: '', loadChildren: () => import('./page/event/event-edit/event-edit.module').then(m => m.EventEditPageModule) }] },
      { path: 'event-add', canActivateChild: [AuthGuard, RoleGuard], data: { roles: ['admin'] }, children: [{ path: '', loadChildren: () => import('./page/event/event-add/event-add.module').then(m => m.EventAddPageModule) }] },
      { path: 'my-payment-detail/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/payment/my-payment-detail/my-payment-detail.module').then(m => m.MyPaymentDetailPageModule) }] },
      { path: 'check-nonMember-payment', children: [{ path: '', loadChildren: () => import('./page/payment/check-nonMember-payment/check-nonMember-payment.module').then(m => m.CheckNonMemberPaymentPageModule) }] },
      { path: 'check-nonMember-payment-result', children: [{ path: '', loadChildren: () => import('./page/payment/check-nonMember-payment-result/check-nonMember-payment-result.module').then(m => m.CheckNonMemberPaymentResultPageModule) }] },
      { path: 'payment-success/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/payment/payment-success/payment-success.module').then(m => m.PaymentSuccessPageModule) }] },
      { path: 'payment-fail', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/payment/payment-fail/payment-fail.module').then(m => m.PaymentFailPageModule) }] },
      { path: 'payment/pay', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/payment/payment-pay/payment-pay.module').then(m => m.PaymentPayModule) }] },
      { path: 'category-detail/:id', children: [{ path: '', loadChildren: () => import('./page/product/category-detail/category-detail.module').then(m => m.CategoryDetailPageModule) }] },
      { path: 'category-detail/:id/:subCategoryId', children: [{ path: '', loadChildren: () => import('./page/product/category-detail/category-detail.module').then(m => m.CategoryDetailPageModule) }] },
      { path: 'accompany', children: [{ path: '', loadChildren: () => import('./page/home/accompany/accompany.module').then(m => m.AccompanyPageModule) }] },
      { path: 'kto-coupon', children: [{ path: '', loadChildren: () => import('./page/home/kto/kto.module').then(m => m.KtoPageModule) }] },
      { path: 'kto-coupon/issue', data: { animationState: 'Child' }, canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/home/kto/kto-issue/kto-issue.module').then(m => m.KtoIssuePageModule) }] },
      { path: 'kto/product', children: [{ path: '', loadChildren: () => import('./page/home/kto/kto-product/kto-product.module').then(m => m.KtoProductPageModule) }] },
      { path: 'kto/product-gangjin', children: [{ path: '', loadChildren: () => import('./page/home/kto/kto-product-gangjin/kto-product-gangjin.module').then(m => m.KtoProductGangjinPageModule) }] },
      { path: 'coaching', children: [{ path: '', loadChildren: () => import('./page/wellness/coaching/coaching.module').then(m => m.CoachingPageModule) }] },
      { path: 'coaching-list', children: [{ path: '', loadChildren: () => import('./page/wellness/coaching/coaching-list/coaching-list.module').then(m => m.CoachingListPageModule) }] },
      { path: 'wellness', children: [{ path: '', loadChildren: () => import('./page/wellness/wellness.module').then(m => m.WellnessPageModule) }] },
      { path: 'wellness/wellness-product/:id', children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-product/wellness-product.module').then(m => m.WellnessProductPageModule) }] },
      { path: 'wellness/wellness-info/:id', data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-info/wellness-info.module').then(m => m.WellnessInfoPageModule) }] },
      { path: 'wellness/wellness-category/:id', data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-category/wellness-category.module').then(m => m.WellnessCategoryPageModule) }] },
      { path: 'wellness/wellness-campaign', children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-campaign/wellness-campaign.module').then(m => m.WellnessCampaignPageModule) }] },
      { path: 'wellness/wellness-stay', children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-stay/wellness-stay.module').then(m => m.WellnessStayPageModule) }] },
      { path: 'wellness/wellness-my-type/:id', data: { animationState: 'Child' }, canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-my-type/wellness-my-type.module').then(m => m.WellnessMyTypePageModule) }] },
      { path: 'wellness/wellness-symptom/:id', data: { animationState: 'Child' }, canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-symptom/wellness-symptom.module').then(m => m.WellnessSymptomPageModule) }] },
      { path: 'wellness/test/processing', children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-processing/wellness-processing.module').then(m => m.WellnessProcessingPageModule) }] },
      { path: 'wellness/test/result/:id', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-result/wellness-result.module').then(m => m.WellnessResultPageModule) }] },
      { path: 'wellness/test/wellness-change-code', canActivateChild: [AuthGuard], children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-change-code/wellness-change-code.module').then(m => m.WellnessChangeCodePageModule) }] },
      { path: 'wellness/test/wellness-donation-info', data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-donation-info/wellness-donation-info.module').then(m => m.WellnessDonationInfoPageModule) }] },
      { path: 'wellness/test/wellness-feed-add/:id', canActivateChild: [AuthGuard], data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-feed-add/wellness-feed-add.module').then(m => m.WellnessFeeAddPageModule) }] },
      { path: 'wellness/test/login', data: { animationState: 'Child' }, children: [{ path: '', loadChildren: () => import('./page/wellness/wellness-login/wellness-login.module').then(m => m.WellnessLoginPageModule) }] },
      { path: 'simple-product-add', canActivateChild: [AuthGuard], loadChildren: () => import('./page/home/simple-product-add/simple-product-add.module').then(m => m.SimpleProductAddPageModule) },
      //TODO add user is host guard
      { path: 'simple-product-edit/:id', canActivateChild: [AuthGuard], loadChildren: () => import('./page/home/simple-product-edit/simple-product-edit.module').then(m => m.SimpleProductEditPageeModule) },
    ]
  },
  {
    path: 'admin', component: AdminPage, canActivateChild: [AuthGuard, RoleGuard], children: [
      { path: '', redirectTo: 'payment', pathMatch: 'full' },
      { path: 'payment', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-payment/admin-payment.module').then(m => m.AdminPaymentPageModule) },
      { path: 'product', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-product/admin-product.module').then(m => m.AdminProductPageModule) },
      { path: 'schedule', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-schedule/admin-schedule.module').then(m => m.AdminSchedulePageModule) },
      { path: 'schedule/add/:id', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-schedule/admin-schedule-add/admin-schedule-add.module').then(m => m.AdminScheduleAddPagePageModule) },
      { path: 'schedule/add/:id/:ticketId', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-schedule/admin-schedule-add/admin-schedule-add.module').then(m => m.AdminScheduleAddPagePageModule) },
      { path: 'schedule/edit/:id', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-schedule/admin-schedule-edit/admin-schedule-edit.module').then(m => m.AdminScheduleEditPageModule) },
      { path: 'user', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-user/admin-user.module').then(m => m.AdminUserPageModule) },
      { path: 'settlement', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-settlement/admin-settlement.module').then(m => m.AdminSettlementPageModule) },
      { path: 'product-order', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-product-order/admin-product-order.module').then(m => m.AdminProductOrderPageModule) },
      { path: 'notification', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-notification/admin-notification.module').then(m => m.AdminNotificationPageModule) },
      { path: 'blog', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-blog/admin-blog.module').then(m => m.AdminBlogPageModule) },
      { path: 'event', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-event/admin-event.module').then(m => m.AdminEventPageModule) },
      { path: 'chat', data: { roles: ['admin'] }, loadChildren: () => import('../app/page/admin/admin-chat/admin-chat.module').then(m => m.AdminChatPageModule) },
      { path: 'banner', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-banner/admin-banner.module').then(m => m.AdminBannerPageModule) },
      { path: 'config', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-config/admin-config.module').then(m => m.AdminConfigPageModule) },
      { path: 'coupon', data: { roles: ['admin'] }, loadChildren: () => import('./page/admin/admin-coupon/admin-coupon.module').then(m => m.AdminCouponPageModule) },
    ]
  },
  {
    path: 'host', component: HostPage, canActivateChild: [AuthGuard], children: [
      { path: '', redirectTo: 'main', pathMatch: 'full', },
      { path: 'main', loadChildren: () => import('./page/host/host-main/host-main.module').then(m => m.HostMainPageModule) },
      {
        path: 'meeting-management', component: MeetingManagementPage, children: [
          { path: '', redirectTo: 'hosted-meetings', pathMatch: 'full' },
          { path: 'hosted-meetings', component: HostedMeetingsPage },
          { path: 'meeting-add', component: MeetingAddPage, canDeactivate: [CanDeactivateGuard] },
          //TODO add user is host guard
          { path: 'meeting-edit/:id', component: MeetingEditPage, canDeactivate: [CanDeactivateGuard] },
        ]
      },
      { path: 'reservation', loadChildren: () => import('./page/host/host-payment/host-payment.module').then(m => m.HostPaymentPageModule) },
      { path: 'schedule', loadChildren: () => import('../app/page/host/host-schedule/host-schedule.module').then(m => m.HostSchedulePageModule) },
      { path: 'schedule/add/:id', loadChildren: () => import('./page/host/host-schedule/host-schedule-add/host-schedule-add.module').then(m => m.HostScheduleAddPagePageModule) },
      { path: 'schedule/add/:id/:ticketId', loadChildren: () => import('./page/host/host-schedule/host-schedule-add/host-schedule-add.module').then(m => m.HostScheduleAddPagePageModule) },
      { path: 'schedule/edit/:id', loadChildren: () => import('./page/host/host-schedule/host-schedule-edit/host-schedule-edit.module').then(m => m.HostScheduleEditPageModule) },
      { path: 'review', loadChildren: () => import('./page/host/host-review/host-review.module').then(m => m.HostReviewPageModule) },
      { path: 'settlement', loadChildren: () => import('./page/host/host-settlement/host-settlement.module').then(m => m.HostSettlementPageModule) },
      { path: 'stat', loadChildren: () => import('./page/host/host-stat/host-stat.module').then(m => m.HostStatPageModule) },
      { path: 'info', loadChildren: () => import('./page/host/host-info/host-info.module').then(m => m.HostInfoPageModule) },
      { path: 'blog', loadChildren: () => import('./page/host/host-blog/host-blog.module').then(m => m.HostBlogPageModule) }
    ]
  },
  { path: '**', redirectTo: '/tabs/home', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, new RouterConfiguration()),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
