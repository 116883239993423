import { Component, Input, OnInit } from '@angular/core';
import { Feed, FeedType, WellnessCategory } from 'src/app/model/feed';
import { User } from 'src/app/model/user';
import { FeedService } from 'src/app/service/feed.service';
import { ReportService } from 'src/app/service/report.service';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'feed-list-comp',
  templateUrl: './feed-list.component.html',
  styleUrls: ['./feed-list.component.scss'],
})
export class FeedListComponent implements OnInit {
  @Input() types: FeedType[];
  @Input() wellnessCategory: WellnessCategory;
  currentUser: User;
  feeds: Feed[];
  currentPage: number;
  nextPage: number;

  constructor(
    private feedService: FeedService,
    private authService: AuthService,
    private reportService: ReportService,
  ) { }

  onScroll() {
    if (this.currentPage < this.nextPage) {
      this.feedService.search({ page: +this.nextPage, limit: 9, userId: this.currentUser?.id, types: this.types, wellnessCategory: this.wellnessCategory }).subscribe(resp => {
        const feeds = resp.items;
        this.feeds = [...this.feeds, ...feeds];
        this.setPagination(resp.meta);
      })
    }
  }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      this.setList();
    });
    if (this.currentUser) {
      this.reportService.getBlockList().subscribe(blocks => {
        if (blocks.length > 0 && this.feeds?.length > 0) {
          this.feeds = this.feeds.filter(feed => {
            return !blocks.find(block => block?.feed?.id === feed.id)
          })
        }
      });
    }
  }

  deleteFeed(feedId: number) {
    this.feeds = this.feeds.filter(f => f.id !== feedId);
  }

  setList() {
    this.feedService.search({ page: 1, limit: 9, userId: this.currentUser?.id, types: this.types, wellnessCategory: this.wellnessCategory }).subscribe(resp => {
      this.feeds = resp.items;
      this.setPagination(resp.meta);
    })
  }

  block(feed: Feed) {
    this.feeds = this.feeds.filter(f => f.id !== feed.id);
  }

  report(feed: Feed) {
    this.feeds = this.feeds.filter(f => f.id !== feed.id);
  }

  private setPagination(meta) {
    this.currentPage = +meta.currentPage;
    const lastPage = +meta.totalPages;
    if (this.currentPage + 1 <= lastPage) {
      this.nextPage = this.currentPage + 1;
    }
  }
}
