import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { ExpectedPrice, Product } from 'src/app/model/product';
import { CheckDesktopService } from 'src/app/service/check-desktop.service';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { EndStandard, TicketBadge } from './../../model/product';
import { FormService } from './../../service/form.service';
import { TicketService } from './../../service/ticket.service';

@Component({
  selector: 'schedule-control',
  templateUrl: './schedule-control.component.html',
  styleUrls: ['./schedule-control.component.scss'],
})
export class ScheduleControlComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() product: Product;
  @Input() type: 'add' | 'edit' | 'copy-add';
  @Input() isLoad: boolean;
  @Output() toggleTicketEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() completeEvent = new EventEmitter();
  random = `${moment().format('YYYY-MM-DDTHH:mm:ss:sss')}-${(Math.random() * 1000000).toFixed()}`;
  segmentMenu = 'schedule';
  minDate = moment().toDate();
  maxDate = moment().add(1, 'y').toDate();
  isDesktop: boolean;
  EndStandard = EndStandard;
  @ViewChild(BsDaterangepickerDirective, { static: false }) datapickerDirective;
  hours = [
    { value: 0, label: '00' },
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
  ];
  minutes = [
    { value: 0, label: '0' },
    { value: 30, label: '30' },
  ];
  badges: TicketBadge[];
  expectedPrices: ExpectedPrice[][] = [];
  segmentValue = 'scheudle';
  @ViewChild('sectionSchedule') sectionSchedule;

  constructor(
    private cds: CheckDesktopService,
    private productService: ProductService,
    private messageService: MessageService,
    private formService: FormService,
    public ticketService: TicketService
  ) { }

  ngOnInit(): void {
    this.cds.isDesktop.subscribe(resp => this.isDesktop = resp);
    this.form.controls.product.setValue(this.product.id);
    if (this.type === 'add' || this.type === 'copy-add') {
      this.addDesc();
    }
    this.productService.getTicketBadges({ page: 1, limit: 9999 }).subscribe(resp => {
      this.badges = resp.items;
    })
    this.form.controls.dowCron.valueChanges.subscribe(dow => {
      let cron = this.form.value.cron;
      if (!cron) {
        cron = `* * * * ${dow}`;
        this.form.controls.cron.setValue(cron);
        return;
      }
      const minute = cron.split(" ")[0];
      const hour = cron.split(" ")[1];
      const time = `${minute} ${hour}`;
      this.form.controls.cron.setValue(`${time} * * ${dow}`);
    })
    this.form.controls.hours.valueChanges.subscribe(hour => {
      let cron = this.form.value.cron;
      if (!cron) {
        cron = `* ${hour} * * *`;
        this.form.controls.cron.setValue(cron);
        return;
      }
      const dow = cron.split(" ")[4];
      const minute = cron.split(" ")[0];
      this.form.controls.cron.setValue(`${minute} ${hour} * * ${dow}`);
    })
    this.form.controls.minutes.valueChanges.subscribe(minute => {
      let cron = this.form.value.cron;
      if (!cron) {
        cron = `${minute} * * * *`;
        this.form.controls.cron.setValue(cron);
        return;
      }
      const dow = cron.split(" ")[4];
      const hour = cron.split(" ")[1];
      this.form.controls.cron.setValue(`${minute} ${hour} * * ${dow}`);
    })
    this.form.controls.period.valueChanges.subscribe(resp => {
      const period = this.form.controls.period;
      const fromValue = resp[0];
      const toValue = resp[1];
      if (Math.abs(moment(toValue).diff(moment(fromValue), 'days')) > 365) {
        period.setErrors({ 'maxDurationDays': true })
      } else {
        if (period.hasError('maxDurationDays')) {
          const { maxDurationDays, ...errors } = period.errors;
          period.setErrors(errors);
          period.updateValueAndValidity()
        }
      }
    })
    if (this.type === 'edit' || this.type === 'copy-add') {
      const { maxParticipants, pricePerPerson, minParticipantsPerTeam, maxParticipantsPerTeam, pricePerTeam, extraPricePerPerson, endStandard, options } = this.form.value;
      const prices = this.getPrices().value;
      if (this.form.value.options.length === 0) {
        this.expectedPrices[0] = endStandard === EndStandard.PER_PERSON ?
          this.ticketService.getPerPersonExpectedPrices(pricePerPerson, prices, maxParticipants)
          : this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices);
      } else {
        options.forEach(option => {
          const list = endStandard === EndStandard.PER_PERSON ?
            this.ticketService.getPerPersonExpectedPrices(option.pricePerPerson, option.prices, option.maxParticipants)
            : this.ticketService.getPerTeamExpectedPrices(option.minParticipantsPerTeam, option.maxParticipantsPerTeam, option.extraPricePerPerson, option.pricePerTeam, option.prices);
          this.expectedPrices.push(list);
        })
      }
    }
  }

  validationMinControl(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const max = control.root.value[controlName];
      const min = control.value;
      const isUpper = max < min;
      return isUpper ? { 'isUpper': { isUpper } } : null;
    };
  }

  validationMin() {
    const max = this.form.controls.maxParticipants;
    const min = this.form.controls.minParticipants;
    if (max.value < min.value) {
      min.setErrors({ 'isUpper': true })
    } else {
      min.setErrors({ 'isUpper': null });
    }
    min.updateValueAndValidity();
    this.updatePerPersonExpectedPrices();
  }

  updatePerPersonExpectedPrices() {
    const { maxParticipants, pricePerPerson } = this.form.value;
    const prices = this.getPrices().value;
    this.expectedPrices[0] = this.ticketService.getPerPersonExpectedPrices(pricePerPerson, prices, maxParticipants)
  }

  updatePerTeamExpectedPrices() {
    const { minParticipantsPerTeam, maxParticipantsPerTeam, pricePerTeam, extraPricePerPerson } = this.form.value;
    const prices = this.getPrices().value;
    this.expectedPrices[0] = this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices)

    this.getPrices().clear();
    for (let i = 0; i < this.expectedPrices[0].length; i++) {
      this.addPrice(this.expectedPrices[0][i].quantity, this.expectedPrices[0][i].price)
    }
  }

  updatePerTeamExpectedPrices2() {
    const { minParticipantsPerTeam, maxParticipantsPerTeam, pricePerTeam, extraPricePerPerson } = this.form.value;
    const prices = this.getPrices().value;
    this.expectedPrices[0] = this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices)
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  onSegmentChange(event) {
    this.segmentMenu = event;
  }

  onShowPicker(event) {
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
      const { cell, isHovered } = hoverEvent;
      if ((isHovered && !!navigator.platform && /iPad|iPhone|iPod|Apple/.test(navigator.platform)) && 'ontouchstart' in window) {
        (this.datapickerDirective as any)._datepickerRef.instance.daySelectHandler(cell);
      }
      return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }

  changeDow(event) {
    this.form.controls.dowCron.setValue(event.dowCron);
    this.form.controls.weekString.setValue(event.weekString);
  }

  clickEndStandard(endStandard) {
    if (this.form.value.endStandard != "") {
      this.messageService.popup({
        title: '판매 단위 선택하기',
        description: endStandard === EndStandard.PER_PERSON ? '팀별 단위 가격 설정 정보가 초기화됩니다.' : '인원 단위 가격 설정 정보가 초기화됩니다.',
        confirmLabel: '확인',
        confirmBtnClass: 'primary',
        confirmHandler: () => {
          this.form.controls.endStandard.setValue(endStandard);
          this.setFormByEndstandard(endStandard)
        },
        cancelLabel: '취소'
      })
    } else {
      this.form.controls.endStandard.setValue(endStandard);
      this.setFormByEndstandard(endStandard)
    }
  }

  setFormByEndstandard(resp) {
    if (this.form.controls.prices) {
      this.form.controls.prices.reset();
      (this.form.controls.prices as FormArray).clear();
    }
    if (this.getOptions().length === 0) {
      if (resp === EndStandard.PER_PERSON) {
        this.form.addControl('minParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.min(1), this.validationMinControl('maxParticipants')])));
        this.form.addControl('maxParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.max(100)])));
        this.form.addControl('pricePerPerson', new FormControl(0, Validators.compose([Validators.required, Validators.max(10000000)])));
        this.form.removeControl('maxTeamCapacity');
        this.form.removeControl('minParticipantsPerTeam');
        this.form.removeControl('maxParticipantsPerTeam');
        this.form.removeControl('pricePerTeam');
        this.form.removeControl('extraPricePerPerson');
      } else {
        this.form.addControl('maxTeamCapacity', new FormControl(0));
        this.form.addControl('minParticipantsPerTeam', new FormControl(0));
        this.form.addControl('maxParticipantsPerTeam', new FormControl(0));
        this.form.addControl('pricePerTeam', new FormControl(0));
        this.form.addControl('extraPricePerPerson', new FormControl(0));
        this.form.removeControl('minParticipants')
        this.form.removeControl('maxParticipants')
        this.form.removeControl('pricePerPerson')
      }
    } else {
      this.getOptions().controls.forEach((formGroup: FormGroup) => {
        if (formGroup.controls.prices) {
          formGroup.controls.prices.reset();
          (formGroup.controls.prices as FormArray).clear();
        }
        if (resp === EndStandard.PER_PERSON) {
          formGroup.addControl('minParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])));
          formGroup.addControl('maxParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.max(100)])));
          formGroup.addControl('pricePerPerson', new FormControl(0, Validators.compose([Validators.required, Validators.max(10000000)])));
          formGroup.removeControl('maxTeamCapacity');
          formGroup.removeControl('minParticipantsPerTeam');
          formGroup.removeControl('maxParticipantsPerTeam');
          formGroup.removeControl('pricePerTeam');
          formGroup.removeControl('extraPricePerPerson');
        } else {
          formGroup.addControl('maxTeamCapacity', new FormControl(0));
          formGroup.addControl('minParticipantsPerTeam', new FormControl(0));
          formGroup.addControl('maxParticipantsPerTeam', new FormControl(0));
          formGroup.addControl('pricePerTeam', new FormControl(0));
          formGroup.addControl('extraPricePerPerson', new FormControl(0));
          formGroup.removeControl('minParticipants')
          formGroup.removeControl('maxParticipants')
          formGroup.removeControl('pricePerPerson')
        }
      })
    }
    this.expectedPrices = [];
  }

  onCheckBadge(badge: TicketBadge) {
    const formArray: FormArray = this.form.controls.badges as FormArray;
    if (this.form.controls.badges.value.includes(badge.id)) {
      const index = formArray.controls.findIndex(control => control.value === badge.id);
      formArray.removeAt(index);
    } else {
      if (this.form.value.badges.length > 2) {
        this.messageService.popup({
          title: '티켓 특징',
          description: '최대 3개까지 선택 가능합니다.',
          cancelLabel: '확인',
        })
        return;
      }
      formArray.push(new FormControl(badge.id));
    }
  }

  close() {
    this.closeEvent.emit();
  }

  complete() {
    this.completeEvent.emit();
  }

  getStatusBadge(status) {
    return this.productService.getStatusBadge(status);
  }

  // prices
  getPrices(): FormArray {
    return this.form.get('prices') as FormArray;
  }

  newPrice(quantity = 0, price = 0): FormGroup {
    return new FormGroup({
      quantity: new FormControl(quantity, new FormControl(0, Validators.compose([Validators.required]))),
      price: new FormControl(price, new FormControl(0, Validators.compose([Validators.required]))),
      discountPrice: new FormControl(price, new FormControl(0, Validators.compose([Validators.required]))),
    })
  }

  addPrice(quantity?: number, price?: number) {
    this.getPrices().push(this.newPrice(quantity, price));
  }

  removePrice(index: number) {
    this.getPrices().removeAt(index);
    if (this.form.controls.endStandard.value === EndStandard.PER_PERSON) {
      this.updatePerPersonExpectedPrices();
    } else {
      this.updatePerTeamExpectedPrices();
    }
  }

  // descs
  getDescs(): FormArray {
    return this.form.get('description') as FormArray;
  }

  newDesc(): FormGroup {
    return new FormGroup({
      desc: new FormControl('', Validators.maxLength(32))
    })
  }

  addDesc() {
    if (this.getDescs().length === 5) {
      this.messageService.popup({
        title: '티켓 설명',
        description: '최대 5개까지 작성 가능합니다.',
        cancelLabel: '확인',
      })
      return;
    }
    this.getDescs().push(this.newDesc());
  }

  removeDesc(index: number) {
    this.getDescs().removeAt(index);
  }

  // options
  updatePerPersonExpectedPricesByOption(form, optionIndex: number) {
    const { maxParticipants, pricePerPerson } = form.value;
    const prices = this.getPricesByOption(optionIndex).value;
    this.expectedPrices[optionIndex] = this.ticketService.getPerPersonExpectedPrices(pricePerPerson, prices, maxParticipants)
  }

  updatePerTeamExpectedPricesByOption(form, optionIndex: number) {
    const { minParticipantsPerTeam, maxParticipantsPerTeam, pricePerTeam, extraPricePerPerson } = form.value;
    const prices = this.getPricesByOption(optionIndex).value;
    this.expectedPrices[optionIndex] = this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices)

    this.getPricesByOption(optionIndex).clear();
    for (let i = 0; i < this.expectedPrices[optionIndex].length; i++) {
      this.addPriceByOption(optionIndex, this.expectedPrices[optionIndex][i].quantity, this.expectedPrices[optionIndex][i].price)
    }
  }

  updatePerTeamExpectedPricesByOption2(form, optionIndex: number) {
    const { minParticipantsPerTeam, maxParticipantsPerTeam, pricePerTeam, extraPricePerPerson } = form.value;
    const prices = this.getPricesByOption(optionIndex).value;
    this.expectedPrices[optionIndex] = this.ticketService.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices)
  }

  addOption() {
    if (!this.form.value.endStandard) {
      this.messageService.popup({
        title: '옵션 생성',
        description: '판매  단위를 먼저 선택해주세요.',
        cancelLabel: '확인',
        cancelHandler: () => {
          const element = document.getElementById(`schedule-control-endStandard-${this.product.id}`);
          window.scrollTo({ top: element.getBoundingClientRect().top + window.pageYOffset - 55, behavior: 'smooth' });
        }
      })
      return;
    }
    if (this.getOptions().length === 0) {
      this.messageService.popup({
        title: '옵션 생성',
        description: '하나의 티켓에 2개 이상의 옵션이 있나요?',
        confirmLabel: '2개 이상의 옵션이 있어요!',
        confirmBtnClass: 'primary',
        confirmHandler: () => {
          this.addOptionControl();
          this.addOptionControl();
          this.removePriceFormControl();
        },
        cancelLabel: '취소',
      })
    } else {
      this.addOptionControl();
      this.removePriceFormControl();
    }
  }

  removePriceFormControl() {
    if (this.form.value.endStandard === EndStandard.PER_PERSON) {
      this.form.removeControl('minParticipants');
      this.form.removeControl('maxParticipants');
      this.form.removeControl('pricePerPerson');
    } else {
      this.form.removeControl('maxTeamCapacity');
      this.form.removeControl('minParticipantsPerTeam');
      this.form.removeControl('maxParticipantsPerTeam');
      this.form.removeControl('pricePerTeam');
      this.form.removeControl('extraPricePerPerson');
    }
    this.form.removeControl('prices');
  }

  getOptions(): FormArray {
    return this.form.get('options') as FormArray;
  }

  newOption(): FormGroup {
    const form = new FormGroup({
      name: new FormControl('', this.formService.getValidators(24)),
      badges: new FormArray([], this.formService.maxLengthArray(3)),
      requestBadgeDescription: new FormControl('', Validators.maxLength(100)),
      description: new FormArray([], this.formService.maxLengthArray(5)),
      prices: new FormArray([]),
    });
    if (this.form.value.endStandard === EndStandard.PER_PERSON) {
      form.addControl('minParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])));
      form.addControl('maxParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.max(100)])));
      form.addControl('pricePerPerson', new FormControl(0, Validators.compose([Validators.required, Validators.max(10000000)])));
    } else {
      form.addControl('maxTeamCapacity', new FormControl(0));
      form.addControl('minParticipantsPerTeam', new FormControl(0));
      form.addControl('maxParticipantsPerTeam', new FormControl(0));
      form.addControl('pricePerTeam', new FormControl(0));
      form.addControl('extraPricePerPerson', new FormControl(0));
    }
    return form;
  }

  addOptionControl() {
    this.getOptions().push(this.newOption());
    this.getDescsByOption(this.getOptions().length - 1).push(this.newDescByOption());
  }

  removeOption(index: number) {
    if (this.getOptions().length === 2) {
      this.messageService.popup({
        title: '옵션 삭제',
        description: '티켓 옵션이 필요하다면 최소 2개의 옵션을 작성해주세요.',
        confirmLabel: '이 옵션은 삭제됩니다.',
        confirmBtnClass: 'primary',
        confirmHandler: () => {
          this.getOptions().removeAt(index);
        },
        cancelLabel: '취소',
      })
    } else if (this.getOptions().length === 1) {
      if (this.form.value.endStandard === EndStandard.PER_PERSON) {
        this.form.addControl('minParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])));
        this.form.addControl('maxParticipants', new FormControl(0, Validators.compose([Validators.required, Validators.max(100)])));
        this.form.addControl('pricePerPerson', new FormControl(0, Validators.compose([Validators.required, Validators.max(10000000)])));
      } else {
        this.form.addControl('maxTeamCapacity', new FormControl(0));
        this.form.addControl('minParticipantsPerTeam', new FormControl(0));
        this.form.addControl('maxParticipantsPerTeam', new FormControl(0));
        this.form.addControl('pricePerTeam', new FormControl(0));
        this.form.addControl('extraPricePerPerson', new FormControl(0));
      }
      this.form.addControl('prices', new FormArray([]))
      this.expectedPrices = [];
      this.getOptions().removeAt(index);
    } else {
      this.getOptions().removeAt(index);
    }
  }

  // descs by option
  getDescsByOption(optionIndex: number): FormArray {
    return this.getOptions().at(optionIndex).get('description') as FormArray;
  }

  newDescByOption(): FormGroup {
    return new FormGroup({
      desc: new FormControl('', Validators.maxLength(32))
    })
  }

  addDescByOption(optionIndex: number) {
    if (this.getDescsByOption(optionIndex).length === 5) {
      this.messageService.popup({
        title: '설명 추가',
        description: '최대 5개까지 작성 가능합니다.',
        cancelLabel: '확인',
      })
      return;
    }
    this.getDescsByOption(optionIndex).push(this.newDescByOption());
  }

  removeDescByOption(optionIndex: number, descIndex: number) {
    this.getDescsByOption(optionIndex).removeAt(descIndex);
  }

  // prices by option
  getPricesByOption(optionIndex: number): FormArray {
    return this.getOptions().at(optionIndex).get('prices') as FormArray;
  }

  newPriceByOption(quantity = 0, price = 0): FormGroup {
    return new FormGroup({
      quantity: new FormControl(quantity, Validators.compose([Validators.required])),
      price: new FormControl(price, Validators.compose([Validators.required])),
      discountPrice: new FormControl(price, new FormControl(0, Validators.compose([Validators.required]))),
    })
  }

  addPriceByOption(optionIndex: number, quantity?: number, price?: number) {
    this.getPricesByOption(optionIndex).push(this.newPriceByOption(quantity, price));
  }

  removePriceByOption(optionIndex: number, priceIndex: number) {
    this.getPricesByOption(optionIndex).removeAt(priceIndex);
    if (this.form.controls.endStandard.value === EndStandard.PER_PERSON) {
      this.updatePerPersonExpectedPricesByOption(this.getOptions().at(optionIndex), optionIndex)
    } else {
      this.updatePerTeamExpectedPricesByOption(this.getOptions().at(optionIndex), optionIndex)
    }
  }

  onCheckBadgeByOption(badge: TicketBadge, optionIndex: number) {
    const formArray: FormArray = this.getOptions().at(optionIndex).get('badges') as FormArray;
    if (formArray.value.includes(badge.id)) {
      const index = formArray.controls.findIndex(control => control.value === badge.id);
      formArray.removeAt(index);
    } else {
      if (formArray.controls.length > 2) {
        this.messageService.popup({
          title: '옵션 특징',
          description: '최대 3개까지 선택 가능합니다.',
          cancelLabel: '확인',
        })
        return;
      }
      formArray.push(new FormControl(badge.id));
    }
  }

  toggle(event) {
    this.toggleTicketEvent.emit(event);
  }
}