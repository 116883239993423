import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  minLengthArray = (min: number) => {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min)
        return null;
      return { MinLengthArray: true };
    }
  }

  maxLengthArray = (max: number) => {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length <= max)
        return null;
      return { MinLengthArray: true };
    }
  }

  getValidators(maxLength: number, validators?: ValidatorFn[]): Validators {
    const basic: ValidatorFn[] = [Validators.required, Validators.maxLength(maxLength)];
    if (!!validators) { return Validators.compose(basic.concat(validators)); }
    return basic;
  }

  getInvalidControls(form: FormGroup): string[] {
    const invalids = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalids.push(name);
      }
    }
    return invalids;
  }

  autoSave(values: any) {
    localStorage.setItem('product-add', JSON.stringify(values))
  }

  loadAutoSave(key: string) {
    const value = localStorage.getItem(key)
    if (!value) return undefined;
    return JSON.parse(value);
  }

  removeAuthSavedValue(key: string) {
    localStorage.removeItem(key);
  }
}
