import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { LoggerService, TargetType } from './../service/logger.service';

@Directive({
  selector: '[click-log]'
})
export class ClickLogDirective implements OnInit {
  @Input() pageName: string = '';
  @Input() eventName: string;
  @Input() targetUrl = '';
  @Input() targetInfo: {
    type: TargetType,
    data?: any
  } = { type: TargetType.ETC }

  constructor(
    private logger: LoggerService
  ) { }

  ngOnInit() { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.logger.addLog({ pageName: this.pageName, eventName: this.eventName, eventType: 'click', targetUrl: this.targetUrl, targetInfo: this.targetInfo });
  }
}