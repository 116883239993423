import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Coupon } from 'src/app/model/coupon';
import { Hashtag, Product } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { AllianceService } from 'src/app/service/alliance.service';
import { CouponService } from 'src/app/service/coupon.service';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { UtilService } from 'src/app/service/util.service';
import { Alliance } from './../../../model/alliance';

@Component({
  selector: 'product-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() user: User
  alliance: Alliance;
  normalHashtags: Hashtag[];
  largestCouponDiscountPrice = 0;
  @ViewChild('couponTpl') couponTpl;
  category: string;
  region: string;

  constructor(
    private bottomSheet: MatBottomSheet,
    private couponService: CouponService,
    private messageService: MessageService,
    private router: Router,
    private allianceService: AllianceService,
    private productService: ProductService,
    private utilService: UtilService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.normalHashtags = this.product.hashtags.filter(h => !h.isAnalysis);
    this.setCouponPossible();
  }

  ngOnInit() {
    this.allianceService.alliance.subscribe(resp => {
      this.alliance = resp;
    })
    this.region = this.utilService.setRegion(this.product);
    this.category = this.utilService.setCategory(this.product?.categories);
  }

  openCouponBm() {
    this.bottomSheet.open(this.couponTpl, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  closeBm() {
    this.bottomSheet.dismiss();
  }

  downloadCoupon(coupon: Coupon) {
    if (this.user) {
      this.couponService.addCouponToUser({
        userId: this.user.id,
        name: coupon.name,
        contents: coupon.contents,
        price: coupon.price,
        expireDuration: coupon.expireDuration,
        availableReservationDate: coupon.availableReservationDate,
        minOrderPrice: coupon.minOrderPrice,
      }).subscribe(resp => {
        this.setCouponPossible();
      })
    } else {
      this.closeBm();
      this.messageService.toast('로그인이 필요합니다.');
      this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
    }
  }

  setCouponPossible() {
    this.productService.getCoupons(this.product.id, true).subscribe(coupons => {
      this.product.coupons = coupons;
      this.largestCouponDiscountPrice = this.product.coupons?.sort((a, b) => a.price > b.price ? -1 : 1)[0]?.price;
      if (this.user) {
        this.couponService.search({ page: 1, limit: 9999, userId: this.user.id, expireDuration: new Date(), isUsed: false }).subscribe(coupons => {
          this.product.coupons = this.product.coupons?.map(coupon => {
            coupon.isPossible = !coupons.items.find(c => c.name === coupon.name);
            return coupon;
          })
        })
      } else {
        this.product.coupons = this.product.coupons?.map(coupon => {
          coupon.isPossible = true;
          return coupon;
        })
      }
    })
  }

  goToFeed() {
    this.router.navigate(['/tabs/feed-list', this.product.id]);
  }

  goToHost() {
    this.router.navigate(['/tabs/profile', this.product.host.id]);
  }
}
