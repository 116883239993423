import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/service/message.service';
declare var naver;

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges {
  @Input() id: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() address: string;
  @Input() mapOption: {
    draggable: true,
    scrollWheel: true
  }
  @Input() placeholder = '';
  @Input() invalid: boolean;
  @Output() searchEvent = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  zoom = 10;
  naverMap: any;
  markers = [];

  constructor(
    private messageService: MessageService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    window.dispatchEvent(new Event('resize'));
  }

  ngAfterViewInit(): void {
    this.initialMap(this.longitude, this.latitude);
    window.dispatchEvent(new Event('resize'));
  }

  initialMap(lon, lat) {
    let mapOptions = {
      center: new naver.maps.LatLng(lat, lon),
      zoom: this.zoom,
      zoomControl: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: naver.maps.MapTypeControlStyle.BUTTON,
        position: naver.maps.Position.TOP_RIGHT
      },
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.SMALL,
        position: naver.maps.Position.TOP_RIGHT
      }
    };
    mapOptions = { ...mapOptions, ...this.mapOption }
    this.naverMap = new naver.maps.Map(this.id, mapOptions);

    const point = new naver.maps.Point(lon, lat);
    this.naverMap.setCenter(point);
    const marker = new naver.maps.Marker({
      position: new naver.maps.LatLng(lat, lon),
      map: this.naverMap
    });
    this.markers.push(marker);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  search(address) {
    this.markers.map(marker => marker.setMap(null));
    naver.maps.Service.geocode({ address }, (status, response) => {
      if (status === naver.maps.Service.Status.ERROR) {
        this.input.nativeElement.value = this.address;
        this.messageService.popup({
          title: '주소 검색',
          description: '주소 검색 중 오류가 발생하였습니다.',
          cancelLabel: '확인',
        })
        return;
      }
      if (response.v2.meta.totalCount === 0) {
        this.input.nativeElement.value = this.address;
        this.messageService.popup({
          title: '주소 검색',
          description: '검색 결과가 없습니다.',
          cancelLabel: '확인',
        })
        return;
      }

      const roadAdress = response.result.items.find(item => item.isRoadAddress == true);
      const jibunAddress = response.result.items.find(item => item.isRoadAddress == false);
      const detail = roadAdress ? roadAdress.addrdetail : jibunAddress.addrdetail;
      const country = detail.country ?? '';
      const sido = detail.sido ?? '';
      const sigugun = detail.sigugun ?? '';
      const dongmyun = detail.dongmyun ?? '';
      const ri = detail.ri ?? '';
      const rest = detail.rest ?? '';

      const item = response.v2.addresses[0];
      this.latitude = +item.y;
      this.longitude = +item.x;
      if (item.roadAddress) this.address = item.roadAddress
      else this.address = item.jibunAddress;
      const point = new naver.maps.Point(this.longitude, this.latitude);

      this.naverMap.setCenter(point);
      this.naverMap.setZoom(17)
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(this.latitude, this.longitude),
        map: this.naverMap
      });
      this.markers.push(marker);
      this.input.nativeElement.value = this.address;
      this.searchEvent.emit({ latitude: this.latitude, longitude: this.longitude, address: this.address, country, sido, sigugun, dongmyun, ri, rest });
    });
  }
}
