<div style="text-align: center">
  <button class="nnb-btn-md btn1 nnb-btn-block" (click)="openChanneltalk()">
    <div style="display: flex;justify-content: center;gap: 8px;align-items: center;">
      <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/channeltalk.svg">
      <div class="text">채널톡 1:1 채팅 문의</div>
    </div>
  </button>

  <br>

  <a href="tel:02-3661-0116" style="text-decoration: none; color: black;">
    <button class="nnb-btn-md btn2 nnb-btn-block">
      <div style="display: flex;justify-content: center;gap: 8px;align-items: center;">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/phone-outgoing.svg">
        <div class="text">고객센터 10:00 ~ 20:00</div>
      </div>
    </button>
  </a>
</div>