<div class="container">
  <ng-container *ngIf="feeds">
    <div *ngIf="feeds.length > 0">
      <ng-container *ngFor="let feed of feeds">
        <div style="margin-bottom: 24px;padding: 0px">
          <feed [feed]="feed" [currentUser]="currentUser" (deleteEvent)="deleteFeed($event)"
            (blockEvent)="block($event)" (reportEvent)="report($event)" [wellnessViewType]="'vertical'"></feed>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!feeds">
    <div style="padding:16px">
      <div style="display:flex;gap:12px;align-items: center;">
        <skeleton [width]="'40px'" [height]="'40px'" [borderRadius]="'100%'"></skeleton>
        <skeleton [width]="'80px'" [height]="'40px'" [borderRadius]="'12px'"></skeleton>
      </div>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'280px'" [borderRadius]="'12px'"></skeleton>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'30px'" [borderRadius]="'12px'"></skeleton>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'30px'" [borderRadius]="'12px'"></skeleton>

      <div style="height:48px"></div>
      <div style="display:flex;gap:12px;align-items: center;">
        <skeleton [width]="'40px'" [height]="'40px'" [borderRadius]="'100%'"></skeleton>
        <skeleton [width]="'80px'" [height]="'40px'" [borderRadius]="'12px'"></skeleton>
      </div>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'280px'" [borderRadius]="'12px'"></skeleton>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'30px'" [borderRadius]="'12px'"></skeleton>
      <div style="height:8px"></div>
      <skeleton [width]="'100%'" [height]="'30px'" [borderRadius]="'12px'"></skeleton>
    </div>
  </ng-container>

  <infinity-scroll (onScroll)="onScroll()"></infinity-scroll>
</div>