import { Component, Inject, Renderer2 } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackBarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<SnackBarComponent>,
    private ren: Renderer2
  ) {
    setTimeout(() => {
      let snackEl = document.getElementsByClassName('mat-snack-bar-container').item(0);
      this.ren.listen(snackEl, 'click', () => {
        this.snackRef.dismiss();
      })
    })
  }
}
