import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import 'moment/locale/ko';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxCronUiConfig } from 'ngx-cron-ui/lib/model/model';
import { QuillEditorComponent } from 'ngx-quill';
import { Amenity, Product, ProductRepresentationPhotoCreateDto } from 'src/app/model/product';
import { AuthService } from 'src/app/service/auth.service';
import { MessageService } from 'src/app/service/message.service';
import { Hashtag } from './../../model/product';
import { CheckDesktopService } from './../../service/check-desktop.service';
import { FileUploadService } from './../../service/file-upload.service';
import { FormService } from './../../service/form.service';

@Component({
  selector: 'meeting-control',
  templateUrl: './meeting-control.component.html',
  styleUrls: ['./meeting-control.component.scss'],
})
export class MeetingControlComponent implements OnInit, AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() quillStyle;
  @Input() hashtags: Hashtag[];
  @Input() amenities: Amenity[];
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  @Input() previewProduct: Product;
  @Input() submitMessage: string;
  @Input() loadMessage: string;
  @Input() isLoad: boolean;

  @Output() onNextEvent = new EventEmitter();
  @Output() onPrevEvent = new EventEmitter();
  @Output() onLastCheckEvent = new EventEmitter();
  @Output() onCheckDiscountPriceEvent = new EventEmitter();
  @Output() onChangeAddressEvent = new EventEmitter();
  @Output() onAddEvent = new EventEmitter();
  @Output() onQuillLoadEvent = new EventEmitter();

  addTag = true;
  MAX_HASHTAG_LENGTH = 20;
  isDesktop = false;
  isShowMenu = false;

  @ViewChild('quill') quill: QuillEditorComponent;

  ngxCronUiConfig: NgxCronUiConfig = {
    option: { minute: false, hour: false, year: false },
    isSetDefaultValue: true,
    isBaseFrequencyNewLine: true
  }

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  days = [];
  hours = [];
  minutes = [];
  reservationHours = [6, 12, 18, 24, 30, 36, 48, 60, 72, 96, 120];

  modalRef: BsModalRef;

  noticeChcked = false;
  descriptions: { title: string, descList: string[], moreDescList?: string[], image?: string }[];
  random = `${moment().format('YYYY-MM-DDTHH:mm:ss:sss')}-${(Math.random() * 1000000).toFixed()}`;

  croppedImage;
  toProductRepresentationPhotoCreateDto = (data: any) => {
    return new ProductRepresentationPhotoCreateDto(data.url, 0, data.file)
  }
  isAdmin = false;

  constructor(
    private cds: CheckDesktopService,
    public fileUploadService: FileUploadService,
    private formService: FormService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.getCurrentUserFromServer().subscribe(user => {
      this.isAdmin = user.role === 'admin';
    })
    this.descriptions = [
      {
        title: '상품을 잘 표현하는 직관적인 한 문장을 만들어 주세요',
        descList: [
          '제목은 노출되는 상품 목록에서 고객에게 어필할 수 있는 첫 문구입니다.',
          '상품 주제를 정확하고 간결하게 표현해 주세요.',
          '지역명, 일정 등의 정보가 포함된 제목은 전달에 더욱 효과적입니다.'
        ],
        moreDescList: [
          '어떤 제목이 좋을지 여전히 고민이신가요?',
          '<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/01-상품-제목">제목 가이드</a>에서 다양한 사례와 팁을 참고해 보세요!'
        ]
      },
      {
        title: '고객의 클릭을 유도하는 대표 이미지를 등록해 주세요',
        descList: [
          '상품 목록에서 고객에게 제일 먼저 노출되는 대표 이미지입니다.',
          '상품의 특성이 잘 드러난 이미지가 좋습니다.',
          '텍스트가 포함된 이미지 사용은 되도록 지양해 주세요.',
          '이미지 사이즈는 1080 x 1080px 또는 1:1 비율로 만들어 주세요.'],
        moreDescList: [
          '대표 이미지가 상품의 첫 인상을 결정해요!',
          '<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/02-대표-이미지">대표 이미지 가이드</a>에서 다양한 사례와 팁을 참고해 보세요!'
        ]
      },
      {
        title: '만남을 위한 구체적인 장소와 소요시간을 알려주세요.',
        descList: [
          "주소에는 도로명 주소를, 상세 장소에는 구체적인 장소 명을 작성해주세요.",
          "이 장소에 오기 위해서 도보, 대중교통, 자차 등으로 어떻게 오면 좋은지 알려주세요.",
          "상품 진행시간은 상품을 진행하는데 걸리는 총 소요 시간을 작성해주시면 됩니다.",
          "예약 가능 시간은 최소 몇 시간 전까지 예약이 가능한지를 작성해주시면 됩니다.",
        ],
        moreDescList: ['<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/03-장소시간">장소/시간 가이드</a>의 팁을 참고해 보세요!']
      },
      {
        title: '상품에 참여하기 위한 가격을 설정해 주세요',
        descList: [
          "메인 화면에 노출되는 가격입니다.",
          "가격의 자릿수 구분을 위해 [,]이나 [.]을 사용하지 말고 숫자만 기입해 주세요.",
          "가격에는 상품의 원래 가격을 기입해 주세요. (무료 상품인 경우 '0'을 기입해 주세요)",
          "할인된 가격을 책정하지 않으려면 숫자를 지우면 됩니다.",
        ],
        moreDescList: ['<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/04-가격">가격 가이드</a>의 팁을 참고해 보세요!']
      },
      {
        title: '상품을 소개하는 매력적인 글과 이미지를 기입해 주세요.',
        descList: [
          "맞춤법이 맞지 않거나 오타가 있을 경우 임의로 수정될 수 있으며, 검수 절차로 인해 상품 판매가 늦어질 수 있습니다. ",
          "너무 긴 글과 많은 사진 사용은 오히려 가독성을 떨어뜨려 쉽게 피로를 유발합니다.",
          "참여자들이 어떤 즐거운 경험을 할 수 있는지, 무엇을 얻어갈 수 있는지 상상해볼 수 있도록 작성해 주세요.",
        ],
        moreDescList: [
          '어떻게 써야 할지 도저히 감이 잡히지 않는다면',
          '<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/05-소개">소개 가이드</a>의 팁을 참고해 보세요!'
        ]
      },
      {
        title: '참여자가 직접 준비해야 할 것을 기입해 주세요.',
        descList: [
          "원활한 진행을 위해 참여자가 꼭 준비해야 할 것을 적어주세요.",
          "깜빡 잊은 바람에 못챙겨올 수 있는 물건을 세심하게 한 번 더 챙겨주셔도 좋아요.",
          "상품에 포함되는 사항과 불포함되는 사항을 명시해주면 참여자들이 상품을 이해하는데 훨씬 쉬울거에요.",
          "숙소 상품이라면 숙소 시설을 선택하여 표시할 수도 있습니다."
        ],
        moreDescList: ['<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/06-준비물">준비물</a>의 팁을 참고해 보세요!']
      },
      {
        title: '참여자에게 당부하거나 중요하게 안내하고 싶은 사항에 관해 기입해 주세요.',
        descList: [
          "최소 인원 모객 미달 또는 천재 지변으로 인한 취소 등 안내사항에 관해 언급해 주세요.",
          "딱히 유의사항이 생각나지 않는다면 '노는법 정책을 따르겠습니다'를 체크해 주세요.",
          "노는법의 유의사항 기본 정책에 추가 또는 삭제 하셔도 괜찮습니다.",
          "유의사항은 추후 참여자와 문제 발생시 중요하게 다뤄지는 증빙자료이기 때문에 신중히 작성해 주세요.",
        ],
        moreDescList: ['<a target="_blank" href="https://nnbhost.super.site/상품-관리하기/07-유의사항">유의사항</a>의 팁을 참고해 보세요!']
      },
      {
        title: '상품 시작일을 기준으로 환불정책을 기입해 주세요.',
        descList: [
          "상품 시작일 자정을 기준으로 환불 정책을 기입해주세요.",
          "예시)",
          "상품 시작일 기준 5일 이전까지(~6일 전) 100% 환불",
          "상품 시작일 기준 1일 전까지(1~5일전) 50% 환불",
          "상품 시작일 기준 당일 이내 환불 불가",
        ],
        moreDescList: ['<a target="_blank"  href="https://nnbhost.super.site/상품-관리하기/08-환불정책">환불정책</a>의 팁을 참고해 보세요!'],
      },
      {
        title: '상품의 상세페이지 내용을 마지막으로 한 번 더 확인해 주세요',
        descList: [
          "최종 확인 페이지가 나오지 않는다면 아직 작성이 안 된 부분이 있는 것이니, 한 번 더 꼼꼼히 확인해 주세요.",
          "상품 최종 확인 페이지에 문제가 없다면 완료 버튼을 눌러주세요!",
          "상품 등록이 완료되면 승인을 위한 심사가 진행됩니다. (영업일 2일 이내 승인 또는 반려 안내를 드립니다.)",
        ]
      },
    ]
    this.cds.isDesktop.subscribe(resp => this.isDesktop = resp);
    for (let i = 0; i < 31; i++) {
      this.days.push(i)
    }
    for (let i = 0; i < 24; i++) {
      this.hours.push(i)
    }
    for (let i = 0; i < 60; i += 10) {
      this.minutes.push(i)
    }
  }

  changeVersion(version: number) {
    this.messageService.popup({
      description: '기존 작성한 내용이 사라집니다. 계속하시겠습니까?',
      cancelLabel: '취소',
      confirmLabel: '확인',
      confirmBtnClass: 'primary',
      confirmHandler: () => {
        this.formGroup.controls.version.setValue(version);
        if (version === 1) {
          this.formGroup.addControl('point', new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])))
          this.formGroup.removeControl('summaries')
          this.formGroup.removeControl('sections')
          this.formGroup.controls.description.addValidators(Validators.required);
        } else if (version === 2) {
          this.formGroup.addControl('summaries', new FormArray([new FormGroup({
            photos: new FormArray([]),
            title: new FormControl('', this.formService.getValidators(20)),
            description: new FormControl('', this.formService.getValidators(200)),
          })]))
          this.formGroup.addControl('sections', new FormArray([new FormGroup({
            title: new FormControl('', this.formService.getValidators(20)),
            paragraphs: new FormArray([new FormGroup({
              title: new FormControl('', this.formService.getValidators(20)),
              time: new FormControl('', Validators.maxLength(20)),
              photos: new FormArray([]),
              description: new FormControl('', Validators.required),
              note: new FormControl(''),
            })])
          })]))
          this.formGroup.removeControl('point')
        }
        this.formGroup.controls.description.reset();
      }
    })
  }

  moveUp(index: number, formGroupName: string) {
    const formArray = this.formGroup.get(formGroupName) as FormArray;
    const control = formArray.at(index);
    formArray.removeAt(index);
    formArray.insert(index - 1, control);
  }

  moveDown(index: number, formGroupName: string) {
    const formArray = this.formGroup.get(formGroupName) as FormArray;
    const control = formArray.at(index + 1);
    formArray.removeAt(index + 1);
    formArray.insert(index, control);
  }

  moveUp2(sectionIndex, index: number, formGroupName: string) {
    const formArray = this.getSection().at(sectionIndex).get(formGroupName) as FormArray;
    const control = formArray.at(index);
    formArray.removeAt(index);
    formArray.insert(index - 1, control);
  }

  moveDown2(sectionIndex, index: number, formGroupName: string) {
    const formArray = this.getSection().at(sectionIndex).get(formGroupName) as FormArray;
    const control = formArray.at(index + 1);
    formArray.removeAt(index + 1);
    formArray.insert(index, control);
  }

  // summary
  getSummary(): FormArray {
    return this.formGroup.get('summaries') as FormArray;
  }

  newSummary(): FormGroup {
    return new FormGroup({
      photos: new FormArray([], Validators.required),
      title: new FormControl('', this.formService.getValidators(20)),
      description: new FormControl('', this.formService.getValidators(200)),
    })
  }

  addSummary() {
    this.getSummary().push(this.newSummary());
  }

  removeSummary(index: number) {
    this.messageService.popup({
      description: '정말로 삭제하시겠습니까?',
      confirmBtnClass: 'primary',
      confirmLabel: '확인',
      confirmHandler: () => {
        this.getSummary().removeAt(index);
      }, cancelLabel: '취소'
    })
  }

  // sections
  getSection(): FormArray {
    return this.formGroup.get('sections') as FormArray;
  }

  newSection(): FormGroup {
    return new FormGroup({
      title: new FormControl('', this.formService.getValidators(20)),
      paragraphs: new FormArray([this.newParagraph()])
    })
  }

  addSection() {
    this.getSection().push(this.newSection());
  }

  removeSection(sectionIndex: number) {
    this.messageService.popup({
      description: '정말로 삭제하시겠습니까?',
      confirmBtnClass: 'primary',
      confirmLabel: '확인',
      confirmHandler: () => {
        this.getSection().removeAt(sectionIndex);
      }, cancelLabel: '취소'
    })
  }

  // paragraphs
  getParagraph(sectionIndex: number): FormArray {
    return this.getSection().at(sectionIndex).get('paragraphs') as FormArray;
  }

  newParagraph(): FormGroup {
    return new FormGroup({
      title: new FormControl('', this.formService.getValidators(20)),
      time: new FormControl('', Validators.maxLength(20)),
      photos: new FormArray([]),
      description: new FormControl('', Validators.required),
      note: new FormControl(''),
    })
  }

  addParagraph(sectionIndex: number) {
    this.getParagraph(sectionIndex).push(this.newParagraph());
  }

  removeParagraph(sectionIndex: number, paragraphIndex: number) {
    this.messageService.popup({
      description: '정말로 삭제하시겠습니까?',
      confirmBtnClass: 'primary',
      confirmLabel: '확인',
      confirmHandler: () => {
        this.getParagraph(sectionIndex).removeAt(paragraphIndex);
      }, cancelLabel: '취소'
    })
  }

  // courses
  getCourse(): FormArray {
    return this.formGroup.get('courses') as FormArray;
  }

  newCourse(): FormGroup {
    return new FormGroup({
      title: new FormControl('', this.formService.getValidators(20)),
      schedules: new FormArray([this.newSchedule()])
    })
  }

  addCourse() {
    this.getCourse().push(this.newCourse());
  }

  removeCourse(courseIndex: number) {
    this.messageService.popup({
      description: '정말로 삭제하시겠습니까?',
      confirmBtnClass: 'primary',
      confirmLabel: '확인',
      confirmHandler: () => {
        this.getCourse().removeAt(courseIndex);
      }, cancelLabel: '취소'
    })
  }

  // schedules
  getSchedule(courseIndex: number): FormArray {
    return this.getCourse().at(courseIndex).get('schedules') as FormArray;
  }

  newSchedule(): FormGroup {
    return new FormGroup({
      subTitle: new FormControl('', this.formService.getValidators(20)),
      content: new FormControl('', Validators.required),
      note: new FormControl(''),
    })
  }

  addScheudle(courseIndex: number) {
    this.getSchedule(courseIndex).push(this.newSchedule());
  }

  removeScheudle(courseIndex: number, scheduleIndex: number) {
    this.messageService.popup({
      description: '정말로 삭제하시겠습니까?',
      confirmBtnClass: 'primary',
      confirmLabel: '확인',
      confirmHandler: () => {
        this.getSchedule(courseIndex).removeAt(scheduleIndex);
      }, cancelLabel: '취소'
    })
  }

  searchMap(event) {
    this.onChangeAddressEvent.emit(event)
  }

  checkAllowPolicy(event) {
    const isChecked = event.target.checked;
    this.noticeChcked = isChecked;
    const value = `✔ 예약 완료 시 카카오톡 안내 메시지가 발송되니 꼭 확인해 주세요.
✔ 여성 없이 남성 만으로 이루어진 팀은 방문하실 수 없습니다.
✔ 일정 중 발생할 수 있는 모든 안전사고에 유의 하시기 바라며, 이용자 본인의 과실로 인한 안전사고의 경우는 이용자 본인이 책임을 지게 됩니다.
✔ 예약한 모임의 일정 변경이나 취소를 원할 시 상세페이지 하단의 ‘환불 규정’을 따르며, 원칙상 당일취소는 불가합니다. 환불 규정을 꼭 숙지해 주세요.
✔ 최소 인원 미달로 인한 상품 진행 불가 취소 시 참가비는 전액 환불됩니다.
✔ 기상 및 천재지변, 호스트님의 상황으로 인한 취소 시 전액 환불됩니다.`
    if (isChecked) {
      if (this.formGroup.controls.notice.value !== '') {
        this.messageService.popup({
          title: '유의사항',
          description: "기존 작성한 유의사항 내용이 사라집니다.",
          confirmLabel: '확인',
          confirmBtnClass: 'primary',
          confirmHandler: () => {
            this.formGroup.controls.notice.setValue(value);
          },
          cancelLabel: '취소',
          cancelHandler: () => {
            setTimeout(() => this.noticeChcked = false)
          }
        })
      } else {
        this.formGroup.controls.notice.setValue(value);
      }
    }
  }

  ngAfterViewInit(): void {
    this.onQuillLoadEvent.emit(this.quill);
  }

  showMenu() {
    this.isShowMenu = !this.isShowMenu;
  }

  next() {
    this.onNextEvent.emit();
  }

  prev() {
    this.onPrevEvent.emit();
  }

  lastCheck() {
    this.onLastCheckEvent.emit();
  }

  checkHashtag(event) {
    this.addTag = event.term.length <= this.MAX_HASHTAG_LENGTH;
  }

  imageCropped(event: { url: string, file: File }) {
    this.croppedImage = new ProductRepresentationPhotoCreateDto(event.url, 0, event.file)
  }

  checkDiscountPrice() {
    this.onCheckDiscountPriceEvent.emit();
  }

  dispatch() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }

  add() {
    this.onAddEvent.emit();
  }

  onCheckAmenity(event, amenity: Amenity) {
    const formArray: FormArray = this.formGroup.controls.amenities as FormArray;
    if (event.target.checked) {
      formArray.push(new FormControl(+event.target.value));
    } else {
      const index = formArray.controls.findIndex(control => control.value === amenity.id);
      formArray.removeAt(index);
    }
  }

  onIsAmenity(event) {
    const control = this.formGroup.controls.isAmenity;
    if (event.target.checked) {
      control.setValue(true);
    } else {
      this.messageService.popup({
        title: '숙소 시설 정보',
        description: '숙소 시설 정보가 초기화됩니다.',
        confirmLabel: '확인',
        confirmBtnClass: 'primary',
        confirmHandler: () => {
          control.setValue(false);
          (this.formGroup.controls.amenities as FormArray).clear();
        },
        cancelLabel: '취소',
        cancelHandler: () => {
          control.setValue(true);
        }
      })
    }
  }
}