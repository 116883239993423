import { Component, OnInit } from '@angular/core';
import { ChannelService } from '../../service/channel.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor(
    private channelService: ChannelService
  ) { }

  ngOnInit() {
  }

  openChanneltalk() {
    this.channelService.openChat()
  }
}
