<ng-container *ngIf="feed.type === FeedType.COACHING">
    <!-- thumbnail -->
    <img *ngIf="!isFeedList" [src]="feed.feedPhotos[0].photo" style="height: 360px;width: 100%;object-fit: cover;">
    <!-- profile -->
    <div style="display: flex;justify-content: space-between;align-items: center;margin:8px 0px;"
        [ngStyle]="{'margin': !isFeedList?'16px':'8px'}">
        <div (click)="goDetailUser(feed.author.id)">
            <div style="display: flex;align-items: center;gap: 8px;">
                <img class="profile-image" [src]="feed.author.profilePhoto">
                <div class="profile-nickname">{{feed.author.nickname}}</div>
                <div *ngIf="feed.author.role === Role.EDITOR" class="nnb-badge nnb-badge-sm nnb-badge-blue">웰니스코치
                </div>
            </div>
        </div>
        <div *ngIf="currentUser && showControl">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/dots.svg"
                *ngIf="feed.author.id === currentUser.id" name="ellipsis-horizontal-outline" class="nnb-icon"
                style="cursor: pointer;" (click)="openBm(feedControlTpl)">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/dots.svg"
                *ngIf="feed.author.id !== currentUser.id" class="nnb-icon" style="cursor: pointer;"
                (click)="openBm(blockOrReportTpl)">
        </div>
    </div>
    <!-- images -->
    <ng-container *ngIf="feed.feedPhotos.length > 0 && isFeedList">
        <a [href]="'/tabs/feed-detail/'+feed.id">
            <img class="lg-feed-img one-image" style="height:280px;width: 100%;" [src]="feed.feedPhotos[0].photo"
                [ngStyle]="{'border-radius': imgageType=='round'? '12px' : ''}">
        </a>
    </ng-container>
    <!-- title -->
    <div style="font-size: 18px;font-weight: 600;line-height: 130%;margin-top: 8px;">
        <ng-container *ngIf="isFeedList">
            <div [ngStyle]="{'margin-left': imgageType === 'default'?'16px':''}">
                <a [href]="'/tabs/feed-detail/'+feed.id">{{feed.title}}</a>
            </div>
        </ng-container>
        <ng-container *ngIf="!isFeedList">
            <div [ngStyle]="{'margin-left': imgageType === 'default'?'16px':''}">{{feed.title}}</div>
            <hr>
        </ng-container>
    </div>
    <!-- text -->
    <div class="text">
        <div *ngIf="!isFeedList" style="padding:0px 16px 16px 16px;">
            <quill-view id="feed-{{feed.id}}" [content]="feed.contents" format="text" theme="snow" [style]="quillStyle">
            </quill-view>
        </div>
        <div *ngIf="isFeedList" [ngStyle]="{padding: wellnessViewType==='vertical' ? '16px':'8px 0px'}">
            <a [href]="'/tabs/feed-detail/'+feed.id">
                <quill-view style="display: none;" id="feed-{{feed.id}}" [content]="feed.contents" format="text"
                    theme="snow" [style]="quillStyle">
                </quill-view>
                <div class="pre-wrap content line-clamp-3 desc" id="feed-{{feed.id}}">{{feed.preview}}
                </div>
            </a>
        </div>
    </div>
    <!-- product -->
    <div *ngIf="feed.product && isShowProduct">
        <a class="product-container" [routerLink]="'/tabs/meeting-detail/'+feed.product.id"
            [ngStyle]="{'border-radius': isFeedList ? '8px':''}">
            <div class="nnb-badge nnb-badge-dark-grey nnb-badge-sm">프로그램</div>
            <div class="text-overflow title">{{feed.product.title}}</div>
        </a>
    </div>
    <!-- profile -->
    <div *ngIf="wellnessViewType === 'detail'"
        style="padding: 16px;margin: 16px;border-radius: 12px;border: 1px solid #ededed;">
        <div (click)="goDetailUser(feed.author.id)">
            <div style="display: flex;align-items: center;gap: 8px;">
                <img class="profile-image" [src]="feed.author.profilePhoto">
                <div class="profile-nickname">{{feed.author.nickname}}</div>
                <div *ngIf="feed.author.role === Role.EDITOR" class="nnb-badge nnb-badge-sm nnb-badge-blue">웰니스코치
                </div>
            </div>
        </div>
        <div style="font-weight: 600;margin: 8px 0px">{{feed.author.catchphrase}}</div>
        <div style="white-space: pre-wrap;">{{feed.author.introduction}}</div>
    </div>
    <!-- like -->
    <div class="like" [ngStyle]="{padding: isFeedList && imgageType !== 'default' ? '10px 0px':'16px 16px 0px 16px'}">
        <div style="display: flex;gap:24px;align-items: center;">
            <div style="display: flex;align-items: center;gap:8px" (click)="like(feed.id,!feed.isSetLike)">
                <img
                    [src]="feed.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'">
                <div class="likes">{{feed.likes}}</div>
            </div>
            <div style="display: flex;align-items: center;gap:8px;" (click)="showDetail(feed)">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/message-circle-2.svg">
                <div class="comments">{{feed.feedComments.length}}</div>
            </div>
        </div>
        <div class="pre-wrap time">{{feed.createdAt|dateAgo}}</div>
    </div>
    <hr *ngIf="wellnessViewType === 'vertical' || wellnessViewType === 'detail'">
    <!-- comment -->
    <div style="padding:16px;text-align: left;" *ngIf="!isFeedList && feed?.feedComments?.length > 0">
        <div *ngFor="let comment of feed.feedComments | slice:0:showCommentLength;">
            <div style="display: flex;align-items: flex-start;gap: 16px;"
                [ngStyle]="{'padding':showCommentAll?'16px 0px':''}">
                <img class="profile-image" [src]="comment.user.profilePhoto">
                <div style="width:100%">
                    <div style="display:flex;justify-content: space-between;">
                        <div style="display:flex;gap:8px;align-items: center;margin-bottom: 8px">
                            <div class="profile-nickname">{{comment.user.nickname}}</div>
                            <div *ngIf="comment.user.role === Role.EDITOR"
                                class="nnb-badge nnb-badge-sm nnb-badge-blue">
                                웰니스코치
                            </div>
                        </div>
                        <div *ngIf="currentUser">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/comment-box.svg"
                                *ngIf="comment.user.id === currentUser.id"
                                (click)="openCommentControl(commentControlTpl, comment)">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/comment-box.svg"
                                *ngIf="comment.user.id !== currentUser.id"
                                (click)="openCommentBlockControl(commentBlockOrReportTpl, comment)">
                        </div>
                    </div>
                    <div class="comment-content" style="margin-bottom: 8px; line-break: anywhere">{{comment.comment}}
                    </div>
                    <div class="pre-wrap time">{{comment.createdAt|dateAgo}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="feed.type !== FeedType.COACHING">
    <!-- profile -->
    <div *ngIf="feed.type !== FeedType.FUSO"
        style="display: flex;justify-content: space-between;align-items: center;padding:12px 16px;">
        <div (click)="goDetailUser(feed.author.id)">
            <div style="display: flex;align-items: center;gap: 8px;">
                <img class="profile-image" [src]="feed.author.profilePhoto">
                <div class="profile-nickname">{{feed.author.nickname}}</div>
                <div *ngIf="feed.author.role === Role.EDITOR" class="nnb-badge nnb-badge-sm nnb-badge-blue">건강코치</div>
            </div>
        </div>
        <div *ngIf="currentUser && showControl">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/dots.svg"
                *ngIf="feed.author.id === currentUser.id" name="ellipsis-horizontal-outline" class="nnb-icon"
                style="cursor: pointer;" (click)="openBm(feedControlTpl)">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/dots.svg"
                *ngIf="feed.author.id !== currentUser.id" class="nnb-icon" style="cursor: pointer;"
                (click)="openBm(blockOrReportTpl)">
        </div>
    </div>
    <div *ngIf="feed.type === FeedType.FUSO"
        style="display: flex;justify-content: space-between;align-items: center;padding:12px 16px;">
        <div class="profile-nickname">{{feed.authorName}}</div>
        <div class="desc">{{feed.createdAt|date:'yyyy-MM-dd'}}</div>
    </div>
    <!-- images -->
    <ng-container *ngIf="feed.feedPhotos.length > 0">
        <ng-container *ngIf="feed.feedPhotos.length === 1">
            <lightgallery [settings]="lightGallerySetting">
                <img class="lg-feed-img one-image" style="width: 100%;" [src]="feed.feedPhotos[0].photo"
                    [ngStyle]="{'border-radius': imgageType=='round'? '24px' : ''}">
            </lightgallery>
        </ng-container>
        <ng-container *ngIf="feed.feedPhotos.length === 2">
            <lightgallery [settings]="lightGallerySetting">
                <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[0].photo" style="height: 211px;width: 100%;"
                    [ngStyle]="{'border-radius': imgageType=='round'? '24px 24px 0px 0px' : ''}">
                <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[1].photo" style="height: 211px;width: 100%;"
                    [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 24px 24px' : ''}">
            </lightgallery>
        </ng-container>
        <ng-container *ngIf="feed.feedPhotos.length === 3">
            <lightgallery [settings]="lightGallerySetting">
                <img class="lg-feed-img feed-img three-image" [src]="feed.feedPhotos[0].photo" style="width: 100%;"
                    [ngStyle]="{'border-radius': imgageType=='round'? '24px 24px 0px 0px' : ''}">
                <div style="display: flex;height: 102px;">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[1].photo" style="width: 50%;"
                        [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 0px 24px' : ''}">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[2].photo" style="width: 50%;"
                        [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 24px 0px' : ''}">
                </div>
            </lightgallery>
        </ng-container>
        <ng-container *ngIf="feed.feedPhotos.length === 4">
            <lightgallery [settings]="lightGallerySetting">
                <img class="lg-feed-img feed-img three-image" [src]="feed.feedPhotos[0].photo" style="width: 100%;"
                    [ngStyle]="{'border-radius': imgageType=='round'? '24px 24px 0px 0px' : ''}">
                <div style="display: flex;height: 102px;">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[1].photo" style="width: 33%;"
                        [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 0px 24px' : ''}">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[2].photo" style="width: 34%;">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[3].photo" style="width: 33%;"
                        [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 24px 0px' : ''}">
                </div>
            </lightgallery>
        </ng-container>
        <ng-container *ngIf="feed.feedPhotos.length > 4">
            <lightgallery [settings]="lightGallerySetting" [onBeforeOpen]="onBeforeOpen" [onInit]="onInit">
                <img class="lg-feed-img feed-img three-image" [src]="feed.feedPhotos[0].photo" style="width: 100%;"
                    [ngStyle]="{'border-radius': imgageType=='round'? '24px 24px 0px 0px' : ''}">
                <div style="display: flex;height: 102px;">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[1].photo" style="width: 33%;"
                        [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 0px 24px' : ''}">
                    <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[2].photo" style="width: 34%;">
                    <div style="position: relative;width: 33%;">
                        <img class="lg-feed-img feed-img" [src]="feed.feedPhotos[3].photo"
                            style="width: 100%;height: 102px;"
                            [ngStyle]="{'border-radius': imgageType=='round'? '0px 0px 24px 0px' : ''}">
                        <span style="position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 14px;
                font-weight: 700;
                background: rgba(23, 23, 24, 0.7);
                border-radius: 100px;
                padding: 6px 8px;">
                            +{{feed.feedPhotos.length- 4}}
                        </span>
                    </div>
                </div>
            </lightgallery>
        </ng-container>
    </ng-container>
    <!-- product -->
    <div *ngIf="feed.product && isShowProduct">
        <a class="product-container" style="border-radius: 12px;" [routerLink]="'/tabs/meeting-detail/'+feed.product.id"
            [ngStyle]="{'margin':feed.feedPhotos.length > 0 ? '16px 16px 0px 16px' : '0px 16px'}">
            <div *ngIf="feed.product.host.id != feed.author.id" class="nnb-badge nnb-badge-dark-grey nnb-badge-sm">참여모임
            </div>
            <div *ngIf="feed.product.host.id == feed.author.id" class="nnb-badge nnb-badge-dark-grey nnb-badge-sm">주최모임
            </div>
            <div class="text-overflow title">{{feed.product.title}}</div>
        </a>
    </div>
    <!-- text -->
    <div [ngStyle]="{'padding':type=='default'?'16px':'8px 0px'}" class="text">
        <div *ngIf="!isFeedList">
            <div class="pre-wrap content">{{feed.contents}}</div>
        </div>
        <div *ngIf="isFeedList">
            <div class="pre-wrap content"
                [ngClass]="{'line-clamp':feed.feedPhotos.length > 0,'line-clamp-10':feed.feedPhotos.length == 0}"
                id="{{currentPage}}-feed-{{feed.id}}">{{feed.contents}}</div>
            <div style="display:flex;gap:8px;align-items: center;margin-top:8px" *ngIf="isFeedMore(feed)"
                (click)="setMore(feed)">
                <div class="more">더보기</div>
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/arrow-right-gray.svg"
                    style="width:6px;height:10.5px">
            </div>
        </div>
    </div>
    <!-- like -->
    <div class="like" *ngIf="type == 'default'">
        <div style="display: flex;gap:24px;align-items: center;">
            <div style="display: flex;align-items: center;gap:8px" (click)="like(feed.id,!feed.isSetLike)">
                <img
                    [src]="feed.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'">
                <div class="likes">{{feed.likes}}</div>
            </div>
            <div style="display: flex;align-items: center;gap:8px;" (click)="showDetail(feed)">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/message-circle-2.svg">
                <div class="comments">{{feed.feedComments.length}}</div>
            </div>
        </div>
        <div class="pre-wrap time">{{feed.createdAt|dateAgo}}</div>
    </div>
    <hr style="margin:0px" *ngIf="type == 'default'">
    <!-- comment -->
    <div style="padding:16px;text-align: left;" *ngIf="type=='default' && feed.feedComments.length > 0">
        <div *ngFor="let comment of feed.feedComments | slice:0:showCommentLength;">
            <div style="display: flex;align-items: flex-start;gap: 16px;"
                [ngStyle]="{'padding':showCommentAll?'16px 0px':''}">
                <img class="profile-image" [src]="comment.user.profilePhoto">
                <div style="width:100%">
                    <div style="display:flex;justify-content: space-between;">
                        <div style="display:flex;gap:8px;align-items: center;margin-bottom: 8px">
                            <div class="profile-nickname">{{comment.user.nickname}}</div>
                            <div *ngIf="comment.user.role === Role.EDITOR"
                                class="nnb-badge nnb-badge-sm nnb-badge-blue">
                                건강코치
                            </div>
                        </div>
                        <div *ngIf="currentUser">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/comment-box.svg"
                                *ngIf="comment.user.id === currentUser.id"
                                (click)="openCommentControl(commentControlTpl, comment)">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/comment-box.svg"
                                *ngIf="comment.user.id !== currentUser.id"
                                (click)="openCommentBlockControl(commentBlockOrReportTpl, comment)">
                        </div>
                    </div>
                    <div class="comment-content" style="margin-bottom: 8px; line-break: anywhere">{{comment.comment}}
                    </div>
                    <div class="pre-wrap time">{{comment.createdAt|dateAgo}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #feedControlTpl>
    <div style="display:flex;justify-content:space-between;align-items: center;padding:16px 0px;margin-bottom:24px"
        (click)="goToEdit()">
        <div style="display:flex;gap:12px;align-items: center;">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/pencil.svg"
                class="nnb-icon">
            <div class="control-label">수정하기</div>
        </div>
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right.svg"
            class="nnb-icon">
    </div>

    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px" (click)="deleteFeed()">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_trash.svg"
            class="nnb-icon">
        <div class="control-label">삭제하기</div>
    </div>

    <button class="nnb-btn-secondary nnb-btn-lg nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #blockOrReportTpl>
    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px;padding:16px 0px;"
        (click)="openBm(blockTpl)">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/eye-off.svg" class="nnb-icon">
        <div class="control-label">차단하기</div>
    </div>

    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px" (click)="openBm(reportTpl)">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/type115.svg" class="nnb-icon">
        <div class="control-label">신고하기</div>
    </div>

    <button class="nnb-btn-secondary nnb-btn-lg nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #reportTpl>
    <div class="desc" style="margin-bottom: 10px;">신고 유형을 선택해주세요.</div>
    <div style="height: 460px;overflow-y: auto;">
        <div *ngFor="let category of reportCategories;" (click)="setReportCategory(category)"
            style="border: 1px solid #d8d8d8;border-radius: 10px;margin-bottom: 10px;padding: 7px 15px;cursor: pointer;"
            [ngStyle]="{'border': reportForm.controls.category.value === category ? '1px solid #8E46EC' : '1px solid #d8d8d8'}">
            {{reportCategory[category]}}
        </div>
        <form-input *ngIf="reportForm.controls.category.value === 'ETC'" [name]="'신고 내용'" [controlName]="'description'"
            [rows]="3" [type]="'textarea'" [group]="reportForm" [errors]="[{key:'maxlength',message:'5000자 제한입니다.'}]"
            [maxLength]="5000" [placeholder]="'신고 사항을 작성해주세요.'">
        </form-input>
    </div>
    <button class="nnb-btn-md nnb-btn-block nnb-btn-primary" [disabled]="reportForm.invalid"
        (click)="report()">신고하기</button>
</ng-template>

<ng-template #blockTpl>
    <div class="desc" style="margin-bottom: 10px;">더 이상 이 피드가 커뮤니티에 노출되지 않습니다.
    </div>
    <button class="nnb-btn-md nnb-btn-block nnb-btn-primary" (click)="block()">차단하기</button>
</ng-template>

<ng-template #commentBlockOrReportTpl>
    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px;padding:16px 0px;"
        (click)="openBm(commentBlockTpl)">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/eye-off.svg" class="nnb-icon">
        <div class="control-label">차단하기</div>
    </div>

    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px" (click)="openBm(commentReportTpl)">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/type115.svg" class="nnb-icon">
        <div class="control-label">신고하기</div>
    </div>

    <button class="nnb-btn-secondary nnb-btn-lg nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #commentReportTpl>
    <div class="desc" style="margin-bottom: 10px;">신고 유형을 선택해주세요.</div>
    <div style="height: 460px;overflow-y: auto;">
        <div *ngFor="let category of reportCategories;" (click)="setReportCategory(category)"
            style="border: 1px solid #d8d8d8;border-radius: 10px;margin-bottom: 10px;padding: 7px 15px;cursor: pointer;"
            [ngStyle]="{'border': reportForm.controls.category.value === category ? '1px solid #8E46EC' : '1px solid #d8d8d8'}">
            {{reportCategory[category]}}
        </div>
        <form-input *ngIf="reportForm.controls.category.value === 'ETC'" [name]="'신고 내용'" [controlName]="'description'"
            [rows]="3" [type]="'textarea'" [group]="reportForm" [errors]="[{key:'maxlength',message:'5000자 제한입니다.'}]"
            [maxLength]="5000" [placeholder]="'신고 사항을 작성해주세요.'">
        </form-input>
    </div>
    <button class="nnb-btn-md nnb-btn-block nnb-btn-primary" [disabled]="reportForm.invalid"
        (click)="reportComment()">신고하기</button>
</ng-template>

<ng-template #commentBlockTpl>
    <div class="desc" style="margin-bottom: 10px;">더 이상 이 댓글이 커뮤니티에 노출되지 않습니다.
    </div>
    <button class="nnb-btn-md nnb-btn-block nnb-btn-primary" (click)="blockComment()">차단하기</button>
</ng-template>

<ng-template #commentControlTpl>
    <div style="display:flex;justify-content:space-between;align-items: center;padding:16px 0px;margin-bottom:24px"
        (click)="openEditCommentControl(editCommentTpl)">
        <div style="display:flex;gap:12px;align-items: center;">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/pencil.svg"
                class="nnb-icon">
            <div class="control-label">수정하기</div>
        </div>
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right.svg"
            class="nnb-icon">
    </div>

    <div style="display:flex;gap:12px;align-items: center;margin-bottom:24px" (click)="deleteFeedComment()">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_trash.svg"
            class="nnb-icon">
        <div class="control-label">삭제하기</div>
    </div>

    <button class="nnb-btn-secondary nnb-btn-lg nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #editCommentTpl>
    <form [formGroup]="commentForm" style="margin-top: 10px;">
        <form-input [name]="''" [controlName]="'comment'" [type]="'textarea'" [group]="commentForm"
            [errors]="[{key:'maxlength',message:'5000자 제한입니다.'}]" [maxLength]="5000" [placeholder]="'댓글을 작성해주세요.'">
        </form-input>
        <button class="nnb-btn-block nnb-btn-md nnb-btn-primary" [disabled]="commentForm.invalid"
            (click)="updateComment()">완료</button>
    </form>
</ng-template>