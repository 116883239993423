import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Alliance } from "src/app/model/alliance";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AllianceService {
  private allianceSource = new BehaviorSubject<Alliance>(undefined);
  alliance = this.allianceSource.asObservable();

  constructor(private http: HttpClient) {}

  setAlliance(alliance: Alliance) {
    return this.allianceSource.next(alliance);
  }

  get(origin: string): Observable<Alliance> {
    return this.http.get<Alliance>(
      `${environment.host}/api/alliances/${encodeURIComponent(origin)}`
    );
  }

  getAll(): Observable<Alliance[]> {
    return this.http.get<Alliance[]>("/api/alliances");
  }
}
