<div class="bs-stepper" #stepperRef>
  <meeting-control [formGroup]="meetingForm" [quillStyle]="quillStyle" [hashtags]="hashtags" [amenities]="amenities"
    [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [previewProduct]="previewProduct"
    [submitMessage]="'상품 만들기 완료'" [loadMessage]="'상품 등록 중'" [isLoad]="isLoad" (onQuillLoadEvent)="quillLoad($event)"
    (onNextEvent)="next()" (onPrevEvent)="prev()" (onLastCheckEvent)="makePreviewProduct()"
    (onChangeAddressEvent)="changeAddress($event)" (onAddEvent)="add()"
    (onCheckDiscountPriceEvent)="checkDiscountPrice()"></meeting-control>
</div>

<ng-template #addPhoneTpl>
  <div class="desc" style="margin-bottom: 10px;">모임 안내를 받기 위해 최초 1회 휴대폰 인증이 필요해요.</div>
  <div style="height:310px;overflow-y: auto;margin-bottom: 15px;">
    <form-input [name]="'휴대폰 번호'" [controlName]="'phone'" [group]="phoneNumberForm" [type]="'phone'"
      [controlName]="'phone'" [user]="user" (smsAuthEvent)="bottomSheet.dismiss()">
    </form-input>
  </div>
</ng-template>