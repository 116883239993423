import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { Product, ProductStatus } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { AuthService } from '../../../service/auth.service';
import { PaymentService } from '../../../service/payment.service';

@Component({
  selector: 'hosted-meetings',
  templateUrl: './hosted-meetings.page.html',
  styleUrls: ['./hosted-meetings.page.scss'],
})
export class HostedMeetingsPage implements OnInit {
  host: User;
  hostedProducts: Product[];
  modalRef: BsModalRef;
  selectedProduct: Product;

  columns: Columns[];
  config: Config;
  pagination = {
    offset: 1,
    limit: 10,
    count: -1
  };

  constructor(
    private authService: AuthService,
    private productService: ProductService,
    private router: Router,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(resp => {
      this.host = resp;
      this.setProducts(1, 10);
    })
    this.config = { ...DefaultConfig };
    this.config.horizontalScroll = true;
    this.config.orderEnabled = false;
    this.columns = [
      { key: 'index', title: '번호', width: '1%' },
      { key: 'check', title: '승인/반려 내역', width: '1%' },
      { key: 'isBusiness', title: '구분', width: '1%' },
      { key: 'representationPhoto', title: '이미지', width: '1%' },
      { key: 'status', title: '상태', width: '1%' },
      { key: 'title', title: '제목', width: '1%' },
      { key: 'edit', title: '관리', width: '1%' },
      { key: 'date', title: '생성일/수정일', width: '1%' },
    ];
  }

  getStatusBadge(status) {
    return this.productService.getStatusBadge(status);
  }

  showChecks(product: Product, template) {
    this.selectedProduct = product;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  edit(id: number, isBusiness: boolean) {
    if (isBusiness) {
      this.router.navigate(['/host/meeting-management/meeting-edit', id])
    } else {
      this.router.navigate(['/tabs/simple-product-edit', id])
    }
  }

  removeProduct(id: number) {
    this.messageService.popup({
      title: '상품 삭제',
      description: '정말로 삭제하시겠습니까?',
      confirmLabel: '확인',
      confirmBtnClass: 'red',
      confirmHandler: () => {
        this.paymentService.getPaymentsCountByProduct(id).subscribe(resp => {
          if (resp > 0) {
            this.productService.manageProduct(id, { status: ProductStatus.DISABLED }).subscribe(resp => {
              this.messageService.popup({
                title: '상품 관리',
                description: '결제한 고객이 있어 상품을 삭제 하지않고 비활성하였습니다.',
                cancelLabel: '확인',
              })
              this.setProducts(this.pagination.offset, this.pagination.limit);
            })
          } else {
            this.productService.deleteProduct(id).subscribe(resp => {
              this.messageService.popup({
                title: '상품 관리',
                description: '상품을 삭제하였습니다.',
                cancelLabel: '확인',
              })
              this.setProducts(this.pagination.offset, this.pagination.limit);
            })
          }
        })
      },
      cancelLabel: '취소'
    })
  }

  setProducts(page: number, limit: number) {
    this.productService.search({ page, limit, status: ProductStatus.ALL, hostId: this.host.id, }).subscribe(products => {
      this.hostedProducts = products.items.map(product => {
        product.productConfirms = product.productConfirms.sort((a, b) => b.id - a.id);
        return product;
      })
      this.pagination = { limit: products.meta.itemsPerPage, offset: products.meta.currentPage, count: products.meta.totalItems };
    });
  }

  onTableEvent(event: any): void {
    if (event.event === 'onPagination') {
      this.setProducts(event.value.page, event.value.limit);
    }
  }

  goToTicket(id: number) {
    this.router.navigate(['/host/schedule'], { queryParams: { productId: id } });
  }
}
