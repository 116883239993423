<div style="padding:24px;text-align: center;border-radius: 12px;" [ngStyle]="{'margin-bottom':buttonLabel?'32px':'0px'}"
  [ngClass]="{'background':backgroundColor}">
  <img *ngIf="image" style="margin-bottom:8px;width:150px;height:140px" [src]="image">
  <div style="font-size:26px;font-weight:600;margin-bottom:8px">{{title}}</div>
  <div *ngIf="subTitle" style="font-size:18px;font-weight:400;line-height:33.8px;"
    [ngStyle]="{'margin-bottom':buttonLabel?'32px':'0px'}">
    {{subTitle}}</div>
  <div *ngIf="description" style="font-size: 18px;margin-bottom:40px;text-align: left;">{{description}}</div>
  <button *ngIf="buttonLabel" style="background: black;max-width:240px;"
    class="nnb-btn-lg nnb-btn-primary nnb-btn-block" (click)="onClick()">{{buttonLabel}}</button>
</div>