import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LightGallery } from 'lightgallery/lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import Quill from 'quill';
import { Feed, FeedType } from 'src/app/model/feed';
import { BlockCreateDto, ReportCategory, ReportCreateDto } from 'src/app/model/report';
import { Role, User } from 'src/app/model/user';
import { LoggerService, TargetType } from 'src/app/service/logger.service';
import { MessageService } from 'src/app/service/message.service';
import { UtilService } from 'src/app/service/util.service';
import { FeedComment } from './../../model/feed';
import { FeedService } from './../../service/feed.service';
import { FormService } from './../../service/form.service';
import { ReportService } from './../../service/report.service';
import { UserService } from './../../service/user.service';

@Component({
  selector: 'feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
})
export class FeedComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() feed: Feed;
  @Input() currentUser: User;
  @Input() type: 'default' | 'summary' = 'default';
  @Input() imgageType: 'default' | 'round' = 'default';
  @Input() wellnessViewType: 'vertical' | 'horizontal' | 'detail';
  @Input() isShowProduct = true;
  @Input() showControl = true;
  @Input() isFeedList = true;
  @Input() showCommentAll = false;
  @Output() deleteEvent = new EventEmitter();
  @Output() blockEvent = new EventEmitter();
  @Output() reportEvent = new EventEmitter();
  reportForm: FormGroup;
  reportCategory = ReportCategory;
  reportCategories = Object.keys(ReportCategory);
  showCommentLength: number;
  selectedComment: FeedComment;
  commentForm: FormGroup;
  currentPage = window.location.href;
  FeedType = FeedType;
  Role = Role;

  lightGallery: LightGallery;
  lightGallerySetting = {
    download: false,
    counter: true,
    plugins: [lgZoom],
    mousewheel: true,
    mobileSettings: {
      controls: true,
    },
    selector: '.lg-feed-img'
  }
  quillStyle;

  constructor(
    private router: Router,
    private feedService: FeedService,
    private userService: UserService,
    private reportService: ReportService,
    private messageService: MessageService,
    private formService: FormService,
    private bottomSheet: MatBottomSheet,
    private logger: LoggerService,
    private utilService: UtilService
  ) { }

  ngAfterViewInit(): void {
    if (this.feed) {
      if (this.feed.type === FeedType.COACHING) {
        const editor = new Quill(`#feed-${this.feed.id}`);
        this.feed.preview = editor?.getText();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showCommentAll) {
      this.showCommentLength = this.feed.feedComments.length;
    } else {
      this.showCommentLength = 1;
    }
  }

  ngOnInit(): void {
    this.quillStyle = this.utilService.getQuillStyle();
    this.reportForm = new FormGroup({
      category: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(5000)])
    })
    this.commentForm = new FormGroup({
      comment: new FormControl('', this.formService.getValidators(5000))
    })
  }

  goDetailUser(id: number) {
    this.router.navigate(['/tabs/profile', id])
  }

  setReportCategory(category: string) {
    this.reportForm.controls.category.setValue(category);
    if (category === 'ETC') {
      this.reportForm.controls.description.setValidators([Validators.required, Validators.maxLength(5000)]);
      this.reportForm.controls.description.reset();
    } else {
      this.reportForm.controls.description.setValidators([Validators.maxLength(5000)]);
      this.reportForm.controls.description.reset();
    }
    this.reportForm.updateValueAndValidity();
  }

  async report() {
    const { description, category } = this.reportForm.value;
    const dto = new ReportCreateDto(ReportCategory[category], undefined, this.feed.id, undefined, description)
    this.reportService.report(dto).subscribe(async resp => {
      this.messageService.toast('🚨 신고되었습니다');
      this.bottomSheet.dismiss();
      this.reportEvent.emit(this.feed);
    })
  }

  block() {
    const dto = new BlockCreateDto(undefined, this.feed.id)
    this.reportService.block(dto).subscribe(resp => {
      this.messageService.toast('🙅🏻‍♀️ 차단 되었습니다.');
      this.bottomSheet.dismiss();
      this.blockEvent.emit(this.feed);
    })
  }

  async reportComment() {
    const { description, category } = this.reportForm.value;
    const dto = new ReportCreateDto(ReportCategory[category], undefined, undefined, this.selectedComment.id, description)
    this.reportService.report(dto).subscribe(async resp => {
      this.messageService.toast('🚨 신고되었습니다');
      this.bottomSheet.dismiss();
      this.reportEvent.emit(this.feed);
    })
  }

  blockComment() {
    const dto = new BlockCreateDto(undefined, undefined, this.selectedComment.id)
    this.reportService.block(dto).subscribe(resp => {
      this.messageService.toast('🙅🏻‍♀️ 차단 되었습니다.');
      this.bottomSheet.dismiss();
      this.blockEvent.emit(this.feed);
    })
  }

  like(id: number, isLike: boolean) {
    if (!this.currentUser) {
      this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
    } else {
      this.userService.likeFeed(id, isLike).subscribe(resp => {
        if (isLike) {
          this.feed.likes += 1;
          this.feed.isSetLike = true;
        } else {
          this.feed.likes -= 1;
          if (this.feed.likes < 0) this.feed.likes = 0;
          this.feed.isSetLike = false;
        }
      })
    }
  }

  showDetail(feed: Feed) {
    if (!location.href.includes('/tabs/feed-detail')) {
      this.logger.addLog({ pageName: 'feed', eventName: `댓글 클릭`, eventType: 'click', targetUrl: `/tabs/feed-detail/${feed.id}`, targetInfo: { type: TargetType.ETC } });
    }
    this.router.navigate(['/tabs/feed-detail', feed.id])
  }

  openBm(template) {
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  openCommentControl(template, comment: FeedComment) {
    this.selectedComment = comment;
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  openCommentBlockControl(template, comment: FeedComment) {
    this.selectedComment = comment;
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  openEditCommentControl(template) {
    this.commentForm.controls.comment.setValue(this.selectedComment.comment);
    this.bottomSheet.open(template, { closeOnNavigation: true, panelClass: 'nnb-bottom-sheet' });
  }

  closeBm() {
    this.bottomSheet.dismiss();
  }

  goToEdit() {
    this.closeBm();
    this.router.navigate(['/tabs/feed-edit/', this.feed.id]);
  }

  async deleteFeed() {
    await this.closeBm();
    this.messageService.popup({
      title: '피드 삭제',
      description: '정말로 삭제하시겠습니까?',
      confirmLabel: '확인',
      confirmBtnClass: 'primary',
      confirmHandler: () => {
        this.feedService.delete(this.feed.id).subscribe(resp => {
          this.messageService.toast('🙆🏻‍♀️ 피드가 삭제되었습니다.')
          this.deleteEvent.emit(this.feed.id);
        })
      },
      cancelLabel: '취소',
    })
  }

  async deleteFeedComment() {
    await this.closeBm();
    this.messageService.popup({
      title: '댓글 삭제',
      description: '정말로 삭제하시겠습니까?',
      confirmLabel: '확인',
      confirmBtnClass: 'primary',
      confirmHandler: () => {
        this.feedService.deleteComment(this.selectedComment.id).subscribe(resp => {
          this.messageService.toast('🙆🏻‍♀️ 댓글이 삭제되었습니다.')
          this.setFeed(this.feed.id);
        })
      },
      cancelLabel: '취소',
    })
  }

  async setFeed(feedId: number) {
    if (this.currentUser) {
      this.feedService.getWhenLogined(feedId).subscribe(resp => {
        this.feed = resp;
      })
    } else {
      this.feedService.get(feedId).subscribe(resp => {
        this.feed = resp;
      })
    }
  }

  updateComment() {
    this.feedService.editComment(this.selectedComment.id, this.commentForm.controls.comment.value).subscribe(resp => {
      this.commentForm.reset();
      this.bottomSheet.dismiss();
      this.setFeed(this.feed.id);
    })
  }

  isFeedMore(feed) {
    const content = document.getElementById(`${this.currentPage}-feed-${feed.id}`);
    if (content) {
      const more = content.scrollHeight > content.clientHeight;
      return more;
    }
    return false;
  }

  setMore(feed) {
    this.logger.addLog({ pageName: 'feed', eventName: `더보기 클릭`, eventType: 'click', targetUrl: `/tabs/feed-detail/${feed.id}`, targetInfo: { type: TargetType.ETC } });
    const content = document.getElementById(`${this.currentPage}-feed-${feed.id}`);
    if (feed.feedPhotos.length > 0) {
      content.classList.remove("line-clamp");
    } else {
      content.classList.remove("line-clamp-10");
    }
  }

  onInit = (detail) => {
    this.lightGallery = detail.instance;
  }

  onBeforeOpen = () => {
    setTimeout(() => {
      this.lightGallery.updateSlides(this.feed.feedPhotos.map(p => { return { src: p.photo } }), this.lightGallery.index)
    }, 500);
  }
}

