import { Category } from './category';
import { ProductReview } from './comment';
import { Coupon } from './coupon';
import { Order } from './payment';
import { User } from './user';

export class Product {
    constructor(
        public id: number,
        public title: string,
        public price: number,
        public discountPrice: number,
        public recommend: string,
        public version: number,
        public address: string,
        public detailAddress: string,
        public howToCome: string,
        public runningDays: number,
        public runningHours: number,
        public runningMinutes: number,
        public reservationHours: number,
        public lat: number,
        public lon: number,
        public host: User,
        public notice: string,
        public checkList: string,
        public includeList: string,
        public excludeList: string,
        public refundPolicy: string,
        public sortOrder: number,
        public status: ProductStatus,
        public isBusiness: boolean,
        public isOffline: boolean,
        public color: string,
        public representationCategory: ProductRepresentationCategory,
        public description?: string,
        public point?: string,
        public summaries?: ProductSummary[],
        public sections?: ProductSection[],
        public closedTicketDate?: ClosedTicketDate[],
        public hashtags?: Hashtag[],
        public categories?: Category[],
        public amenities?: Amenity[],
        public likes?: number,
        public isSetLike?: boolean,
        public tickets?: Ticket[],
        public createdAt?: Date,
        public updatedAt?: Date,
        public representationPhotos?: ProductRepresentationPhoto[] | ProductRepresentationPhotoCreateDto[],
        public courses?: Course[] | CourseCreateDto[],
        public productReviews?: ProductReview[],
        public productConfirms?: ProductConfirm[],
        public onlineMethod?: OnlineMehtod,
        public orders?: Order[],
        public reviewFeedCount?: number,
        public badge = '',
        public badgeLabel = '',
        public hostCommissionRate?: number,
        public isPb?: boolean,
        public isCommerce?: boolean,
        public coupons?: Coupon[],
        public onlyWomen?: boolean,
        public country?: string,
        public sido?: string,
        public sigugun?: string,
        public dongmyun?: string,
        public ri?: string,
        public rest?: string,
        public priceImage?: string,
        public bizName?: string,
        public agency?: string,
        public agencyImage?: string,
        public minParticipants?: number,
        public maxParticipants?: number,
        public maxPrice?: number,
        public isAddDiscountPrice?: boolean,
    ) { }
}

export class ClosedTicketDate {
    constructor() { }
    public date: Date;
}

export class ProductCreateDto {
    constructor(
        public version: number,
        public title: string,
        public recommend: string,
        public address: string,
        public detailAddress: string,
        public howToCome: string,
        public runningDays: number,
        public runningHours: number,
        public runningMinutes: number,
        public reservationHours: number,
        public lat: number,
        public lon: number,
        public country: string,
        public sido: string,
        public sigugun: string,
        public dongmyun: string,
        public ri: string,
        public rest: string,
        public price: number,
        public discountPrice: number,
        public notice: string,
        public checkList: string,
        public includeList: string,
        public excludeList: string,
        public refundPolicy: string,
        public sortOrder: number,
        public status: ProductStatus,
        public isBusiness: boolean,
        public isOffline: boolean,
        public hashtags: HashtagCreateDto[],
        public representationPhotos: ProductRepresentationPhotoCreateDto[],
        public color: string,
        public point?: string,
        public description?: string,
        public summaries?: ProductSummary[],
        public sections?: ProductSection[],
        public amenities?: Amenity[],
        public courses?: CourseCreateDto[],
        public onlineMethod?: OnlineMehtod,
        public categories?: Category[] | number[],
        public onlyWomen?: boolean,
        public priceImage?: string,
        public representationCategory?: ProductRepresentationCategory
    ) { }
}

export enum ProductStatus {
    ALL = '전체',
    TEST = '테스트',
    ENTERED = '오픈중',
    DISABLED = '비활성',
    END = '종료됨',
}

export enum OnlineMehtod {
    DIRECT = 'DIRECT',
    NONUNBUB = 'NONUNBUB'
}

export class ProductRequest {
    constructor(
        public id: number,
        public product: Product,
        public userId: number,
        public numberOfPeople: number,
        public phoneNumber: string,
        public message: string,
        public isChecked: boolean,
        public checkedAt?: Date
    ) { }
}

export class ProductRequestCreateDto {
    constructor(
        public productId: number,
        public numberOfPeople: number,
        public message: string,
        public isChecked?: boolean
    ) { }
}

export class Hashtag {
    constructor(
        public id: number,
        public name: string,
        public type: HashtagType,
        public isAnalysis: boolean,
        public createdAt: Date,
        public updatedAt: Date,
        public groupName?: string
    ) { }
}

export class HashtagCreateDto {
    constructor(
        public type: HashtagType = HashtagType.PRODUCT,
        public isAnalysis = false,
        public name?: string,
        public id?: string
    ) { }
}

export enum HashtagType {
    PRODUCT = 'PRODUCT',
    USER = 'USER'
}

export class ProductRepresentationPhoto {
    constructor(
        public id: number,
        public product: Product,
        public photo: string,
        public sortOrder: number,
    ) { }
}

export class ProductRepresentationPhotoCreateDto {
    constructor(
        public photo: string,
        public sortOrder: number,
        public file?: File
    ) { }
}

export interface ProductSearchDto {
    page?: number;
    limit?: number;
    status?: ProductStatus;
    statusList?: ProductStatus[];
    hostId?: number;
    userId?: number;
    categoryIds?: number[];
    representationCategories?: string[];
    hashtags?: string[];
    analysisHashtags?: string[];
    from?: string;
    to?: string;
    recentlyOpenDays?: number;
    searchTerm?: string;
    isBusiness?: boolean;
    productIds?: number[];
    sidos?: string[];
    orderBy?: string;
}

export interface ProductUpdateDto {
    version?: string;
    title?: string;
    point?: string;
    recommend?: string;
    summaries?: ProductSummary[];
    description?: string;
    sections?: ProductSection[];
    courses?: CourseCreateDto[];
    address?: string;
    detailAddress?: string;
    howToCome?: string;
    runningDays?: number,
    runningHours?: number,
    runningMinutes?: number;
    reservationHours?: number;
    price?: number,
    discountPrice?: number,
    lat?: number;
    lon?: number;
    country?: string,
    sido?: string,
    sigugun?: string,
    dongmyun?: string,
    ri?: string,
    rest?: string,
    notice?: string;
    checkList?: string;
    includeList?: string;
    excludeList?: string;
    refundPolicy?: string;
    sortOrder?: number;
    status?: ProductStatus;
    isBusiness?: boolean;
    hashtags?: HashtagCreateDto[];
    categories?: Category[] | number[];
    representationPhotos?: ProductRepresentationPhotoCreateDto[];
    color?: string;
    amenities?: Amenity[];
    isOffline?: boolean,
    onlineMethod?: OnlineMehtod,
    onlyWomen?: boolean;
    representationCategory?: ProductRepresentationCategory;
}

export interface ProductManageDto {
    sortOrder?: number;
    status?: ProductStatus;
    badgeLabel?: string;
    hostCommissionRate?: number,
    isPb?: boolean,
    isCommerce?: boolean,
    categories?: number[];
    hashtags?: string[];
    bizName?: string;
    agency?: string;
    agencyImage?: string;
    memo?: string;
    minParticipants?: number;
    price?: number;
    discountPrice?: number;
    maxParticipants?: number;
    maxPrice?: number;
    isAddDiscountPrice?: boolean;
    hostId?: number;
}

export class ProductConfirm {
    constructor(
        public id: number,
        public user: User,
        public product: Product,
        public rejectComment: string,
        public isConfirm: boolean,
        public createdAt: Date,
        public updatedAt: Date,

    ) { }
}

export interface ProductConfirmCreateDto {
    productId: number,
    rejectComment?: string,
    isConfirm?: boolean
}

export interface ProductBadge {
    class: string;
    content: string;
}

export interface HashtagSearchDto {
    page?: number;
    limit?: number;
    isAnalysis?: boolean;
}

export class Course {
    constructor(
        public id: number,
        public product: Product,
        public title: string,
        public subTitle: string,
        public content: string,
        public note: string,
    ) { }
}

export class CourseCreateDto {
    constructor(
        public title: string,
        public subTitle: string,
        public content: string,
        public note: string,
    ) { }
}

export class Amenity {
    constructor(
        public id: number,
        public name: string,
    ) { }
}

export class Ticket {
    constructor(
        public id: number,
        public product: number | Product,
        public name: string,
        public date: string,
        public description: string,
        public isActivated: boolean,
        public isDeactivated: boolean,
        public isClosed: boolean,
        public isReservationEnd: boolean,
        public isOld: boolean,
        public isHidden: boolean,
        public badges?: TicketBadge[] | number[],
        public endStandard?: EndStandard,
        public prices?: TicketPrice[],
        public parent?: Ticket,
        public options?: Ticket[],
        public requestBadgeDescription?: string,
        public group?: TicketGroup,
        public minParticipants?: number,
        public maxParticipants?: number,
        public pricePerPerson?: number,
        public maxTeamCapacity?: number,
        public minParticipantsPerTeam?: number,
        public maxParticipantsPerTeam?: number,
        public pricePerTeam?: number,
        public extraPricePerPerson?: number,
        public descriptions?: string[],
        public minPrice?: ExpectedPrice,
        public paymentedCount?: number
    ) { }
}

export class TicketBadge {
    constructor(
        public id: number,
        public ticket: number | Ticket,
        public name: string,
    ) { }
}

export class TicketPrice {
    constructor(
        public id: number,
        public ticket: number | Ticket,
        public quantity: number,
        public price: number,
        public discountPrice: number,
    ) { }
}

export enum EndStandard {
    PER_PERSON = "인원별",
    PER_TEAM = "팀별",
}

export class TicketCreateBulkDto {
    constructor(
        public productId: number,
        public group: TicketGroup,
        public tickets: TicketCreateDto[],
    ) { }
}

export class TicketGroup {
    constructor(
        public name: string,
        public color: string,
        public from: Date,
        public to: Date,
        public cron: string,
        public dowCron: string,
        public weekString: string,
        public hours: number,
        public minutes: number,
        public optionTicketCount: number,
        public closedDates?: string | Date[],
        public id?: number,
        public activatedTicketCount?: boolean,
        public tickets?: Ticket[]
    ) { }
}

export class TicketCreateDto {
    constructor(
        public name: string,
        public date: Date,
        public badges: TicketBadgeCreateDto[],
        public prices: TicketPriceCreateDto[],
        public requestBadgeDescription: string,
        public endStandard: EndStandard,
        public minParticipants: number,
        public maxParticipants: number,
        public pricePerPerson: number,
        public maxTeamCapacity: number,
        public minParticipantsPerTeam: number,
        public maxParticipantsPerTeam: number,
        public pricePerTeam: number,
        public extraPricePerPerson: number,
        public description?: string,
        public options?: Ticket[],
        public isActivated?: boolean,
        public isDeactivated?: boolean,
        public isClosed?: boolean,
        public isReservationEnd?: boolean,
        public isOld?: boolean,
        public isHidden?: boolean,
    ) { }
}

export class TicketBadgeCreateDto {
    constructor(
        public name: string,
    ) { }
}

export class TicketPriceCreateDto {
    constructor(
        public quantity: number,
        public price: number,
    ) { }
}

export interface TicketSearchDto {
    productId?: number[];
    from?: string;
    to?: string;
    isOld?: boolean;
}

export interface ExpectedPrice {
    quantity: number;
    price: number;
    discountPrice: number;
    discountPercent: number;
    pricePerPerson?: number;
}

export class Section {
    constructor(
        public id: number,
        public subTitle: string,
        public title1: string,
        public title2: string,
        public title1Strong: boolean,
        public title2Strong: boolean,
        public order: number,
        public type: SectionType,
        public productSectionMap: ProductSectionMap[],
        public products: Product[],
        public moreLink?: string,
        public moreLabel?: string,
        public icon?: string,
    ) { }
}

export interface ProductSectionMap {
    id: number;
    productId: number;
    sectionId: number;
    order: number
    product?: Product;
}

export enum SectionType {
    HORIZONTAL = '미디움 사이즈 상품 가로스크롤',
    VERTICAL = '라지 사이즈 상품 세로스크롤',
    LIST = '스몰 사이즈 상품 세로스크롤',
    FEED = '피드',
    INFLUENCER = '인플루언서',
    LIGHT = '벙개모임',
    BESTCATEGORY = '카테고리인기',
    ACCOMPANY = '동행자별',
}

export class SectionUpdateDto {
    constructor(
        public sections: SectionDto[]
    ) { }
}

export class SectionDto {
    constructor(
        public id: number,
        public subTitle: string,
        public title1: string,
        public title2: string,
        public title1Strong: boolean,
        public title2Strong: boolean,
        public order: number,
        public type: SectionType,
        public productSectionMap: ProductSectionDto[],
        public moreLabel?: string,
        public moreLink?: string,
        public icon?: string,
    ) { }
}

export class ProductSectionDto {
    constructor(
        public productId: number,
        public sectionId: number,
        public id?: number,
        public order?: number
    ) { }
}

export class CategorySearchDto {
    page: number;
    limit: number;
    tier?: number;
    includeParent?: boolean;
}

export class ProductSection {
    title: string;
    paragraphs: Paragraph[];
}

export class Paragraph {
    title: string;
    time: string;
    description: string;
    note: string;
    photos: string[];
}

export class ProductSummary {
    photos: string[];
    title: string;
    description: string;
}

export enum ProductRepresentationCategory {
    LODGE = '숙박',
    ONEDAY = '당일',
    LEARN = '배움',
    LIGHT = '벙개',
}