<ng-container *ngIf="price !== 0">
  <ng-container *ngIf="!!discountPrice">
    <div class="origin">{{price | number}}원</div>
    <div class="discount">{{discountPrice | number}}원<span class="desc"> 부터</span></div>
  </ng-container>

  <ng-container *ngIf="!discountPrice">
    <div class="discount">{{price | number}}원<span class="desc">
        부터</span></div>
  </ng-container>
</ng-container>

<ng-container *ngIf="price === 0">
  <div class="discount">무료</div>
</ng-container>