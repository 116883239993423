<div *ngIf="chatRoom" style="display: flex;align-items: center;gap:18px;padding:16px" (click)="clickChatRoom()"
  [ngStyle]="{background: backgroundColor}">
  <div>
    <div *ngIf="chatRoom.users.length === 1">
      <div class="image-cropper" style="margin-left: 6px;">
        <img [src]="chatRoom.users[0].profilePhoto" class="rounded" />
      </div>
    </div>
    <div *ngIf="chatRoom.users.length === 2">
      <div style="display: flex;">
        <div class="image-cropper" style="width: 38px;height: 38px;">
          <img [src]="chatRoom.users[0].profilePhoto" class="rounded" />
        </div>
        <div class="image-cropper" style="width: 38px;height: 38px;margin-left: -15px;">
          <img [src]="chatRoom.users[1].profilePhoto" class="rounded" />
        </div>
      </div>
    </div>
    <div *ngIf="chatRoom.users.length === 3">
      <div style="display: flex;">
        <div class="image-cropper" style="width: 32px;height: 32px;margin-top: 5px;">
          <img [src]="chatRoom.users[0].profilePhoto" class="rounded" />
        </div>
        <div class="image-cropper" style="width: 32px;height: 32px;    margin-left: -21px;
                        margin-top: -16px;">
          <img [src]="chatRoom.users[1].profilePhoto" class="rounded" />
        </div>
        <div class="image-cropper" style="width: 32px;height: 32px;    margin-left: -15px;
                        margin-top: 5px;">
          <img [src]="chatRoom.users[2].profilePhoto" class="rounded" />
        </div>
      </div>
    </div>
    <div *ngIf="chatRoom.users.length >= 4">
      <div style="display: flex;flex-direction: column;">
        <div style="display: flex;">
          <div class="image-cropper" style="width: 31px;height: 31px;">
            <img [src]="chatRoom.users[0].profilePhoto" class="rounded" />
          </div>
          <div class="image-cropper" style="width: 31px;height: 31px;margin-left: -7px;">
            <img [src]="chatRoom.users[1].profilePhoto" class="rounded" />
          </div>
        </div>
        <div style="display: flex;margin-top:-5px">
          <div class="image-cropper" style="width: 31px;height: 31px;">
            <img [src]="chatRoom.users[2].profilePhoto" class="rounded" />
          </div>
          <div class="image-cropper" style="width: 31px;height: 31px;margin-left: -7px;">
            <img [src]="chatRoom.users[3].profilePhoto" class="rounded" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width:100%">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <div *ngIf="!chatRoom.ticket" class="nnb-badge nnb-badge-blue nnb-badge-sm">
          1:1 호스트 문의</div>
        <div *ngIf="chatRoom.ticket" class="nnb-badge nnb-badge-purple nnb-badge-sm">
          {{chatRoom?.ticket?.date|date:'yyyy.MM.dd'}} 참여자 단체방</div>
      </div>
      <div class="time-ago" *ngIf="chatRoom.lastChatCreatedAt">{{chatRoom.lastChatCreatedAt|dateAgo}}</div>
    </div>

    <div class="title line-clamp" *ngIf="chatRoom.ticket">{{chatRoom.title}}</div>
    <div class="title line-clamp" *ngIf="!chatRoom.ticket">1:1 문의방</div>

    <div class="last-chat line-clamp" *ngIf="chatRoom.lastChatMessage">{{chatRoom.lastChatMessage}}</div>

    <div style="display:flex;gap:4px;align-items:center">
      <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user.svg"
        style="width:12px;height:12px">
      <div class="description">{{chatRoom.users.length}}명 참여</div>
    </div>
  </div>
</div>