export const environment = {
  production: true,
  version: "1.0.0",
  googleMapApiKey: "AIzaSyA7_4V2hnx0zKkRb4o7tWd-BqTyajkex_0",
  ACCESS_KEY_ID: "AKIASIGCH2X5RHRDW67N",
  SECREATE_ACCESS_KEY: "Ybfno1ns17OKi+ru2/7ckDHnZ9rC5i0N9eFvasg4",
  REGION: "ap-northeast-2",
  BUCKET: "nonunbub",
  folder: {
    meeting: "meetings",
    event: "events",
    blog: "blogs",
    banner: "etc",
    user: "users",
    review: "reviews",
    chat: "chats",
    feed: "feeds",
    host: "hosts",
  },
  KAKAO_AUTH_KEY: "bc7b219e8b40aae4b2e1745ce1aba555",
  host: "https://nonunbub.com",
  adminUsers: [
    338, 398, 430, 3382, 4681, 3378, 3377, 934, 4, 6137, 373, 775, 1029, 3030,
    3891,
  ],
  adminIp: "218.235.68.140",
  naverPay: {
    clientId: "9249V_u8L1DHWiyxRMQl",
    mode: "production",
  },
  kakaoPay: {
    cid: "",
    adminKey: "8bd5b067ff47e57668e62de336351a91",
  },
};
