import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { FeedService } from 'src/app/service/feed.service';
import { PaymentService } from 'src/app/service/payment.service';
import { FeedType } from '../model/feed';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class UserIsReviewFeedValidGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private paymentService: PaymentService,
    private feedService: FeedService,
    private router: Router,
    private messageService: MessageService,
  ) { }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.authService.getCurrentUser().toPromise()
    const paymentId = +childRoute.params?.id;
    if (paymentId) {
      const payment = await this.paymentService.getPurchasedProduct(paymentId).toPromise();
      const feed = await this.feedService.search({ page: 1, limit: 1, userId: user.id, paymentId, types: [FeedType.REVIEW] }).toPromise();
      if (feed.items.length > 0) {
        this.messageService.popup({
          title: '피드 등록',
          description: '이미 후기 피드를 작성하셨습니다.',
          cancelLabel: '확인',
        })
        this.router.navigate(['/tabs/feed-detail', feed.items[0].id]);
        return false
      }
      if (payment.order.user.id == user.id && !payment.paymentCancel) {
        return true
      } else {
        this.router.navigate(['/tabs/feed']);
        return false
      }
    }
    this.router.navigate(['/tabs/feed']);
    return false;
  }
}
