import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from '../model/pagination';
import { HostStatistics } from '../model/payment';
import { Feed, FeedComment, FeedCommentCreateDto, FeedCreateDto, FeedPhoto, FeedSearchDto, FeedUpdateDto } from './../model/feed';
import { UrlService } from './url.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  urlPrefix = UrlService.prefix;
  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  getQueryFromDto(dto: FeedSearchDto) {
    const types = dto?.types?.map(t => `"${t}"`)
    const analysisHashtags = dto?.analysisHashtags?.map(h => `"${h}"`)
    const query = this.utilService.setQueryParams({ ...dto, types, analysisHashtags });
    return query;
  }

  search(dto: FeedSearchDto): Observable<Pagination<Feed>> {
    return this.http.get<Pagination<Feed>>(`${this.urlPrefix}/feeds?${this.getQueryFromDto(dto)}`);
  }

  searchRefeined(dto: FeedSearchDto): Observable<Pagination<Feed>> {
    return this.http.get<Pagination<Feed>>(`${this.urlPrefix}/feeds/refined?${this.getQueryFromDto(dto)}`);
  }

  searchPhotos(dto: FeedSearchDto): Observable<Pagination<FeedPhoto>> {
    return this.http.get<Pagination<FeedPhoto>>(`${this.urlPrefix}/feeds/photos?${this.getQueryFromDto(dto)}`);
  }

  add(dto: FeedCreateDto): Observable<Feed> {
    return this.http.post<Feed>(`${this.urlPrefix}/feeds`, dto)
  }

  edit(id: number, dto: FeedUpdateDto): Observable<Feed> {
    return this.http.put<Feed>(`${this.urlPrefix}/feeds/${id}`, dto);
  }

  delete(id: number) {
    return this.http.delete(`${this.urlPrefix}/feeds/${id}`);
  }

  get(id: number): Observable<Feed> {
    return this.http.get<Feed>(`${this.urlPrefix}/feeds/${id}`);
  }

  getByPayment(paymentId: number): Observable<Feed> {
    return this.http.get<Feed>(`${this.urlPrefix}/feeds/payment/${paymentId}`);
  }

  getWhenLogined(id: number): Observable<Feed> {
    return this.http.get<Feed>(`${this.urlPrefix}/feeds/logined/${id}`);
  }

  addComment(dto: FeedCommentCreateDto): Observable<FeedComment> {
    return this.http.post<FeedComment>(`${this.urlPrefix}/feeds/comment`, dto);
  }

  editComment(id: number, comment: string): Observable<Feed> {
    return this.http.put<Feed>(`${this.urlPrefix}/feeds/comment/${id}`, { comment });
  }

  deleteComment(id: number) {
    return this.http.delete(`${this.urlPrefix}/feeds/comment/${id}`);
  }

  getFeedScore() {
    return this.http.get<HostStatistics>(`/api/feeds/stat/feed_score`);
  }

  addView(feedId: number) {
    return this.http.post(`/api/feeds/views`, { feedId })
  }
}
