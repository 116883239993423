<div style="display: flex;align-items: center; padding:1rem">
  <a [link]="'/tabs/meeting-detail/' + payment.order.product.id"
    [routerLink]="'/tabs/meeting-detail/' + payment.order.product.id"
    style="text-decoration: none; color: black;flex-shrink:0">
    <img style="cursor: pointer;width: 100px;height: 100px;border: 1px solid #dbdbdb;border-radius: 4px;"
      [src]="payment.order.product.representationPhotos[0].photo" />
  </a>&nbsp;&nbsp;&nbsp;&nbsp;
  <div>
    <div *ngIf="payment.paymentCancel" class="nnb-badge nnb-badge-red" style="margin-bottom: 5px;">취소됨</div>
    <div style="font-weight: bold;">{{payment.order.product.title}}</div>
    <div>총 결제금액: {{payment.payPrice|number}}원</div>
    <div *ngIf="isDetailView" style="display: flex; gap: 5px">
      <a [link]="'/tabs/my-payment-detail/' + payment.id" [routerLink]="'/tabs/my-payment-detail/' + payment.id"
        style="text-decoration: none; color: black;">
        <button class="nnb-btn-secondary nnb-btn-sm">결제 상세보기</button>
      </a>

      <a *ngIf="payment?.feeds?.length === 0 && !payment.paymentCancel" [link]="'/tabs/feed-add/' + payment.id"
        [routerLink]="'/tabs/feed-add/' + payment.id" style="text-decoration: none; color: black;">
        <button class="nnb-btn-secondary nnb-btn-sm">후기피드 작성하기</button>
      </a>

      <a *ngIf="payment?.feeds?.length > 0 && !payment.paymentCancel" [link]="'/tabs/feed-edit/' + payment.feeds[0].id"
        [routerLink]="'/tabs/feed-edit/' + payment.feeds[0].id" style="text-decoration: none; color: black;">
        <button class="nnb-btn-secondary nnb-btn-sm">후기피드 수정하기</button>
      </a>
    </div>
  </div>
</div>