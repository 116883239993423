import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'cron-ui',
  templateUrl: './cron-ui.component.html',
  styleUrls: ['./cron-ui.component.scss'],
})
export class CronUiComponent implements OnChanges {
  @Output() onChangeEvent = new EventEmitter();
  @Input() dowCron: string;
  group: FormGroup = new FormGroup({
    frequency: new FormControl()
  })
  daysOfWeek = [
    { value: 1, label: '월', isChecked: false },
    { value: 2, label: '화', isChecked: false },
    { value: 3, label: '수', isChecked: false },
    { value: 4, label: '목', isChecked: false },
    { value: 5, label: '금', isChecked: false },
    { value: 6, label: '토', isChecked: false },
    { value: 0, label: '일', isChecked: false },
  ];
  isCustom: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dowCron) {
      if (this.isCustom === undefined) {
        this.isCustom = ['0,1,2,3,4,5,6', '1,2,3,4,5', '0,6'].indexOf(this.dowCron) == -1;
      }
      this.group.controls.frequency.setValue(this.isCustom ? 'custom' : this.dowCron);
      if (this.isCustom) {
        this.isCustom = true;
        const list = this.dowCron.split(',').map(v => +v);
        this.daysOfWeek = this.daysOfWeek.map(dow => {
          dow.isChecked = list.indexOf(dow.value) != -1
          return dow;
        })
      } else {
        this.isCustom = false;
      }
    }
  }

  setFrequency(frequency) {
    this.group.controls.frequency.setValue(frequency);
    if (frequency !== 'custom') {
      this.isCustom = false;
      this.daysOfWeek = this.daysOfWeek.map(d => {
        d.isChecked = false;
        return d;
      });
      this.onChangeEvent.emit({ dowCron: frequency, weekString: this.getWeekStringByDowCron(frequency) });
    } else {
      this.isCustom = true;
    }
  }

  getWeekStringByDowCron(frequency) {
    let weekString = '';
    if (frequency === '0,1,2,3,4,5,6') weekString = '매일 7회';
    else if (frequency === '1,2,3,4,5') weekString = '평일 5회';
    else if (frequency === '0,6') weekString = '주말 2회';
    else {
      const list = frequency.split(',');
      const temp = list.map(v => {
        return this.daysOfWeek.filter(d => d.value === +v)[0].label;
      }).join(' / ');
      weekString = temp;
    }
    return weekString;
  }

  getDowCronByWeekString(weekString: string) {
    let dowCron = '';
    if (weekString === '매일 7회') dowCron = '0,1,2,3,4,5,6';
    else if (weekString === '평일 5회') dowCron = '1,2,3,4,5';
    else if (weekString === '주말 2회') dowCron = '0,6';
    else { dowCron = 'custom'; }
    return dowCron;
  }

  setDow(dow: number, isChecked: boolean) {
    this.daysOfWeek.filter(d => d.value === dow)[0].isChecked = !isChecked;
    const result = this.daysOfWeek.filter(d => d.isChecked).map(d => d.value).join(',');
    this.onChangeEvent.emit({ dowCron: result, weekString: this.getWeekStringByDowCron(result) });
  }
}