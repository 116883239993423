import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnChanges {
  @Input() value;
  @Input() segments: [{ value: string, label: string }];
  @Output() changeEvent = new EventEmitter();
  @ViewChild('tabs') tabs: TabsetComponent;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectTab(this.value);
  }

  segmentChanged(event) {
    this.changeEvent.emit(event);
  }

  selectTab(value: string) {
    if (this.tabs) {
      this.tabs.tabs[this.segments.map(v => v.value).indexOf(value)].active = true;
    }
  }
}
