import { Coupon } from 'src/app/model/coupon';
import { Product } from 'src/app/model/product';
import { Feed } from './feed';
import { Ticket } from './product';
import { Review } from './review';
import { NonMember, NonMemberCreateDto, User } from './user';

export class PaymentCreateDto {
    constructor(
        public order: OrderCreateDto | NonMemberOrderCreateDto,
        public pgName: PG,
        public pgOrderId: string,
        public payAt: Date,
        public totalPrice: number,
        public payMethod: PayMethod,
        public payPrice: number,
        public payCommissionPrice: number,
        public result: boolean,
        public resultMessage: string,
        public cardName?: string,
        public cardNum?: string,
        public cardReceipt?: string,
        public bankName?: string,
        public bankNum?: string,
        public createdAt?: Date,
        public updatedAt?: Date,
    ) { }
}

export class OrderCreateDto {
    constructor(
        public userId: number,
        public productId: number,
        public couponId: number,
        public point: number,
        public orderAt: Date,
        public orderItems: OrderItemCreateDto[],
    ) { }
}

export class NonMemberOrderCreateDto {
    constructor(
        public productId: number,
        public orderAt: Date,
        public orderItems: OrderItemCreateDto[],
        public nonMember: NonMemberCreateDto
    ) { }
}

export class OrderItemCreateDto {
    constructor(
        public ticketId: number,
        public count: number,
    ) { }
}

export interface PreOrderCreateDto {
    preOrderKey: string,
    userId: number,
    productId: number,
    couponId: number,
    point: number,
    price: number,
    alliance: string,
    orderItems: OrderItemCreateDto[],
    surveyId?: number,
}

export class Payment {
    id: number;
    order: Order;
    pgName: PG;
    pgOrderId: string;
    payAt: Date;
    totalPrice: number;
    payMethod: PayMethod;
    payPrice: number;
    payCommissionPrice: number;
    result: boolean;
    resultMessage: string;
    cardName: string;
    cardNum: string;
    cardReceipt: string;
    bankName: string;
    bankNum: string;
    createdAt: Date;
    updatedAt: Date;
    isRequestReview = false;
    reviews?: Review[];
    paymentCancel?: PaymentCancel;
    paymentCancelRequests?: PaymentCancelRequest[];
    feeds?: Feed[];
    error?: any;
    tickets?: Ticket[];
    ticketList: TicketFixedPrice[]
}

export interface TicketFixedPrice {
    date: string;
    name: string;
    price: number;
    quantity: number;
    isHidden?: boolean;
}

export interface PaymentCancel {
    id: number;
    reason: string;
    refundPrice: number;
    cancelAt: Date;
    refundCoupon: boolean;
    refundPoint: boolean;
    payment: Payment;
}

export interface PaymentCancelDto {
    reason: string;
    refundPrice: number;
    refundCoupon: boolean;
    refundPoint: boolean;
    payment: number;
}

export interface Order {
    id: number;
    user: User;
    nonMember: NonMember;
    product: Product;
    coupon: Coupon;
    point: number;
    orderAt: Date;
    description: string;
    zipCode?: string;
    address?: string;
    detailAddress?: string;
    orderItems: OrderItem[];
    payment?: Payment
}

export interface OrderItem {
    id: number;
    order: Order;
    ticket: Ticket;
    count: number;
}

export enum PG {
    NAVER_PAY = "NAVER_PAY",
    KAKAO_PAY = "KAKAO_PAY",
    PAYPLE = "PAYPLE"
}

export enum PayMethod {
    CARD = "CARD",
    TRANSFER = "TRANSFER",
    DIRECT_CARD = "DIRECT_CARD",
    DIRECT_TRANSFER = "DIRECT_TRANSFER",
    NAVER_SMARTSTORE = "NAVER_SMARTSTORE",
    GMARKET = "GMARKET",
    SIMPLE_CARD = "SIMPLE_CARD",
    FREE = "FREE"
}

export interface PaymentSearchDto {
    page?: number;
    limit?: number;
    productId?: number;
    isBusiness?: boolean;
    start?: Date;
    end?: Date;
    excludeCancel?: boolean;
    searchTerm?: string;
    productIds?: number[];
    paymentId?: number;
    orderSearchTerm?: string;
}

export interface MyPaymentSearchDto {
    page: number;
    limit: number;
    isLast: boolean;
    userId?: number;
    excludeCancel?: boolean;
    includeProductHashtag?: boolean;
}

export interface PaymentSlectInfo {
    id: number,
    tickets: Ticket[]
}

export interface PaypleUserDefine {
    phoneNumber: string,
    userId: number,
    userEmail: string,
    userName: string,
    mid: number,
    couponId: number | string,
    point: number,
    tickets: { ticketId: number, quantity: number, totalPrice: number }[],
    alliance: string,
    surveyId?: number,
    host: string,
    socarCar?: string,
    socarId?: string,
}

export interface PaypleAuthResponse {
    cst_id: string,
    custKey: string,
    AuthKey: string
}

export interface PaypleCardAdd {
    PCD_PAY_RST: string,
    PCD_PAY_CODE: string,
    PCD_PAY_MSG: string,
    PCD_CST_ID: string,
    PCD_PAY_TYPE: string,
    PCD_PAY_WORK: string,
    PCD_PAYER_ID: string,
    PCD_PAYER_NO: string,
    PCD_PAY_CARDNAME: string,
    PCD_PAY_CARDNUM: string
}

export class PaypleCardAddDto {
    PCD_PAYER_NO: number;
    PCD_PAYER_BIRTH: string;
    PCD_PAY_CARDNUM: string;
    PCD_PAY_CARDEXYEAR: string;
    PCD_PAY_CARDEXMONTH: string;
    PCD_PAY_CARDPW: string;
}

export interface PaypleGetMethod {
    PCD_PAY_RST: string,
    PCD_PAY_CODE: string,
    PCD_PAY_MSG: string,
    PCD_PAY_TYPE: string,
    PCD_PAY_BANKACCTYPE: string,
    PCD_PAYER_ID: string,
    PCD_PAY_CARDNAME: string
    PCD_PAY_CARDNUM: string
    PCD_PAY_BANKNAME: string
    PCD_PAY_BANKNUM: string
}

export interface HostStatistics {
    data: number;
    diff: number;
}

export interface CanReservationItemDto {
    optionId: number;
    count: number;
}

export interface CanReservationDto {
    options: CanReservationItemDto[];
}

export interface KakaoPayReadyDto {
    userId: number,
    productId: number,
    productTitle: string,
    tickets: { id: number, quantity: number, totalPrice: number }[],
    price: number,
    coupon: number,
    point: number,
    alliance: string,
    surveyId?: number,
    beforePage: string,
    mode: 'pc-web' | 'mobile-web' | 'mobile-app',
    host: string,
    socarCar?: string,
    socarId?: string
}

export interface NaverPayCash {
    npointCashAmount: number,
    giftCardCashAmount: number,
    primaryCashAmount: number,
    primaryPayMeans: string,
    totalCashAmount: number,
    supplyCashAmount: number,
    vatCashAmount: number,
}

export interface PaymentCancelRequest {
    id?: number;
    paymentId?: number;
    payment?: Payment;
    type: PaymentCancelType;
    status?: PaymentCancelStatus;
    reason: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum PaymentCancelType {
    CANCEL = "취소",
    CHANGE = "변경"
}

export enum PaymentCancelStatus {
    REQUEST_COMPLETED = "신청완료",
    CANCEL_COMPLETED = "처리완료"
}

export class PaymentCancelRequsetSearchDto {
    page?: number;
    limit?: number;
    status?: PaymentCancelStatus;
    type?: PaymentCancelType;
}