<div style="display:flex; gap:16px;margin-bottom:25px">
  <form [formGroup]="group">
    <form-input [type]="'radio-box'" [radios]="[
    {value: '0,1,2,3,4,5,6' , titleTpl: eveydayTpl},
    {value: '1,2,3,4,5', titleTpl: nonWeekendTpl},
    {value: '0,6', titleTpl: weekendTpl},
    {value: 'custom', titleTpl: customTpl},
    ]" [group]="group" [controlName]="'frequency'" (valueChangeEvent)="setFrequency($event)"></form-input>
    <ng-template #eveydayTpl>
      <div style="display:flex;gap:8px">
        <div>매일 7회</div>
        <div class="nnb-badge nnb-badge-sm nnb-badge-outline">월 / 화 / 수 / 목 / 금 / 토 / 일</div>
      </div>
    </ng-template>
    <ng-template #nonWeekendTpl>
      <div style="display:flex;gap:8px">
        <div>평일 5회</div>
        <div class="nnb-badge nnb-badge-sm nnb-badge-outline">월 / 화 / 수 / 목 / 금</div>
      </div>
    </ng-template>
    <ng-template #weekendTpl>
      <div style="display:flex;gap:8px">
        <div>주말 2회</div>
        <div class="nnb-badge nnb-badge-sm nnb-badge-outline">토 / 일</div>
      </div>
    </ng-template>
    <ng-template #customTpl>
      <div style="display:flex;gap:8px">
        <div>직접 선택하기</div>
      </div>
    </ng-template>
  </form>
</div>
<div *ngIf="isCustom" class="custom-dow">
  <div style="margin-bottom: 16px">원하시는 요일을 선택해주세요. <span class="desc">(직접 선택)</span></div>
  <div style="display:flex; gap:16px;margin-bottom:25px;flex-wrap: wrap;">
    <ng-container *ngFor="let dow of daysOfWeek">
      <div class="dow" [ngClass]="{'checked':dow.isChecked}" (click)="setDow(dow.value, dow.isChecked)">{{dow.label}}
      </div>
    </ng-container>
  </div>
</div>