<ng-container *ngIf="paginationId">
  <pagination-template #p="paginationApi" (pageChange)="pageChanged($event)" [id]="paginationId">
    <div class="pagination" [ngStyle]="style">
      <div class="pagination-previous page" *ngIf="!p.isFirstPage()" (click)="p.previous()"
        [class.disabled]="p.isFirstPage()">
        <a>
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-left-purple.svg">
        </a>
      </div>
      <div class="pagination-previous page" *ngIf="p.isFirstPage()" [class.disabled]="p.isFirstPage()">
        <a class="transparency">
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-left-purple.svg">
        </a>
      </div>
      <ng-container *ngFor="let page of p.pages">
        <div *ngIf="p.getCurrent() !== page.value" (click)="p.setCurrent(page.value)"
          [class.current]="p.getCurrent() === page.value" class="page-number">
          <a><span>{{ page.label }}</span></a>
        </div>
        <div *ngIf="p.getCurrent() === page.value" [class.current]="p.getCurrent() === page.value" class="page-number">
          <div><span>{{ page.label }}</span></div>
        </div>
      </ng-container>
      <div class="pagination-next page" *ngIf="!p.isLastPage()" (click)="p.next()" [class.disabled]="p.isLastPage()">
        <a>
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right-purple.svg">
        </a>
      </div>
      <div class="pagination-next page" *ngIf="p.isLastPage()" [class.disabled]="p.isLastPage()">
        <a class="transparency">
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right-purple.svg">
        </a>
      </div>
    </div>
  </pagination-template>
</ng-container>