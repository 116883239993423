import { Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CourseCreateDto, HashtagCreateDto, HashtagType, ProductCreateDto } from 'src/app/model/product';
import { AuthService } from 'src/app/service/auth.service';
import { MessageService } from 'src/app/service/message.service';
import { ProductService } from 'src/app/service/product.service';
import { S3Service } from 'src/app/service/s3.service';
import { environment } from 'src/environments/environment';
import { ProductStatus } from '../../../model/product';
import { FormService } from '../../../service/form.service';
import { UtilService } from '../../../service/util.service';
import { MeetingControl } from './meeting-control';

@Component({
  selector: 'meeting-add',
  templateUrl: './meeting-add.page.html',
  styleUrls: ['./meeting-add.page.scss'],
})
export class MeetingAddPage extends MeetingControl implements OnInit {

  constructor(
    private formService: FormService,
    private router: Router,
    public ngZone: NgZone,
    public s3Service: S3Service,
    private utilService: UtilService,
    public authService: AuthService,
    private fb: FormBuilder,
    private productService: ProductService,
    private messageService: MessageService,
    public bottomSheet: MatBottomSheet
  ) {
    super(ngZone, s3Service, authService, bottomSheet);
  }

  ngOnInit() {
    //TODO set list hashtags
    this.productService.searchHashtag({ page: 1, limit: 9999, isAnalysis: false }).subscribe(resp => {
      this.hashtags = resp.items;
    })
    this.productService.searchAmenity({ page: 1, limit: 9999 }).subscribe(resp => {
      this.amenities = resp.items;
    })
    this.authService.getCurrentUser().subscribe(user => {
      this.user = user;
      this.loadMap();
    });
    this.quillStyle = this.utilService.getQuillStyle();
    this.meetingForm = this.fb.group({
      version: new FormControl(2),
      title: new FormControl('', this.formService.getValidators(30)),
      hashtags: new FormControl('', Validators.required),
      photos: new FormArray([], Validators.required),
      address: new FormControl('', this.formService.getValidators(65535)),
      detailAddress: new FormControl('', this.formService.getValidators(500)),
      howToCome: new FormControl('', Validators.maxLength(1000)),
      runningDays: new FormControl(0, Validators.compose([Validators.required, Validators.min(0), Validators.max(30)])),
      runningHours: new FormControl(3, Validators.compose([Validators.required, Validators.min(0), Validators.max(23)])),
      runningMinutes: new FormControl(0, Validators.compose([Validators.required, Validators.min(0), Validators.max(50)])),
      reservationHours: new FormControl(24, Validators.compose([Validators.required, Validators.min(6), Validators.max(120)])),
      price: new FormControl('', this.formService.getValidators(10, [Validators.min(0), Validators.max(100000000), Validators.pattern("^[0-9]*$")])),
      discountPrice: new FormControl('', [Validators.min(1), Validators.max(100000000), Validators.pattern("^[0-9]*$"), this.validateDiscountPrice('price')]),
      recommend: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])),
      // point: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(500)])),
      description: new FormControl(''),
      summaries: new FormArray([new FormGroup({
        photos: new FormArray([]),
        title: new FormControl('', this.formService.getValidators(20)),
        description: new FormControl('', this.formService.getValidators(200)),
      })]),
      sections: new FormArray([new FormGroup({
        title: new FormControl('', this.formService.getValidators(20)),
        paragraphs: new FormArray([new FormGroup({
          title: new FormControl('', this.formService.getValidators(20)),
          time: new FormControl('', Validators.maxLength(20)),
          photos: new FormArray([]),
          description: new FormControl('', Validators.required),
          note: new FormControl(''),
        })])
      })]),
      courses: new FormArray([]),
      notice: new FormControl('', Validators.maxLength(500)),
      checkList: new FormControl('', this.formService.getValidators(500)),
      includeList: new FormControl('', Validators.maxLength(500)),
      excludeList: new FormControl('', Validators.maxLength(500)),
      amenities: new FormArray([]),
      isAmenity: new FormControl(),
      refundPolicy: new FormControl('', this.formService.getValidators(1000)),
    })
  }

  loadMap() {
    this.latitude = this.SEOUL_LATITUDE;
    this.longitude = this.SEOUL_LONGITUDE;
    this.zoom = 12;
  }

  async add() {
    this.bottomSheet.dismiss();
    this.isLoad = true;
    const { version, title, hashtags, photos, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes,
      reservationHours, price, discountPrice, recommend, summaries, amenities,
      sections, courses, notice, checkList, includeList, excludeList, refundPolicy, point, description } = this.meetingForm.value;
    const hashtagNames = hashtags.map(v => {
      if (v.label) return new HashtagCreateDto(HashtagType.PRODUCT, false, v.label);
      else return new HashtagCreateDto(HashtagType.PRODUCT, false, undefined, v);
    });
    if (version === 2) {
      let summaryPhotoList = [];
      for (let summary of summaries) {
        for (let photo of summary.photos) {
          if (photo.file) {
            const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
            await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { height: 800, fit: 'cover' }, gif: true });
            const link = res.Location.replace(`/meetings`, `/meetings-resized`);
            summaryPhotoList.push(link)
          } else {
            summaryPhotoList.push(photo.photo ?? photo);
          }
        }
        summary.photos = summaryPhotoList;
        summaryPhotoList = [];
      }
      let sectionPhotoList = [];
      for (let section of sections) {
        for (let paragraph of section.paragraphs) {
          for (let photo of paragraph.photos) {
            if (photo.file) {
              const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
              await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { width: 800, height: 800, fit: 'cover' }, gif: true });
              const link = res.Location.replace(`/meetings`, `/meetings-resized`);
              sectionPhotoList.push(link)
            } else {
              sectionPhotoList.push(photo.photo ?? photo)
            }
          }
          paragraph.photos = sectionPhotoList;
          sectionPhotoList = [];
        }
      }
    }
    const courseList = [];
    courses.forEach(course => {
      course.schedules.forEach(schedule => {
        courseList.push(new CourseCreateDto(course.title, schedule.subTitle, schedule.content, schedule.note));
      })
    });
    const dp = (discountPrice === '' || discountPrice === null) ? null : +discountPrice;
    for (let photo of photos) {
      if (photo.file) {
        const res = await this.s3Service.uploadFile(photo.file, environment.folder.meeting);
        await this.s3Service.resizeImage(res.Key, `meetings-resized`, { options: { width: 800, height: 800, fit: 'cover' }, gif: true });
        const link = res.Location.replace(`/meetings`, `/meetings-resized`);
        photo.photo = link;
        delete photo.file;
      }
    }

    const dto = new ProductCreateDto(version, title, recommend, address, detailAddress, howToCome, runningDays, runningHours, runningMinutes,
      reservationHours, this.latitude, this.longitude, this.country, this.sido, this.sigugun, this.dongmyun, this.ri, this.rest, +price, dp,
      notice, checkList, includeList, excludeList, refundPolicy, 0, ProductStatus.DISABLED, true, true, hashtagNames, photos,
      this.utilService.getRandomColor(), point, description, summaries, sections, amenities, courseList, undefined, undefined, false)
    this.productService.addProduct(dto).subscribe(product => {
      this.messageService.popup({
        title: '상품 관리',
        description: '상품을 생성하였습니다. 판매를 위해서 티켓 등록 및 호스트 정보 수정을 해주세요.',
        confirmLabel: '티켓등록 하러가기',
        confirmBtnClass: 'primary',
        confirmHandler: () => {
          this.router.navigate(['/host/schedule'], { queryParams: { productId: product.id } });
        },
        cancelLabel: '닫기',
      })
      this.passDeactivate = true;
      this.isLoad = false;
      this.router.navigate(['/host/meeting-management/hosted-meetings']);
      this.meetingForm.reset();
    }, error => {
      this.messageService.popup({
        title: '상품 관리',
        description: '상품 등록에 문제가 발생했습니다. 작성하신 내용이 저장되지 않을 수 있으니 백업 하시고 노는법에게 문의주세요.',
        cancelLabel: '확인',
      })
      console.log(error);
      this.isLoad = false;
    })
  }
}

