<ng-container *ngIf="coupon">
  <div>
    <div class="price" [ngStyle]="{'color':isPossible?'':'#ccc'}">{{coupon.price|number}}원</div>
    <div class="name" [ngStyle]="{'color':isPossible?'':'#ccc'}">{{coupon.name}}</div>
    <div style="display:flex;justify-content:space-between">
      <div class="title" [ngStyle]="{'color':isPossible?'':'#ccc'}">최소 결제금액</div>
      <div>
        <div class="content" [ngStyle]="{'color':isPossible?'':'#ccc'}" *ngIf="coupon.minOrderPrice > 0">
          {{coupon.minOrderPrice|number}}원</div>
        <div class="content" [ngStyle]="{'color':isPossible?'':'#ccc'}" *ngIf="!coupon.minOrderPrice">없음</div>
      </div>
    </div>
    <div style="display:flex;justify-content:space-between">
      <div class="title" [ngStyle]="{'color':isPossible?'':'#ccc'}">쿠폰 만료일</div>
      <div class="content" [ngStyle]="{'color':isPossible?'':'#ccc'}">
        {{coupon.expireDuration|date:'yyyy-MM-dd HH:mm'}} 까지</div>
    </div>
    <div style="display:flex;justify-content:space-between" *ngIf="coupon.availableReservationDate">
      <div class="title" [ngStyle]="{'color':isPossible?'':'#ccc'}">티켓예약 가능일</div>
      <div class="content" [ngStyle]="{'color':isPossible?'':'#ccc'}">
        {{coupon.availableReservationDate|date:'yyyy-MM-dd HH:mm'}} 까지</div>
    </div>
    <hr>
    <div [ngStyle]="{'color':isPossible?'':'#ccc'}" class="contents">{{coupon.contents}}</div>
    <div *ngIf="coupon.notAvailableReason" class="contents" style="color:red">{{coupon.notAvailableReason}}</div>
  </div>
</ng-container>
<ng-container *ngIf="!coupon">
  <div class="no-use">사용 안함</div>
</ng-container>