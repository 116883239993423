import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ROUTER_CONFIGURATION, Router } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CookieModule, CookieService } from 'ngx-cookie';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { MessageService } from 'src/app/service/message.service';
import { environment } from './../environments/environment';
import { AppRoutingModule, RouterConfiguration } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminPageModule } from './page/admin/admin.module';
import { HostPageModule } from './page/host/host.module';
import { ChannelService } from './service/channel.service';
import { ErrorHttpInterceptor } from './service/error-http-interceptor';
import { LoggerService } from './service/logger.service';
import { TokenInterceptor } from './service/token.interceptor';
import { SharedModule } from './shared.module';

export function jwtOptionsFactory(cookieService: CookieService) {
  return {
    tokenGetter: () => { return cookieService.get('access_token'); }
  }
}

const config: SocketIoConfig = { url: `${environment.host}/nnb-chat`, options: {} };

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AdminPageModule,
    HostPageModule
  ],
  providers: [
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true,
      deps: [Router, MessageService, LoggerService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ClientVersionInterceptor, multi: true },
    ChannelService,
    { provide: LOCALE_ID, useValue: 'ko' },
    RouterConfiguration,
    { provide: ROUTER_CONFIGURATION, useExisting: RouterConfiguration }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
