import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnChanges {
  @Input() config: PopupConfig;
  @Input() modalRef;
  @ViewChild('popup') popup;

  constructor(
    private modalService: BsModalService,
    private cookieService: CookieService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.show();
    }
  }

  show() {
    this.modalRef = this.modalService.show(this.popup, { animated: !!this.config.isNested, class: 'modal-popup', ignoreBackdropClick: !!this.config.isStrict, keyboard: !this.config.isStrict });
  }

  confirm() {
    this.modalRef.hide();
    if (this.config?.confirmHandler) {
      this.config?.confirmHandler();
    }
  }

  cancel() {
    this.modalRef.hide();
    if (this.config?.cancelHandler) {
      this.config?.cancelHandler();
    }
  }

  closeUtilPeriod() {
    this.cookieService.put(this.config.closeBtnUntilPeriod.cookieKey, 'true', { expires: moment().add(this.config.closeBtnUntilPeriod.days, 'd').toDate() });
    this.modalRef.hide();
  }
}

export interface PopupConfig {
  type?: 'error';
  title?: string;
  description?: string;
  confirmLabel?: string;
  confirmBtnClass?: string;
  cancelLabel?: string;
  confirmHandler?;
  cancelHandler?;
  image?: string;
  isNested?: boolean;
  isStrict?: boolean;
  closeBtnUntilPeriod?: {
    label: string;
    days: number;
    cookieKey: string;
  };
  template?: any;
}