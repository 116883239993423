<ng-container *ngIf="!isDesktop">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav>
      <div class="sidemenu" *ngIf="isDesktop" style="display:flex;align-items:center;gap:12px">
        <div>
          <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/tabs/home'">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
              style="width: 55px;">
          </a>
        </div>
        <div style="color:#6c757d" [routerLink]="'/admin'">관리자</div>
      </div>

      <div style="width: 230px">
        <div class="sidemenu" [routerLink]="'/admin/payment'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-check-48.png">
          결제 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/product'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-product-documents-48.png">
          상품 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/schedule'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-48.png">
          티켓 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/user'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-team-48.png">
          사용자 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/settlement'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coins-48.png">
          정산 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/product-order'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-numeric-48.png">
          모임 전시 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/notification'" [routerLinkActive]="'active'"
          (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-push-notifications-48.png">
          푸시알림 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/event'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-autumntime-48.png">
          이벤트 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/blog'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-typewriter-with-paper-48.png">
          게시판 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/chat'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-chat-48.png">
          채팅 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/banner'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-banner-48.png">
          배너 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/coupon'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coupon-48.png">
          쿠폰 관리
        </div>
        <div class="sidemenu" [routerLink]="'/admin/config'" [routerLinkActive]="'active'" (click)="sidenav.toggle()">
          <img style="width: 24px;"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-settings-48.png">
          설정 관리
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <header class="header" [headerTpl]="headerTpl" [isBackButton]="false" [style]="{}"></header>
      <ng-template #headerTpl>
        <div style="display:flex">
          <div style="flex:1">
            <img (click)="sidenav.toggle()"
              src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/menu.svg">
          </div>
          <div class="header-title-md">
            <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/tabs/home'">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
                style="width: 55px;">
            </a>&nbsp;&nbsp;<span style="color:#6c757d" [routerLink]="'/admin'">관리자</span>
          </div>
          <div style="flex:1"></div>
        </div>
      </ng-template>
      <div style="padding: 0px 16px">
        <router-outlet></router-outlet>
        <div style="height:74px"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="isDesktop">
  <div style="display:flex;">
    <div style="border-right: 1px solid #f1f3f5;min-width: 230px;">
      <header class="header" [headerTpl]="headerTpl" [isBackButton]="false" [style]="{}"></header>
      <ng-template #headerTpl>
        <div class="header-title-md">
          <a style="text-decoration: none; cursor: pointer;" [routerLink]="'/tabs/home'">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo.svg"
              style="width: 55px;">
          </a>&nbsp;&nbsp;<span style="color:#6c757d" [routerLink]="'/admin'">관리자</span>
        </div>
      </ng-template>

      <div class="sidemenu" [routerLink]="'/admin/payment'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-check-48.png">
        결제 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/product'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-product-documents-48.png">
        상품 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/schedule'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-calendar-48.png">
        티켓 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/user'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-team-48.png">
        사용자 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/settlement'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coins-48.png">
        정산 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/product-order'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-numeric-48.png">
        모임 전시 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/notification'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-push-notifications-48.png">
        푸시알림 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/event'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-autumntime-48.png">
        이벤트 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/blog'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-typewriter-with-paper-48.png">
        게시판 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/chat'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-chat-48.png">
        채팅 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/banner'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-banner-48.png">
        배너 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/coupon'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-coupon-48.png">
        쿠폰 관리
      </div>
      <div class="sidemenu" [routerLink]="'/admin/config'" [routerLinkActive]="'active'">
        <img style="width: 24px;"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/admin/icons8-settings-48.png">
        설정 관리
      </div>
    </div>
    <div style="padding:55px 32px 160px 32px; width: 100%">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>