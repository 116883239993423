<div style="margin-bottom: 10px;">
  <a [link]="'/host/meeting-management/meeting-add'" [routerLink]="'/host/meeting-management/meeting-add'">
    <button style="margin-right:5px" class="nnb-btn-md nnb-btn-primary">비즈니스 상품 개설</button>
  </a>
</div>
<ngx-table [id]="'host-product-table'" [configuration]="config" [data]="hostedProducts" [columns]="columns"
  [pagination]="pagination" (event)="onTableEvent($event)">
  <ng-template let-row let-index="index">
    <td>
      <div style="display: flex;align-items: center; gap: 5px;">
        <div>{{ (pagination.offset-1) * pagination.limit + (index+1)}}</div>
      </div>
    </td>
    <td>
      <button class="nnb-btn-secondary nnb-btn-sm" *ngIf="row.productConfirms.length > 0"
        (click)="showChecks(row, checkTpl)">
        승인/반려내역</button>
    </td>
    <td>
      <div class="nnb-badge nnb-badge-blue" *ngIf="row.isBusiness">비즈니스 상품</div>
      <div class="nnb-badge nnb-badge-green" *ngIf="!row.isBusiness">벙개모임</div>
    </td>
    <td>
      <img [src]="row.representationPhotos[0].photo" style="height: 48px;min-width:48px;border-radius: 10px;">
    </td>
    <td>
      <div class="nnb-badge" [ngClass]="'nnb-badge-'+getStatusBadge(row.status).badge">
        {{row.status}}</div>
    </td>
    <td>
      <a [link]="'/tabs/meeting-detail/' + row.id" [routerLink]="'/tabs/meeting-detail/' + row.id">{{row.title}}</a>
    </td>
    <td>
      <div style="display: flex;gap: 3px;align-items: center;">
        <button class="nnb-btn-outline nnb-btn-sm" (click)="goToTicket(row.id)">티켓관리</button>
        <button class="nnb-btn-outline nnb-btn-sm" (click)="edit(row.id,row.isBusiness)">수정</button>
        <button class="nnb-btn-red nnb-btn-sm" (click)="removeProduct(row.id)">삭제</button>
      </div>
    </td>
    <td>
      <div class="desc">
        {{row.createdAt|date:'yyyy-MM-dd HH:mm'}}<br>{{row.updatedAt|date:'yyyy-MM-dd HH:mm'}}
      </div>
    </td>
  </ng-template>
</ngx-table>

<ng-template #checkTpl>
  <div class="modal-header">
    <p class="modal-title pull-left">검토 내역</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding:30px">
    <div style="margin-top:10px;height: 500px;overflow-y: auto;">
      <table class="table">
        <tr>
          <td>검토 일시</td>
          <td>결과</td>
          <td>반려 사유</td>
        </tr>
        <tr *ngFor="let confirm of selectedProduct.productConfirms; let index=index">
          <td class="desc">{{confirm.createdAt|date:'yyyy-MM-dd HH:mm'}}</td>
          <td>
            <div *ngIf="confirm.isConfirm" class="nnb-badge nnb-badge-green">승인</div>
            <div *ngIf="!confirm.isConfirm" class="nnb-badge nnb-badge-red">반려</div>
          </td>
          <td class="pre-wrap">{{confirm.rejectComment}}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>