<div *ngIf="viewType===ProductSection.LIST" (click)="goDetailPage(product)" class="list">
  <img class="photo" [src]="product.representationPhotos[0].photo">
  <div style="width: 100%;">
    <div *ngIf="product.badgeLabel" class="nnb-badge nnb-badge-md nnb-badge-black-bg" style="margin-bottom: 6px;">
      {{product.badgeLabel}}</div>
    <div [ngTemplateOutlet]="saleTpl"></div>
  </div>
</div>

<div *ngIf="viewType===ProductSection.VERTICAL" (click)="goDetailPage(product)" class="vertical">
  <swiper [config]="{pagination: {
      clickable: true,
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 3
    }}" style="border-radius:12px;margin-bottom: 14px;">
    <ng-template swiperSlide *ngFor="let photo of product.representationPhotos">
      <div *ngIf="product.badgeLabel" style="position: absolute;left: 8px;top: 8px;">
        <div class="nnb-badge nnb-badge-md nnb-badge-black-bg" style="height:31px;padding:8px;font-size:15px">
          {{product.badgeLabel}}
        </div>
      </div>
      <div style="position: absolute;right: 8px;top: 8px;">
        <div *ngIf="product.isSetLike" (click)="setLike(product.id, false)"
          style="opacity: 0.8;background:#FFF;width: 40px;height: 40px;padding: 9px;border-radius: 6px;">
          <img style="width:22px;height:22px"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg">
        </div>
        <div *ngIf="!product.isSetLike" (click)="setLike(product.id, true)"
          style="opacity: 0.8;background:#FFF;width: 40px;height: 40px;padding: 8px;border-radius: 6px;">
          <img style="width:24px;height:24px"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-black-off.svg">
        </div>
      </div>
      <div *ngIf="product.discountPrice > 0" style="position: absolute;left: 8px;bottom: 23px;">
        <div class="nnb-badge nnb-badge-md nnb-badge-purple-bg" style="height:31px;padding:8px;font-size:15px">
          특가상품
        </div>
      </div>
      <img [src]="photo.photo" class="photo">
    </ng-template>
  </swiper>
  <div [ngTemplateOutlet]="saleTpl"></div>
</div>

<div *ngIf="viewType===ProductSection.HORIZONTAL || viewType===ProductSection.LIGHT
 || viewType===ProductSection.BESTCATEGORY || viewType===ProductSection.ACCOMPANY" (click)="goDetailPage(product)"
  class="horizontal">
  <div style="position: relative;">
    <div *ngIf="product.badgeLabel" style="position: absolute;left: 8px;top: 8px;">
      <div class="nnb-badge nnb-badge-md nnb-badge-black-bg" style="height:31px;padding:8px;font-size:15px">
        {{product.badgeLabel}}
      </div>
    </div>
    <div style="position: absolute;right: 8px;top: 8px;">
      <div *ngIf="product.isSetLike" (click)="setLike(product.id, false)"
        style="opacity: 0.8;background:#FFF;width: 40px;height: 40px;padding: 9px;border-radius: 6px;">
        <img style="width:22px;height:22px"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg">
      </div>
      <div *ngIf="!product.isSetLike" (click)="setLike(product.id, true)"
        style="opacity: 0.8;background:#FFF;width: 40px;height: 40px;padding: 8px;border-radius: 6px;">
        <img style="width:24px;height:24px"
          src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-black-off.svg">
      </div>
    </div>
    <div *ngIf="product.discountPrice > 0" style="position: absolute;left: 8px;bottom: 23px;">
      <div class="nnb-badge nnb-badge-md nnb-badge-purple-bg" style="height:31px;padding:8px;font-size:15px">
        특가상품
      </div>
    </div>
    <img [src]="product.representationPhotos[0].photo" class="photo">
  </div>
  <div [ngTemplateOutlet]="saleTpl"></div>
</div>

<ng-template #saleTpl>
  <div style="margin-bottom: 12px;">
    <div [ngTemplateOutlet]="titleTpl"></div>
  </div>
  <div *ngIf="showSale" style="display:flex;gap:12px;flex-direction: column;">
    <div style="line-height: 100%;">
      <div *ngIf="product.discountPrice">
        <div style="display:flex;align-items:center;gap:4px;margin-bottom:4px">
          <div style="color:#570DCA;font-size: 18px;font-weight: 700;line-height: 100%;">
            {{((product.price-product.discountPrice)/product.price*100).toFixed(0)}}%</div>
          <div style="color: #A6A8B8;font-size: 14px;font-weight: 400;text-decoration-line: line-through;">
            {{product.price|number}}원</div>
        </div>
        <div style="font-size: 20px;font-weight: 600;letter-spacing: -0.4px;">
          <div *ngIf="product.discountPrice > 0" style="display:flex;gap:4px;align-items: flex-end;">
            <div>{{product.discountPrice|number}}원</div>
            <div *ngIf="product.minParticipants"
              style="font-size: 15px;font-weight: 500;line-height: 100%;color:#5B6371">
              ({{product.minParticipants}}인 기준)
            </div>
          </div>
          <div *ngIf="product.discountPrice == 0">무료</div>
        </div>
      </div>
      <div *ngIf="!product.discountPrice">
        <div style="font-size: 20px;font-weight: 600;letter-spacing: -0.4px;">
          <div *ngIf="product.price > 0" style="display:flex;gap:4px;align-items: flex-end;">
            <div>{{product.price|number}}원</div>
            <div *ngIf="product.minParticipants"
              style="font-size: 15px;font-weight: 500;line-height: 100%;color:#5B6371">
              ({{product.minParticipants}}인 기준)
            </div>
          </div>
          <div *ngIf="product.price == 0">무료</div>
        </div>
      </div>
      <div *ngIf="product.maxParticipants && product.maxPrice"
        style="display:flex;gap:4px;align-items: center;margin-top:8px">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user-dark-grey.svg">
        <div style="color: #5B6371;font-size: 14px;font-weight: 600;line-height: 100%;">
          {{product.maxParticipants}}인</div>
        <div style="color: #5B6371;font-size: 14px;font-weight: 600;line-height: 100%;">
          {{product.maxPrice|number}}원</div>
      </div>
    </div>
    <div style="display:inline-flex;gap:6px;flex-direction:column;align-items: flex-start;">
      <div *ngIf="product.isAddDiscountPrice" class="nnb-badge nnb-badge-md nnb-badge-blue"
        style="height:30px;display: inline-flex;gap: 3px;align-items: center;background: #EFF6FF;color:#1D4ED8">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/receipt-tax.svg">
        추가인원 할인
      </div>
      <!-- <div class="nnb-badge nnb-badge-md nnb-badge-purple"
        style="height:30px;display: inline-flex;gap: 3px;align-items: center;">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/coupon-ticket.svg">
        최대 10,000원 쿠폰할인
      </div> -->
      <div *ngIf="product.agency" class="nnb-badge nnb-badge-md nnb-badge-light-grey"
        style="height:30px;display: inline-flex;gap: 3px;align-items: center;">
        <img *ngIf="product.agencyImage" style="width:16px;height:16px" [src]="product.agencyImage">
        {{product.agency}}
      </div>
    </div>
    <div style="display:flex;gap:17.5px;align-items: center;">
      <div style="display:flex;gap:4px;align-items: center;" *ngIf="product.reviewFeedCount > 0">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/message-filled.svg"
          style="width:18px;height:18px">
        <div class="info">후기</div>
        <div class="info">{{product.reviewFeedCount}}건</div>
      </div>
      <div style="display:flex;gap:4px;align-items: center;" *ngIf="product.likes > 0">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-dark-grey.svg"
          style="width:18px;height:18px">
        <div class="info">찜</div>
        <div class="info">{{product.likes}}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #titleTpl>
  <div
    style="display:flex;gap:4px;align-items: center;font-size: 15px;font-weight: 500;margin-bottom: 2px;color:#5B6371">
    <div>{{product.representationCategory}}·{{region}}</div>
    <div>{{product.bizName}}</div>
  </div>
  <div class="title">{{product.title}}</div>
</ng-template>