import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HostInfo, HostInfoSearchDto, HostInfoUpdateDto, Influencer, User, UserAddPointDto, UserSetting } from 'src/app/model/user';
import { Pagination, PaginationSearchDto } from '../model/pagination';
import { EarnPointType, UserPointHistory } from '../model/user';
import { UserCreateDto, UserSearchDto, UserUpdateDto } from './../model/user';
import { AuthService } from './auth.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  urlPrefix = UrlService.prefix;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  search(dto: UserSearchDto): Observable<Pagination<User>> {
    return this.http.post<Pagination<User>>(`${this.urlPrefix}/users/search`, dto);
  }

  signup(user: UserCreateDto): Observable<User> {
    return this.http.post<User>(`/api/users`, user);
  }

  get(nid: number): Observable<User> {
    return this.http.get<User>(`${this.urlPrefix}/users/${nid}`);
  }

  getByUserCode(userCode: string): Observable<User> {
    return this.http.get<User>(`api/users/userCode/${userCode}`)
  }

  getProfile(nid: number): Observable<User> {
    return this.http.get<User>(`${this.urlPrefix}/users/profile/user/${nid}`);
  }

  getProfileWithUserInfo(nid: number): Observable<User> {
    return this.http.get<User>(`${this.urlPrefix}/users/profile/user/${nid}/userInfo`);
  }

  getFollowings(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.urlPrefix}/users/followings/list/${id}`)
  }

  getFolloweds(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.urlPrefix}/users/followeds/list/${id}`)
  }

  async editAndUpdateToken(userId, dto: UserUpdateDto): Promise<User> {
    const user = await this.http.put<User>(`/api/users/${userId}`, dto).toPromise();
    const token = await this.authService.updateToken(userId).toPromise();
    this.authService.setToken(token.accessToken);
    return user;
  }

  likeProduct(productId: number, isLike: boolean) {
    if (isLike) {
      this.authService.updateUserInfo({ isLikeProductRead: false }).subscribe();
    }
    return this.http.post(`api/users/likes/product`, { id: productId, isLike });
  }

  likeUser(userId: number, isLike: boolean) {
    return this.http.post(`api/users/likes/user`, { id: userId, isLike });
  }

  likeFeed(feedId: number, isLike: boolean): Observable<any> {
    return this.http.post<any>(`api/users/likes/feed`, { id: feedId, isLike });
  }

  findPassword(email: string): any {
    return this.http.post(`auth/find-password`, { email })
  }

  resetPassword(validationCode: string, password: string): any {
    return this.http.put(`auth/reset-password`, { validationCode, password });
  }

  searchPointHistory(page: number, limit: number): Observable<Pagination<UserPointHistory>> {
    if (!page) page = 1;
    return this.http.get<Pagination<UserPointHistory>>(`${this.urlPrefix}/users/point/history?page=${page}&limit=${limit}`);
  }

  getPointStatisticsByType(): Observable<{ type: EarnPointType, value: number }[]> {
    return this.http.get<{ type: EarnPointType, value: number }[]>(`${this.urlPrefix}/users/point/history/statistics`);
  }

  getPointTopPercent(): Observable<{ percent: number }> {
    return this.http.get<{ percent: number }>(`${this.urlPrefix}/users/point/history/top-percent`);
  }

  getSettings(): Observable<UserSetting> {
    return this.http.get<UserSetting>('api/settings');
  }

  setSettings(unreadAlarm: boolean): Observable<UserSetting> {
    return this.http.put<UserSetting>('api/settings', { unreadAlarm });
  }

  update(userId: number, dto: UserUpdateDto): Observable<User> {
    return this.http.put<User>(`api/users/${userId}`, dto);
  }

  updatePhoneNumber(userId: number, phoneNumber: string): Observable<User> {
    return this.http.put<User>(`api/users/phoneNumber/${userId}`, { phoneNumber });
  }

  updateRole(userId: number, dto: UserUpdateDto): Observable<User> {
    return this.http.put<User>(`api/users/${userId}/role`, dto);
  }

  updateUserCode(userCode: string): Observable<User> {
    return this.http.put<User>(`api/users/userCode/checkAndUpdate`, { userCode });
  }

  deleteUser() {
    return this.http.delete('api/users');
  }

  getHostInfoByCurrent(): Observable<HostInfo> {
    return this.http.get<HostInfo>(`api/hostInfo`);
  }

  getHostInfoByHostId(hostId: number): Observable<HostInfo> {
    return this.http.get<HostInfo>(`api/hostInfo/host/${hostId}`);
  }

  searchHostInfo(dto: HostInfoSearchDto): Observable<Pagination<HostInfo>> {
    return this.http.post<Pagination<HostInfo>>(`${this.urlPrefix}/hostInfo/search`, dto);
  }

  updateHostInfo(userId: number, dto: HostInfoUpdateDto) {
    return this.http.put(`api/hostInfo/${userId}`, dto);
  }

  getLikeProductCount(): Observable<number> {
    return this.http.get<number>(`api/users/likes/product/count`);
  }

  setUserTypeTestAnswer(answers: string, recommendCode?: string) {
    return this.http.post(`api/users/typeTest`, { answers, recommendCode });
  }

  getTypeTestAnswer() {
    return this.http.get(`api/users/typeTest/answer`);
  }

  getParticipantCount(): Observable<number> {
    return this.http.get<number>(`api/users/typeTest/participantCount`);
  }

  getDonationCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`api/users/typeTest/donationCount`);
  }

  getMyDonationCount(): Observable<number> {
    return this.http.get<number>(`api/users/typeTest/my/donationCount`);
  }

  getDonationRankList(): Observable<{ count: number, photo: string, nickname: string }[]> {
    return this.http.get<{ count: number, photo: string, nickname: string }[]>(`api/users/typeTest/donationRank`);
  }

  searchInfluencers(searchDto: PaginationSearchDto): Observable<Pagination<Influencer>> {
    return this.http.post<Pagination<Influencer>>('/api/users/influencers/list', searchDto)
  }

  addPoint(dto: UserAddPointDto) {
    return this.http.post('/api/users/addPoint', dto);
  }
}
