import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { ExpectedPrice, Product, Ticket, TicketBadge } from 'src/app/model/product';
import { CheckDesktopService } from 'src/app/service/check-desktop.service';
import { MessageService } from 'src/app/service/message.service';
import { PaymentService } from 'src/app/service/payment.service';
import { ProductService } from 'src/app/service/product.service';
import { EndStandard } from '../../model/product';
import { TicketService } from './../../service/ticket.service';

@Component({
  selector: 'schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss'],
})
export class ScheduleDetailComponent implements OnInit {
  @Input() product: Product;
  @Input() type: 'ticket-view' | 'ticket-group-view';
  @Input() isAdmin = false;
  @Input() frequency: string;
  @Input() ticket: Ticket;
  @Output() toggleReservationEndEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  isDesktop: boolean;
  EndStandard = EndStandard;
  @ViewChild(BsDaterangepickerDirective, { static: false }) datapickerDirective;
  badges: TicketBadge[];
  expectedPrices: ExpectedPrice[][] = [];
  isReservationEnd: boolean;

  constructor(
    private cds: CheckDesktopService,
    private productService: ProductService,
    private router: Router,
    private messageService: MessageService,
    public ticketService: TicketService,
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.cds.isDesktop.subscribe(resp => this.isDesktop = resp);
    this.paymentService.isTicketReservationEnd(this.ticket.id).subscribe(isTicketReservationEnd => {
      this.isReservationEnd = isTicketReservationEnd;
      this.productService.getTicketBadges({ page: 1, limit: 9999 }).subscribe(resp => {
        this.badges = resp.items;
        this.ticket.badges = (this.ticket.badges as TicketBadge[]).map(b => b.id);
        this.ticket.options = this.ticket.options.map(option => {
          option.badges = (option.badges as TicketBadge[]).map(b => b.id);
          return option;
        })
        if (this.ticket.options.length === 0) {
          this.expectedPrices[0] = this.ticket.endStandard === EndStandard.PER_PERSON ?
            this.ticketService.getPerPersonExpectedPrices(this.ticket.pricePerPerson, this.ticket.prices, this.ticket.maxParticipants)
            : this.ticketService.getPerTeamExpectedPrices(this.ticket.minParticipantsPerTeam, this.ticket.maxParticipantsPerTeam, this.ticket.extraPricePerPerson, this.ticket.pricePerTeam, this.ticket.prices);
        } else {
          this.ticket.options.forEach(option => {
            const list = this.ticket.endStandard === EndStandard.PER_PERSON ?
              this.ticketService.getPerPersonExpectedPrices(option.pricePerPerson, option.prices, option.maxParticipants)
              : this.ticketService.getPerTeamExpectedPrices(option.minParticipantsPerTeam, option.maxParticipantsPerTeam, option.extraPricePerPerson, option.pricePerTeam, option.prices);
            this.expectedPrices.push(list);
          })
        }
      })
    })
  }

  onShowPicker(event) {
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = (hoverEvent) => {
      const { cell, isHovered } = hoverEvent;
      if ((isHovered && !!navigator.platform && /iPad|iPhone|iPod|Apple/.test(navigator.platform)) && 'ontouchstart' in window) {
        (this.datapickerDirective as any)._datepickerRef.instance.daySelectHandler(cell);
      }
      return dayHoverHandler(hoverEvent);
    };
    event.dayHoverHandler = hoverWrapper;
  }

  close() {
    this.closeEvent.emit();
  }

  getStatusBadge(status) {
    return this.productService.getStatusBadge(status);
  }

  toggleReservationEnd(clicked) {
    this.productService.setReservationEndTicket(this.ticket.id, clicked).subscribe(resp => {
      this.toggleReservationEndEvent.emit();
    });
  }

  disableToogleClick() {
    this.messageService.popup({
      title: '예약마감 설정',
      description: '결제 예약으로 인해 마감 되었기 때문에 설정이 불가능합니다.',
      cancelLabel: '확인',
      isNested: true
    });
  }

  copy() {
    this.router.navigate([`${this.isAdmin ? '/admin/schedule/add' : '/host/schedule/add'}`, this.product.id, this.ticket.id]);
    this.close();
  }

  edit() {
    this.messageService.popup({
      title: '티켓 수정',
      description: '이미 결제 완료한 고객에게는 수정 이전의 티켓 내용이 유지됩니다',
      confirmLabel: '진행하기',
      confirmBtnClass: 'secondary',
      confirmHandler: () => {
        this.close();
        this.router.navigate([`${this.isAdmin ? '/admin/schedule/edit/' : '/host/schedule/edit/'}`, this.ticket.group.id])
      },
      isNested: true,
      cancelLabel: '취소'
    })
  }
}
