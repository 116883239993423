import { Injectable } from '@angular/core';
import { ExpectedPrice } from 'src/app/model/product';
import { EndStandard, Ticket } from '../model/product';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(
  ) { }

  getNumberRange(from: number, to: number): number[] {
    if (+to >= +from) {
      return new Array(+to - +from + 1).fill('').map((v, i) => i + +from);
    } else {
      return [];
    }
  }

  getPositionFromRange(number: number, range: number[]) {
    if (range.length === 1) {
      return 0;
    }
    for (let i = 0; i < range.length; i++) {
      if (range[i + 1] !== undefined) {
        if (range[i] <= number && number < range[i + 1]) {
          return i;
        }
      } else {
        return range.length - 1;
      }
    }
  }

  getRange(number, ranges) {
    for (var i = 0; i < ranges.length; ++i) {
      if (number <= ranges[i]) {
        return i;
      }
    }
    return ranges.length - 1;
  }

  getPercent(a, b) {
    return +((b - a) / b * 100).toFixed(1);
  }

  getPerPersonExpectedPrices(pricePerPerson: number, prices: any[], maxParticipants: number): ExpectedPrice[] {
    const priceList = [{ quantity: 1, price: pricePerPerson }, ...prices];
    const quantityList = this.getNumberRange(1, maxParticipants);
    const result = quantityList.map(quantity => {
      const index = this.getPositionFromRange(quantity, priceList.map(p => p.quantity));
      const price = +priceList[index].price;
      const totalPrice = pricePerPerson * quantity;
      const discountPrice = price * quantity;
      const discountPercent = this.getPercent(price, pricePerPerson);
      return {
        quantity,
        price: totalPrice,
        discountPrice,
        discountPercent,
        pricePerPerson: price
      } as ExpectedPrice;
    })
    return result;
  }

  getPerTeamExpectedPrices(minParticipantsPerTeam: number, maxParticipantsPerTeam: number, extraPricePerPerson: number, pricePerTeam: number, prices: any[]): ExpectedPrice[] {
    if (prices?.length > 0) {
      prices = prices.sort((a, b) => {
        return a.quantity < b.quantity ? -1 : 1;
      })
    }
    const pricesTemp = JSON.parse(JSON.stringify(prices));
    if (pricesTemp.length > 1) {
      for (var i = 1; i < minParticipantsPerTeam; i++) {
        pricesTemp.shift();
      }
    }
    const quantityList = this.getNumberRange(+minParticipantsPerTeam, +maxParticipantsPerTeam);
    const result = quantityList.map(quantity => {
      let price = +pricePerTeam + (+extraPricePerPerson * (quantity - +minParticipantsPerTeam));
      let discountPrice = price;
      let discountPercent = 0;
      if (pricesTemp.length > 0) {
        const p = pricesTemp[quantity - +minParticipantsPerTeam] ? pricesTemp[quantity - +minParticipantsPerTeam].price : 0;
        const d = pricesTemp[quantity - +minParticipantsPerTeam] ? +pricesTemp[quantity - +minParticipantsPerTeam].discountPrice : 0;
        if (p > d) {
          discountPercent = this.getPercent(d, p);
          discountPrice = d;
          price = p;
        }
      }
      return {
        quantity,
        price,
        discountPrice,
        discountPercent
      } as ExpectedPrice;
    });
    let min = [];
    for (var i = 1; i < minParticipantsPerTeam; i++) {
      min.push({ ...result[0], quantity: i })
    }
    min.reverse().forEach(m => {
      result.unshift(m)
    })

    return result;
  }

  getTicketPrice(ticket: Ticket, quantity: number): number {
    if (ticket.endStandard === EndStandard.PER_PERSON) {
      const { pricePerPerson, prices, maxParticipants } = ticket;
      return this.getPerPersonExpectedPrices(pricePerPerson, prices, maxParticipants).find(v => v.quantity === quantity).discountPrice;
    } else {
      const { minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices } = ticket;
      return this.getPerTeamExpectedPrices(minParticipantsPerTeam, maxParticipantsPerTeam, extraPricePerPerson, pricePerTeam, prices).find(v => v.quantity === quantity).discountPrice
    }
  }

  setMinPrice(ticket: Ticket) {
    const expectedPrices = ticket.endStandard === EndStandard.PER_PERSON ?
      this.getPerPersonExpectedPrices(ticket.pricePerPerson, ticket.prices, ticket.maxParticipants) :
      this.getPerTeamExpectedPrices(ticket.minParticipantsPerTeam, ticket.maxParticipantsPerTeam, ticket.extraPricePerPerson, ticket.pricePerTeam, ticket.prices);
    if (expectedPrices.length > 0) {
      if (ticket.prices.length > 0) {
        ticket.minPrice = expectedPrices[0];
      }
    }
    return ticket;
  }
}
