import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupConfig } from 'src/app/component/popup/popup.component';
import { SnackBarComponent } from './../component/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  event = new EventEmitter();

  constructor(
    private snackBar: MatSnackBar
  ) { }

  popup(config: PopupConfig) {
    this.event.emit(config);
  }

  toast(message: string, verticalPosition?: 'top' | 'bottom', type?: string, icon?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: { message, type, icon },
      duration: 3500,
      horizontalPosition: 'center',
      verticalPosition: verticalPosition ?? 'bottom',
      panelClass: 'nnb-snack-bar'
    })
  }
}