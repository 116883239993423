import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss'],
})
export class InfinityScrollComponent {
  @Input() loadingThrottle = 1000;
  @Output() onScroll = new EventEmitter();
  isLoading = false;

  constructor() { }

  onScrollHandler() {
    if (!this.isLoading) {
      this.isLoading = true;
      setTimeout(() => {
        this.onScroll.emit();
        this.isLoading = false
      }, this.loadingThrottle)
    }
  }
}
