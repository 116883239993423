<ng-template #popup>
  <div class="popup-container" [ngStyle]="{'border':config.isNested?'1px solid #dee2e6':''}">
    <ng-container *ngIf="!config.template">
      <ng-container *ngIf="!config.type">
        <div *ngIf="config.title" class="title">{{config.title}}</div>
        <img *ngIf="config.image" [src]="config.image" style="width:100%;margin-bottom: 12px;">
        <div *ngIf="config.description" class="description">{{config.description}}</div>
      </ng-container>

      <ng-container *ngIf="config.type === 'error'">
        <img *ngIf="config.image" [src]="config.image" style="margin-bottom: 24px;">
        <div *ngIf="config.title" class="error-title">{{config.title}}</div>
        <div *ngIf="config.description" class="error-description">{{config.description}}</div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="config.template">
      <ng-template [ngTemplateOutlet]="config.template"></ng-template>
    </ng-container>

    <button *ngIf="config.confirmLabel && config.confirmBtnClass" class="popup-btn nnb-btn-lg nnb-btn-block"
      [ngClass]="'nnb-btn-'+config.confirmBtnClass" (click)="confirm()">{{config.confirmLabel}}</button>
    <button class="popup-btn nnb-btn-lg nnb-btn-block nnb-btn-outline"
      (click)="cancel()">{{config.cancelLabel}}</button>
  </div>

  <div *ngIf="config.closeBtnUntilPeriod" style="display:flex;justify-content: flex-end;width: 66%;margin-top: 6px;">
    <div style="color: #ffffff;font-size: 18px;font-weight: 600;cursor: pointer;" (click)="closeUtilPeriod()">
      {{config.closeBtnUntilPeriod.label}}</div>
  </div>
</ng-template>