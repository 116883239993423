import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Coupon, CouponCreateDto, CouponSearchDto } from './../model/coupon';
import { Pagination } from './../model/pagination';
import { UrlService } from './url.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  urlPrefix = UrlService.prefix;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  add(code: string) {
    return this.http.post('/api/coupons/code', { code })
  }

  addCouponToUser(dto: CouponCreateDto) {
    return this.http.post('/api/coupons/user', dto);
  }

  search(dto: CouponSearchDto): Observable<Pagination<Coupon>> {
    const query = this.utilService.setQueryParams(dto);
    return this.http.get<Pagination<Coupon>>(`api/coupons?${query}`);
  }

  addKtoCoupon() {
    return this.http.post('api/coupons/kto', {})
  }

  checkKtoCoupon(checkIn: string, payAmt: number): Observable<{ result: string }> {
    return this.http.post<{ result: string }>('api/coupons/kto/check', {
      checkIn, payAmt
    })
  }
}
