import { Product } from 'src/app/model/product';
import { Payment } from './payment';
import { User } from './user';

export class Feed {
    constructor(
        public id: number,
        public feedPhotos: FeedPhoto[],
        public author: User,
        public contents: string,
        public product: Product,
        public payment: Payment,
        public views: number,
        public createdAt: Date,
        public updatedAt: Date,
        public feedComments: FeedComment[],
        public type: FeedType,
        public title?: string,
        public wellnessCategory?: WellnessCategory,
        public authorName?: string,
        public score?: number,
        public collapseContents?: string,
        public lineCount?: number,
        public isMore?: boolean,
        public likes?: number,
        public isSetLike?: boolean,
        public preview?: string
    ) { }
}

export enum WellnessCategory {
    ALL = '전체',
    EXERCISE = '운동',
    MIND = '마음관리',
    TIME = '시간활용',
    FITNESS = '피트니스다이닝',
    BEAUTY = '뷰티웰빙',
}

export class FeedPhoto {
    constructor(
        public id: number,
        public feed: Feed,
        public photo: string,
        public createdAt: Date,
        public updatedAt: Date
    ) { }
}

export class FeedComment {
    constructor(
        public id: number,
        public user: User,
        public feed: Feed,
        public comment: string,
        public contents: string,
        public parent: FeedComment,
        public children: FeedComment[],
        public createdAt: Date,
        public updatedAt: Date,
    ) { }
}

export class FeedCreateDto {
    constructor(
        public contents: string,
        public productId: number,
        public type: FeedType,
        public paymentId: number,
        public photos?: FeedPhoto[],
        public score?: number,
        public title?: string,
        public wellnessCategory?: WellnessCategory,
    ) { }
}

export interface FeedUpdateDto {
    userId: number,
    photos?: FeedPhotoCreateDto[],
    contents?: string,
    productId?: number,
    paymentId?: number,
    score?: number,
    title?: string,
    wellnessCategory?: WellnessCategory
}

export class FeedPhotoCreateDto {
    constructor(
        public photo: string,
        public file?: File
    ) { }
}

export class FeedCommentCreateDto {
    constructor(
        public feedId: number,
        public comment: string,
        public parentId?: number,
    ) { }
}

export class FeedSearchDto {
    constructor(
        public page: number,
        public limit: number,
        public types: FeedType[],
        public userId?: number,
        public hostId?: number,
        public paymentId?: number,
        public productId?: number,
        public isBusiness?: boolean,
        public hasPhoto?: boolean,
        public analysisHashtags?: string[],
        public bestOrder?: boolean,
        public wellnessCategory?: WellnessCategory
    ) { }
}

export enum FeedType {
    REVIEW = '리뷰',
    COACHING = '건강코칭',
    FUSO = '푸소',
    MISSION = '미션인증'
}