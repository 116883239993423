<button class="nnb-btn-outline nnb-btn-sm" style="border: none;font-size:16px;font-weight: 500;"
  (click)="openShareModal(shareTpl)">공유하기</button>

<ng-template #shareTpl>
  <div class="section-title">공유하기</div>
  <div class="sns-share">
    <div class="sns" (click)="share('kakaotalk')">
      <img class="sns-icon" style="width: 55px;"
        src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/sns-share/kakaotalk.svg">
      <p class="label">카카오톡</p>
    </div>
    <div class="sns" (click)="share('link')">
      <img class="sns-icon" src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/sns-share/link.svg">
      <p class="label">링크복사</p>
    </div>
  </div>
  <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>