<div class="frame" *ngIf="product" style="width: 480px;border:1px solid #e6e6e6">
  <swiper [config]="representationScrollConfigs" *ngIf="product.representationPhotos.length>1">
    <ng-template swiperSlide *ngFor="let photo of product.representationPhotos" style="cursor: pointer;">
      <img [src]="photo.photo" style="width: auto;max-width: 100%;height: auto;max-height: 100%;">
    </ng-template>
  </swiper>
  <img *ngIf="product.representationPhotos.length===1" [src]="product.representationPhotos[0].photo" />
  <main>
    <ng-container *ngIf="product.version === 1">
      <!-- 상품 메뉴 -->
      <div class="menu">
        <div class="top-menu" [ngClass]="'section-introduction-'+product.id"
          (click)="setPosition('introduction',product.id)">
          소개
        </div>

        <div *ngIf="product.courses?.length > 0" class="top-menu" [ngClass]="'section-schedule-'+product.id"
          (click)="setPosition('schedule',product.id)">
          일정
        </div>

        <div *ngIf="product.isBusiness" class="top-menu" [ngClass]="'section-info-'+product.id"
          (click)="setPosition('info',product.id)">
          정보
        </div>

        <div class="top-menu" [ngClass]="'section-place-'+product.id" (click)="setPosition('place',product.id)">
          장소
        </div>

        <div class="top-menu" [ngClass]="'section-feed-'+product.id" (click)="setPosition('feed',product.id)">
          후기
        </div>
      </div>
      <!-- 체크 포인트 & 추천 성향-->
      <div *ngIf="product.id !== 614 && product.id !== 848">
        <div *ngIf="product.point && product.recommend" class="section" [id]="'section-introduction-'+product.id">
          <ng-container *ngIf="product.point">
            <div class="info-box">
              <div class="label">체크 포인트</div>
              <div class="pre-wrap">{{product.point}}</div>
            </div>
          </ng-container>
          <div *ngIf="product.point && product.recommend" style="margin-bottom: 24px;"></div>
          <ng-container *ngIf="product.recommend">
            <div class="info-box">
              <div class="label">추천 성향</div>
              <div class="pre-wrap">{{product.recommend}}</div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="product.recommend" style="margin-bottom: 24px;"></div>
      </div>
      <!-- 소개 -->
      <div>
        <div *ngIf="product.description" [id]="'section-introduction-'+product.id">
          <div class="section">
            <div class="section-title">소개</div>
            <quill-view *ngIf="product.isBusiness" [content]="product.description" format="text" theme="snow"
              [style]="quillStyle">
            </quill-view>
            <p *ngIf="!product.isBusiness" class="pre-wrap">{{product.description}}</p>
            <br>
          </div>
        </div>

      </div>
      <!-- [섹션] 일정 -->
      <ng-container *ngIf="product.id !== 614">
        <div class="section" [id]="'section-schedule-'+product.id" *ngIf="product.courses?.length > 0">
          <div class="section-title">일정</div>
          <div class="course" *ngFor="let course of product.courses">
            <div class="title">{{course.title}}</div>
            <ng-container *ngFor="let schedule of course.schedules">
              <div style="display:flex;gap:9px;min-height: 48px;align-items: center;">
                <div style="display:flex;align-items: center;gap: 8px;">
                  <div class="subTitle">{{schedule.subTitle}}</div>
                  <div style="border-left: 2.5px solid #e5e5e5;height: 23px;"></div>
                </div>
                <div class="content2">{{schedule.content}}</div>
              </div>
              <hr style="margin:12px 0px;">
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- [섹션] 정보  -->
      <div class="section" [id]="'section-info-'+product.id" *ngIf="product.isBusiness">
        <div class="section-title">정보</div>
        <!-- 숙소 -->
        <ng-container *ngIf="product.amenities.length > 0">
          <div class="info-box" style="margin-bottom:24px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 16px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/house-siding.svg">
                <div class="info-title">숙소</div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
              <ul *ngFor="let amenity of product.amenities" style="width: 50%;margin: 7px 0px;padding-left:22px">
                <li>{{amenity.name}}</li>
              </ul>
            </div>
          </div>
        </ng-container>
        <!-- 준비물 -->
        <ng-container *ngIf="product.checkList">
          <div class="info-box" style="margin-bottom:24px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 16px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/shopping-bag.svg">
                <div class="info-title">준비물</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.checkList}}</div>
          </div>
        </ng-container>
        <!-- 포함 사항 -->
        <ng-container *ngIf="product.includeList">
          <div class="info-box" style="margin-bottom:24px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 16px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/type99.svg">
                <div class="info-title">포함 사항</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.includeList}}</div>
          </div>
        </ng-container>
        <!-- 불포함 사항 -->
        <ng-container *ngIf="product.excludeList">
          <div class="info-box" style="margin-bottom:24px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 16px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_box.svg">
                <div class="info-title">불포함 사항</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.excludeList}}</div>
          </div>
        </ng-container>
        <!-- 유의사항 -->
        <ng-container *ngIf="product.notice">
          <div class="info-box" style="margin-bottom:24px" (click)="openBm(noticeTpl)">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <div class="label" style="margin-bottom:0px">
                <div style="display: flex;gap:8px;">
                  <img
                    src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_alert-triangle.svg">
                  <div class="info-title">유의 사항</div>
                </div>
              </div>
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right.svg"
                class="nnb-icon">
            </div>
          </div>
        </ng-container>
        <!-- 환불정책 -->
        <ng-container *ngIf="product.price !== 0">
          <div class="info-box" (click)="openBm(refundTpl)">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <div class="label" style="margin-bottom:0px">
                <div style="display: flex;gap:8px;">
                  <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/cash-banknote.svg">
                  <div class="info-title">환불 정책</div>
                </div>
              </div>
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right.svg"
                class="nnb-icon">
            </div>
          </div>
        </ng-container>
      </div>
      <!-- [섹션] 장소  -->
      <div class="section" [id]="'section-place-'+product.id">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 16px;">
          <div class="section-title" style="margin:0px">장소</div>
          <div *ngIf="product.isOffline">
            <button class="nnb-btn-secondary nnb-btn-md">
              주소 복사</button>
          </div>
        </div>
        <div [id]="'product-detail-map-' + product.id"
          style="height:15.313rem;border: 1px solid #e2e2e2;border-radius: 4px;"></div>
        <ng-container *ngIf="product.isOffline">
          <div style="display:flex;gap:8px;margin-top:24px;">
            <img [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/map-2.svg'">
            <div>
              <div>{{product.address}}</div>
              <div>{{product.detailAddress}}</div>
            </div>
          </div>
          <button *ngIf="product.howToCome" class="nnb-btn-outline nnb-btn-lg nnb-btn-block" style="margin-top: 24px;"
            (click)="openBm(howToGoTpl)">대중교통 이용방법</button>
        </ng-container>
        <ng-container *ngIf="!product.isOffline">
          <div>온라인 모임입니다.</div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="product.version === 2">
      <!-- 상품 메뉴 -->
      <div class="menu">
        <div class="top-menu" [ngClass]="'section-introduction-'+product.id"
          (click)="setPosition('introduction',product.id)">
          상세
        </div>
        <div *ngIf="product.courses?.length > 0" class="top-menu" [ngClass]="'section-schedule-'+product.id"
          (click)="setPosition('schedule',product.id)">
          일정
        </div>
        <div *ngIf="product.priceImage" class="top-menu" [ngClass]="'section-price-'+product.id"
          (click)="setPosition('price',product.id)">
          가격
        </div>
        <div class="top-menu" [ngClass]="'section-place-'+product.id" (click)="setPosition('place',product.id)">
          장소
        </div>
        <div *ngIf="product.isBusiness" class="top-menu" [ngClass]="'section-info-'+product.id"
          (click)="setPosition('info',product.id)">
          안내/정책
        </div>
        <div class="top-menu" [ngClass]="'section-feed-'+product.id" (click)="setPosition('feed',product.id)">
          후기
        </div>
      </div>
      <div class="section" style="padding:32px 0px" [id]="'section-introduction-'+product.id">
        <!-- 추천 성향 -->
        <div *ngIf="product.recommend">
          <div style="padding:16px 16px 40px 16px">
            <div style="font-size: 18px;font-weight: 500;margin-bottom:4px">추천 성향</div>
            <div class="pre-wrap">{{product.recommend}}</div>
          </div>
        </div>
        <!-- 기타 정보 -->
        <div style="padding: 0px 16px" *ngIf="product.isBusiness && product.description">
          <quill-view [content]="product.description" format="text" theme="snow" [style]="quillStyle"></quill-view>
        </div>
        <!-- 상세 요약 -->
        <div style="margin-bottom:48px">
          <div style="font-size: 22px;font-weight: 600;padding:16px;margin-bottom:16px">상세요약</div>
          <div *ngFor="let summary of product.summaries; let index=index;">
            <div style="padding:24px 16px" [ngStyle]="{background: index%2===0?'#F7F7F8':''}">
              <div style="display:flex;gap:8px;align-items: center;margin-bottom: 8px;">
                <div style="display: flex;justify-content: center;align-items: center;
                            background: #340879;color:white;font-weight:700;border-radius: 4px;width:28px;height:28px">
                  {{index+1|number:'2.0'}}</div>
                <div style=" font-size: 20px;font-weight: 500;">{{summary.title}}</div>
              </div>
              <div style="font-size: 18px;font-weight: 400;margin-bottom: 8px">{{summary.description}}</div>
              <img [src]="summary.photos[0].photo" style="width: 100%;height: 180px;object-fit: cover;">
            </div>
          </div>
        </div>
        <!-- 상세 -->
        <div *ngIf="product.sections">
          <div *ngFor="let section of product.sections" style="margin-bottom: 32px;">
            <div style="font-size: 22px;font-weight: 600;padding: 16px;margin-bottom: 16px;line-height: 100%;">
              {{section.title}}
            </div>
            <div *ngFor="let paragraph of section.paragraphs" style="margin-bottom: 32px;">
              <div style="padding:8px 16px;margin-bottom: 16px;">
                <div style="width:40px;height:6px;background: #D2D2DE;margin-bottom:8px"></div>
                <div style="font-size: 20px;font-weight: 600;line-height: 150%;">
                  {{paragraph.title}}</div>
                <div *ngIf="paragraph.time" style="margin-top: 8px;padding: 7px 10px;border-radius: 60px;border:1px solid #D2D2DE;
                            display:inline-flex;gap:4px;align-items: center;">
                  <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/clock.svg"
                    style="width:20px;height:20px">
                  <div>{{paragraph.time}}</div>
                </div>
              </div>
              <ng-container *ngIf="paragraph.photos.length > 1">
                <swiper [navigation]="true" [config]="{pagination: {
                            type: 'bullets',
                            dynamicBullets: true,
                            dynamicMainBullets: 3
                          }}">
                  <ng-template swiperSlide *ngFor="let photo of paragraph.photos">
                    <img [src]="photo.photo" class="photo">
                    <div style="height:24px"></div>
                  </ng-template>
                </swiper>
              </ng-container>
              <ng-container *ngIf="paragraph.photos.length === 1">
                <img [src]="paragraph.photos[0].photo" class="photo">
              </ng-container>
              <div class="pre-wrap"
                style="margin-top: 16px;padding:8px 16px;margin-bottom: 16px;font-size: 18px;font-weight: 400;line-height: 150%;">
                {{paragraph.description}}
              </div>
              <div *ngIf="paragraph.note" style="padding:0px 16px 16px 16px;">
                <div class="pre-wrap"
                  style="font-size: 16px;font-weight: 400;line-height: 160%;padding: 16px;border-radius: 4px;background: #F2F3F8;">
                  {{paragraph.note}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- [섹션] 일정 -->
      <div class="section" [id]="'section-schedule-'+product.id" *ngIf="product.courses?.length > 0">
        <div style="font-size: 20px;font-weight: 500;margin-bottom:16px">일정</div>
        <div class="course" *ngFor="let course of product.courses let last=last;">
          <div style="display:flex;align-items: center;gap:8px;margin-bottom:8px">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/dot-badge.png">
            <div style="font-size: 18px;font-weight: 500;">{{course.title}}</div>
          </div>
          <div style="border-left: 1.4px solid #E8E8EF;padding-left: 16px;">
            <div *ngFor="let schedule of course.schedules" style="padding:12px 0px;border-bottom: 1.4px solid #E8E8EF">
              <div style="display:flex;align-items: center;gap:4px;margin-bottom:8px">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/clock.svg"
                  style="width:17px;height:17px">
                <div style="font-size: 16px;font-weight: 500;">{{schedule.subTitle}}</div>
              </div>
              <div style="font-size: 16px;font-weight: 500;padding-left: 20px;">{{schedule.content}}</div>
              <div *ngIf="schedule.note" class="pre-wrap"
                style="font-size: 15px;line-height: 140%;color:#5B6371;padding-left: 20px;margin-top:4px">
                <div>{{schedule.note}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="!last" style="height:32px"></div>
        </div>
      </div>
      <!-- [섹션] 가격 -->
      <div class="section" [id]="'section-price-'+product.id" *ngIf="product.priceImage">
        <div style="font-size: 20px;font-weight: 500;margin-bottom:16px">가격</div>
        <img [src]="product.priceImage">
      </div>
      <!-- [섹션] 장소  -->
      <div class="section" [id]="'section-place-'+product.id">
        <div style="font-size: 20px;font-weight: 500;margin-bottom:16px">장소</div>
        <ng-container *ngIf="product.isOffline">
          <div [id]="'product-detail-map-' + product.id"
            style="height:15.313rem;border: 1px solid #e2e2e2;border-radius: 8px;"></div>
          <div style="display:flex;gap:8px;align-items: center;margin-bottom:4px;margin-top:16px">
            <img [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/map-pin-off.svg'">
            <div style="font-size: 18px;font-weight: 500;">주소</div>
          </div>
          <ul style="margin-bottom: 8px;padding-left:27px">
            <li>{{product.address}}, {{product.detailAddress}}</li>
          </ul>
          <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block">주소 복사</button>
          <ng-container *ngIf="product.howToCome">
            <div style="display:flex;gap:8px;align-items: center;margin-bottom:8px;margin-top:16px">
              <img [src]="'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/map-2.svg'">
              <div style="font-size: 18px;font-weight: 500;">오시는 방법</div>
            </div>
            <div class="pre-wrap" style="padding:16px;border-radius: 4px;background:#F2F3F8">
              {{product.howToCome}}
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!product.isOffline">
          <div>온라인 모임입니다.</div>
        </ng-container>
      </div>
      <!-- [섹션] 안내/정책  -->
      <div class="section" [id]="'section-info-'+product.id">
        <div style="font-size: 20px;font-weight: 500;margin-bottom:16px">안내/정책</div>
        <!-- 숙소 -->
        <ng-container *ngIf="product.amenities.length > 0">
          <div style="margin-bottom:40px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 4px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/house-siding.svg">
                <div style="font-size: 18px;font-weight: 500;">숙소</div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
              <ul *ngFor="let amenity of product.amenities" style="width: 50%;margin: 7px 0px;padding-left:22px">
                <li>{{amenity.name}}</li>
              </ul>
            </div>
          </div>
        </ng-container>
        <!-- 준비물 -->
        <ng-container *ngIf="product.checkList">
          <div style="margin-bottom:40px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 4px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/brand-appgallery.svg">
                <div style="font-size: 18px;font-weight: 500;">준비물</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.checkList}}</div>
          </div>
        </ng-container>
        <!-- 포함 사항 -->
        <ng-container *ngIf="product.includeList">
          <div style="margin-bottom:40px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 16px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/type99.svg">
                <div style="font-size: 18px;font-weight: 500;">포함사항</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.includeList}}</div>
          </div>
        </ng-container>
        <!-- 불포함 사항 -->
        <ng-container *ngIf="product.excludeList">
          <div style="margin-bottom:40px">
            <div class="label">
              <div style="display: flex;gap:8px;margin-bottom: 4px;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_box.svg">
                <div style="font-size: 18px;font-weight: 500;">불포함 사항</div>
              </div>
            </div>
            <div class="pre-wrap" style="padding:10px 10px 0px 3px">{{product.excludeList}}</div>
          </div>
        </ng-container>
        <!-- 유의사항 -->
        <ng-container *ngIf="product.notice">
          <div style="padding:16px;border-radius: 4px;background:#F2F3F8;">
            <div style="font-weight:700;margin-bottom:4px">유의사항</div>
            <div class="pre-wrap">{{product.notice}}</div>
          </div>
        </ng-container>
        <!-- 환불정책 -->
        <ng-container *ngIf="product.price !== 0">
          <div style="margin-top:16px;padding:14px 16px;border-radius: 8px;border:1px solid #E8E8EF;"
            (click)="openBm(refundTpl)">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <div style="font-size: 18px;font-weight: 500;">환불 정책</div>
              <div style="display:flex;gap:4px;align-items: center;">
                <div style="color:#5B6371;font-size: 18px;font-weight: 500;">보기</div>
                <img class="nnb-icon"
                  src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right-grey-800.svg">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </main>
</div>

<ng-template #howToGoTpl>
  <div class="section-title">오시는 방법</div>
  <div class="pre-wrap" style="margin-bottom:24px"> {{product.howToCome}}</div>
  <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #noticeTpl>
  <div class="section-title">유의 사항</div>
  <div class="pre-wrap" style="margin-bottom:24px"> {{product.notice}}</div>
  <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #refundTpl>
  <div class="section-title">환불 정책</div>
  <div class="pre-wrap" style="margin-bottom: 16px;">{{product.refundPolicy}}</div>
  <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>