import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Category } from 'src/app/model/category';
import { Amenity, CategorySearchDto, HashtagSearchDto, ProductConfirm, Ticket, TicketBadge, TicketCreateBulkDto, TicketSearchDto } from 'src/app/model/product';
import { Coupon } from '../model/coupon';
import { Pagination, PaginationSearchDto } from '../model/pagination';
import { Hashtag, Product, ProductConfirmCreateDto, ProductCreateDto, ProductManageDto, ProductSearchDto, ProductStatus, ProductUpdateDto } from '../model/product';
import { TicketGroup } from './../model/product';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  urlPrefix = UrlService.prefix;

  constructor(
    private http: HttpClient
  ) { }

  addProduct(dto: ProductCreateDto): Observable<Product> {
    return this.http.post<Product>(`/api/products`, dto);
  }

  updateProductSortOrders(dto: { id: number, sortOrder: number }[]) {
    return this.http.put(`/api/products/management/sort/order`, dto)
  }

  updateProduct(id: number, dto: ProductUpdateDto): Observable<Product> {
    return this.http.put<Product>(`/api/products/${id}`, dto);
  }

  manageProduct(id: number, dto: ProductManageDto) {
    return this.http.put(`/api/products/management/${id}`, dto)
  }

  getRefinedProduct(productId: number, userId?: number): Observable<Product> {
    if (userId) return this.http.get<Product>(`${this.urlPrefix}/products/product/refined/${productId}/user/${userId}`)
    else return this.http.get<Product>(`${this.urlPrefix}/products/product/refined/${productId}`)
  }

  // TODO: 점진적으로 getRefinedProduct로 변경해야함 또는 order, payment가 있어야하는 곳에서만 사용해야함
  getProduct(productId: number, includeOld?: boolean): Observable<Product> {
    return this.http.get<Product>(`${this.urlPrefix}/products/product/${productId}?includeOld=${includeOld}`)
  }

  addViewCount(productId: number, userId?: number): Observable<any> {
    return this.http.post<any>(`${this.urlPrefix}/products/product/viewCount`, { productId, userId })
  }

  getProductWithAllTickets(productId: number): Observable<Product> {
    return this.http.get<Product>(`${this.urlPrefix}/products/product/with-all-tickets/${productId}`)
  }

  search(search: ProductSearchDto): Observable<Pagination<Product>> {
    return this.http.post<Pagination<Product>>(`/api/products/search`, search);
  }

  searchByTerm(search: ProductSearchDto): Observable<Pagination<Product>> {
    return this.http.post<Pagination<Product>>(`/api/products/searchByTerm`, search);
  }

  deleteProduct(id: number) {
    return this.http.delete(`/api/products/${id}`);
  }

  getLikeProducts(search: PaginationSearchDto): Observable<Pagination<Product>> {
    return this.http.get<Pagination<Product>>(`/api/products/likes?page=${search.page}&limit=${search.limit}`)
  }

  getStatusBadge(status) {
    switch (status) {
      case ProductStatus.ALL: return { badge: 'blue' };
      case ProductStatus.ENTERED: return { badge: 'green' };
      case ProductStatus.DISABLED: return { badge: 'dark-grey' };
      case ProductStatus.TEST: return { badge: 'orange' };
      case ProductStatus.END: return { badge: 'outline' };
    }
  }

  searchHashtag(search: HashtagSearchDto): Observable<Pagination<Hashtag>> {
    return this.http.post<Pagination<Hashtag>>(`/api/hashtags`, search);
  }

  searchCategory(search: CategorySearchDto): Observable<Pagination<Category>> {
    return this.http.post<Pagination<Category>>(`/api/categories`, search);
  }

  searchAmenity(search: PaginationSearchDto): Observable<Pagination<Amenity>> {
    return this.http.post<Pagination<Amenity>>(`/api/products/search/amenity`, search);
  }

  canReservation(optionDate: string, reservationHours: number): boolean {
    const now = moment();
    const reservationTime = moment(optionDate).subtract(reservationHours, 'hour');
    const canReservation = now.isBefore(reservationTime);
    return canReservation;
  }

  confirmOrReject(dto: ProductConfirmCreateDto): Observable<ProductConfirm> {
    return this.http.post<ProductConfirm>('/api/product-confirm', dto)
  }

  getTotalViewsByMonth(year: number): Observable<{ product: Product, data: { year: number, month: number, totalViews: number, }[] }[]> {
    return this.http.post<{ product: Product, data: { year: number, month: number, totalViews: number, }[] }[]>(`/api/products/stat/total_views_by_month`, { year });
  }

  getRecommendSearchTerm(): Observable<{ id: number, term: string }[]> {
    return this.http.get<{ id: number, term: string }[]>(`/api/products/recommend_search_term`);
  }

  updateRecommendSearchTerm(value) {
    return this.http.post(`/api/products/recommend_search_term`, value);
  }

  getAvailableTicketDates(id: number): Observable<{ date: Date, isClosed: boolean, isReservationEnd: boolean, product?: Product }[]> {
    return this.http.get<{ date: Date, isClosed: boolean, isReservationEnd: boolean, product?: Product }[]>(`/api/products/tickets/dates/${id}`)
  }

  getTicketByDate(productId: number, date: string): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(`/api/products/tickets/product/${productId}/date/${date}`)
  }

  addTickets(dto: TicketCreateBulkDto) {
    return this.http.post(`/api/products/tickets`, dto);
  }

  editTickets(groupId: number, dto: TicketCreateBulkDto) {
    return this.http.put(`/api/products/tickets/${groupId}`, dto);
  }

  getTicketBadges(search: PaginationSearchDto): Observable<Pagination<TicketBadge>> {
    return this.http.post<Pagination<TicketBadge>>(`/api/products/tickets/search/badge`, search);
  }

  searchTickets(search: TicketSearchDto): Observable<Ticket[]> {
    return this.http.post<Ticket[]>(`/api/products/tickets/search`, search);
  }

  getTicketGroups(productId: number, isOld: boolean, isActivated?: boolean, isDeactivated?: boolean): Observable<TicketGroup[]> {
    return this.http.post<TicketGroup[]>(`/api/products/tickets/groups`, { productId, isOld, isActivated, isDeactivated });
  }

  getTicketGroup(groupId: number): Observable<TicketGroup> {
    return this.http.get<TicketGroup>(`/api/products/tickets/group/${groupId}`);
  }

  activateTicketGroup(groupId: number, isActivate: boolean) {
    return this.http.post(`/api/products/tickets/group/activate`, { groupId, isActivate });
  }

  deleteTicketGroup(groupId: number) {
    return this.http.delete(`/api/products/tickets/group/${groupId}`);
  }

  setReservationEndTicket(ticketId: number, isReservationEnd: boolean) {
    return this.http.post(`/api/products/tickets/reservationEnd`, { ticketId, isReservationEnd });
  }

  setCloseTicket(productId: number, date: Date, isClosed: boolean) {
    return this.http.post(`/api/products/tickets/close`, { productId, date, isClosed });
  }

  getTicket(id: number) {
    return this.http.get<Ticket>(`/api/products/tickets/${id}`)
  }

  getTicketByGroup(groupId: number, isOld?: boolean) {
    return this.http.post<Ticket>(`/api/products/tickets/group`, { groupId, isOld })
  }

  getChildrenCategory(categoryId): Observable<Category[]> {
    return this.http.get<Category[]>(`/api/categories/${categoryId}/children`)
  }

  getCoupons(productId: number, canDownload?: boolean): Observable<Coupon[]> {
    return this.http.post<Coupon[]>(`/api/products/coupons/byProduct`, { productId, canDownload });
  }
}