import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.authService.isAuthenticated();
    //TODO 유효하지 않을 경우 Refresh Token으로 Access Token 재발급 요청
    if (currentUser) {
      return true;
    }
    this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
