import { Injectable } from '@angular/core';
declare var ChannelIO;

@Injectable({
  providedIn: 'root'
})
export class ChannelService {
  constructor() { }

  boot(settings) {
    ChannelIO('boot', settings);
  }

  track(eventType: ChannelTrackEventType, eventProperty) {
    ChannelIO('track', eventType, eventProperty);
  }

  updateUser(eventProperty) {
    ChannelIO('updateUser', eventProperty);
  }

  openChat() {
    ChannelIO('showMessenger');
  }
}

export enum ChannelTrackEventType {
  PURCHASE = 'Purchase',
  FEED_ADD = 'FeedAdd',
  SIGNUP = 'Signup'
}