<div class="frame">
    <ng-container *ngIf="ticket">
        <!-- 상품 정보 -->
        <ng-container *ngIf="type === 'ticket-view'">
            <div class="top-menu">
                <div style="display:flex;justify-content:space-between">
                    <div style="font-size:20px;font-weight:400">{{ticket.date|date:'YYYY년 M월 d일 티켓'}}</div>
                    <button class="nnb-btn-sm nnb-btn-outline" (click)="close()">닫기</button>
                </div>
            </div>
            <div class="section-md">
                <div class="reservation-end-container" style="margin-bottom:24px" *ngIf="!ticket.isOld">
                    <div>
                        <div style="font-size:18px;font-weight:500">예약 마감 설정하기</div>
                        <div style="display:flex;align-items:center;gap:4px;">
                            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/info-circle.svg"
                                style="width:14px;height:14px">
                            <div class="desc" style="font-size:15px">해당 일자 상품이 완판되었다면 예약 마감 상태를 변경하실 수 있습니다.</div>
                        </div>
                    </div>
                    <div>
                        <mat-slide-toggle *ngIf="!isReservationEnd" [checked]="ticket.isReservationEnd"
                            (change)="toggleReservationEnd($event.checked)"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="isReservationEnd" [checked]="ticket.isReservationEnd"
                            [disabled]="isReservationEnd" (click)="disableToogleClick()"></mat-slide-toggle>
                    </div>
                </div>
                <div class="heading-1" style="margin-bottom:16px">티켓의 상품입니다.</div>
                <div class="product" *ngIf="product">
                    <img class="img" [src]="product.representationPhotos[0].photo">
                    <div>
                        <div class="title">{{product.title}}</div>
                        <div class="nnb-badge" [ngClass]="'nnb-badge-'+getStatusBadge(product.status).badge">
                            {{product.status}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'ticket-group-view' && ticket">
            <div class="top-menu">
                <div style="display:flex;justify-content:space-between">
                    <div style="font-size:20px;font-weight:400">상품 티켓 보기</div>
                    <div style="display:flex;gap:8px">
                        <button class="nnb-btn-sm nnb-btn-outline" (click)="close()">닫기</button>
                        <button class="nnb-btn-sm nnb-btn-primary" (click)="copy()">복제하기</button>
                        <button class="nnb-btn-sm nnb-btn-primary" *ngIf="!ticket.isOld" (click)="edit()">수정하기</button>
                    </div>
                </div>
            </div>
            <div class="section-md">
                <div class="heading-1" style="margin-bottom:16px">티켓의 상품입니다.</div>
                <div class="product" *ngIf="product">
                    <img class="img" [src]="product.representationPhotos[0].photo">
                    <div>
                        <div class="title">{{product.title}}</div>
                        <div class="nnb-badge" [ngClass]="'nnb-badge-'+getStatusBadge(product.status).badge">
                            {{product.status}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- 일정 선택하기 -->
        <div class="section-md">
            <div class="heading-1">일정 선택하기</div>
            <div style="margin-bottom:72px">
                <div class="heading-2">1. 티켓 판매 기간을 선택하세요. <span class="desc">(필수)</span>
                </div>
                <input value="{{ticket.group.from|date:'YYYY-MM-dd'}} ~ {{ticket.group.to|date:'YYYY-MM-dd'}}" disabled
                    class="form-control" style="width:250px">
            </div>

            <div style="margin-bottom: 72px;">
                <div class="heading-2">2. 일주일에 몇 번 판매할까요? <span class="desc">(필수)</span></div>
                <input value="{{ticket.group.weekString}}" disabled class="form-control" style="width:250px">
            </div>

            <div class="heading-2">3. 몇 시에 시작할까요? <span class="desc">(필수)</span></div>
            <div style="display:flex;gap:8px;align-items: center;">
                <input value="{{ticket.group.hours}}시 {{ticket.group.minutes}}분" disabled class="form-control"
                    style="width:250px">
            </div>
        </div>

        <!-- 판매 단위 선택하기 -->
        <div class="section-md">
            <div class="endStandard">
                <div class="heading-1">판매 단위 선택하기</div>
                <div class="radio-box-container"
                    [ngClass]="{'end-standard-active':ticket.endStandard === EndStandard.PER_PERSON}">
                    <div class="title">인원</div>
                    <div class="desc">티켓 <strong>판매 단위가 '1명, 10명과 같은 인원 수'</strong></div>
                    <div class="desc">예시 1) 저희 상품은 하루에 최소 2명에서 최대 10명의 고객을 모실 수 있습니다.</div>
                </div>
                <div class="radio-box-container"
                    [ngClass]="{'end-standard-active':ticket.endStandard === EndStandard.PER_TEAM}">
                    <div class="title">팀</div>
                    <div class="desc">티켓 <strong>판매 단위가 '1팀, 10팀과 같은 팀 수'</strong></div>
                    <div class="desc">예시 1) 저희 숙소에는 방이 3개 있어 하루에 3팀을 수용할 수 있습니다.</div>
                    <div class="desc">예시 2) 저희 수업은 최대 인원 10명인 2팀을 동시에 수용할 수 있습니다.</div>
                </div>
            </div>
        </div>

        <!-- 티켓 기본 정보 입력하기 -->
        <div class="section-md">
            <div class="heading-1">티켓 기본 정보 입력하기</div>
            <div style="margin-bottom: 72px;">
                <div class="heading-2">1. 티켓명을 입력하세요. <span class="desc">(필수)</span></div>
                <input value="{{ticket.name}}" disabled class="form-control" style="width:250px">
            </div>

            <div style="margin-bottom: 24px">
                <div class="heading-2">2. 티켓 특징을 최대 3개 선택해주세요. <span class="desc">(선택)</span></div>
                <div style="display: flex;flex-wrap: wrap;gap: 16px;">
                    <div *ngFor="let badge of badges;">
                        <div class="chips" [ngClass]="{'chip-activate':ticket.badges.includes(badge.id)}"
                            style="display:flex;align-items:center;gap:3px;">
                            <div>{{badge.name}}</div>
                            <img *ngIf="ticket.badges.includes(badge.id)"
                                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg"
                                style="width: 16px;">
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-bottom: 72px">
                <div class="heading-3">호스트님만의 강조하고 싶은 티켓의 특징이 있다면 남겨주세요.</div>
                <textarea rows="4" value="{{ticket.requestBadgeDescription}}" disabled class="form-control"></textarea>
            </div>

            <div style="margin-bottom: 72px">
                <div class="heading-2">3. 티켓에 대한 한 줄 설명 작성하기 <span class="desc">(선택)</span></div>
                <div style="margin-bottom:12px">
                    <div *ngFor="let desc of ticket.descriptions; let index = index;">
                        <input value="{{desc}}" disabled class="form-control">
                    </div>
                </div>
            </div>
        </div>

        <!-- 옵션 기본 정보 입력하기 -->
        <ng-container *ngIf="ticket.options.length > 0">
            <div *ngFor="let option of ticket.options; let optionIndex = index;">
                <div class="section-md">
                    <div style="display:flex;justify-content: space-between;">
                        <div class="heading-1">옵션 {{optionIndex+1}}번</div>
                    </div>
                    <div class="heading-1">옵션 기본 정보 입력하기</div>
                    <div style="margin-bottom: 72px;">
                        <div class="heading-2">1. 옵션명을 입력하세요. <span class="desc">(필수)</span></div>
                        <input value="{{option.name}}" disabled class="form-control" style="width:250px">
                    </div>

                    <div style="margin-bottom: 24px">
                        <div class="heading-2">2. 옵션 특징을 최대 3개 선택해주세요. <span class="desc">(선택)</span></div>
                        <div style="display: flex;flex-wrap: wrap;gap: 16px;">
                            <div *ngFor="let badge of badges;">
                                <div class="chips" [ngClass]="{'chip-activate':option.badges.includes(badge.id)}"
                                    style="display:flex;align-items:center;gap:3px;">
                                    <div>{{badge.name}}</div>
                                    <img *ngIf="option.badges.includes(badge.id)"
                                        src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-purple.svg"
                                        style="width: 16px;">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div class="heading-3">호스트님만의 강조하고 싶은 옵션의 특징이 있다면 남겨주세요.</div>
                        <textarea rows="4" value="{{option.requestBadgeDescription}}" disabled
                            class="form-control"></textarea>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div class="heading-2">3. 옵션에 대한 한 줄 설명 작성하기 <span class="desc">(선택)</span></div>
                        <div style="margin-bottom:12px">
                            <div *ngFor="let desc of option.descriptions; let index = index;">
                                <input value="{{desc}}" disabled class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 옵션 인원 단위 가격 설정 -->
                <div *ngIf="ticket.endStandard === EndStandard.PER_PERSON">
                    <div class="section-md">
                        <div class="heading-1">인원 단위 가격 설정</div>
                        <div style="margin-bottom:72px">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>1. 하루에 최대</div>
                                <input value="{{option.maxParticipants}}" disabled class="form-control"
                                    style="width:100px">
                                <div>명의 고객을 모실 수 있습니다.</div>
                            </div>
                            <div class="desc">최대 인원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                        </div>

                        <div style="margin-bottom: 72px">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>2. 시작 가능 최소 인원은</div>
                                <input value="{{option.minParticipants}}" disabled class="form-control"
                                    style="width:100px">
                                <div>명 입니다.</div>
                            </div>
                            <div class="desc">모임 진행을 위한 최소 인원 수 입니다.</div>
                        </div>

                        <div style="margin-bottom: 72px">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>3. 1인 기본 가격은</div>
                                <input value="{{option.pricePerPerson}}" disabled class="form-control"
                                    style="width:100px">
                                <div>원 입니다.</div>
                            </div>
                            <div class="desc">입력하신 1인 기본 가격을 기준으로 인원별 금액 범위를 별도로 설정할 수 있습니다.</div>
                        </div>

                        <div class="heading-2" style="margin-bottom:4px">4. 가격 범위 별도 설정하기 <span class="desc">(선택)</span>
                        </div>
                        <div class="desc" style="margin-bottom: 24px">원하는 인원 수 만큼 가격을 별도로 설정할 수 있습니다.</div>
                        <div class="prices" *ngIf="option.prices.length>0">
                            <div *ngFor="let price of option.prices; let index = index; let last=last;">
                                <div style="display:flex;gap:8px;align-items:center;;flex-wrap: wrap;"
                                    [ngStyle]="{'margin-bottom':last?'':'16px'}">
                                    <input value="{{price.quantity}}" disabled class="form-control" style="width:100px">
                                    <div>인 이상 시 1인 가격은</div>
                                    <input value="{{price.price}}" disabled class="form-control" style="width:100px">
                                    <div>원 입니다.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="padding: 24px">
                        <div class="heading-1">인당 티켓 가격 미리보기</div>
                        <div class="desc" style="margin-bottom: 16px">입력하신 인원과 가격 기준으로 계산한 인원당 티켓 가격입니다.</div>
                        <div style="background: #fff;padding: 24px 16px; border-radius: 12px;" *ngIf="expectedPrices">
                            <div *ngFor="let price of expectedPrices[optionIndex]; let last=last"
                                style="display:flex;justify-content:space-between;"
                                [ngStyle]="{'margin-bottom':last?'':'24px'}">
                                <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                                <div style="display:flex;gap:8px">
                                    <div>{{price.pricePerPerson|number}}원 X {{price.quantity|number}}명 = </div>
                                    <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원
                                    </div>
                                    <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                        *ngIf="price.discountPercent == 0">정가</div>
                                    <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                        *ngIf="price.discountPercent != 0">
                                        {{price.discountPercent|number}}% 할인
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="ticket.endStandard === EndStandard.PER_TEAM">
                    <div class="section-md">
                        <div class="heading-1" style="margin-bottom:4px">팀 단위 가격 설정</div>
                        <div style="margin-bottom: 72px">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>1. 하루에 최대</div>
                                <input value="{{option.maxTeamCapacity}}" disabled class="form-control"
                                    style="width:100px">
                                <div>팀을 수용할 수 있습니다.</div>
                            </div>
                            <div class="desc" style="margin-bottom:24px">최대 팀원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                            <div class="min-max-container">
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>한 팀의 최소 수용 인원은</div>
                                    <input value="{{option.minParticipantsPerTeam}}" disabled class="form-control"
                                        style="width:100px">
                                    <div>명 입니다.</div>
                                </div>
                                <div class="desc" style="margin-bottom:40px">모임 진행을 위한 최소 인원 수 입니다.</div>
                                <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                    class="heading-2">
                                    <div>한 팀의 최대 수용 인원은</div>
                                    <input value="{{option.maxParticipantsPerTeam}}" disabled class="form-control"
                                        style="width:100px">
                                    <div>명 입니다.</div>
                                </div>
                                <div class="desc">모임 진행을 위한 최대 인원 수 입니다.</div>
                            </div>
                        </div>

                        <div style="margin-bottom: 32px">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;margin-bottom:40px"
                                class="heading-2">
                                <div>2. 1팀의 시작 가격은</div>
                                <input value="{{option.pricePerTeam}}" disabled class="form-control"
                                    style="width:100px">
                                <div>원 입니다.</div>
                            </div>
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>한 명 당 추가 금액은</div>
                                <input value="{{option.extraPricePerPerson}}" disabled class="form-control"
                                    style="width:100px">
                                <div>원 입니다.</div>
                            </div>
                            <div class="desc" style="margin-bottom:40px">입력하신 1팀 시작 가격과 추가 금액을 기준으로 인원별 금액을 계산합니다.</div>
                        </div>

                        <div class="price-container">
                            <table class="table price-table">
                                <tr>
                                    <td style="font-weight: 500;padding:8px 8px 8px 2px">인원</td>
                                    <td style="font-weight: 500;padding:8px 8px 8px 2px">정가</td>
                                    <td style="font-weight: 500;padding:8px 8px 8px 2px">할인가</td>
                                    <td style="font-weight: 500;padding:8px">할인율</td>
                                </tr>
                                <tr *ngFor="let price of option.prices; let index = index; let last=last;">
                                    <ng-container *ngIf="index >= +option.minParticipantsPerTeam-1">
                                        <td>{{price.quantity}}명 가격</td>
                                        <td>{{price.price|number}}원</td>
                                        <td>{{price.discountPrice|number}}원</td>
                                        <td style=" padding: 2px 2px 2px 8px">
                                            <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                                style="margin-top: 10px" *ngIf="price.price == price.discountPrice"> 정가
                                            </div>
                                            <div class="nnb-badge nnb-badge-sm nnb-badge-blue" style="margin-top: 10px"
                                                *ngIf="price.price > price.discountPrice">
                                                {{ticketService.getPercent(price.discountPrice, price.price)}}% 할인
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style="padding: 24px">
                        <div class="heading-1">한 팀 티켓 가격 미리보기</div>
                        <div class="desc" style="margin-bottom: 16px">한 팀 기준으로 계산한 인원당 티켓 가격입니다.</div>
                        <div style="background: #fff;padding: 24px 16px; border-radius: 12px;">
                            <div *ngFor="let price of expectedPrices[optionIndex]; let last=last"
                                style="display:flex;justify-content:space-between;"
                                [ngStyle]="{'margin-bottom':last?'':'24px'}">
                                <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                                <div style="display:flex;gap:8px">
                                    <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원</div>
                                    <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                        *ngIf="price.discountPercent == 0">
                                        정가
                                    </div>
                                    <div class="nnb-badge nnb-badge-sm nnb-badge-blue"
                                        *ngIf="price.discountPercent != 0">
                                        {{price.discountPercent|number}}% 할인
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- 인원 단위 가격 설정 -->
        <ng-container *ngIf="ticket.options.length === 0">
            <div *ngIf="ticket.endStandard === EndStandard.PER_PERSON">
                <div class="section-md">
                    <div class="heading-1">인원 단위 가격 설정</div>
                    <div style="margin-bottom:72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>1. 하루에 최대</div>
                            <input value="{{ticket.maxParticipants}}" disabled class="form-control" style="width:100px">
                            <div>명의 고객을 모실 수 있습니다.</div>
                        </div>
                        <div class="desc">최대 인원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>2. 시작 가능 최소 인원은</div>
                            <input value="{{ticket.minParticipants}}" disabled class="form-control" style="width:100px">
                            <div>명 입니다.</div>
                        </div>
                        <div class="desc">모임 진행을 위한 최소 인원 수 입니다.</div>
                    </div>

                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>3. 1인 기본 가격은</div>
                            <input value="{{ticket.pricePerPerson}}" disabled class="form-control" style="width:100px">
                            <div>원 입니다.</div>
                        </div>
                        <div class="desc">입력하신 1인 기본 가격을 기준으로 인원별 금액 범위를 별도로 설정할 수 있습니다.</div>
                    </div>

                    <div class="heading-2" style="margin-bottom:4px">4. 가격 범위 별도 설정하기 <span class="desc">(선택)</span>
                    </div>
                    <div class="desc" style="margin-bottom: 24px">원하는 인원 수 만큼 가격을 별도로 설정할 수 있습니다.</div>
                    <div class="prices" *ngIf="ticket.prices.length>0">
                        <div *ngFor="let price of ticket.prices; let index = index; let last=last;">
                            <div style="display:flex;gap:8px;align-items:center;;flex-wrap: wrap;"
                                [ngStyle]="{'margin-bottom':last?'':'16px'}">
                                <input value="{{price.quantity}}" disabled class="form-control" style="width:100px">
                                <div>인 이상 시 1인 가격은</div>
                                <input value="{{price.price}}" disabled class="form-control" style="width:100px">
                                <div>원 입니다.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="padding: 24px">
                    <div class="heading-1">인당 티켓 가격 미리보기</div>
                    <div class="desc" style="margin-bottom: 16px">입력하신 인원과 가격 기준으로 계산한 인원당 티켓 가격입니다.</div>
                    <div style="background: #fff;padding: 24px 16px; border-radius: 12px;" *ngIf="expectedPrices">
                        <div *ngFor="let price of expectedPrices[0]; let last=last"
                            style="display:flex;justify-content:space-between;"
                            [ngStyle]="{'margin-bottom':last?'':'24px'}">
                            <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                            <div style="display:flex;gap:8px">
                                <div>{{price.pricePerPerson|number}}원 X {{price.quantity|number}}명 = </div>
                                <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원
                                </div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                    *ngIf="price.discountPercent == 0">정가</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue" *ngIf="price.discountPercent != 0">
                                    {{price.discountPercent|number}}% 할인
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="ticket.endStandard === EndStandard.PER_TEAM">
                <div class="section-md">
                    <div class="heading-1" style="margin-bottom:4px">팀 단위 가격 설정</div>
                    <div style="margin-bottom: 72px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>1. 하루에 최대</div>
                            <input value="{{ticket.maxTeamCapacity}}" disabled class="form-control" style="width:100px">
                            <div>팀을 수용할 수 있습니다.</div>
                        </div>
                        <div class="desc" style="margin-bottom:24px">최대 팀원이 모집되면 티켓은 자동으로 마감됩니다.</div>
                        <div class="min-max-container">
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>한 팀의 최소 수용 인원은</div>
                                <input value="{{ticket.minParticipantsPerTeam}}" disabled class="form-control"
                                    style="width:100px">
                                <div>명 입니다.</div>
                            </div>
                            <div class="desc" style="margin-bottom:40px">모임 진행을 위한 최소 인원 수 입니다.</div>
                            <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                                class="heading-2">
                                <div>한 팀의 최대 수용 인원은</div>
                                <input value="{{ticket.maxParticipantsPerTeam}}" disabled class="form-control"
                                    style="width:100px">
                                <div>명 입니다.</div>
                            </div>
                            <div class="desc">모임 진행을 위한 최대 인원 수 입니다.</div>
                        </div>
                    </div>

                    <div style="margin-bottom: 32px">
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;margin-bottom:40px"
                            class="heading-2">
                            <div>2. 1팀의 시작 가격은</div>
                            <input value="{{ticket.pricePerTeam}}" disabled class="form-control" style="width:100px">
                            <div>원 입니다.</div>
                        </div>
                        <div style="display:flex;align-items:center;gap:8px;margin-bottom:4px;flex-wrap: wrap;"
                            class="heading-2">
                            <div>한 명 당 추가 금액은</div>
                            <input value="{{ticket.extraPricePerPerson}}" disabled class="form-control"
                                style="width:100px">
                            <div>원 입니다.</div>
                        </div>
                        <div class="desc" style="margin-bottom:40px">입력하신 1팀 시작 가격과 추가 금액을 기준으로 인원별 금액을 계산합니다.</div>
                    </div>

                    <div class="price-container">
                        <table class="table price-table">
                            <tr>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">인원</td>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">정가</td>
                                <td style="font-weight: 500;padding:8px 8px 8px 2px">할인가</td>
                                <td style="font-weight: 500;padding:8px">할인율</td>
                            </tr>
                            <tr *ngFor="let price of ticket.prices; let index = index; let last=last;">
                                <ng-container *ngIf="index >= +ticket.minParticipantsPerTeam-1">
                                    <td>{{price.quantity}}명 가격</td>
                                    <td>{{price.price|number}}원</td>
                                    <td>{{price.discountPrice|number}}원</td>
                                    <td style=" padding: 2px 2px 2px 8px">
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                            style="margin-top: 10px" *ngIf="price.price == price.discountPrice"> 정가
                                        </div>
                                        <div class="nnb-badge nnb-badge-sm nnb-badge-blue" style="margin-top: 10px"
                                            *ngIf="price.price > price.discountPrice">
                                            {{ticketService.getPercent(price.discountPrice, price.price)}}% 할인
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                </div>

                <div style="padding: 24px">
                    <div class="heading-1">한 팀 티켓 가격 미리보기</div>
                    <div class="desc" style="margin-bottom: 16px">한 팀 기준으로 계산한 인원당 티켓 가격입니다.</div>
                    <div style="background: #fff;padding: 24px 16px; border-radius: 12px;" *ngIf="expectedPrices">
                        <div *ngFor="let price of expectedPrices[0]; let last=last"
                            style="display:flex;justify-content:space-between;"
                            [ngStyle]="{'margin-bottom':last?'':'24px'}">
                            <div style="font-size:18px;font-weight:500">{{price.quantity}}명 가격</div>
                            <div style="display:flex;gap:8px">
                                <div style="font-size:18px;font-weight:500">{{price.discountPrice|number}}원</div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-light-grey"
                                    *ngIf="price.discountPercent == 0">
                                    정가
                                </div>
                                <div class="nnb-badge nnb-badge-sm nnb-badge-blue" *ngIf="price.discountPercent != 0">
                                    {{price.discountPercent|number}}% 할인
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>