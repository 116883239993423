import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlockAllCreateDto, BlockCreateDto, ReportCreateDto } from '../model/report';
import { Block } from '../model/user';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  urlPrefix = UrlService.prefix;
  constructor(
    private http: HttpClient,
  ) { }

  report(dto: ReportCreateDto) {
    return this.http.post('api/report', dto)
  }

  block(dto: BlockCreateDto) {
    return this.http.post('api/block', dto)
  }

  blockAll(dto: BlockAllCreateDto) {
    return this.http.post('api/block-all', dto)
  }

  unblock(dto: BlockCreateDto) {
    return this.http.post('api/block/unblock', dto)
  }

  isBlock(dto: BlockCreateDto): Observable<{ isBlock: boolean }> {
    return this.http.post<{ isBlock: boolean }>('api/block/isBlock', dto)
  }

  getBlockList(): Observable<Block[]> {
    return this.http.get<Block[]>('api/block')
  }
}
