import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserInfo, UserInfoUpdateDto } from './../model/user';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  ACCESS_TOKEN = 'access_token';

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private messageService: MessageService,
  ) {
  }

  getCurrentUserFromServer(): Observable<User> {
    return this.http.get<User>(`/api/users/user/current`)
  }

  getCurrentUserFromServerWithUserInfo(): Observable<User> {
    return this.http.get<User>(`/api/users/user/current/userInfo`)
  }

  getCurrentUser(): Observable<User> {
    const user: User = this.jwtHelper.decodeToken(this.getToken());
    if (!user) return of(undefined);
    return of(user);
  }

  updateUserInfo(userInfoUpdateDto: UserInfoUpdateDto): Observable<UserInfo> {
    return this.http.put<UserInfo>('/api/users/userInfo', userInfoUpdateDto)
  }

  requestAuthSms(phoneNumber: string): Observable<boolean> {
    return this.http.post<boolean>(`/auth/sms`, { 'phoneNumber': phoneNumber })
  }

  checkAuthSms(phoneNumber: string, authNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`/auth/sms?phoneNumber=${phoneNumber}&authNumber=${authNumber}`)
  }

  login(email: string, password: string) {
    return this.http.post('/auth/login', { email, password }).pipe(
      map(accessToken => {
        this.setToken(accessToken['access_token']);
      })
    )
  }

  async logout() {
    this.removeToken();
    const userAgent = this.cookieService.get('nnb_platform');
    if (userAgent == 'NNB-ANDROID' || userAgent == 'NNB-IOS') {
      window.location.href = `/app/logout`;
    } else {
      window.location.href = `/tabs/home`;
    }
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  getToken(): string {
    return this.cookieService.get(this.ACCESS_TOKEN);
  }

  setToken(token: string): void {
    this.removeToken();
    const expires = moment().add(6, 'months').toDate()
    this.cookieService.put(this.ACCESS_TOKEN, token, { expires });
  }

  removeToken(): void {
    this.cookieService.remove(this.ACCESS_TOKEN, { domain: '.nonunbub.com', secure: true })
    this.cookieService.remove(this.ACCESS_TOKEN)
  }

  updateToken(id: number): Observable<any> {
    return this.http.get<any>(`/auth/token/${id}`)
  }

  toastNeedLogin() {
    this.messageService.popup({
      title: '노는법 로그인',
      description: '로그인이 필요합니다.',
      cancelLabel: '확인',
    })
    this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
  }
}
