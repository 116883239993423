import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatRoom } from './../../model/chat';

@Component({
  selector: 'chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent {
  @Input() chatRoom: ChatRoom;
  @Input() isEnd = false;
  @Input() backgroundColor: string;
  @Output() clickChatRoomEvent = new EventEmitter();

  constructor() { }

  clickChatRoom() {
    this.clickChatRoomEvent.emit(this.chatRoom)
  }
}
