<ng-container *ngIf="product && dates">
    <ng-container *ngIf="product.status === ProductStatus.ENTERED || product.status === ProductStatus.TEST">
        <ng-container *ngIf="dates.length > 0">
            <div style="display: flex;align-items: center;gap:8px">
                <div (click)="like(product.id,!product.isSetLike)" class="heart-container" click-log
                    [pageName]="'product-detail'" [eventName]="'좋아요 클릭'">
                    <img [src]="product.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'"
                        style="width:24px;height:24px;min-width:24px;min-height:24px">
                </div>
                <button class="nnb-btn-outline nnb-btn-md" style="height: 52px;font-size: 18px;width:32%"
                    (click)="openShareModal(product,shareTpl)">공유</button>
                <button class="nnb-btn-primary nnb-btn-md" style="height: 52px;font-size: 18px;width:52%"
                    (click)="openBm(selectTicketTpl)" click-log [pageName]="'product-detail'" [eventName]="'일정 보러가기'"
                    [targetUrl]="'/tabs/meeting-detail/'+product.id" [targetInfo]="{type:'PRODUCT', data:product}">
                    일정 보러가기</button>
            </div>
        </ng-container>
        <ng-container *ngIf="dates.length === 0">
            <div style="display: flex;align-items: center;gap:8px">
                <div (click)="like(product.id,!product.isSetLike)" class="heart-container" click-log
                    [pageName]="'product-detail'" [eventName]="'좋아요 클릭'">
                    <img [src]="product.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'"
                        style="width:24px;height:24px;min-width:24px;min-height:24px">
                </div>
                <button class="nnb-btn-outline nnb-btn-md" style="height: 52px;font-size: 18px;width:32%"
                    (click)="openShareModal(product,shareTpl)">
                    공유하기</button>
                <button class="nnb-btn-primary nnb-btn-md" style="height: 52px;font-size: 18px;width:52%"
                    (click)="question()" click-log [pageName]="'product-detail'" [eventName]="'일정 문의하기 클릭'"
                    [targetUrl]="'/tabs/meeting-detail/'+product.id" [targetInfo]="{type:'PRODUCT', data:product}">
                    일정 문의하기</button>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="product.status === ProductStatus.DISABLED">
        <div style="display: flex;align-items: center;gap:8px">
            <div (click)="like(product.id,!product.isSetLike)" class="heart-container" click-log
                [pageName]="'product-detail'" [eventName]="'좋아요 클릭'">
                <img [src]="product.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'"
                    style="width:24px;height:24px;min-width:24px;min-height:24px">
            </div>
            <button class="nnb-btn-primary nnb-btn-md" style="height: 52px;font-size: 18px;width:84%" [disabled]="true">
                비활성된 모임입니다</button>
        </div>
    </ng-container>

    <ng-container *ngIf="product.status === ProductStatus.END">
        <div style="display: flex;align-items: center;gap:8px">
            <div (click)="like(product.id,!product.isSetLike)" class="heart-container" click-log
                [pageName]="'product-detail'" [eventName]="'좋아요 클릭'">
                <img [src]="product.isSetLike ? 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-red-on.svg' : 'https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/heart-grey-off.svg'"
                    style="width:24px;height:24px;min-width:24px;min-height:24px">
            </div>
            <button class="nnb-btn-primary nnb-btn-md" style="height: 52px;font-size: 18px;width:52%" [disabled]="true">
                종료된 모임입니다</button>
        </div>
    </ng-container>
</ng-container>

<ng-template #selectTicketTpl>
    <div
        style="display: flex;justify-content: space-between;align-items: center;padding:12px 16px;position: sticky;top: 0;background: white;border-bottom: 1px solid #f2f3f8;">
        <div>
            <div style="display:flex;gap:4px;align-items: center;">
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/clock.svg"
                    style="width:16px;height:16px">
                <div class="time">{{product.representationCategory}} • {{startDateString}}</div>
            </div>
            <div style="display:flex;gap:8px;align-items: center;cursor: pointer;" (click)="clickSelectedDate()"
                *ngIf="!selectedDate">
                <div class="select-date-text">일정 선택하기</div>
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right-grey.svg">
            </div>
            <div style="display:flex;gap:8px;align-items: center;cursor: pointer;" (click)="isCalendarView = true"
                *ngIf="selectedDate">
                <div class="select-date">{{selectedDate|date:'MM.dd EEEE'}}</div>
                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/chevron-right.svg">
            </div>
        </div>
        <button class="nnb-btn-md nnb-btn-outline" style="width:96px" (click)="closeBm()">접기</button>
    </div>

    <hr style="margin: 0px">

    <div *ngIf="isCalendarView">
        <form [formGroup]="form">
            <div style="display:flex;justify-content:center">
                <mat-calendar [startAt]="startAt" [dateFilter]="dateFilter" [dateClass]="dateClass"
                    [headerComponent]="calendarHeader" [selected]="form.get('date').value"
                    (selectedChange)="updateFormDate($event)"></mat-calendar>
            </div>
        </form>
    </div>

    <div *ngIf="!isCalendarView">
        <div>
            <div class="ticket-container">
                <ng-container *ngFor="let ticket of selectedTickets; let last=last">
                    <!-- TODO: 인원별, 팀별일 때 '잔여 티켓'의 개념을 확립해야 함 -->
                    <div *ngIf="product.id === 672 &&
                    ticket.isActivated &&
                    !ticket.isClosed &&
                    !ticket.isDeactivated &&
                    !ticket.isHidden &&
                    !ticket.isOld &&
                    !ticket.isReservationEnd" style="font-size:16px;font-weight:500;margin-bottom:8px">
                        <span style="color:#5B6371">잔여 티켓 </span>
                        <span style="color:#4971FF">{{(ticket.endStandard===EndStandard.PER_PERSON
                            ?ticket.maxParticipants:ticket.maxParticipantsPerTeam)-ticket.paymentedCount}}개</span>
                    </div>
                    <div *ngIf="
                    isShowParticipants &&
                    ticket.isActivated &&
                    !ticket.isClosed &&
                    !ticket.isDeactivated &&
                    !ticket.isHidden &&
                    !ticket.isOld &&
                    !ticket.isReservationEnd" style="font-size:16px;font-weight:500;margin-bottom:8px">
                        <span style="color:#5B6371">참여 현황 </span>
                        <span
                            style="color:#4971FF">{{ticket.paymentedCount}}/{{ticket.endStandard===EndStandard.PER_PERSON
                            ?ticket.maxParticipants:ticket.maxParticipantsPerTeam}}</span>
                    </div>

                    <ng-container *ngIf="ticket.options.length === 0">
                        <div class="ticket" (click)="selectTicket(ticket)"
                            [ngClass]="{'selected':selectedTicket?.id === ticket.id}">
                            <ng-container *ngIf="ticket.badges.length == 0">
                                <div style="display:flex;justify-content:space-between">
                                    <div class="name">{{ticket.name}}</div>
                                    <div [ngTemplateOutlet]="checkTicketTpl"
                                        [ngTemplateOutletContext]="{ $implicit: ticket }"></div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="ticket.badges.length > 0">
                                <div style="display:flex;justify-content:space-between">
                                    <div style="display:flex;gap:6px;margin-bottom:12px;flex-wrap: wrap;">
                                        <div *ngFor="let badge of ticket.badges" class="nnb-badge nnb-badge-black">
                                            {{badge.name}}</div>
                                    </div>
                                    <div [ngTemplateOutlet]="checkTicketTpl"
                                        [ngTemplateOutletContext]="{ $implicit: ticket }"></div>
                                </div>
                                <div class="name">{{ticket.name}}</div>
                            </ng-container>
                            <ng-container *ngIf="ticket.prices.length === 0">
                                <div class="price">{{(ticket.endStandard === EndStandard.PER_PERSON ?
                                    ticket.pricePerPerson : ticket.pricePerTeam) |number}}원 ~</div>
                            </ng-container>
                            <ng-container *ngIf="ticket.prices.length > 0 && ticket.minPrice">
                                <div *ngIf="ticket.minPrice.discountPercent===0">
                                    <div class="price">{{ticket.minPrice.price|number}}원</div>
                                </div>
                                <div *ngIf="ticket.minPrice.discountPercent!==0" style="display:flex;gap:6px">
                                    <div class="price">{{ticket.minPrice.discountPrice|number}}원</div>
                                    <div class="nnb-badge nnb-badge-md nnb-badge-blue">
                                        {{ticket.minPrice.discountPercent|number}}% 할인</div>
                                </div>
                            </ng-container>
                            <div style="display:flex;gap:4px;align-items: center;">
                                <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user.svg"
                                    style="width:13px;height:13px">
                                <div *ngIf="ticket.endStandard === EndStandard.PER_PERSON">
                                    <div>{{ticket.minParticipants}}인 ~ {{ticket.maxParticipants}}인</div>
                                </div>
                                <div *ngIf="ticket.endStandard === EndStandard.PER_TEAM">
                                    <div>{{ticket.minParticipantsPerTeam}}인 ~ {{ticket.maxParticipantsPerTeam}}인
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="ticket?.descriptions?.length > 0">
                                <hr>
                                <ul style="padding: 0px;margin: 0px 0px 0px 20px;">
                                    <li *ngFor="let desc of ticket.descriptions" class="description">{{desc}}</li>
                                </ul>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="ticket.options.length > 0">
                        <div class="ticket">
                            <div style="display:flex;gap:6px;margin-bottom:12px;flex-wrap: wrap;">
                                <div *ngFor="let badge of ticket.badges" class="nnb-badge nnb-badge-black">
                                    {{badge.name}}</div>
                            </div>
                            <div class="name">{{ticket.name}}</div>
                            <div *ngFor="let option of ticket.options"
                                style="display:flex;gap:24px;margin-bottom:8px;align-items: center;">
                                <div class="option-name">{{option.name}}</div>
                                <ng-container *ngIf="option.prices.length === 0">
                                    <div class="option-price">{{
                                        (ticket.endStandard === EndStandard.PER_PERSON ? option.pricePerPerson :
                                        option.pricePerTeam)|number}}원 ~</div>
                                </ng-container>
                                <ng-container *ngIf="option.prices.length > 0 && option.minPrice">
                                    <div *ngIf="option.minPrice.discountPercent===0">
                                        <div class="option-price">{{option.minPrice.price|number}}원</div>
                                    </div>
                                    <div *ngIf="option.minPrice.discountPercent!==0"
                                        style="display:flex;gap:6px;align-items: center;">
                                        <div class="option-price">{{option.minPrice.discountPrice|number}}원</div>
                                        <div class="nnb-badge nnb-badge-md nnb-badge-blue">
                                            {{option.minPrice.discountPercent|number}}% 할인</div>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="ticket?.descriptions?.length > 0">
                                <hr>
                                <ul style="padding: 0px;margin: 0px 0px 0px 20px;">
                                    <li *ngFor="let desc of ticket.descriptions" class="description">{{desc}}</li>
                                </ul>
                            </ng-container>
                            <div *ngFor="let option of ticket.options; let last=last" style="margin-top:12px">
                                <div class="option" (click)="selectTicket(option)"
                                    [ngClass]="{'selected':selectedTicket?.id === option.id}">
                                    <ng-container *ngIf="option.badges.length == 0">
                                        <div style="display:flex;justify-content:space-between">
                                            <div class="name">{{option.name}}</div>
                                            <div [ngTemplateOutlet]="checkTicketTpl"
                                                [ngTemplateOutletContext]="{ $implicit: option }"></div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="option.badges.length > 0">
                                        <div style="display:flex;justify-content:space-between">
                                            <div style="display:flex;gap:6px;margin-bottom:12px;flex-wrap: wrap;">
                                                <div *ngFor="let badge of option.badges"
                                                    class="nnb-badge nnb-badge-black">
                                                    {{badge.name}}</div>
                                            </div>
                                            <div [ngTemplateOutlet]="checkTicketTpl"
                                                [ngTemplateOutletContext]="{ $implicit: option }"></div>
                                        </div>
                                        <div class="name">{{option.name}}</div>
                                    </ng-container>
                                    <div style="display:flex;gap:4px;align-items: center;">
                                        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user.svg"
                                            style="width:13px;height:13px">
                                        <div *ngIf="ticket.endStandard === EndStandard.PER_PERSON">
                                            <div>{{option.minParticipants}}인 ~ {{option.maxParticipants}}인</div>
                                        </div>
                                        <div *ngIf="ticket.endStandard === EndStandard.PER_TEAM">
                                            <div>{{option.minParticipantsPerTeam}}인 ~
                                                {{option.maxParticipantsPerTeam}}인
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="option.description">
                                        <hr>
                                        <ul style="padding: 0px;margin: 0px 0px 0px 20px;">
                                            <li *ngFor="let desc of option.descriptions" class="description">
                                                {{desc}}
                                            </li>
                                        </ul>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="footer" style="padding:0px;bottom:0px;box-shadow: 0 -6px 14px -6px rgb(0 0 0 / 14%);">
            <div [@enterAnimation] *ngIf="selectedTicket">
                <div class="select-quantity">
                    <div style="display:flex;justify-content:space-between;align-items: center;">
                        <div class="quantity-label">인원</div>
                        <div style="display: flex;align-items: center;gap: 16px;">
                            <div style="display: flex;align-items: center;height:45px;">
                                <ng-container *ngIf="selectedTicket.endStandard === EndStandard.PER_PERSON">
                                    <div class="count-minus-input" [ngClass]="{'count-input-disabled':quantity === 1}"
                                        (click)="changeCount(false)">-</div>
                                    <div
                                        style="display:flex;gap:6px;width: 80px;justify-content: center;align-items: center;">
                                        <div class="count-input">{{quantity}}</div>
                                        <div class="count-input-label">명</div>
                                    </div>
                                    <div class="count-plus-input"
                                        [ngClass]="{'count-input-disabled':quantity + selectedTicket.paymentedCount === selectedTicket.maxParticipants}"
                                        (click)="changeCount(true)">+</div>
                                </ng-container>
                                <ng-container *ngIf="selectedTicket.endStandard === EndStandard.PER_TEAM">
                                    <div class="count-minus-input" [ngClass]="{'count-input-disabled':quantity === 1}"
                                        (click)="changeCount(false)">-</div>
                                    <div
                                        style="display:flex;gap:6px;width: 80px;justify-content: center;align-items: center;">
                                        <div class="count-input">{{quantity}}</div>
                                        <div class="count-input-label">명</div>
                                    </div>
                                    <div class="count-plus-input"
                                        [ngClass]="{'count-input-disabled':quantity === selectedTicket.maxParticipantsPerTeam}"
                                        (click)="changeCount(true)">+</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <hr style="margin:16px 0px">
                    <div class="discount-price-container" *ngIf="discountPercent !== 0">
                        <div>할인</div>
                        <div style="display: flex;gap: 6px;align-items: center;">
                            <div class="nnb-badge nnb-badge-md nnb-badge-blue">{{discountPercent|number}}% 할인</div>
                            <div style="text-decoration: line-through;">{{price|number}}원</div>
                        </div>
                    </div>
                    <div style="display:flex;justify-content:space-between">
                        <div class="expected-price-label">결제 예상 금액</div>
                        <div style="display:flex;gap:2px;align-items:center" [id]="'payment-select-'+product.id">
                            <div class="expected-price">{{discountPrice|number}}</div>
                            <div class="expected-price-unit">원</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding: 10px 16px;">
                <button class="nnb-btn-primary nnb-btn-lg nnb-btn-block" (click)="payment(addPhoneTpl)" click-log
                    [pageName]="'product-detail'" [eventName]="'다음단계'" [targetUrl]="'/tabs/meeting-detail/'+product.id"
                    [targetInfo]="{type:'PRODUCT', data:product}">
                    다음단계</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #shareTpl>
    <div class="section-title">공유하기</div>
    <div class="sns-share">
        <div class="sns" (click)="share('kakaotalk')" click-log [pageName]="'product-detail'"
            [eventName]="'공유하기 클릭(카카오톡)'" [targetInfo]="{type:'PRODUCT',data:product}">
            <img class="sns-icon" style="width: 55px;"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/sns-share/kakaotalk.svg">
            <p class="label">카카오톡</p>
        </div>
        <div class="sns" (click)="share('link')" click-log [pageName]="'product-detail'" [eventName]="'공유하기 클릭(링크복사)'"
            [targetInfo]="{type:'PRODUCT',data:product}">
            <img class="sns-icon"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/sns-share/link.svg">
            <p class="label">링크복사</p>
        </div>
    </div>
    <button class="nnb-btn-secondary nnb-btn-md nnb-btn-block" (click)="closeBm()">닫기</button>
</ng-template>

<ng-template #checkTicketTpl let-ticket>
    <ng-container *ngIf="!ticket.isReservationEnd">
        <ng-container *ngIf="selectedTicket">
            <img *ngIf="selectedTicket.id == ticket.id"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-circle-on.svg">
            <img *ngIf="selectedTicket.id != ticket.id"
                src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-circle-off.svg">
        </ng-container>
        <img *ngIf="!selectedTicket"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-circle-off.svg">
    </ng-container>
    <ng-container *ngIf="ticket.isReservationEnd">
        <div class="nnb-badge nnb-badge-md nnb-badge-dark-grey">마감 티켓</div>
    </ng-container>
</ng-template>

<ng-template #addPhoneTpl>
    <div class="desc" style="margin-bottom: 10px;">모임 안내를 받기 위해 최초 1회 휴대폰 인증이 필요해요.</div>
    <div style="height:310px;overflow-y: auto;margin-bottom: 15px;">
        <form-input [name]="'휴대폰 번호'" [controlName]="'phone'" [group]="phoneNumberForm" [type]="'phone'"
            [controlName]="'phone'" [user]="user" (smsAuthEvent)="closeAddPhoneTpl(selectTicketTpl)">
        </form-input>
    </div>
</ng-template>