import { Directive, EventEmitter, Output } from "@angular/core";
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/service/auth.service';
import { User } from "../model/user";

@Directive({
    selector: '[scroll-depth]',
    host: { '(window:scroll)': 'track()' }
})
export class ScrollDepthDirective {
    @Output() scrollDepthEvent = new EventEmitter();
    user: User;

    constructor(
        private authService: AuthService,
        private cookieService: CookieService
    ) {
        this.authService.getCurrentUser().subscribe(user => {
            this.user = user;
        });
    }

    track() {
        const isClicked = this.cookieService.get('is_clicked_login_bm') === 'true';
        const result = this.getVerticalScrollPercentage();
        if (!this.user && result > 75 && result < 80 && !Number.isNaN(result) && !isClicked) {
            this.scrollDepthEvent.emit();
        }
    }

    getVerticalScrollPercentage() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        return scrolled;
    }
}