<div class="footer" style="padding-bottom:80px">
  <div class="container">
    <div style="margin-bottom: 10px;">
      <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/home/logo-white.svg"
        style="width:3rem">
    </div>
    <div class="row">
      <div class="col-md">
        <ul class="footer-list">
          <li>(주)바바그라운드</li>
          <li>대표: 허정 | 사업자등록번호: 393-87-01601</li>
          <li>관광사업자 등록번호: 2023-000001호</li>
          <li>통신판매업신고번호 : 2023-서울마포-0287</li>
          <li>
            <a href="https://blog.naver.com/babaground" class="footer-link" target="_blank">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/footer/footer-naver-blog.png"
                style="width:20px">&nbsp;블로그
            </a>&nbsp;
            <a href="https://band.us/band/75866264" class="footer-link" target="_blank">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/footer/footer-naver-band.png"
                style="width:20px">&nbsp;밴드
            </a>&nbsp;
            <a href="https://www.youtube.com/channel/UCdlug-_m_DVkFBAK7iKEMsw/videos" class="footer-link"
              target="_blank">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/footer/footer-youtube.png"
                style="width:20px">&nbsp;유튜브
            </a>&nbsp;
            <a href="https://pf.kakao.com/_lyeixb" class="footer-link" target="_blank">
              <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/sns-share/kakaotalk.png"
                style="width:20px">&nbsp;카카오톡
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md">
        <ul class="footer-list">
          <li>이메일: help@nonunbub.com</li>
          <li>주소: 서울 중구 세종대로 136 서울파이낸스센터 3, 3층</li>
          <li>고객센터: 02-3661-0116 10:00 ~ 20:00</li>
          <li><a style="color: #ffffff" target="_blank" href="https://pf.kakao.com/_lyeixb/chat">상시 카카오톡 고객센터</a></li>
          <li>
            <a *ngIf="!isApple" href="https://nonunbub.com/tabs/iframe" class="footer-link" target="_blank">
              노는법 소개&nbsp;
            </a>
            <a href="https://nonunbub.notion.site/68e2260b9b454192ab4d61a365148896" class="footer-link" target="_blank">
              이용약관
            </a>&nbsp;
            <a href="https://nonunbub.notion.site/82458a50cb4d4d30a99963a61bbe2e75" class="footer-link" target="_blank">
              개인정보처리방침
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>