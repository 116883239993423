<div [formGroup]="group">
  <!-- label -->
  <div style="display:flex;" [ngStyle]="{'justify-content':name?'space-between':'flex-end'}">
    <div *ngIf="name" class="input-label" #label [ngStyle]="{'color':invalid?'red':''}">
      {{name}}&nbsp;<span class="invalid-input" *ngIf="isRequired && isRequiredLabel">(필수)</span>
    </div>

    <!-- text count -->
    <div *ngIf="textCountPosition === 'top'" class="desc" [ngStyle]="{display: (type==='text' || type==='textarea'|| type==='password') 
          && !!group.controls[controlName].value
          && maxLength !== 0
          ?'block':'none'}">
      <span [ngStyle]="{color:group.controls[controlName].errors?.maxlength && maxLength!==0 ? 'red':''}">
        {{group.controls[controlName].value?.length}}</span> / {{maxLength}} 자
    </div>
  </div>

  <!-- text, number, password, textarea, count -->
  <ng-container
    *ngIf="type==='text' || type==='number'|| type==='password'|| type==='tel' || type==='textarea' || type==='count'">
    <ng-container *ngIf="type==='text' || type==='number'|| type==='password'|| type==='tel'">
      <input #formInput class="input text-input" [ngStyle]="{'color':invalid?'red':'','border':invalid?'1px solid red':'','border-radius':invalid?'12px':'',
        'text-align':textAlign,'height':height+'px','font-size':fontSize+'px'}"
        [ngClass]="{'input-font-26':fontSize===26}" [autocomplete]="false" [attr.disabled]="disabled ? true: null"
        [type]="type" [formControlName]="controlName" clearInput (ngModelChange)="changeEvent($event)"
        clearOnEdit="false" [placeholder]="placeholder" (focus)="focus()" (focusout)="focusout()"
        (input)="checkNumber($event)" [mask]="mask?mask:''" [suffix]="suffix?suffix:''" [showMaskTyped]="showMaskTyped"
        [dropSpecialCharacters]="dropSpecialCharacters">
    </ng-container>
    <ng-container *ngIf="type==='textarea'">
      <textarea class="input" style="padding:20px 12px"
        [ngStyle]="{'color':invalid?'red':'','border': border?  invalid?'1px solid red':'' : 'none','border-radius':invalid?'6px':''}"
        [attr.disabled]="disabled ? true: null" [rows]="rows" [maxlength]="maxLength==0?undefined:maxLength"
        [formControlName]="controlName" clearInput (change)="changeEvent($event.target.value)"
        [placeholder]="placeholder" (focus)="focus()" (focusout)="focusout()">
      </textarea>
    </ng-container>
    <ng-container *ngIf="type==='count'">
      <div style="display: flex;align-items: center;height:45px;">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/minus-circle-grey.svg"
          (click)="changeCount(false)">
        <input style="font-weight: bold;color:#50596c;font-size: 18px;" [formControlName]="controlName" type="number"
          class="form-control count-input" disabled>
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/plus-circle-grey.svg"
          (click)="changeCount(true)">
      </div>
    </ng-container>
    <div style="display:flex;justify-content:space-between">
      <form-errors style="margin-left:2px" [formGroup]="group" [controlName]="controlName" [errors]="errors"
        [isUnUseDirty]="isUnUseDirty" [isRequired]="isRequired">
      </form-errors>
      <!-- text count -->
      <div *ngIf="textCountPosition === 'bottom'" class="desc" [ngStyle]="{display: (type==='text' || type==='textarea'|| type==='password') 
       && !!group.controls[controlName].value
       && maxLength !== 0
       ?'block':'none'}">
        <span [ngStyle]="{color:group.controls[controlName].errors?.maxlength && maxLength!==0 ? 'red':''}">
          {{group.controls[controlName].value?.length}}</span> / {{maxLength}} 자
      </div>
    </div>
  </ng-container>
  <!-- select -->
  <ng-container *ngIf="type==='select'">
    <ng-select *ngIf="groupName" #formInput class="input text-input" [attr.disabled]="disabled ? true: null" [ngStyle]="{'color':invalid?'red':'','border':invalid?'1px solid red':'','border-radius':invalid?'6px':'',
      'padding-top': (multiple || size != 'normal') ? '' : '5px', 'min-height':size == 'normal'?'53px':'38px'}"
      [formControlName]="controlName" [searchable]="searchable" [clearable]="clearable" [notFoundText]="notFoundText"
      [placeholder]="placeholder" [addTag]="addTag" [multiple]="multiple" [class.select]="name"
      (search)="selectSearchEvent($event)" (add)="addEvent($event)" (remove)="removeEvent($event)"
      (ngModelChange)="changeEvent($event)" (focus)="focus()" (focusout)="focusout()" [groupBy]="groupName"
      [items]="selectList" [bindLabel]="valueProp" [bindValue]="valueProp" [maxSelectedItems]="maxSelectedItems">
      <ng-template ng-optgroup-tmp let-item="item">
        {{item[groupName] || '기타'}}
      </ng-template>
    </ng-select>

    <ng-select *ngIf="!groupName" #formInput class="input text-input" [attr.disabled]="disabled ? true: null" [ngStyle]="{'color':invalid?'red':'','border':invalid?'1px solid red':'','border-radius':invalid?'6px':'',
      'padding-top': (multiple || size != 'normal') ? '' : '5px', 'min-height':size == 'normal'?'53px':'38px'}"
      [formControlName]="controlName" [searchable]="searchable" [clearable]="clearable" [notFoundText]="notFoundText"
      [placeholder]="placeholder" [addTag]="addTag" [multiple]="multiple" [class.select]="name"
      (search)="selectSearchEvent($event)" (add)="addEvent($event)" (remove)="removeEvent($event)"
      (ngModelChange)="changeEvent($event)" (focus)="focus()" (focusout)="focusout()" [groupBy]="groupName"
      [maxSelectedItems]="maxSelectedItems">
      <ng-option *ngFor="let element of selectList" [value]="valueProp?element[valueProp]:element">
        <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ $implicit: element }">
        </ng-container>
        <ng-container *ngIf="!template">{{element}}</ng-container>
      </ng-option>
    </ng-select>

    <form-errors [formGroup]="group" [controlName]="controlName" [errors]="errors" [isUnUseDirty]="isUnUseDirty"
      [isRequired]="isRequired">
    </form-errors>
  </ng-container>
  <!-- date time -->
  <ng-container *ngIf="type==='date-time'">
    <input class="input text-input" [attr.disabled]="disabled ? true: null"
      [ngStyle]="{'width':selectMode === 'range'?'222px':'', 'color':invalid?'red':'','border':invalid?'1px solid red':'','border-radius':invalid?'6px':''}"
      [selectMode]="selectMode" [owlDateTimeTrigger]="period" [owlDateTime]="period" [min]="minDate" [max]="maxDate"
      [formControlName]="controlName" [placeholder]="placeholder" (focus)="focus()" (focusout)="focusout()">
    <owl-date-time #period [pickerType]="pickerType" [startView]="startView">
    </owl-date-time>
    <form-errors [formGroup]="group" [controlName]="controlName" [errors]="errors" [isUnUseDirty]="isUnUseDirty"
      [isRequired]="isRequired">
    </form-errors>
  </ng-container>
  <!-- map -->
  <ng-container *ngIf="type==='map' && latitude && longitude">
    <map (searchEvent)="searchMap($event)" [address]="group.controls[controlName].value" [latitude]="latitude"
      [placeholder]="placeholder" [invalid]="invalid" [longitude]="longitude" [id]="id" [mapOption]="mapOption"></map>
  </ng-container>
  <!-- phone -->
  <ng-container *ngIf="type==='phone'">
    <form [formGroup]="group">
      <div style="display: flex;flex-direction: column;margin-bottom:16px">
        <div style="margin-bottom:4px">
          <input class="input text-input" type="tel"
            [ngStyle]="{'color':invalid?'red':'','border':invalid?'1px solid red':'','border-radius':invalid?'6px':''}"
            [formControlName]="controlName" placeholder="01012345678"
            [attr.disabled]="user?.phoneNumber || isSMSAuth ? '' : null">
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <div class="invalid-input" [ngStyle]="{'visibility':invalid?'visible':'hidden'}">입력이 올바르지 않습니다.</div>
          <div *ngIf="!isSMSAuth">
            <button *ngIf="!user?.phoneNumber" class="nnb-btn-primary nnb-btn-sm"
              [attr.disabled]="group.controls[controlName].value === '' || invalid ? '' : null"
              (debounceClick)="requestAuthSMS()" [throttleTime]="2000">인증번호 발송</button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isSMSAuth && isRequestAuth">
        <div style="display: flex;flex-direction: column;">
          <div *ngIf="isRequestAuth" style="margin-bottom:4px">
            <form [formGroup]="group">
              <label class="label" style="margin:0px" [attr.data-remainTime]="remainTime">
                <input autofocus class="authInput" #authNumber placeholder="인증번호를 입력해주세요." />
              </label>
            </form>
          </div>
          <div *ngIf="remainTime === '00:00'" style="display: flex;align-items: center;justify-content: space-between;">
            <div class="invalid-input" [ngStyle]="{'visibility':!isSMSAuth && !!remainTime? 'visible':'hidden'}">
              &nbsp;인증시간 만료, 다시 요청해주세요
            </div>
            <button class="nnb-btn-primary nnb-btn-sm" (click)="authSMS()">
              인증번호 확인</button>
          </div>
          <div *ngIf="remainTime != '00:00'" style="display: flex;justify-content: flex-end;">
            <button class="nnb-btn-primary nnb-btn-sm" (click)="authSMS()">
              인증번호 확인</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isSMSAuth">
        <div class="desc" style="margin-top: -16px;margin-bottom: 8px;">인증 완료!</div>
      </ng-container>
    </form>
  </ng-container>
  <!-- photo -->
  <ng-container *ngIf="type==='photo'">
    <div class="photo-container">
      <button [disabled]="group.controls[controlName].value.length===maxPhotos" class="camera-btn" type="button"
        (click)="fileInput.click()">
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/camera.svg" class="nnb-icon">
        <div class="text">{{group.controls[controlName].value.length}}/{{maxPhotos}}</div>
        <input #fileInput [multiple]="multiplePhoto" type="file" [accept]="canGif?'image/*':'image/png, image/jpeg'"
          (change)="onFileInput($event,cropTpl)" style="display:none;" />
      </button>
      <div *ngIf="group.controls[controlName].value.length === 0 && useInitialBtn">
        <button style="width: 80px;margin-left: 10px;height: 69px;line-height: 18px;" class="nnb-btn-sm nnb-btn-outline"
          (click)="setInitialImage()">기본이미지<br>사용하기</button>
      </div>
      <div
        style="display: flex;overflow-x: auto;overflow-y:hidden;flex-wrap: nowrap;padding-right: 25px;margin-top: -32px;">
        <div *ngFor="let photo of group.controls[controlName].value; let index=index"
          style="display: flex; flex-direction: column;align-items: center; flex: 0 0 auto;margin-left: 10px;margin-top: 6px;">
          <div style="position: relative;top: 25px;left: 23px;">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/cancel.svg" class="nnb-icon"
              (click)="deletePhoto(index)">
          </div>
          <img class="input-image" [src]="photo.photo" (click)="editPhoto(index, photo, cropTpl)">
        </div>
      </div>
    </div>
  </ng-container>
  <!-- radio-box -->
  <ng-container *ngIf="type==='radio-box'">
    <div [ngClass]="{'raido-box-horizontal':radioType==='horizontal'}">
      <div *ngFor="let radio of radios" class="radio-box-container" (click)="changeEvent(radio.value)"
        [ngClass]="{'radio-box-checked':group.value[controlName] === radio.value}"
        style="cursor:pointer;max-width: 512px" [ngStyle]="{'width':radioType==='horizontal'?'100%':''}">
        <div style="display:flex; justify-content: space-between;align-items: flex-start;gap:8px;"
          [ngStyle]="titleStyle">
          <div [ngStyle]="{'margin-bottom':radio.contentTpl ? '12px':''}">
            <div class="pre-wrap" *ngIf="!radio.content" [ngTemplateOutlet]="radio.titleTpl"></div>
            <div class="pre-wrap" *ngIf="radio.content">{{radio.content}}</div>
          </div>
          <img *ngIf="group.value[controlName] === radio.value"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-circle-on.svg">
          <img *ngIf="group.value[controlName] !== radio.value"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/check-circle-off.svg">
          <input type="radio" [name]="controlName" [formControlName]="controlName" [value]="radio.value"
            style="display:none">
        </div>
        <div [ngTemplateOutlet]="radio.contentTpl"></div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #cropTpl>
  <div style="padding: 20px;">
    <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;">
      <div style="font-size: 20px;font-weight: bold;">이미지 편집하기</div>
      <div style="display: flex;gap: 6px;">
        <button class="nnb-btn-secondary nnb-btn-sm" (click)="modalRef.hide()">취소</button>
        <button class="nnb-btn-primary nnb-btn-sm" (click)="addPhoto()">완료</button>
      </div>
    </div>
    <button (click)="rotateRight()" class="nnb-btn-outline nnb-btn-sm" style="float: right;margin-bottom: 5px;">
      <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/reload.svg">회전
    </button>
    <image-cropper [backgroundColor]="'#fff'" [imageFile]="selectedImageFile"
      [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="aspectRatio" (imageCropped)="imageCropped($event)"
      [containWithinAspectRatio]="false" [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight"
      [imageQuality]="100" [format]="'jpeg'" [canvasRotation]="canvasRotation"
      style="border: 1px solid #dcdcdc; max-height: 70vh;">
    </image-cropper>
  </div>
</ng-template>