<ng-container *ngIf="!!reviews && !!meta">
  <strong *ngIf="displayLabel">댓글</strong>&nbsp;
  <span class="desc" *ngIf="displayLabel && !!reviews">{{meta.totalItemsWithChildren}}개</span>

  <ng-container *ngIf="editable">
    <div style="display: flex;align-items: baseline;">
      <textarea class="form-control comment-textarea" #comment rows="1" maxlength="1000"
        (click)="canWrite? '':needLogin()" (input)="onInput($event)" [(ngModel)]="commentInput"
        style="margin-right: 10px;width:70%" [disabled]="!canWrite" [placeholder]="'댓글을 입력해주세요.'"></textarea>
      <button class="nnb-btn-primary nnb-btn-sm" style="height: 37px;" (click)="ok()">등록</button>
    </div>
  </ng-container>
  <br>

  <div *ngFor="let review of reviews | paginate: {
      id:meta.paginationId,
      itemsPerPage: meta.itemsPerPage, 
      currentPage: meta.currentPage,
      totalItems:meta.totalItems
    }; let i = index" class="review-wrapper">
    <!-- parent comments -->
    <div style="margin-bottom: 14px;">
      <ng-container *ngIf="review.comment !== DELETE_FLAG">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px;">
          <div style="display: flex;align-items: center;">
            <a [link]="'/tabs/profile/' + review.user.id" [routerLink]="'/tabs/profile/' + review.user.id">
              <img *ngIf="review.user.profilePhoto" [src]="review.user.profilePhoto" class="avatar" />
            </a>
            <div>
              <div>{{review.user.nickname}}</div>
              <div class="date">{{review.createdAt|date:'yyyy.MM.dd'}}</div>
            </div>
          </div>
          <div>
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_trash.svg"
              *ngIf="editable && review.canDelete" class="comment-icon" (click)="deleteComment(review)">
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/message-circle-2.svg"
              *ngIf="editable" class="comment-icon" (click)="showChildComment(i, review)">
          </div>
        </div>
        <a *ngIf="displayProduct" [link]="'/tabs/meeting-detail/' + review.product.id"
          [routerLink]="'/tabs/meeting-detail/' + review.product.id">
          <p class="date" style="margin-bottom: -4px;">{{review.product.title}}</p>
        </a>
        <p *ngIf="review.isMore" class="comment pre-wrap">{{review.comment|truncate:[200,'...']}}</p>
        <p *ngIf="!review.isMore" class="comment pre-wrap">{{review.comment}}</p>
        <p *ngIf="review.comment.length > 200 && review.isMore" class="desc comment" style="cursor: pointer;"
          (click)="review.isMore = !review.isMore">더보기</p>
        <p *ngIf="review.comment.length > 200 && !review.isMore" class="desc comment" style="cursor: pointer;"
          (click)="review.isMore = !review.isMore">접기</p>
      </ng-container>
      <ng-container *ngIf="review.comment === DELETE_FLAG">
        <div style="display: flex;  margin-top: 8px;">
          <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/user.svg" class="avatar">
          <p class="desc" style="margin-top: 8px;">{{review.comment}}</p>
        </div>
      </ng-container>
    </div>
    <!-- child comments -->
    <ng-container *ngFor="let child of review.children">
      <div style="margin-left: 10px;">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px;">
          <div style="display: flex;align-items: center;">
            <a [link]="'/tabs/profile/' + child.user.id" [routerLink]="'/tabs/profile/' + child.user.id">
              <img *ngIf="child.user.profilePhoto" [src]="child.user.profilePhoto" class="avatar" />
            </a>
            <div>
              <div>{{child.user.nickname}}</div>
              <div class="date">{{child.createdAt|date:'yyyy.MM.dd'}}</div>
            </div>
          </div>
          <div>
            <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/tabler_trash.svg"
              class="comment-icon" *ngIf="editable && child.canDelete" (click)="deleteComment(child)">
          </div>
        </div>
        <div class="child-comment">
          <p *ngIf="child.isMore" class="comment pre-wrap">{{child.comment|truncate:[200,'...']}}</p>
          <p *ngIf="!child.isMore" class="comment pre-wrap">{{child.comment}}</p>
          <p *ngIf="child.comment.length > 200 && child.isMore" class="desc comment" style="cursor: pointer;"
            (click)="child.isMore = !child.isMore">더보기</p>
          <p *ngIf="child.comment.length > 200 && !child.isMore" class="desc comment" style="cursor: pointer;"
            (click)="child.isMore = !child.isMore">접기</p>
        </div>
      </div>
    </ng-container>

    <div #addChildInput style="display: none;">
      <div style="margin-left: 10px;display: flex;align-items: baseline;">
        <textarea class="form-control comment-textarea" #comment rows="1" maxlength="1000"
          (click)="canWrite? '':needLogin()" (input)="onInput($event)" [(ngModel)]="commentInput"
          style="margin-right: 10px;width:70%" [disabled]="!canWrite" [placeholder]="'댓글을 입력해주세요.'"></textarea>
        <button class="nnb-btn-primary nnb-btn-sm" style="height: 37px;" (click)="okChild(i, review)">등록</button>
      </div>
    </div>
  </div>
</ng-container>

<pagination *ngIf="!!meta && reviews.length>0" (onChange)="pageChanged($event)" [paginationId]="meta.paginationId">
</pagination>