<ng-container *ngFor="let section of sections">
  <div class="section-md section-0" *ngIf="section.type == SectionType.HORIZONTAL">
    <div style="padding-left: 16px">
      <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
      <swiper [config]="sliderConfig">
        <ng-template swiperSlide *ngFor="let product of section.products">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
  <div class="section-md" *ngIf="section.type == SectionType.VERTICAL">
    <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
    <div *ngFor="let product of section.products; let last=last" [ngStyle]="{'margin-bottom':last?'0px':'32px'}">
      <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }"></div>
    </div>
  </div>
  <div class="section-md" *ngIf="section.type == SectionType.LIST">
    <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
    <div *ngFor="let product of section.products; let last=last" [ngStyle]="{'margin-bottom':last?'0px':'32px'}">
      <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }"></div>
    </div>
  </div>
  <div class="section-md" *ngIf="section.type == SectionType.FEED">
    <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
    <swiper [config]="feedSliderConfig">
      <ng-template swiperSlide *ngFor="let feed of feeds">
        <feed [feed]="feed" [type]="'summary'" [imgageType]="'round'" [isShowProduct]="true"></feed>
      </ng-template>
    </swiper>
  </div>
  <div class="section-md section-0" *ngIf="section.type == SectionType.INFLUENCER">
    <div style="padding-left: 16px">
      <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
      <swiper [config]="sliderConfig">
        <ng-template swiperSlide *ngFor="let influencer of influencers">
          <a [link]="influencer.link">
            <div class="influencer">
              <div style="display:flex;justify-content: center;">
                <img [src]="influencer.image" class="photo">
              </div>
              <div style="display:flex;align-items: center;justify-content: space-between;margin-bottom:16px;">
                <div style="display:flex;gap:8px;align-items: center;">
                  <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/instagram.svg"
                    style="width:20px;height:20px">
                  <div class="nickname">{{influencer.nickname}}</div>
                </div>
                <div class="pre-wrap time">{{influencer.createdAt|dateAgo}}</div>
              </div>
              <div class="content line-clamp">{{influencer.content}}</div>
              <div class="desc" style="cursor: pointer;text-decoration: underline;margin-top:2px">
                더보기</div>
            </div>
          </a>
        </ng-template>
      </swiper>
    </div>
  </div>
  <div class="section-md section-0" *ngIf="section.type == SectionType.LIGHT">
    <div style="padding-left: 16px">
      <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
      <swiper [config]="sliderConfig">
        <ng-template swiperSlide *ngFor="let product of lightProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
  <div class="section-md section-0" *ngIf="section.type == SectionType.BESTCATEGORY">
    <div style="padding-left: 16px">
      <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
      <div class="feed-selector-container"
        style="display: flex;gap: 8px;align-items: center;overflow-x: auto;margin-bottom: 12px;">
        <div class="feed-selector" [ngClass]="{'active':categorySelectedMenu === 'lodge'}"
          (click)="selectCategoryFilter('lodge')">
          <img *ngIf="categorySelectedMenu === 'lodge'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-bed-white.svg">
          <img *ngIf="categorySelectedMenu !== 'lodge'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-bed-black.svg">
          <div [ngStyle]="{color:categorySelectedMenu === 'lodge'?'white':'black'}">숙박</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':categorySelectedMenu === 'oneday'}"
          (click)="selectCategoryFilter('oneday')">
          <img *ngIf="categorySelectedMenu === 'oneday'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-sun-white.svg">
          <img *ngIf="categorySelectedMenu !== 'oneday'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-sun-black.svg">
          <div [ngStyle]="{color:categorySelectedMenu === 'oneday'?'white':'black'}">당일</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':categorySelectedMenu === 'learn'}"
          (click)="selectCategoryFilter('learn')">
          <img *ngIf="categorySelectedMenu === 'learn'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-drawtool-white.svg">
          <img *ngIf="categorySelectedMenu !== 'learn'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-drawtool-black.svg">
          <div [ngStyle]="{color:categorySelectedMenu === 'learn'?'white':'black'}">배움</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':categorySelectedMenu === 'nature'}"
          (click)="selectCategoryFilter('nature')">
          <img *ngIf="categorySelectedMenu === 'nature'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-tree-white.svg">
          <img *ngIf="categorySelectedMenu !== 'nature'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-tree-black.svg">
          <div [ngStyle]="{color:categorySelectedMenu === 'nature'?'white':'black'}">자연</div>
        </div>
      </div>
      <swiper [config]="sliderConfig" *ngIf="categorySelectedMenu==='lodge'">
        <ng-template swiperSlide *ngFor="let product of lodgeProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="categorySelectedMenu==='oneday'">
        <ng-template swiperSlide *ngFor="let product of onedayProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="categorySelectedMenu==='learn'">
        <ng-template swiperSlide *ngFor="let product of learnProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="categorySelectedMenu==='nature'">
        <ng-template swiperSlide *ngFor="let product of natureProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
  <div class="section-md section-0" *ngIf="section.type == SectionType.ACCOMPANY">
    <div style="padding-left: 16px">
      <div [ngTemplateOutlet]="titleTpl" [ngTemplateOutletContext]="{ $implicit: section }"></div>
      <div class="feed-selector-container"
        style="display: flex;gap: 8px;align-items: center;overflow-x: auto;margin-bottom: 12px;">
        <div class="feed-selector" [ngClass]="{'active':accompanySelectedMenu === 'friends'}"
          (click)="selectAccompanyFilter('friends')">
          <img *ngIf="accompanySelectedMenu === 'friends'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-happy-white.svg">
          <img *ngIf="accompanySelectedMenu !== 'friends'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-happy-black.svg">
          <div [ngStyle]="{color:accompanySelectedMenu === 'friends'?'white':'black'}">친구들과</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':accompanySelectedMenu === 'family'}"
          (click)="selectAccompanyFilter('family')">
          <img *ngIf="accompanySelectedMenu === 'family'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-user-white.svg">
          <img *ngIf="accompanySelectedMenu !== 'family'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-user-black.svg">
          <div [ngStyle]="{color:accompanySelectedMenu === 'family'?'white':'black'}">친정식구</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':accompanySelectedMenu === 'children'}"
          (click)="selectAccompanyFilter('children')">
          <img *ngIf="accompanySelectedMenu === 'children'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-baby-white.svg">
          <img *ngIf="accompanySelectedMenu !== 'children'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-baby-black.svg">
          <div [ngStyle]="{color:accompanySelectedMenu === 'children'?'white':'black'}">자녀들과</div>
        </div>
        <div class="feed-selector" [ngClass]="{'active':accompanySelectedMenu === 'pet'}"
          (click)="selectAccompanyFilter('pet')">
          <img *ngIf="accompanySelectedMenu === 'pet'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-paw-white.svg">
          <img *ngIf="accompanySelectedMenu !== 'pet'"
            src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/filter-paw-black.svg">
          <div [ngStyle]="{color:accompanySelectedMenu === 'pet'?'white':'black'}">애견동반</div>
        </div>
      </div>
      <swiper [config]="sliderConfig" *ngIf="accompanySelectedMenu==='friends'">
        <ng-template swiperSlide *ngFor="let product of friendsProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="accompanySelectedMenu==='family'">
        <ng-template swiperSlide *ngFor="let product of familyProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="accompanySelectedMenu==='children'">
        <ng-template swiperSlide *ngFor="let product of childrenProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
      <swiper [config]="sliderConfig" *ngIf="accompanySelectedMenu==='pet'">
        <ng-template swiperSlide *ngFor="let product of petProducts">
          <div [ngTemplateOutlet]="productCardTpl" [ngTemplateOutletContext]="{ $implicit: {section, product} }">
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</ng-container>

<ng-template #titleTpl let-section>
  <div style="display:flex;justify-content: space-between;margin-bottom: 24px;">
    <div>
      <div style="font-size: 13px;font-weight: 500;line-height: 100%;margin-bottom:8px;color:#2B2B2B">
        {{section.subTitle}}
      </div>
      <div style="font-size: 20px;line-height: 150%;" [ngStyle]="{'font-weight':section.title1Strong?'700':'500'}">
        {{section.title1}}
      </div>
      <div style="font-size: 20px;line-height: 150%;" [ngStyle]="{'font-weight':section.title2Strong?'700':'500'}">
        {{section.title2}}
      </div>
      <div class="moreLabel" *ngIf="section.moreLabel && section.moreLink">
        <div><a [href]="section.moreLink">{{section.moreLabel}}</a></div>
        <img src="https://nonunbub.s3.ap-northeast-2.amazonaws.com/static/fe-assets/new/arrow-right-dark-grey.svg">
      </div>
    </div>
    <div [ngStyle]="{'margin-right':SectionType.HORIZONTAL||SectionType.INFLUENCER?'16px':''}">
      <img [src]="section.icon" style="height: 64px;">
    </div>
  </div>
</ng-template>

<ng-template #productCardTpl let-data>
  <div>{{data.title}}</div>
  <product-card [viewType]="data.section.type" [product]="data.product">
  </product-card>
</ng-template>