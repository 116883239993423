import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogInfo, LoggerService, TargetType } from './logger.service';
import { MessageService } from './message.service';

export class ErrorHttpInterceptor implements HttpInterceptor {
  private isUpdated = true;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private logger: LoggerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // 시스템 에러
        if (error.status === 502) {
          this.logger.addLog({
            pageName: 'common',
            eventName: '502 error',
            eventType: 'error',
            targetUrl: '',
            targetInfo: { type: TargetType.ETC, data: '502 에러' }
          } as LogInfo);
          this.messageService.popup({
            description: '일시적인 오류가 발생하였습니다. 확인을 누르시면 페이지를 새로고침합니다.',
            confirmLabel: '확인',
            confirmBtnClass: 'primary',
            confirmHandler: () => {
              setTimeout(() => {
                window.location.reload();
              }, 500)
            },
            cancelLabel: '취소',
            isNested: true
          })
          return;
        }
        if (error.status >= 500) {
          if (error.error.type === 'NNB_ERROR_TYPE') {
            const message = error.error.reason ? `${error.error.message}(${error.error.reason})` : error.error.message
            this.messageService.popup({
              title: '노는법 오류',
              description: message,
              cancelLabel: '확인',
              isNested: true
            })
          } else {
            if (!!error.error) {
              this.messageService.popup({
                title: '노는법 오류',
                description: error.error,
                cancelLabel: '확인',
                isNested: true
              })
            }
            else {
              this.messageService.popup({
                title: '노는법 오류',
                description: '알 수 없는 오류가 발생하였습니다. 문의 부탁드립니다.',
                cancelLabel: '확인',
                isNested: true
              })
            }
          }
        }
        // 로그인 실패
        if (error.status === 401) {
          if (!window.location.href.includes('/tabs/login')) {
            this.router.navigate(['/tabs/login'], { queryParams: { returnUrl: this.router.url } });
          }
        }
        // 권한 없음
        if (error.status === 403) {
          this.messageService.popup({
            title: '노는법 오류',
            description: '권한이 없습니다.',
            cancelLabel: '확인',
            isNested: true
          })
        }
        // 클라이언트 버전 업데이트
        if (error.status === 418) {
          if (this.isUpdated) {
            this.isUpdated = false;
            this.messageService.popup({
              title: '노는법 업데이트',
              description: '노는법 필수 업데이트를 위해 재시작됩니다.',
              cancelLabel: '확인',
              isNested: true
            })
            window.location.reload();
          }
        }
        // 사용자 에러
        if (error.status === 400 || error.status === 404) {
          if (error.error.type === 'NNB_ERROR_TYPE') {
            const message = error.error.reason ? `${error.error.message}(${error.error.reason})` : error.error.message
            this.messageService.popup({
              title: '오류',
              description: message,
              cancelLabel: '확인',
              isNested: true
            })
          }
        }
        return throwError(error);
      })
    )
  }
}