import { Component, Input } from '@angular/core';
import { Coupon } from 'src/app/model/coupon';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent {
  @Input() coupon: Coupon;
  @Input() isPossible = true;
}
