import { Component, Input, OnInit } from '@angular/core';
import { Payment } from 'src/app/model/payment';

@Component({
  selector: 'my-payment',
  templateUrl: './my-payment.component.html',
  styleUrls: ['./my-payment.component.scss'],
})
export class MyPaymentComponent implements OnInit {
  @Input() payment: Payment;
  @Input() lines = '';
  @Input() isDetailView = true;

  constructor(
  ) { }

  ngOnInit() {
  }
}
