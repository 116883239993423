import { Product } from 'src/app/model/product';
import { Coupon } from './coupon';
import { Feed, FeedComment } from './feed';
import { Location } from './location';

export class User {
    constructor(
        public id: number,
        public email: string,
        public password: string,
        public name: string,
        public phoneNumber: string,
        public provider: string,
        public thirdpartyId: string,
        public point: number,
        public earnPoint: number,
        public birthday: string,
        public ageRange: string,
        public nickname: string,
        public gender: Gender,
        public profilePhoto: string,
        public location: Location,
        public catchphrase: string,
        public introduction: string,
        public agreementTermsOfService: boolean,
        public agreementCollectPersonal: boolean,
        public agreementMarketing: boolean,
        public role: Role,
        public zipCode: string,
        public address: string,
        public detailAddress: string,
        public geoLocation: string,
        public userCode: string,
        public company: string,
        public createdAt: Date,
        public updatedAt: Date,
        public description?: string,
        public ci?: string,
        public di?: string,
        public sido?: string,
        public sigugun?: string,
        public type?: string,
        public menopauseLevel?: string,
        public utmSource?: string,
        public utmMedium?: string,
        public utmCampaign?: string,
        public utmTerm?: string,
        public utmContent?: string,
        public products?: Product[],
        public coupons?: Coupon[],
        public productLikes?: any[],
        public followingLikes?: any[],
        public followedLikes?: any[],
        public productReviews?: Comment[],
        public feeds?: Feed[],
        public hostInfo?: HostInfo,
        public userInfo?: UserInfo
    ) { }
}

export class NonMember {
    constructor(
        public id: number,
        public email: string,
        public name: string,
        public phoneNumber: string
    ) { }
}

export enum Role {
    ADMIN = 'admin',
    EDITOR = 'editor',
    USER = 'user',
}

export enum Gender {
    MALE = "남성",
    FEMALE = "여성"
}

export class UserSearchDto {
    constructor(
        public page: number,
        public limit: number,
        public searchTerm?: string,
        public roleList?: Role[],
    ) { }
}

export class UserCreateDto {
    constructor(
        public nickname: string,
        public email: string,
        public name: string,
        public password: string,
        public phoneNumber: string,
        public agreementTermsOfService: boolean,
        public agreementCollectPersonal: boolean,
        public agreementMarketing: boolean
    ) { }
}

export class NonMemberCreateDto {
    constructor(
        public email: string,
        public name: string,
        public phoneNumber: string
    ) { }
}

export interface UserUpdateDto {
    password?: string,
    name?: string,
    phoneNumber?: string,
    points?: number,
    birthday?: Date,
    ageRange?: string,
    nickname?: string,
    gender?: Gender,
    profilePhoto?: string,
    catchphrase?: string,
    introduction?: string,
    zipCode?: string,
    address?: string,
    detailAddress?: string,
    agreementMarketing?: boolean,
    geoLocation?: string,
    description?: string,
    sido?: string,
    sigugun?: string,
    type?: string,
    menopauseLevel?: string,
    userCode?: string,
    company?: string
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
    utmTerm?: string
    utmContent?: string,
    role?: Role
}

export class UserPointHistory {
    constructor(
        public id: number,
        public point: number,
        public reason: string,
        public earnPointType: EarnPointType,
        public createdAt: Date,
        public updatedAt: Date
    ) { }
}

export enum EarnPointType {
    QUIZ = "QUIZ",
    PAYMENT = "PAYMENT",
    FEED = "FEED",
    ETC = "ETC"
}

export class Following {
    constructor(
        public id: number,
        public followingId: User,
        public createdAt: Date,
        public updatedAt: Date
    ) { }
}

export class Followed {
    constructor(
        public id: number,
        public followedId: User,
        public createdAt: Date,
        public updatedAt: Date
    ) { }
}

export class UserSetting {
    constructor(
        public id: number,
        public unreadAlarm: boolean,
        public createdAt: Date,
        public updatedAt: Date
    ) { }
}

export interface HostInfoUpdateDto {
    bank?: string,
    businessType?: string,
    name?: string,
    bankNumber?: string,
    bankCopyFile?: string,
    idImage?: string,
    email?: string,
    registrationNumber?: string,
    byHost?: boolean,
    isUpdated?: boolean,
}

export class HostInfo {
    constructor(
        public user: User,
        public bank: string,
        public businessType: 'personal' | 'corperation',
        public name: string,
        public bankNumber: string,
        public bankCopyFile: string,
        public idImage: string,
        public email: string,
        public registrationNumber: string,
        public byHost: boolean,
        public isUpdated: boolean,
        public createdAt: string,
        public updatedAt: string,
    ) { }
}

export class UserInfo {
    constructor(
        public user: User,
        public purchasedCount: number,
        public purchasedPrice: number,
        public isLikeProductRead: boolean,
        public isAccompanyRead: boolean,
        public isBlogRead: boolean,
        public isEventRead: boolean,
        public isWellnessOpenRead: boolean,
        public createdAt: string,
        public updatedAt: string,
    ) { }
}

export class Block {
    constructor(
        public reporter: User,
        public user?: User,
        public feed?: Feed,
        public feedComment?: FeedComment,
    ) { }
}

export class HostInfoSearchDto {
    constructor(
        public page: number,
        public limit: number,
        public isUpdated?: boolean,
    ) { }
}

export interface UserInfoUpdateDto {
    isAllUserUpdate?: boolean;
    isLikeProductRead?: boolean;
    isAccompanyRead?: boolean;
    isBlogRead?: boolean;
    isEventRead?: boolean;
    isWellnessOpenRead?: boolean;
}

export interface Influencer {
    id: number;
    nickname: string;
    image: string;
    content: string;
    link: string;
}

export interface UserAddPointDto {
    id: number;
    point: number;
    reason: string;
}