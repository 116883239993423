import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { User } from 'src/app/model/user';
import { environment } from 'src/environments/environment';
import { BandPost } from '../model/band';
import { Blog } from './../model/blog';
import { Event } from './../model/event';
import { Product } from './../model/product';
import { Quiz } from './../model/quiz';
import { AuthService } from './../service/auth.service';

export enum TargetType {
  PRODUCT = 'PRODUCT',
  BANNER = 'BANNER',
  EVENT = 'EVENT',
  BLOG = 'BLOG',
  FEED = 'FEED',
  QUIZ = 'QUIZ',
  SEARCH = 'SEARCH',
  ETC = 'ETC'
}

export interface LogInfo {
  pageName: string;
  eventName: string;
  eventType: string;
  targetUrl: string;
  targetInfo: { type: TargetType, data?: any };
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  addLog(info: LogInfo) {
    this.authService.getCurrentUser().subscribe(user => {
      const ip = this.cookieService.get('nnb_client_ip');
      if (user) {
        if (!environment.adminUsers.find(u => u === user.id) && ip !== environment.adminIp) {
          this.postLog(user, ip, info);
        }
      } else {
        if (ip !== environment.adminIp) {
          this.postLog(user, ip, info);
        }
      }
    })
  }

  private postLog(user: User, ip: string, info: LogInfo) {
    const platform = this.deviceService.getDeviceInfo();
    const data = {
      userId: user ? user.id : 0,
      clientIp: ip,
      eventType: info.eventType,
      eventName: info.eventName,
      url: window.location.href,
      targetUrl: info.targetUrl,
      pageName: info.pageName,
      browser: platform.browser,
      browserVersion: platform.browser_version,
      device: platform.device,
      deviceType: platform.deviceType,
      orientation: platform.orientation,
      os: platform.os,
      osVersion: platform.os_version,
      userAgent: platform.userAgent,
      targetInfo: JSON.stringify(this.setTargetInfo(info.targetInfo))
    }
    this.http.post('/api/logger', data).subscribe(resp => {
      console.log('logged');
    })
  }

  setTargetInfo(targetInfo: any) {
    switch (targetInfo.type) {
      case TargetType.PRODUCT: {
        const product = targetInfo.data as Product;
        targetInfo = {
          id: product.id,
          title: product.title,
          price: {
            origin: product.price,
            discount: product.discountPrice
          }
        }
        break;
      }
      case TargetType.BANNER: {
        const banner = targetInfo.data;
        targetInfo = {
          name: banner.id
        }
        break;
      }
      case TargetType.EVENT: {
        const event = targetInfo.data as Event;
        targetInfo = {
          id: event.id,
          title: event.title,
          type: event.type
        }
        break;
      }
      case TargetType.BLOG: {
        const blog = targetInfo.data as Blog;
        targetInfo = {
          id: blog.id,
          title: blog.title,
          type: blog.type
        }
        break;
      }
      case TargetType.FEED: {
        const feed = targetInfo.data as BandPost;
        targetInfo = {
          id: feed.post_key,
          comment_count: feed.comment_count,
          emotion_count: feed.emotion_count,
        }
        break;
      }
      case TargetType.QUIZ: {
        if (targetInfo.data?.quiz) {
          const quiz = targetInfo.data.quiz as Quiz
          const userAnswer = targetInfo.data.userAnswer;
          targetInfo = {
            id: quiz.id,
            question: quiz.question,
            userAnswer
          }
        } else {
          const quiz = targetInfo.data as Quiz
          targetInfo = {
            id: quiz.id,
            question: quiz.question
          }
        }
        break;
      }
      case TargetType.SEARCH: {
        targetInfo = targetInfo.data;
        break;
      }
      case TargetType.ETC: {
        targetInfo = {}
        break;
      }
    }
    return targetInfo;
  }
}
