import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var Kakao;

@Component({
  selector: 'wellness-card',
  templateUrl: './wellness-card.component.html',
  styleUrls: ['./wellness-card.component.scss'],
})
export class WellnessCardComponent implements OnInit {
  @Input() title: string;
  @Input() backgroundColor = true;
  @Input() image?: string;
  @Input() subTitle?: string;
  @Input() description?: string;
  @Input() buttonLabel?: string;
  @Output() buttonClickEvent = new EventEmitter();

  constructor(
  ) {
  }

  ngOnInit() {

  }

  onClick() {
    this.buttonClickEvent.emit();
  }
}
